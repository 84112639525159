import React from "react";
import {
  Text,
  View,
  Image,
  Link,
} from "@react-pdf/renderer";
import * as _ from "lodash";
import { getRawMessage } from "components/Language";

function Feedback({feedback}) {
  return (
    <View
      style={{marginTop: 15, backgroundColor: "#f0f1f2"}}>
      {feedback.map((mFeed, i) => {
        return (
          <View
            style={{
              flexDirection: "row",
              marginRight: 22,
            }}
            key={i}
          >
            {i === 0 ? (
              <Image
                style={{
                  width: 15,
                  height: 15,
                  marginLeft: 5,
                  marginTop: 9,
                }}
                src="/images/ideap.png"
              />
            ) : (
              <Image
                style={{
                  width: 15,
                  height: 15,
                  marginLeft: 5,
                  marginTop: 9,
                }}
                src="/images/grey.png"
              />
            )}
            <View style={{marginRight: 25}}>
              {mFeed.frase?.includes("http") ? (
                <Link
                  style={{
                    color: "#383b41",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "justify",
                    fontFamily: "Roboto-Light",
                    textDecoration: "underline",
                  }}
                  src={mFeed.frase}
                >
                  {getRawMessage("announce.press_here")}
                </Link>
              ) : (
                <Text
                  break
                  style={{
                    padding: 10,
                    color: "#383b41",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "justify",
                    fontFamily: "Roboto-Light",
                  }}
                >
                  {mFeed.frase}
                </Text>
              )}
            </View>
          </View>
        );
      })}
    </View>
  );
}

export default Feedback;
