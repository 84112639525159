import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import { preguntasActions } from "actions/preguntasActions";
import NavBarSimple from "../../../NavBar/NavBarSimple";
import ShowClaveHeader from "../Components/ShowClaveHeader.js";
import PreguntaContestarSingle from "./PreguntaContestarSingle.js";
import FooterContestar from "../Components/FooterContestar.js";
import "../preguntas.css";
import Button from "@material-ui/core/Button";
import LateralBar from "../Components/LateralBar";
import * as _ from "lodash";
import { directorActions } from "actions/directorActions";
import { claveActions } from "actions/claveActions";
import {useSelector, useDispatch} from 'react-redux'
import { Modal } from "react-bootstrap";
import {history} from "helpers/history";
import {Language} from 'components/Language';

const PreguntasContestarContainer = ({location, numeroPregunta}) => {
  const dispatch = useDispatch();
  const [numClavesContestadas, setNumClavesContestadas] = useState(null);
  const clave = location.clave ?? 0;

  const {
    cambiaPregunta,
    cambioPreguntaDisponible,
    respuestasXPreguntas,
    isClaveContestada,
    claveList,
    clavesContestadas,
    respuestasNoObligatoriasClave,
    claveInfo,
  } = useSelector(state => state.claveReducer);

  const showPreguntaOpcional = useSelector(
    (state) => state.claveReducer.showOpcional
  );

  const listaPreguntas = useSelector(
    (state) => state.claveReducer.preguntasXClave
  );
  const listaPreguntasContestadas = useSelector(
    (state) => state.claveReducer.preguntasContestadasList
  );
  
  const [preguntaActual, setPreguntaActual] = useState(numeroPregunta ?? 0);
  const centro = JSON.parse(localStorage.getItem("xcuserv2")).centros_id;
  const [cambioPedido, setCambioPedido] = useState(false);

  const [modalInShowFinal, setModalideInShowFinal] = useState(false);
  const [isContested, setIsContested] = useState(false);
  
  const [opcionalState, setOpcionalState] = useState(0);
  const [loadedOpcionales, setLoadedOpcionales] = useState(false);

  useEffect(() => {
    dispatch(preguntasActions.showOpcional());
    if (clave === null) history.push("/auth/start");
    else {
      dispatch(preguntasActions.getClaveInfo({ id: claveList?.[clave].id }));
      dispatch(preguntasActions.getPreguntasXClave({id: claveList?.[clave].id}));
      dispatch(
        preguntasActions.getPreguntasContestadasClave({
          id: claveList?.[clave].id,
          idcentro: centro,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (listaPreguntas) {
      var idPreguntas = "";
      listaPreguntas.map((item) => {
        idPreguntas = idPreguntas + " " + item.id;
      });

      loadRespuestasXPreguntas(0);
    }
  }, [listaPreguntas]);

  useEffect(() => {
    if (isClaveContestada && isClaveContestada.completed){
      dispatch(claveActions.getClavesContestadas());
      dispatch(preguntasActions.showOpcional());
      dispatch(directorActions.checkResultados());

      dispatch(claveActions.getNoObligatoriasClave());
      setIsContested(true);
    }

    if (isClaveContestada && !isClaveContestada.completed){
      setIsContested(false);
      setModalideInShowFinal(true);
    }
  }, [isClaveContestada]);

  useEffect(() => {
    if (
      clavesContestadas &&
      respuestasNoObligatoriasClave &&
      showPreguntaOpcional
    ) {
      var tempClavesContestadasNum = 0;
      var tempclavesCompleted = new Array();
      var añadir = false;
      for (var x = 1; x <= 10; x++) {
        añadir = true;
        clavesContestadas.map((item) => {
          if (item.num == x) {
            añadir = false;
            tempclavesCompleted[x] = item;
          }
        });

        if (añadir) tempclavesCompleted[x] = { num: x };
      }
      var count = 0;
      clavesContestadas.map((item) => {
        count++;

        if (item.clavecompleted == 1) {
          tempClavesContestadasNum = tempClavesContestadasNum + 1;
          tempclavesCompleted.push(1);
        } else {
          var preguntasOpcionalesClave =
            respuestasNoObligatoriasClave[item.claveid];
          let counter = 0;
          var clavesTemp = _.keys(preguntasOpcionalesClave);

          clavesTemp.map((item2) => {
            if (
              preguntasOpcionalesClave[item2] === 0 &&
              showPreguntaOpcional[item2] === 0
            ) counter = counter + 1;
          });

          if (item.preguntascontestadas + counter == item.preguntastotal) {
            tempclavesCompleted[count].clavecompleted = 1;
            tempclavesCompleted.push(1);
            tempClavesContestadasNum = tempClavesContestadasNum + 1;
          } else {
            tempclavesCompleted.push(0);
          }
        }
      });

      if (isContested){
        setNumClavesContestadas(tempClavesContestadasNum);
        setModalideInShowFinal(true);
      }
    }
  }, [
    clavesContestadas,
    respuestasNoObligatoriasClave,
    showPreguntaOpcional
  ]);

  useEffect(() => {
    setOpcionalState(opcionalState + 1);
  }, [showPreguntaOpcional]);

  const setPregunta = (id) => {
    dispatch(preguntasActions.cambioPregunta());
    dispatch(preguntasActions.showOpcional());
    dispatch(
      preguntasActions.getPreguntasContestadasClave({
        id: claveList?.[clave].id,
        idcentro: centro,
      })
    );

    var counter = 0;

    listaPreguntas.map((item) => {
      if (item.id == id) {
        setPreguntaActual(counter);
        loadRespuestasXPreguntas(counter);
      }

      counter = counter + 1;
    });
  };

  const nextClave = () => {
    setModalideInShowFinal(false);

    dispatch(preguntasActions.restartClavePreguntas());
    dispatch(directorActions.saveHistorico(1, 2, "saved"));

    let mClave = 1;

    if (clave < 9) mClave = clave + 1;
    else mClave = 0
    
    history.push({
      pathname: "/auth/preguntas",
      clave: mClave ,
    });
  };

  const ruletaInicial = () => {
    setModalideInShowFinal(false);

    dispatch(preguntasActions.restartClavePreguntas());
    dispatch(directorActions.saveHistorico(1, 2, "saved"));

    history.push({pathname: "/auth/start"});
  };

  const loadRespuestasXPreguntas = (numPreg) => {
    if (listaPreguntas[numPreg] && listaPreguntas[numPreg].id)
      dispatch(preguntasActions.getRespuestasByPregunta({
        id: listaPreguntas[numPreg].id
      }));
    else setModalideInShowFinal(true);
  }

  useEffect(() => {
    if(cambioPreguntaDisponible == true){
      dispatch(preguntasActions.cambioPreguntaRealizado());
      nextPregunta();
    }
  }, [cambioPreguntaDisponible]);

  const nextPregunta = () => {
    setLoadedOpcionales(false);
    dispatch(preguntasActions.cambioPregunta());
    dispatch(preguntasActions.showOpcional());
    dispatch(
      preguntasActions.getPreguntasContestadasClave({
        id: claveList?.[clave].id,
        idcentro: centro,
      })
    );

    setCambioPedido(true);
  };

  const previousPregunta = () => {
    if (clave === 0 && preguntaActual === 0)
      history.push({
        pathname: "/auth/start",
        clave: clave + 1,
      });
    else {
      dispatch(preguntasActions.cambioPregunta());
      dispatch(preguntasActions.showOpcional());
      dispatch(
        preguntasActions.getPreguntasContestadasClave({
          id: claveList?.[clave].id,
          idcentro: centro,
        })
      );
      var anteriorPregunta = preguntaActual - 1;
      if (anteriorPregunta == -1) {
        dispatch(preguntasActions.restartClavePreguntas());

        history.push({
          pathname: "/auth/preguntas",
          clave: clave - 1,
        });
      } else {
        if (showPreguntaOpcional[listaPreguntas[anteriorPregunta].id] == 0) {
          if (
            showPreguntaOpcional[listaPreguntas[anteriorPregunta - 1].id] == 0
          ) {
            setPreguntaActual(preguntaActual - 3);
            loadRespuestasXPreguntas(preguntaActual - 3);
          } else {
            setPreguntaActual(preguntaActual - 2);
            loadRespuestasXPreguntas(preguntaActual - 2);

          }
        } else {
          setPreguntaActual(preguntaActual - 1);
          loadRespuestasXPreguntas(preguntaActual - 1);
        }
      }
    }
  };

  useEffect(() => {
    if (listaPreguntas && preguntaActual && listaPreguntas[preguntaActual]) {
      
      if (listaPreguntas[preguntaActual].opcional == 1) {
        //dispatch(preguntasActions.showOpcional({id: listaPreguntas[preguntaActual].id}));
      }
    }
  }, [preguntaActual]);

  useEffect(() => {
    if (showPreguntaOpcional) setLoadedOpcionales(true)
  }, [showPreguntaOpcional]);

  useEffect(() => {
    if (
      cambioPedido &&
      cambiaPregunta &&
      showPreguntaOpcional &&
      listaPreguntas &&
      listaPreguntasContestadas &&
      loadedOpcionales
    ) {
      if (
        preguntaActual + 1 == listaPreguntas.length || 
          (preguntaActual + 1 == listaPreguntas.length-1 &&
          showPreguntaOpcional[listaPreguntas[preguntaActual + 1].id] == 0)
      ) dispatch(claveActions.isContestada({id: claveInfo.id}));
      else {
        let siguientePregunta = preguntaActual + 3;
      
        if (showPreguntaOpcional[listaPreguntas[preguntaActual + 1].id] == 0) {
          if (
            showPreguntaOpcional[listaPreguntas[preguntaActual + 2].id] == 0
          ) siguientePregunta = preguntaActual + 3;
          else
            siguientePregunta = preguntaActual + 2;
        } else {
          siguientePregunta = preguntaActual + 1;
        }
        setPreguntaActual(siguientePregunta);
        loadRespuestasXPreguntas(siguientePregunta);
      }

      setCambioPedido(false);
    }
  }, [
    cambiaPregunta,
    listaPreguntasContestadas,
    listaPreguntas,
    showPreguntaOpcional,
    loadedOpcionales
  ]);

  return (
    <div className="dsfdsfsd">
      <NavBarSimple auth={true} />

      {modalInShowFinal && (
        <Modal
          show={modalInShowFinal }
          onHide={() => setModalideInShowFinal(false)}
        >
          <Modal.Body className="modal-footer-custom">
            <label><Language langKey="questionary.without_quest" /></label>
          </Modal.Body>
          <Modal.Footer>
            <Grid container>
            {numClavesContestadas < 9 ? (
                <Grid container>
                  <Grid item xs={6}>
                    <Button
                      className={"btnPreguntasModal btnCenter"}
                      onClick={(e) => ruletaInicial()}
                    >
                      <Language langKey="questionary.back_to_home" />
                    </Button>
                  </Grid>

                  <Grid item xs={6} align="right">
                    <Button
                      className={"btnPreguntasModal btnCenter"}
                      onClick={(e) => nextClave()}
                    >
                      <Language langKey="questionary.go_to_next" />
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12} align="center">
                  <Button
                    className={"btnPreguntasModal"}
                    onClick={(e) => ruletaInicial()}
                  >
                    <Language langKey="questionary.back_to_home" />
                  </Button>
                </Grid>
              )}
            </Grid>
          </Modal.Footer>
        </Modal>
      )}

      {claveInfo &&
      listaPreguntas &&
      centro &&
      showPreguntaOpcional &&
      respuestasXPreguntas &&
      listaPreguntasContestadas &&
      modalInShowFinal == false ? (
        <div>
          <ShowClaveHeader clave={claveInfo} />

          {showPreguntaOpcional && (
            <PreguntaContestarSingle
              listaPreguntasContestadas={listaPreguntasContestadas}
              listaPreguntas={listaPreguntas}
              slideIn={true}
              claveNum={claveInfo.num}
              pregunta={listaPreguntas[preguntaActual]}
              centro={centro}
              color={claveInfo.color}
              nextPregunta={nextPregunta}
              previousPregunta={previousPregunta}
              respuestasXPreguntas={respuestasXPreguntas}
              showPreguntaOpcional={showPreguntaOpcional}
              opcionalState={opcionalState}
            />
          )}

          {showPreguntaOpcional && (
            <LateralBar
              setPregunta={setPregunta}
              listaPreguntasContestadas={listaPreguntasContestadas}
              listaPreguntas={listaPreguntas}
              pregunta={listaPreguntas[preguntaActual]}
              color={claveInfo.color}
              showPreguntaOpcional={showPreguntaOpcional}
            />
          )}
          <FooterContestar
            clave={claveInfo.num}
            color={claveInfo.color}
            nextPregunta={nextPregunta}
            previousPregunta={previousPregunta}
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default PreguntasContestarContainer;
