import SvgIcon from "@material-ui/core/SvgIcon";
import React from "react";

export default function ArrowAzul(props) {
  return (
    <SvgIcon {...props}>
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#2B74C4"
        strokeLinecap="round"
        strokeWidth="2"
        d="M6 10L12 16 18 10"
      />
    </SvgIcon>
  );
}
