import actionTypes from "constants/actionTypes";
const initialState = {cargos:null};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CARGOS_LIST:
      return {
        ...state,
        cargos: action.payload.data,
      };
    default:
      return state;
  }
};
