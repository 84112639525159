import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { history } from "helpers/history";
import { v1 as uuidv1 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { preguntasActions } from "actions/preguntasActions";
import * as _ from "lodash";
import "../preguntas.css";
import CampoAbierto from "./Respuestas/CampoAbierto.js";
import RadioButton from "./Respuestas/RadioButton.js";
import EscalaNota from "./Respuestas/EscalaNota.js";
import CheckBox from "./Respuestas/CheckBox.js";
import TablaRadioButton from "./Respuestas/TablaRadioButton.js";
import { directorActions } from "actions/directorActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import TablaCheckBox from "./Respuestas/TablaCheckBox";
import { Modal } from "react-bootstrap";
import { claveActions } from "actions/claveActions";
import { Language } from "components/Language";

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: "rgba(94, 94, 94, 0.89)",
    borderRadius: "18px",
    fontSize: "14px !important",
    padding: "12px",
  },
  customArrow: {
    color: "rgba(94, 94, 94, 0.89",
  },
}));

function PreguntaContestarComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const apiDONE = useSelector((state) => state.loading.APIactions);
  const [modalInShowFinal, setModalideInShowFinal] = useState(false);

  const {
    respuestasNivelCentro,
    respuestasCentro,
    clavesContestadas,
    subpreguntasRespuestas,
    subPreguntas,
  } = useSelector(state => state.claveReducer); 

  const isPreguntaCompleted = useSelector(
    (state) => state.claveReducer.preguntaCompleted
  );

  const loading = useSelector((state) => state.loading.loading);
  const Niveles = useSelector((state) => state.directorReducer.getNiveles);

  const slideIn = props.slideIn;
  const [readySubpreguntas, setReadySubpreguntas] = useState(false);
  const [subRespuestasList, setSubRespuestasList] = useState(false);

  const [
    subpreguntasContestadasReady,
    setSubpreguntasContestadasReady,
  ] = useState(false);

  useEffect(() => {
    dispatch(claveActions.getClavesContestadas());
    dispatch(directorActions.getNiveles());
  }, [apiDONE]);

  useEffect(() => {
    if (props.pregunta) {
      dispatch(
        preguntasActions.isPreguntaCompleted({
          pregunta: props.pregunta.id,
          centro: props.centro,
        })
      );
      if (
        props.pregunta.tipospregunta_id == 6 ||
        props.pregunta.subtipospregunta_id != 4
      ) {
        dispatch(
          preguntasActions.getRespuestasNivelCentro({
            idPregunta: props.pregunta.id,
            idCentro: props.centro,
          })
        );
      } else {
        dispatch(
          preguntasActions.ObtenerRespuestasByPreguntaCentro({
            idPregunta: props.pregunta.id,
            idCentro: props.centro,
          })
        );
      }
    }
  }, [Niveles, clavesContestadas, props.respuestas, props.pregunta]);

  useEffect(() => {
    if (props.pregunta) {
      console.log(props);
      if ([4,7].includes(props.pregunta.tipospregunta_id))
        dispatch(
          preguntasActions.getSubpreguntas({idPregunta: props.pregunta.id})
        );
      else if (
        props.pregunta.tipospregunta_id == 6 &&
        [4,7].includes(props.pregunta.subtipospregunta_id)
      ) dispatch(
          preguntasActions.getSubpreguntasFilterNivel({
            idPregunta: props.pregunta.id,
            idCentro: props.centro,
          })
        );

      dispatch(
        preguntasActions.isPreguntaCompleted({
          pregunta: props.pregunta.id,
          centro: props.centro,
        })
      );

      dispatch(
        preguntasActions.ObtenerRespuestasByPreguntaCentro({
          idPregunta: props.pregunta.id,
          idCentro: props.centro,
        })
      );
    } else ruletaInicial();
  }, [props.pregunta]);

  useEffect(() => {
    let centro_pregunta_id;

    if (subPreguntas && isPreguntaCompleted) {
      isPreguntaCompleted.map((item) => {
        if (item.preguntas_id === props.pregunta.id)
          centro_pregunta_id = item.id;
      });

      let subRespuestasList = {};
      let idSubpreguntas = "";

      console.log(subPreguntas);

      subPreguntas.forEach((item) => {
        if (idSubpreguntas == "") idSubpreguntas = item.id;
        else idSubpreguntas = idSubpreguntas + "," + item.id;
      });

      if (isPreguntaCompleted !== null && centro_pregunta_id) {
        dispatch(
          preguntasActions.getsubpreguntaRespuestas({
            idSubpreguntas: idSubpreguntas,
            centroPreguntaId: centro_pregunta_id,
          })
        );
      } else setSubpreguntasContestadasReady(true);

      props.respuestas.map((item) => {
        if (!subRespuestasList[item.subpreguntas_id]) {
          subRespuestasList[item.subpreguntas_id] = new Array();
        }
        subRespuestasList[item.subpreguntas_id].push(item);
      });

      setSubRespuestasList(subRespuestasList);
      setReadySubpreguntas(true);
    }
  }, [props.respuestas, isPreguntaCompleted, subPreguntas]);

  useEffect(() => {
    if (subpreguntasRespuestas != null && subpreguntasRespuestas.length > 0) {
      setSubpreguntasContestadasReady(true);
    }
  }, [subpreguntasRespuestas]);

  const showTituloPregunta = (titulo, tooltip) => {
    if (tooltip !== "" && tooltip !== null) {
      var mWords = titulo.split(" ");
      var mRet = [];
      mWords.map((word) => {
        if (word.includes("*")) {
          mRet.push(
            <Tooltip
              key={uuidv1()}
              classes={{
                tooltip: classes.customTooltip,
                arrow: classes.customArrow,
              }}
              arrow
              placement="top"
              title={
                <span style={{ fontSize: "12px; !important;" }}>{tooltip}</span>
              }
              style={{ maxWidth: 600 }}
            >
              <span className="underlinetool">{word.replace("*", " ")}</span>
            </Tooltip>
          );
        } else {
          mRet.push(word + " ");
        }
      });
      return mRet;
    } else {
      return <span>{titulo}</span>;
    }
  };

  const nextClave = () => {
    setModalideInShowFinal(false);
    dispatch(preguntasActions.restartClavePreguntas());
    dispatch(directorActions.saveHistorico(1, 2, "saved"));

    let mClave = 1;
    if (props.claveNum < 9) {
      mClave = props.claveNum + 1;
    } else {
      mClave = 0;
    }
    history.push({
      pathname: "/auth/preguntas",
      clave: props.claveNum,
    });
  };

  const ruletaInicial = () => {
    setModalideInShowFinal(false);
    dispatch(preguntasActions.restartClavePreguntas());
    dispatch(directorActions.saveHistorico(1, 2, "saved"));

    history.push({
      pathname: "/auth/start",
    });
  };

  return (
    <div>
      {modalInShowFinal && (
        <Modal
          style={{ backgroundColor: "blue" }}
          show={modalInShowFinal}
          onHide={() => setModalideInShowFinal(false)}
        >
          <Modal.Body>
            <label><Language langKey="questionary.without_quest" /></label>
          </Modal.Body>
          <Modal.Footer>
            <Grid container>
              <Grid container>
                <Grid item xs={6}>
                  <Button
                    className={"btnPreguntasModal btnCenter"}
                    onClick={(e) => ruletaInicial()}
                  >
                    <Language langKey="questionary.back_to_home" />
                  </Button>
                </Grid>

                <Grid item xs={6} align="right">
                  <Button
                    className={"btnPreguntasModal btnCenter"}
                    onClick={(e) => nextClave()}
                  >
                    <Language langKey="questionary.go_to_next" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Modal.Footer>
        </Modal>
      )}

      { props.pregunta &&
        props.showPreguntaOpcional?.[props.pregunta.id] === 0 &&
        props.nextPregunta()}
      { props.pregunta &&
        props.showPreguntaOpcional?.[props.pregunta.id] !== 0 && (
          <Grid container>
            <Grid item xs={false} md={false} lg={2} xl={2}></Grid>

            <Grid
              item
              xs={12}
              md={12}
              lg={8}
              xl={8}
              className="GridContestarReponsive"
            >
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="gridTituloContestar"
                >
                  <Slide
                    direction="left"
                    in={slideIn && !loading}
                    mountOnEnter
                    unmountOnExit
                  >
                    <label className="tituloClaveContestar">
                      {props.claveNum}.{props.pregunta.num}:{" "}
                      {showTituloPregunta(
                        props.pregunta.titulo,
                        props.pregunta.tooltip
                      )}
                    </label>
                  </Slide>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                xl={12}
                className="gridSubtituloContestar"
              >
                <Grid container>
                  <Slide
                    direction="left"
                    in={slideIn && !loading}
                    mountOnEnter
                    unmountOnExit
                  >
                    <div 
                      className="subtituloContestar"
                      dangerouslySetInnerHTML={{__html: props.pregunta.subtitulo}}
                    ></div>
		    {/* <label className="subtituloContestar">
                      {props.pregunta.subtitulo}
                    </label>*/}
                  </Slide>
                </Grid>
              </Grid>
              <Slide
                direction="left"
                in={slideIn && !loading}
                mountOnEnter
                unmountOnExit
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="gridPreguntas"
                >
                  <Grid container>
                    {props.respuestas &&
                    respuestasCentro &&
                    props.pregunta.codigo == "OPEN" ? (
                      <CampoAbierto
                        isnivel={false}
                        key={uuidv1()}
                        isPreguntaCompleted={isPreguntaCompleted}
                        respuestasCentro={respuestasCentro}
                        respuestas={props.respuestas}
                        color={props.color}
                        nextPregunta={props.nextPregunta}
                        previousPregunta={props.previousPregunta}
                        centro={props.centro}
                      />
                    ) : (
                      <div></div>
                    )}
                    {props.respuestas &&
                    respuestasCentro &&
                    props.pregunta.codigo == "SIMPLE" ? (
                      <RadioButton
                        key={uuidv1()}
                        isPreguntaCompleted={isPreguntaCompleted}
                        respuestasCentro={respuestasCentro}
                        respuestas={props.respuestas}
                        color={props.color}
                        nextPregunta={props.nextPregunta}
                        previousPregunta={props.previousPregunta}
                        centro={props.centro}
                      />
                    ) : (
                      <div></div>
                    )}
                    {props.respuestas &&
                    respuestasCentro &&
                    props.pregunta.codigo == "RATING" ? (
                      <EscalaNota
                        key={uuidv1()}
                        isPreguntaCompleted={isPreguntaCompleted}
                        respuestasCentro={respuestasCentro}
                        respuestas={props.respuestas}
                        color={props.color}
                        nextPregunta={props.nextPregunta}
                        previousPregunta={props.previousPregunta}
                        centro={props.centro}
                      />
                    ) : (
                      <div></div>
                    )}
                    {props.respuestas &&
                    respuestasCentro &&
                    props.pregunta.codigo == "MULTIPLE" ? (
                      <CheckBox
                        isnivel={false}
                        key={uuidv1()}
                        isPreguntaCompleted={isPreguntaCompleted}
                        respuestasCentro={respuestasCentro}
                        respuestas={props.respuestas}
                        color={props.color}
                        nextPregunta={props.nextPregunta}
                        previousPregunta={props.previousPregunta}
                        centro={props.centro}
                        pregunta={props.pregunta}
                        claveContestada={
                          clavesContestadas.filter(
                            (c) => c.num === props.claveNum
                          )[0]
                        }
                      />
                    ) : (
                      <div></div>
                    )}
                    {props.respuestas &&
                    respuestasCentro &&
                    props.pregunta.codigo == "NIVEL" &&
                    respuestasNivelCentro &&
                    respuestasNivelCentro[0] &&
                    respuestasNivelCentro[0].codigo == "MULTIPLE" ? (
                      <CheckBox
                        key={uuidv1()}
                        isnivel={true}
                        isPreguntaCompleted={isPreguntaCompleted}
                        respuestasCentro={respuestasCentro}
                        respuestas={props.respuestas}
                        previousPregunta={props.previousPregunta}
                        color={props.color}
                        nextPregunta={props.nextPregunta}
                        centro={props.centro}
                        pregunta={props.pregunta}
                        claveContestada={
                          clavesContestadas.filter(
                            (c) => c.num === props.claveNum
                          )[0]
                        }
                      />
                    ) : (
                      <div></div>
                    )}
                    {props.respuestas &&
                      (props.pregunta.codigo == "MATRIX" ||
                        (props.pregunta.codigo == "NIVEL" &&
                          props.pregunta.subtipospregunta_id == "4")) &&
                      readySubpreguntas &&
                      subRespuestasList &&
                      subPreguntas &&
                      isPreguntaCompleted != null && (
                        <TablaRadioButton
                          key={uuidv1()}
                          subpreguntasRespuestas={
                            subpreguntasRespuestas
                              ? subpreguntasRespuestas
                              : null
                          }
                          subRespuestas={subRespuestasList}
                          subPreguntas={subPreguntas}
                          isPreguntaCompleted={isPreguntaCompleted}
                          respuestasCentro={respuestasCentro}
                          previousPregunta={props.previousPregunta}
                          respuestas={props.respuestas}
                          color={props.color}
                          nextPregunta={props.nextPregunta}
                          centro={props.centro}
                        />
                      )}
                    {props.respuestas &&
                      (props.pregunta.codigo == "MATRIXMULTIPLE" ||
                        (props.pregunta.codigo == "NIVEL" &&
                          props.pregunta.subtipospregunta_id == "7")) &&
                      readySubpreguntas &&
                      subRespuestasList &&
                      subPreguntas &&
                      isPreguntaCompleted != null && (
                        <TablaCheckBox
                          key={uuidv1()}
                          subpreguntasRespuestas={
                            subpreguntasRespuestas
                              ? subpreguntasRespuestas
                              : null
                          }
                          subRespuestas={subRespuestasList}
                          subPreguntas={subPreguntas}
                          isPreguntaCompleted={isPreguntaCompleted}
                          respuestasCentro={respuestasCentro}
                          respuestas={props.respuestas}
                          previousPregunta={props.previousPregunta}
                          color={props.color}
                          nextPregunta={props.nextPregunta}
                          centro={props.centro}
                        />
                      )}
                  </Grid>
                </Grid>
              </Slide>
            </Grid>

            <Grid item xs={false} md={false} lg={2} xl={2}></Grid>
          </Grid>
        )}
    </div>
  );
}

export default PreguntaContestarComponent;
