/* eslint-disable react/prop-types */
import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { useSelector, useDispatch } from "react-redux";
import FormControl from '@material-ui/core/FormControl';

function DateField(props) {
  const dispatch = useDispatch();
  
  var emailvalid = useSelector((state) => state.authReducer.emailvalid);
  var emailerror = useSelector((state) => state.authReducer.emailerror);
  const [nombre, setNombre] = React.useState("");
  const [apellidos, setApellidos] = React.useState("");
  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 370,
    },
    selectEmpty: {
      
    },
  }));
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>

          <Grid item xs={12}>
            <div className="formText">{props.titulo}</div>
            <FormControl variant="outlined" className={classes.formControl} style={{marginTop:"3px"}}>

            <KeyboardDatePicker
                  disableToolbar
                  variant="outlined"
                  inputVariant="outlined"
                  format="yyyy"
                  views={["year"]}
                  emptyLabel={props.placeholder}
                  id="date-picker-inline"
                  value={props.campo}
                  onChange={props.setCampo}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
        />
                      </FormControl>

            {props.error && (
              <div className="errorLayer">
                <i className="fa fa-exclamation-triangle marginerror"></i>
                {props.errorMessage}
              </div>
            )}
          </Grid>
          </MuiPickersUtilsProvider>

  );
}
export default (DateField);
