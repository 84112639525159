import React, { useState, useMemo } from "react";
import logoXcelence from "assets/img/logoXcelence.png";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { userActions } from "actions/userActions";
import { getRawMessage, Language } from 'components/Language';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguge } from "actions/settings.action";
import { AccountCircleOutlined, Language as LanguageIcon } from '@material-ui/icons';
import { Link } from "react-router-dom";
import { ResearcherDropdown } from './ResearcherDropdown';

const NavBarAuth = () => {
  const [dropLang, setDropLang] = useState(false);
  const [state, setState] = useState({
    expandedOne: false,
    expandedTwo: false,
    isExpanded: false,
    user: ''
  });

  const dispatch = useDispatch();
  const locale = useSelector((state) => state.setting.locale);

  useMemo(() => {
    setState({
      ...state,
      user: JSON.parse(localStorage?.getItem("xcuserv2")),
    })
  }, []);

  const { expandedOne, expandedTwo } = state;
  const perfil = state.user ? +state.user.perfil : -1;

  const showDropDownOne = (e) => {
    e.preventDefault();
    setState({
      ...state,
      expandedOne: true,
    });
  }

  const closeDropDownOne = (e) => {
    e.preventDefault();
    setState({
      ...state,
      expandedOne: false,
    });
  }

  const showDropDownTwo = (e) => {
    e.preventDefault();
    setState({
      ...state,
      expandedTwo: true,
    });
  }

  const closeDropDownTwo = (e) => {
    e.preventDefault();
    setState({
      ...state,
      expandedTwo: false,
    });
  }

  const logout = (e) => {
    e.preventDefault();

    dispatch(userActions.logout());
  }

  const handleChangeLocale = (lang = 'ES') => {
    localStorage.setItem('lang', lang);

    const reg = new RegExp('^/(es|ca)/');
    const url = new URL(window.location.href);
    url.pathname = url.pathname.replace(reg, `/${lang.toLowerCase()}/`);

    window.history.pushState({}, '', url);

    dispatch(changeLanguge(lang));
  }

  const getCurrentRoute = (path) => {
    let ret = false;
    const mRuta = window.location.pathname;

    if (mRuta === path) ret = true;

    return ret;
  }

  const getClassName = (path) => {
    let res = 'menu-item-navbar nav-link';
    const mRuta = window.location.pathname;

    if (mRuta === path || mRuta === `/${lang}${path}`) {
      res += ' ' + 'menu-item-navbar-selected';
    }

    return res;
  }

  const lang = locale.toLowerCase();

  return (
    <Navbar bg="light" expand="lg" className="menuPrincipal">
      <Link
        className="py-2 navbar-brand"
        to={{ pathname: perfil === -1 ? '/user/login' : '/auth/start' }}>
        <img height="45px" src={logoXcelence} />
      </Link>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="justify-content-end"
      >
        <Nav className="justify-content-end menuHori">
          {(perfil === 2 || perfil === 0) &&
            <ResearcherDropdown />
          }

          {(perfil === 1 || perfil === 3) && (
            <NavDropdown
              show={expandedOne}
              onMouseOver={(e) => showDropDownOne(e)}
              onMouseLeave={(e) => closeDropDownOne(e)}
              className={`menuonboard1 menu-item-navbar ${getCurrentRoute("/auth/start")
                  ? `menu-item-navbar-selected`
                  : ``
                }`}
              title={<Language langKey="menu" />}
              id="basic-nav-dropdown"
            >
              {(localStorage.getItem("xccompleto") !== "1" && (
                <Link
                  className="dropdown-item submenu-item-navbar"
                  to={{ pathname: "/auth/start" }}
                >
                  <Language langKey="todo_autoquiz" />

                  <i
                    className="fa fa-chevron-right posMenu"
                    aria-hidden="true"
                  />
                </Link>
              ))}

              <Link
                className="dropdown-item submenu-item-navbar"
                to={{ pathname: "/auth/resultado" }}
              >
                <Language langKey="view_results" />&nbsp;
                <i
                  className="fa fa-chevron-right posMenu"
                  aria-hidden="true"
                />
              </Link>

              <NavDropdown.Item
                href={`/${locale}/Cuestionario_Xcelence.pdf`}
                download
                className={`submenu-item-navbar`}
                target="_blank"
              >
                <Language langKey="download" />&nbsp;
                <i className="fa fa-download posMenu" aria-hidden="true" />
              </NavDropdown.Item>

              <NavDropdown.Item
                href="/use_manual.pdf"
                className="submenu-item-navbar"
                download
                target="_blank"
              >
                <Language langKey="guide" />
                <i
                  className="fa fa-download posMenu"
                  aria-hidden="true"
                />
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://www.youtube.com/watch?v=Tux4vFNWups"
                className={`submenu-item-navbar`}
                target="_blank"
              >
                <Language langKey="help" />
                <i
                  className="fa fa-chevron-right posMenu"
                  aria-hidden="true"
                />
              </NavDropdown.Item>

              <NavDropdown.Item
                href="/auth/historico"
                className={`submenu-item-navbar`}
              >
                <Language langKey="historical_answer" />&nbsp;
                <i
                  className="fa fa-chevron-right posMenu"
                  aria-hidden="true"
                />
              </NavDropdown.Item>
            </NavDropdown>
          )}

          {perfil !== -1 && (
            <NavDropdown
              show={expandedTwo}
              onMouseOver={(e) => showDropDownTwo(e)}
              onMouseLeave={(e) => closeDropDownTwo(e)}
              className={`menuonboard2 menu-item-navbar  dropdown-menu-right ${getCurrentRoute("/auth/centro")
                  ? `menu-item-navbar-selected`
                  : ``
                }`}
              title={getRawMessage('hello') + " " + state.user.nombre}
              id="basic-nav-dropdown"
            >
              {(perfil === 1 || perfil === 3) && (
                <Link
                  className="dropdown-item submenu-item-navbar"
                  to={{ pathname: "/auth/centro" }}
                >
                  <Language langKey="center_info" />
                  <i
                    className="fa fa-chevron-right posMenu"
                    aria-hidden="true"
                  />
                </Link>
              )}
              <Link
                className="dropdown-item submenu-item-navbar"
                to={{ pathname: "/auth/datos" }}
              >
                <Language langKey="user_info" />
                <i
                  className="fa fa-chevron-right posMenu"
                  aria-hidden="true"
                />
              </Link>
              {(perfil === 1) && (
                <Link
                  className="dropdown-item submenu-item-navbar"
                  to={{ pathname: "/auth/invita" }}
                >
                    <Language langKey="manage_collaborator" />
                    <i
                      className="fa fa-chevron-right posMenu"
                      aria-hidden="true"
                    />
                </Link>
              )}

              <NavDropdown.Item
                onClick={(e) => logout(e)}
                className={`submenu-item-navbar`}
              >
                <Language langKey="logout" />
                <i className="fa fa-power-off posMenu" aria-hidden="true" />
              </NavDropdown.Item>
            </NavDropdown>
          )}
          <NavDropdown
            show={dropLang}
            onMouseOver={() => setDropLang(true)}
            onMouseLeave={() => setDropLang(false)}
            className="menu-item-navbar lang-dropdown"
            title={<><LanguageIcon fontSize="small" /> {locale}</>}
          >
            <NavDropdown.Item
              onClick={handleChangeLocale.bind(null, 'ES')}
              className="submenu-item-navbar"
            >
              <Language langKey="lang.es" />
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={handleChangeLocale.bind(null, 'CA')}
              className="submenu-item-navbar"
            >
              <Language langKey="lang.ca" />
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBarAuth;
