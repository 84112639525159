import actionTypes from "constants/actionTypes";

const initialState = { counter: 0 };

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INCREMENT_COUNTER:
      return {
        ...state,
        counter: state.counter + 1
      };
    case actionTypes.DECREMENT_COUNTER:
      return {
        ...state,
        counter: state.counter - 1
      };
    case actionTypes.SET_COUNTER:
      return {
        ...state,
        counter: action.payload
      };
    default:
      return state;
  }
};
