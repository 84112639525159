import React from "react";
import { Snackbar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import MuiAlert from '@material-ui/lab/Alert';
import { snackbarSetting } from "actions/settings.action";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const FixedSackbar = () => {
  const snackbar = useSelector(state => state.loading.snackbar);  
  const dispatch = useDispatch();

  const handleCloseSnackbar = () => {
    dispatch(snackbarSetting(null));
  }

  return !!snackbar &&
    <Snackbar
      open={true}
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      autoHideDuration={3000}
      onClose={handleCloseSnackbar}
    >
      <Alert
        onClose={handleCloseSnackbar}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
}
