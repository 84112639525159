import React, { useEffect, useRef } from "react";
import * as _ from "lodash";

import Chart from 'chart.js/auto';

export default function BarChart({projectKey, onComplete, config = [], abandono}) {
  const chartRef = useRef(null);
  const colors = ["#95d4da", "#63b1bb", "#3d858f"];

  const findLabel = (value) => {
    let resp = '';

    let esta = config.filter((v) =>
      abandono ? v.arribaA === value : v.arribaR === value
    );

    if (esta && esta.length > 0)
      resp = abandono ? esta[0].nAbandono : esta[0].nRepes;

    return resp;
  };

  useEffect(() => {
    let mLabels = [];
    let mDatasets = [];
    const data = [];

    config.forEach((item, i) => {
      mLabels.push(item.curso);
      data.push(abandono ? item.arribaA : item.arribaR)
    });

    mDatasets.push({
      lineTension: 0,
      backgroundColor: colors[0],
      borderColor: "transparent",
      borderWidth: 1,
      data,
    });

    const onCompleteAnimation = () => {
      const chartToBase64 = chartRef?.current?.toBase64Image();

      onComplete(chartToBase64);

      chartRef.current.destroy();
    };

    const ctx = document.getElementById(projectKey);

    const configuration = {
      type: "bar",
      options: {
        legend: {position: "bottom"},
        animation: {
          onComplete: onCompleteAnimation,
        },
        scales: {
          x: {
            grid: {display: false},
            ticks: {
              autoSkip: true,
              fontStyle: "bold",
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
          y: {
            grid: {display: false},
            ticks: {
              callback: function(value) {
                let mLabel = findLabel(value);
                return mLabel;
            },
            precision: 0,
             fontStyle: "bold",
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
        },
        plugins: {
          title: {
            display: false,
            text: abandono ? "Abandono escolar" : "Repetición del curso",
            font: {
              size: 8,
            },
          },
          datalabels: {
            anchor: "end",
            align: "top",
            formatter: Math.round,
            font: {
              weight: "bold",
            },
          },
          legend: {
            display: false,
          },
        },
      },
      data: {
        labels: mLabels,
        datasets: mDatasets,
      },
    };

    chartRef.current = new Chart(ctx, configuration);

    return () => {
      chartRef.current.destroy();
    };
  }, [onComplete, projectKey]);

  const width = 870 + (config.length * 30); 

  return (
    <canvas
      id={projectKey}
      width={width}
      height={width/2}
      style={{
        display: 'none',
        position: 'fixed',
        maxWidth: width,
        maxHeight: width /2,
        minWidth: width,
        minHeight: width/2,
        overflow: 'hidden',
        top: 0,
      }}
    />
  );
}
