import actionTypes from "constants/actionTypes";

export const centroActions = {
  searchCentro,
  getCursos,
  getMeasureRanges,
  searchCentroName,
};
function getMeasureRanges() {
  return {
    type: actionTypes.GET_MEASURE_RANGES,
  };
}

function searchCentroName(name) {
  var payload = { name };
  return {
    type: actionTypes.SEARCHCENTRO_NAME,
    payload: payload,
  };
}

function searchCentro(codigo) {
  return {
    type: actionTypes.SEARCHCENTRO,
    codigo: codigo,
  };
}

function getCursos() {
  return {
    type: actionTypes.GET_CURSOS,
  };
}
