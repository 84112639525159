import React, { useState, useEffect } from "react";
import NavBarSimple from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "actions/userActions";
import { getRawMessage, Language } from "components/Language.js";
import { useForceUpdate } from "utils/custon-hooks.js";

function Reset(props) {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [repeat, setRepeat] = useState("");
  const [valid, setValid] = useState(false);
  const [error, setError] = useState(false);
  const [errorRepeat, setErrorRepeat] = useState(false);
  const [errorCode, setErrorCode] = useState(0);
  const [infoMessage, setInfoMessage] = useState(false);
  const forceUpdate = useForceUpdate();

  var resetUser = useSelector((state) => state.authReducer.reset);
  var resetError = useSelector((state) => state.authReducer.resetError);
  var mLoading = useSelector((state) => state.loading.loading);
  const locale = useSelector((state) => state.setting.locale);

  useEffect(() => {
    forceUpdate();
  }, [locale]);

  const setChangeUsername = (event) => {
    setUsername(event.target.value);
  };
  const setChangeRepeat = (event) => {
    setRepeat(event.target.value);
  };
  const setChangePassword = (event) => {
    setPassword(event.target.value);
  };
  const validEmail = function(mEmail) {
    var isValid = true;
    var EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!mEmail.match(EMAIL_REGEX)) {
      isValid = false;
    }
    return isValid;
  };
  const check = () => {
    var isValid = true;
    if (password.length > 7) {
      if (password !== repeat) {
        setErrorRepeat(true);
      } else {
        setErrorRepeat(false);
        isValid = true;
      }
    } else {
      isValid = false;
    }
    setValid(isValid);
  };

  const saveForm = () => {
    setValid(true);
    if (password !== repeat) setErrorRepeat(true);
    else {
      if (validEmail(username)) {
        setErrorRepeat(false);
        setValid(false);
        dispatch(
          userActions.resetPassword(
            username,
            password,
            props.match.params.token
          )
        );
      } else {
        setErrorCode(400);
        setError(true);
      }
    }
  };

  useEffect(() => {
    if (resetError) {
      setError(true);
      setErrorCode(resetError);
    } else {
      if (resetUser) {
        setInfoMessage(true);
      } else {
        check();
        setError(false);
      }
    }
  }, [mLoading, resetError, resetUser, password, repeat]);

  return (
    <div className="fullPage">
      <NavBarSimple />
      <div className="defaultFull">
        <Grid container spacing={3} justifyContent="center" className="defaultgrid">
          <Grid item xs={12}>
            <div className="h1home">
              <Language langKey="change_pwd" />
            </div>
            <div className="txtHome marginSubTitle">
              <Language langKey="info_recover" />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="formText">
              <Language langKey="user" /> *
            </div>

            <TextField
              onChange={(e) => setChangeUsername(e)}
              id="username"
              name="username"
              error={error}
              className="inputMargin widthF"
              variant="outlined"
              required
              placeholder={getRawMessage("user_placeholder")}
            />
            {errorCode !== 0 && (
              <div className="errorLayer">
                <i className="fa fa-exclamation-triangle marginerror"></i>
                <Language langKey={"recover_error_" + errorCode} />
              </div>
            )}
          </Grid>

          <Grid item xs={12}>
            <div className="formText">
              <Language langKey="pwd" /> *
            </div>

            <TextField
              onChange={(e) => setChangePassword(e)}
              id="password"
              name="password"
              type="password"
              defaultValue={password}
              className="inputMargin widthF passwordField"
              variant="outlined"
              required
              placeholder={getRawMessage("pwd_placeholder")}
            />
          </Grid>
          <Grid item xs={12}>
            <div className="formText">
              <Language langKey="repeat_pwd" /> *
            </div>
            <TextField
              onChange={(e) => setChangeRepeat(e)}
              className="inputMargin widthF passwordField"
              defaultChecked={repeat}
              error={errorRepeat}
              type="password"
              variant="outlined"
              required
              id="passwordRepeat"
              placeholder={getRawMessage("repeat_pwd")}
            />
            {errorRepeat && (
              <div className="errorLayer">
                <i className="fa fa-exclamation-triangle marginerror"></i>
                <Language langKey="pwd_not_match" /> *
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={() => saveForm()}
              variant="contained"
              disabled={valid && !mLoading ? false : true}
              className={`registerWidth ${
                valid && !mLoading ? "btnHome" : "btnDisabled"
              }`}
            >
              <Language langKey="change_pwd" />
            </Button>
            {mLoading && (
              <CircularProgress size={24} className="centerLoading" />
            )}

            {infoMessage && (
              <div className="infoLayer">
                <i className="fa fa-info marginerror"></i>
                <Language langKey="success_recover" />&nbsp;
                <a href="/user/login" className="maindarkLink">
                  <span style={{ fontSize: "1.2em !important" }}>
                    <Language langKey="here" />
                  </span>
                </a>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default Reset;
