/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import NavBar from "../NavBar/NavBar.js";
import NavBarAuth from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { contenidoActions } from "actions/contenidoActions";
import Posts from "./Posts.js";
import CustomPagination from "./CustomPagination.js";
import { claveActions } from "actions/claveActions";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ArrowAzul from "assets/css/ArrowAzul.js";
import Button from "@material-ui/core/Button";
import * as _ from "lodash";
function Practicas(props) {
  const { t, i18n } = props;
  const dispatch = useDispatch();
  const history = useHistory();


  history.push("/user/start");

  var apiDONE = useSelector((state) => state.loading.APIactions);
  var claves = useSelector((state) => state.claveReducer.claveList);
  var getPracticas = useSelector(
    (state) => state.contenidoReducer.getPracticas
  );
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(4);

  var mLoading = useSelector((state) => state.loading.loading);
  const [claveSelected, setClaveSelected] = useState(-1);
  useEffect(() => {
    dispatch(claveActions.getClaves());
    dispatch(contenidoActions.getPracticas());
  }, []);

  useEffect(() => {
    // console.log(getPracticas);
    setPosts(getPracticas);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    var currentPosts;
    if (getPracticas && getPracticas.length > 0) {
      setTotal(getPracticas.length);
      setCurrentPosts(getPracticas.slice(indexOfFirstPost, indexOfLastPost));
    }
  }, [getPracticas]);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const resetFilter = () => {
    setClaveSelected(-1);
    setCurrentPage(1);
    const indexOfLastPost = 1 * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    setCurrentPosts(posts.slice(indexOfFirstPost, indexOfLastPost));
    setTotal(posts.length);
  };

  const applyFilter = () => {
    // console.log(posts);
    var mSalida = _.filter(posts, {
      claves_id: claveSelected,
    });
    setTotal(mSalida.length);
    const indexOfLastPost = 1 * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    setCurrentPosts(mSalida.slice(indexOfFirstPost, indexOfLastPost));
    // console.log(currentPosts);
  };

  return (
    <div>
      {localStorage.getItem("xcauth") &&
      localStorage.getItem("xcauth") === "true" ? (
        <NavBarAuth />
      ) : (
        <NavBar />
      )}
      <div
        style={{ overflow: "auto", marginBottom: "30px", marginTop: "25px" }}
      >
        <Grid container spacing={3} justifyContent="center" className="defaultgrid">
          <Grid item xs={12}>
            <div className="h1home" style={{ textTransform: "uppercase" }}>
              {t("menuitems.practicas")}
            </div>

            <div
              className="txtHome marginSubTitle"
              dangerouslySetInnerHTML={{
                __html: t("contenido.practicasinfo", {
                  interpolation: { escapeValue: false },
                }),
              }}
            />

            <div
              style={{ marginTop: "43px" }}
              className="menuSeparatorFull"
            ></div>
            <Grid container spacing={3} style={{ paddingLeft: "40px" }}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                {claves ? (
                  <FormControl variant="outlined">
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      className="inputMargin"
                      style={{ maxWidth: 350 }}
                      IconComponent={ArrowAzul}
                      id="demo-simple-select-outlined"
                      value={claveSelected}
                      onChange={(e) => setClaveSelected(e.target.value)}
                    >
                      <MenuItem value={-1}>
                        {t("contenido.todasclaves")}
                      </MenuItem>
                      {claves.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.num} - {item.titulo}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                ) : (
                  <div></div>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className="rowFilter">
                  <div className="columnFilter" style={{ paddingTop: "15px" }}>
                    <a
                      className="mainLink"
                      href="#"
                      onClick={resetFilter}
                      style={{ cursor: "pointer" }}
                    >
                      {t("contenido.resetFiltros")}
                    </a>
                  </div>
                  <div className="columnFilter">
                    <Button
                      onClick={applyFilter}
                      className="btnHome btnFilter"
                      style={{ cursor: "pointer" }}
                    >
                      {t("contenido.aplicafiltros")}
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div
              style={{ marginTop: "13px" }}
              className="menuSeparatorFull"
            ></div>
          </Grid>
          <Grid item xs={12}>
            {getPracticas && getPracticas.length > 0 && (
              <div>
                <Posts
                  props={props}
                  getPracticas={currentPosts}
                  loading={mLoading}
                />
                <CustomPagination
                  currentPage={currentPage}
                  postsPerPage={postsPerPage}
                  totalPosts={total}
                  paginate={paginate}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default withTranslation()(Practicas);
