export default {
  INCREMENT_COUNTER: "INCREMENT_COUNTER",
  DECREMENT_COUNTER: "DECREMENT_COUNTER",

  INCREMENT_COUNTER_DOUBLE: "INCREMENT_COUNTER_DOUBLE",
  DECREMENT_COUNTER_DOUBLE: "DECREMENT_COUNTER_DOUBLE",

  API_ERRORED: "API_ERRORED",

  SET_COUNTER: "SET_COUNTER",
  START_REQUEST: "START_REQUEST",
  END_REQUEST: "END_REQUEST",
  LOGIN_USER: "LOGIN_USER",

  LOGOUT: "LOGOUT",
  LOGIN_DATA: "LOGIN_DATA",
  LOGIN_ERROR: "LOGIN_ERROR",

  REMOVEUSER: "REMOVEUSER",
  FORGOTPASSWORD: "FORGOTPASSWORD",

  FORGOTPASSWORD_DATA: "FORGOTPASSWORD_DATA",
  FORGOTPASSWORD_ERROR: "FORGOTPASSWORD_ERROR",
  RESETPASSWORD: "RESETPASSWORD",

  RESETPASSWORD_DATA: "RESETPASSWORD_DATA",
  RESETPASSWORD_ERROR: "RESETPASSWORD_ERROR",

  FINDDNI: "FINDDNI",
  FINDDNI_DATA: "FINDDNI_DATA",
  FINDDNI_ERROR: "FINDDNI_ERROR",

  FINDEMAIL: "FINDEMAIL",
  FINDEMAIL_DATA: "FINDEMAIL_DATA",
  FINDEMAIL_ERROR: "FINDEMAIL_ERROR",

  FINDEMAILDIRECTOR: "FINDEMAILDIRECTOR",
  FINDEMAILDIRECTOR_DATA: "FINDEMAILDIRECTOR_DATA",
  FINDEMAILDIRECTOR_ERROR: "FINDEMAILDIRECTOR_ERROR",

  ALTAINVESTIGADOR: "ALTAINVESTIGADOR",
  ALTAINVESTIGADOR_DATA: "ALTAINVESTIGADOR_DATA",
  ALTAINVESTIGADOR_ERROR: "ALTAINVESTIGADOR_ERROR",

  ALTAOTROS: "ALTAOTROS",
  ALTAOTROS_DATA: "ALTAOTROS_DATA",
  ALTAOTROS_ERROR: "ALTAOTROS_ERROR",
  ALTAOTROS_CLEAR: "ALTAOTROS_CLEAR",

  ALTADIRECTOR: "ALTADIRECTOR",
  ALTADIRECTOR_DATA: "ALTADIRECTOR_DATA",
  ALTADIRECTOR_ERROR: "ALTADIRECTOR_ERROR",

  CHANGEPASSWORD: "CHANGEPASSWORD",
  CHANGEPASSWORD_DATA: "CHANGEPASSWORD_DATA",
  CHANGEPASSWORD_ERROR: "CHANGEPASSWORD_ERROR",
  FIRSTPASSWORD: "FIRSTPASSWORD",
  FIRSTPASSWORD_DATA: "FIRSTPASSWORD_DATA",
  FIRSTPASSWORD_ERROR: "FIRSTPASSWORD_ERROR",

  SEARCHCENTRO: "SEARCHCENTRO",
  SEARCHCENTRO_DATA: "SEARCHCENTRO_DATA",
  SEARCHCENTRO_ERROR: "SEARCHCENTRO_ERROR",

  SEARCHCENTRO_NAME: "SEARCHCENTRO_NAME",
  SEARCHCENTRO_NAME_DATA: "SEARCHCENTRO_NAME_DATA",
  SEARCHCENTRO_NAME_ERROR: "SEARCHCENTRO_NAME_ERROR",

  END_REGISTER: "END_REGISTER",
  END_TRANSACTION: "END_TRANSACTION",

  CONTENIDOSHOME: "CONTENIDOSHOME",
  CONTENIDOSHOME_DATA: "CONTENIDOSHOME_DATA",
  CONTENIDOSHOME_ERROR: "CONTENIDOSHOME_ERROR",

  CLAVES: "CLAVES",
  CLAVES_DATA: "CLAVES_DATA",
  CLAVES_ERROR: "CLAVES_ERROR",

  GETCOLABORADORES: "GETCOLABORADORES",
  GETCOLABORADORES_DATA: "GETCOLABORADORES_DATA",
  GETCOLABORADORES_ERROR: "GETCOLABORADORES_ERROR",

  ADDCOLABORADOR: "ADDCOLABORADOR",
  ADDCOLABORADOR_DATA: "ADDCOLABORADOR_DATA",
  ADDCOLABORADOR_ERROR: "ADDCOLABORADOR_ERROR",
  GET_CARGOS: "GET_CARGOS",
  SET_CARGOS_LIST: "SET_CARGOS_LIST",
  CREATE_SUSCRIPTION_NEWSLETTER: "CREATE_SUSCRIPTION_NEWSLETTER",
  GET_CLAVE_INFO: "GET_CLAVE_INFO",
  SET_CLAVE_INFO: "SET_CLAVE_INFO",
  GET_PREGUNTAS_CLAVE: "GET_PREGUNTAS_CLAVE",
  SET_PREGUNTAS_CLAVE: "SET_PREGUNTAS_CLAVE",
  GET_PREGUNTAS_CONTESTADAS: "GET_PREGUNTAS_CONTESTADAS",
  GET_RESPUESTAS_BY_PREGUNTA: "GET_RESPUESTAS_BY_PREGUNTA",
  SET_RESPUESTAS_BY_PREGUNTA: "SET_RESPUESTAS_BY_PREGUNTA",

  DELCOLABORADOR: "DELCOLABORADOR",
  DELCOLABORADOR_ERROR: "DELCOLABORADOR_ERROR",
  DELCOLABORADOR_DATA: "DELCOLABORADOR_DATA",

  SENDCOLABORADOR: "SENDCOLABORADOR",
  SENDCOLABORADOR_ERROR: "SENDCOLABORADOR_ERROR",
  SENDCOLABORADOR_DATA: "SENDCOLABORADOR_DATA",

  GETHISTORICO: "GETHISTORICO",
  GETHISTORICO_ERROR: "GETHISTORICO_ERROR",
  GETHISTORICO_DATA: "GETHISTORICO_DATA",
  GETCENTRO: "GETCENTRO",
  GETCENTRO_DATA: "GETCENTRO_DATA",
  GETCENTRO_ERROR: "GETCENTRO_ERROR",

  UPDATECENTRO: "UPDATECENTRO",
  UPDATECENTRO_DATA: "UPDATECENTRO_DATA",
  UPDATECENTRO_ERROR: "UPDATECENTRO_ERROR",

  GETNIVELESCENTRO: "GETNIVELESCENTRO",
  GETNIVELESCENTRO_DATA: "GETNIVELESCENTRO_DATA",
  GETNIVELESCENTRO_ERROR: "GETNIVELESCENTRO_ERROR",

  UPDATENIVELESCENTRO: "UPDATENIVELESCENTRO",
  UPDATENIVELESCENTRO_DATA: "UPDATENIVELESCENTRO_DATA",
  UPDATENIVELESCENTRO_ERROR: "UPDATENIVELESCENTRO_ERROR",

  UPDATE_C_CURSOS: "UPDATE_C_CURSOS",
  UPDATE_C_CURSOS_DATA: "UPDATE_C_CURSO_DATAS",
  UPDATE_C_CURSOS_ERROR: "UPDATE_C_CURSOS_ERROR:",

  DELUSUARIO: "DELUSUARIO",
  DELUSUARIO_DATA: "DELUSUARIO_DATA",
  DELUSUARIO_ERROR: "DELUSUARIO_ERROR",

  UPDATEUSUARIO: "UPDATEUSUARIO",
  UPDATEUSUARIO_DATA: "UPDATEUSUARIO_DATA",
  UPDATEUSUARIO_ERROR: "UPDATEUSUARIO_ERROR",
  RESTART_PREGUNTAS_CLAVES: "RESTART_PREGUNTAS_CLAVES",
  GET_RESPUESTAS_PREGUNTAS_CENTRO: "GET_RESPUESTAS_PREGUNTAS_CENTRO",

  END_TRANSACTION_CENTRO: "END_TRANSACTION_CENTRO",
  GET_CURSOS: "GET_CURSOS",
  GET_CURSOS_DATA: "GET_CURSOS_DATA",
  GET_CURSOS_ERROR: "GET_CURSOS_ERROR",

  GET_NIVELES: "GET_NIVELES",
  GET_NIVELES_DATA: "GET_NIVELES_DATA",
  GET_NIVELES_ERROR: "GET_NIVELES_ERROR",
  GET_MEASURE_RANGES: "GET_MEASURE_RANGES",
  GET_MEASURE_RANGES_SUCCESS: "GET_MEASURE_RANGES_SUCCESS",
  GETRANGOS_ERROR: "GETRANGOS_ERROR",
  UPDATENIVELES: "UPDATENIVELES",
  UPDATENIVELES_DATA: "UPDATENIVELES_DATA",
  UPDATENIVELES_ERROR: "UPDATENIVELES_ERROR",
  SET_RESPUESTAS_PREGUNTAS_CENTRO: "SET_RESPUESTAS_PREGUNTAS_CENTRO",
  CAMBIO_PREGUNTA: "CAMBIO_PREGUNTA",
  SAVE_CAMPO_ABIERTO: "SAVE_CAMPO_ABIERTO",
  SAVE_CAMPO_ABIERTO_AND_PREGUNTA: "SAVE_CAMPO_ABIERTO_AND_PREGUNTA",
  IS_PREGUNTA_COMPLETED: "IS_PREGUNTA_COMPLETED",
  SET_PREGUNTA_COMPLETED: "SET_PREGUNTA_COMPLETED",
  SAVE_RADIO_BUTTON: "SAVE_RADIO_BUTTON",
  SAVE_RADIO_BUTTON_AND_PREGUNTA: "SAVE_RADIO_BUTTON_AND_PREGUNTA",
  GET_PREGUNTAS_CONTESTADAS_CLAVE: "GET_PREGUNTAS_CONTESTADAS_CLAVE",
  GETPRACTICAS: "GETPRACTICAS",
  GETPRACTICAS_DATA: "GETPRACTICAS_DATA",
  GETPRACTICAS_ERROR: "GETPRACTICAS_ERROR",
  GETPRACTICA: "GETPRACTICA",
  GETPRACTICA_DATA: "GETPRACTICA_DATA",
  GETPRACTICA_ERROR: "GETPRACTICA_ERROR",
  SET_PREGUNTAS_CONTESTADAS_LIST_CLAVE: "SET_PREGUNTAS_CONTESTADAS_LIST",
  GET_SUBPREGUNTAS: "GET_SUBPREGUNTAS",
  SET_SUBPREGUNTAS: "SET_SUBPREGUNTAS",
  GET_SUBPREGUNTAS_RESPUESTAS: "GET_SUBPREGUNTAS_RESPUESTAS",
  SET_SUBPREGUNTAS_RESPUESTAS: "SET_SUBPREGUNTAS_RESPUESTAS",
  SAVE_TABLE_RADIO_AND_PREGUNTA: "SAVE_TABLE_RADIO_AND_PREGUNTA",
  SAVE_TABLE_RADIO: "SAVE_TABLE_RADIO",
  SAVE_TABLE_CHECKBOX_AND_PREGUNTA: "SAVE_TABLE_CHECKBOX_AND_PREGUNTA",
  SAVE_TABLE_CHECKBOX: "SAVE_TABLE_CHECKBOX",
  GET_CLAVES_CONTESTADAS: "GET_CLAVES_CONTESTADAS",
  SET_CLAVES_CONTESTADAS: "SET_CLAVES_CONTESTADAS",
  SET_ISCLAVE_CONTESTADAS:"SET_ISCLAVE_CONTESTADAS",
  GET_ISCLAVE_CONTESTADAS:"GET_ISCLAVE_CONTESTADAS",
  GET_RESULTADOS_CLAVE_CENTRO: "GET_RESULTADOS_CLAVE_CENTRO",
  GET_RESULTADOS_CLAVE_CENTRO_SUCCESS: "GET_RESULTADOS_CLAVE_CENTRO_SUCCESS",
  GET_RESULTADOS_CLAVE_CENTRO_ERROR: "GET_RESULTADOS_CLAVE_CENTRO_ERROR",
  GET_RECURSOS: "GET_RECURSOS",
  GET_CURSOS_RECURSOS: "GET_CURSOS_RECURSOS",
  SET_CURSOS_RECURSOS: "SET_CURSOS_RECURSOS",
  GET_COMUNIDADES: "GET_COMUNIDADES",
  SET_COMUNIDADES: "SET_COMUNIDADES",
  GET_COMUNIDADES_RECURSOS: "GET_COMUNIDADES_RECURSOS",
  SET_COMUNIDADES_RECURSOS: "SET_COMUNIDADES_RECURSOS",

  SET_RECURSOS: "SET_RECURSOS",
  GET_RESULTADOS_PREGUNTAS_CLAVE_CENTRO:
    "GET_RESULTADOS_PREGUNTAS_CLAVE_CENTRO",
  GET_RESULTADOS_PREGUNTAS_CLAVE_CENTRO_SUCCESS:
    "GET_RESULTADOS_PREGUNTAS_CLAVE_CENTRO_SUCCESS",
  GET_RESULTADOS_PREGUNTAS_CLAVE_CENTRO_ERROR:
    "GET_RESULTADOS_PREGUNTAS_CLAVE_CENTRO_ERROR",
  SAVEHISTORICO: "SAVEHISTORICO",
  SAVEHISTORICO_SUCCESS: "SAVEHISTORICO_SUCCESS",
  SAVEHISTORICO_ERROR: "SAVEHISTORICO_ERROR",

  SEND_RESULTADOS: "SEND_RESULTADOS",
  SEND_RESULTADOS_SUCCESS: "SEND_RESULTADOS_SUCCESS",
  SEND_RESULTADOS_ERROR: "SEND_RESULTADOS_ERROR",

  CHECK_RESULTADOS: "CHECK_RESULTADOS",
  CHECK_RESULTADOS_SUCCESS: "CHECK_RESULTADOS_SUCCESS",
  CHECK_RESULTADOS_ERROR: "CHECK_RESULTADOS_ERROR",

  GETCENTROFULL: "GETCENTROFULL",
  GETCENTROFULL_DATA: "GETCENTROFULL_DATA",
  GETCENTROFULL_ERROR: "GETCENTROFULL_ERROR",

  GET_RESPUESTAS_NIVEL_CENTRO: "GET_RESPUESTAS_NIVEL_CENTRO",
  SET_RESPUESTAS_NIVEL_CENTRO: "SET_RESPUESTAS_NIVEL_CENTRO",
  GET_SUBPREGUNTAS_FILTERED_NIVELES: "GET_SUBPREGUNTAS_FILTERED_NIVELES",
  GETFULLRESULTADOS: "GETFULLRESULTADOS",
  GETFULLRESULTADOS_DATA: "GETFULLRESULTADOS_DATA",
  GETFULLRESULTADOS_ERROR: "GETFULLRESULTADOS_ERROR",
  GETFILTROS: "GETFILTROS",
  GETFILTROS_DATA: "GETFILTROS_DATA",
  GETFILTROS_ERROR: "GETFILTROS_ERROR",
  CLEAN_EMAIL_VERIFICATION: "CLEAN_EMAIL_VERIFICATION",
  ACTIVATE_COLABORADOR: "ACTIVATE_COLABORADOR",
  SHOW_OPCIONAL: "SHOW_OPCIONAL",
  SET_SHOW_OPCIONAL: "SET_SHOW_OPCIONAL",
  RESET_OPCIONAL: "RESET_OPCIONAL",
  GET_NO_OBLIGATORIAS_CLAVE: "GET_NO_OBLIGATORIAS_CLAVE",
  SET_NO_OBLIGATORIAS_CLAVE: "SET_NO_OBLIGATORIAS_CLAVE",

  GET_PREGUNTAS_DOWN: "GET_PREGUNTAS_DOWN",
  GET_PREGUNTAS_DOWN_DATA: "GET_PREGUNTAS_DOWN_DATA",
  GET_PREGUNTAS_DOWN_ERROR: "GET_PREGUNTAS_DOWN_ERROR",

  GETRESULTADOSGLOBAL: "GETRESULTADOSGLOBAL",
  GETRESULTADOSGLOBAL_SUCCESS: "GETRESULTADOSGLOBAL_SUCCESS",
  GETRESULTADOSGLOBAL_ERROR: "GETRESULTADOSGLOBAL_ERROR",

  GET_CUESTIONARIO: "GET_CUESTIONARIO",
  GET_CUESTIONARIO_SUCCESS: "GET_CUESTIONARIO_SUCCESS",
  GET_CUESTIONARIO_ERROR: "GET_CUESTIONARIO_ERROR",
  SAVE_RESULTADOS: "SAVE_RESULTADOS",
  SAVE_RESULTADOS_DATA: "SAVE_RESULTADOS_DATA",
  SAVE_RESULTADOS_ERROR: "SAVE_RESULTADOS_ERROR",
  GET_BBDD: "GET_BBDD",
  GET_BBDD_SUCCESS: "GET_BBDD_SUCCESS",
  GET_BBDD_ERROR: "GET_BBDD_ERROR",
  GET_TOOLKITS: "GET_TOOLKITS",
  SET_TOOLKITS_LIST: "SET_TOOLKITS_LIST",
  GETCENTROSHOME: "GETCENTROSHOME",
  GETCENTROSHOME_DATA: "GETCENTROSHOME_DATA",
  GETCENTROSHOME_ERROR: "GETCENTROSHOME_ERROR",
  BORRAR_CENTRO: "BORRAR_CENTRO",
  BORRAR_CENTRO_DATA: "BORRAR_CENTRO_DATA",
  BORRAR_CENTRO_ERROR: "BORRAR_CENTRO_ERROR",
  TRASPASA_USUARIO: "TRASPASA_USUARIO",
  TRASPASA_USUARIO_DATA: "TRASPASA_USUARIO_DATA",
  TRASPASA_USUARIO_ERROR: "TRASPASA_USUARIO_ERROR",
  CAMBIO_PREGUNTA_REALIZADO: "CAMBIO_PREGUNTA_REALIZADO",
  PREGUNTA_GUARDADA: "PREGUNTA_GUARDADA",
  AVISAR_DIRECTOR: "AVISAR_DIRECTOR",
  AVISAR_DIRECTOR_DATA: "AVISAR_DIRECTOR_DATA",
  AVISAR_DIRECTOR_ERROR: "AVISAR_DIRECTOR_ERROR",
  SET_PRECIOS_FILTER: "SET_PRECIOS_FILTER",
  SET_CLAVES_FILTER: "SET_CLAVES_FILTER",
  SET_CLAVES_FILTER_START: "SET_CLAVES_FILTER_START",
  SET_COMUNIDADES_FILTER: "SET_COMUNIDADES_FILTER",
  SET_COMUNIDADES_FILTER_START: "SET_COMUNIDADES_FILTER_START",
  SET_CURSOS_FILTER: "SET_CURSOS_FILTER",
  SET_CURSOS_FILTER_START: "SET_CURSOS_FILTER_START",

  RESET_FILTERS_READY: "RESET_FILTERS_READY",
  SET_CLAVES_PRECIOS_START: "SET_CLAVES_PRECIOS_START",
  FINDCENTROBYCODIGO: "FINDCENTROBYCODIGO",
  FINDCENTROBYCODIGO_DATA: "FINDCENTROBYCODIGO_DATA",
  FINDCENTROBYCODIGO_ERROR: "FINDCENTROBYCODIGO_ERROR",


  GET_RESULTADOS_CLAVE_CENTRO_PERIODO:"GET_RESULTADOS_CLAVE_CENTRO_PERIODO",
  GET_RESULTADOS_CLAVE_CENTRO_PERIODO_SUCCESS:"GET_RESULTADOS_CLAVE_CENTRO_PERIODO_SUCCESS",
  GET_RESULTADOS_CLAVE_CENTRO_PERIODO_ERROR:"GET_RESULTADOS_CLAVE_CENTRO_PERIODO_ERROR",



  GET_PERIODOS: "GET_PERIODOS",
  GET_PERIODOS_DATA: "GET_PERIODOS_DATA",
  GET_PERIODOS_ERROR: "GET_PERIODOS_ERROR",
  GETRESULTADOSGLOBALHIST:"GETRESULTADOSGLOBALHIST",
  GETRESULTADOSGLOBALHIST_SUCCESS:"GETRESULTADOSGLOBALHIST_SUCCESS",
  GETRESULTADOSGLOBALHIST_ERROR:"GETRESULTADOSGLOBALHIST_ERROR",

  GET_ANTERIORES:"GET_ANTERIORES",
  GET_ANTERIORES_DATA:"GET_ANTERIORES_DATA",
  GET_ANTERIORES_ERROR:"GET_ANTERIORES_ERROR",
  GET_RESULTADOS_CLAVE_CENTRO_PERIODO_HIST:"GET_RESULTADOS_CLAVE_CENTRO_PERIODO_HIST",
  GET_RESULTADOS_CLAVE_CENTRO_PERIODO_HIST_DATA:"GET_RESULTADOS_CLAVE_CENTRO_PERIODO_HIST_DATA",

  GET_RESULTADOS_CLAVE_CENTRO_PERIODO_HIST_ERROR:"GET_RESULTADOS_CLAVE_CENTRO_PERIODO_HIST_ERROR",
  GET_RESULTADOS_CLAVE_CENTRO_PERIODOCO:"GET_RESULTADOS_CLAVE_CENTRO_PERIODOCO",
  GET_RESULTADOS_CLAVE_CENTRO_PERIODOCO_SUCCESS:"GET_RESULTADOS_CLAVE_CENTRO_PERIODOCO_SUCCESS",
  GET_RESULTADOS_CLAVE_CENTRO_PERIODOCO_ERROR:"GET_RESULTADOS_CLAVE_CENTRO_PERIODOCO_ERROR",

  GET_LASTPERIODOS:"GET_LASTPERIODOS",
  GET_LASTPERIODOS_DATA:"GET_LASTPERIODOS_DATA",

  GET_LASTPERIODOS_ERROR:"GET_LASTPERIODOS_ERROR",


  GET_NIVELESANT: "GET_NIVELESANT",
  GET_NIVELESANT_DATA: "GET_NIVELESANT_DATA",
  GET_NIVELESANT_ERROR: "GET_NIVELESANT_ERROR",

  GET_PERSPECTIVAS:"GET_PERSPECTIVAS",
  GET_TIPOMATERIAL:"GET_TIPOMATERIAL",
  GET_TIPOMATERIAL_DATA:"GET_TIPOMATERIAL_DATA",
  GET_PERSPECTIVAS_DATA:"GET_PERSPECTIVAS_DATA",


  GET_RECURSO:"GET_RECURSO",
  GET_RECURSO_DATA:"GET_RECURSO_DATA",
  GET_RECURSO_ERROR:"GET_RECURSO_ERROR",

  GET_RECURSOS_REL:"GET_RECURSOS_REL",
  GET_RECURSOS_REL_DATA:"GET_RECURSOS_REL_DATA",
  GET_RECURSOS_REL_ERROR:"GET_RECURSOS_REL_ERROR",

  GET_PERIODOSALL_DATA:"GET_PERIODOSALL_DATA",
  GET_PERIODOSALL_ERROR:"GET_PERIODOSALL_ERROR",
  GET_PERIODOSALL:"GET_PERIODOSALL",
  GET_BDDALL:"GET_BDDALL",
  GET_BDDALL_DATA:"GET_BDDALL_DATA",
  GET_BDDALL_ERROR:"GET_BDDALL_ERROR",
  GET_DATOSGRAFICO:"GET_DATOSGRAFICO",
  GET_DATOSGRAFICO_DATA:"GET_DATOSGRAFICO_DATA",
  GET_DATOSGRAFICO_ERROR:"GET_DATOSGRAFICO_ERROR",

  PUNTUAR : "PUNTUAR",
  CREATE_BEST_PRACTICE : "CREATE_BEST_PRACTICE",
  SET_PUNTUAR : "SET_PUNTUAR"
};
