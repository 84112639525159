import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";
import { history } from "helpers/history";

function* getClaves() {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v3/claves/activas";
    const res = yield xcService.getToken(apiEndpoint);

    if (res.status === 200) {
      yield put({ type: actionTypes.CLAVES_DATA, payload: res.data });
    } else {
      yield put({
        type: actionTypes.CLAVES_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* getClaveInfo({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v3/clave/" + payload.id;
    const res = yield xcService.getToken(apiEndpoint);

    console.log('questions');

    if (res.status === 200) {
      yield put({ type: actionTypes.SET_CLAVE_INFO, payload: res.data });
    } else {
      yield put({
        type: actionTypes.CLAVES_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

export default function* watcherClave() {
  yield takeEvery(actionTypes.CLAVES, getClaves);
  yield takeEvery(actionTypes.GET_CLAVE_INFO, getClaveInfo);
  yield takeEvery(actionTypes.GET_PREGUNTAS_CLAVE, getPreguntasXClave);
  
  yield takeEvery(
    actionTypes.GET_PREGUNTAS_CONTESTADAS,
    getPreguntasContestadas
  );
  yield takeEvery(
    actionTypes.GET_RESPUESTAS_BY_PREGUNTA,
    getRespuestasByPregunta
  );
  yield takeEvery(
    actionTypes.GET_RESPUESTAS_PREGUNTAS_CENTRO,
    getRespuestasPreguntasCentro
  );
  yield takeEvery(actionTypes.SAVE_CAMPO_ABIERTO, saveCampoAbierto);
  yield takeEvery(
    actionTypes.SAVE_CAMPO_ABIERTO_AND_PREGUNTA,
    SaveCampoAbiertoAndPregunta
  );
  yield takeEvery(actionTypes.SAVE_RADIO_BUTTON, saveRadioButton);
  yield takeEvery(
    actionTypes.SAVE_RADIO_BUTTON_AND_PREGUNTA,
    saveRadioButtonAndPregunta
  );
  yield takeEvery(
    actionTypes.GET_PREGUNTAS_CONTESTADAS_CLAVE,
    getPreguntasContestadasClave
  );
  yield takeEvery(
    actionTypes.GET_SUBPREGUNTAS_RESPUESTAS,
    getSubpreguntasRespuestas
  );
  yield takeEvery(
    actionTypes.SAVE_TABLE_RADIO_AND_PREGUNTA,
    saveTableRadioAndPregunta
  );
  yield takeEvery(actionTypes.SAVE_TABLE_RADIO, saveTableRadio);
  yield takeEvery(actionTypes.SAVE_TABLE_CHECKBOX, saveTableCheckbox);
  yield takeEvery(actionTypes.SAVE_TABLE_CHECKBOX_AND_PREGUNTA, saveTableCheckboxAndPregunta);
  yield takeEvery(actionTypes.GET_CLAVES_CONTESTADAS, getClavesContestadas);
  yield takeEvery(actionTypes.IS_PREGUNTA_COMPLETED, getPreguntaCompleted);
  yield takeEvery(actionTypes.GET_SUBPREGUNTAS, getSubpreguntas);
  yield takeEvery(
    actionTypes.GET_SUBPREGUNTAS_FILTERED_NIVELES,
    getSubpreguntasFilteredNiveles,
  );

  yield takeEvery(actionTypes.GET_RESPUESTAS_NIVEL_CENTRO, getRespuestasNivelCentro);
  yield takeEvery(actionTypes.GET_NO_OBLIGATORIAS_CLAVE, getRespuestasNoObligatoriasClave);
  yield takeEvery(actionTypes.GET_ISCLAVE_CONTESTADAS, isCompletada);
  
  function* isCompletada({payload}) {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint = "/v3/iscompleta/" + payload.id;
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.SET_ISCLAVE_CONTESTADAS,
          payload: res.data,
        });

        yield put({
          type: actionTypes.SET_ISCLAVE_CONTESTADAS,
          payload: null,
        });
      } else {
        
        yield put({
          type: actionTypes.CLAVES_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }
  function* getClavesContestadas() {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint = "/v3/clavescompletadas";
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.SET_CLAVES_CONTESTADAS,
          payload: res.data,
        });
      } else {
        yield put({
          type: actionTypes.CLAVES_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getRespuestasNoObligatoriasClave() {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint = "/v3/respuestasnoobligatoriasclave";
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.SET_NO_OBLIGATORIAS_CLAVE,
          payload: res.data,
        });
      } else {
        
        yield put({
          type: actionTypes.CLAVES_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }
  
  function* getPreguntasXClave({ payload }) {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint = "/v3/preguntas/clave/" + payload.id;
      const res = yield call(xcService.getToken, apiEndpoint);

      if (res.status === 200)
        yield put({type: actionTypes.SET_PREGUNTAS_CLAVE, payload: res.data});
      else
        history.push("/auth/start");
    } catch (e) {
      yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getPreguntasContestadasClave({ payload }) {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint = "/v3/preguntasCompletedClave/" + payload.id;
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.SET_PREGUNTAS_CONTESTADAS_LIST_CLAVE,
          payload: res.data,
        });
      } else {
        yield put({
          type: actionTypes.CLAVES_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }
  
  function* getRespuestasByPregunta({ payload }) {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint = "/v3/respuestas/pregunta/" + payload.id;
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          
          type: actionTypes.SET_RESPUESTAS_BY_PREGUNTA,
          payload: res.data,
        });
      } else {
        
        yield put({
          type: actionTypes.CLAVES_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getRespuestasPreguntasCentro({ payload }) {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint =
        "/v3/preguntasContestadas/" +
        payload.idPregunta;
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.SET_RESPUESTAS_PREGUNTAS_CENTRO,
          payload: res.data,
        });
      } else {
        
        yield put({
          type: actionTypes.CLAVES_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getPreguntaCompleted({ payload }) {
    yield put({ type: actionTypes.START_REQUEST });
    try {
      let apiEndpoint =
        "/v3/preguntaCompleted/" + payload.pregunta + "/" + payload.centro;
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        if (res.data.length > 0) {
          yield put({
            type: actionTypes.SET_PREGUNTA_COMPLETED,
            payload: res.data,
          });
        } else {
          yield put({
            type: actionTypes.SET_PREGUNTA_COMPLETED,
            payload: res.data,
          });
        }
      } else {
        
        yield put({
          type: actionTypes.CLAVES_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getPreguntasContestadas({ payload }) {
    yield put({ type: actionTypes.START_REQUEST });
    try {
      let apiEndpoint =
        "/v3/preguntaCompleted/" + payload.pregunta + "/" + payload.centro;
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        if (res.data.length > 0) {
          yield put({
            type: actionTypes.SET_PREGUNTA_COMPLETED,
            payload: res.data,
          });
        } else {
          yield put({
            type: actionTypes.SET_PREGUNTA_COMPLETED,
            payload: null,
          });
        }
      } else {
        
        yield put({
          type: actionTypes.CLAVES_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* saveCampoAbierto({ payload }) {
    try {
      let apiEndpoint = "/v3/respuestasCentro/savecampoabierto";
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.postToken(apiEndpoint, payload).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({ type: "PREGUNTA_GUARDADA", payload: "" });
        yield put({ type: "API_DONE", payload: res.data });

      } 
      else {
        
        yield put({
          type: actionTypes.CLAVES_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
    }
  }

  function* SaveCampoAbiertoAndPregunta({ payload }) {
    
    try {
      let apiEndpoint = "/v3/respuestasCentro/savecampoabiertoAndPregunta";
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.postToken(apiEndpoint, payload).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({ type: "PREGUNTA_GUARDADA", payload: "" });
        yield put({ type: "API_DONE", payload: res.data });
      } else {
        
        yield put({
          type: actionTypes.CLAVES_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
    }
  }

  function* saveRadioButton({ payload }) {
    try {
      let apiEndpoint = "/v3/respuestasCentro/saveradiobutton";
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.postToken(apiEndpoint, payload).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({ type: "PREGUNTA_GUARDADA", payload: "" });

        yield put({ type: "API_DONE", payload: res.data });
      } else {
        
        yield put({
          type: actionTypes.CLAVES_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
    }
  }

  function* saveRadioButtonAndPregunta({ payload }) {
    try {
      let apiEndpoint = "/v3/respuestasCentro/saveRadioButtonAndPregunta";
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.postToken(apiEndpoint, payload).then((response) => {
              resolve(response);
            });
          })
      );

      if (res.status === 200) {        
        yield put({ type: "PREGUNTA_GUARDADA", payload: "" });
        yield put({ type: "API_DONE", payload: res.data });
      }
    } catch (e) {
      yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
    }
  }

  function* getSubpreguntas({ payload }) {
    yield put({ type: actionTypes.START_REQUEST });
    try {
      let apiEndpoint = "/v3/subpreguntas/" + payload.idPregunta;
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({ type: actionTypes.SET_SUBPREGUNTAS, payload: res.data });
      } else {
        
        yield put({
          type: actionTypes.CLAVES_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }
  
  function* getSubpreguntasFilteredNiveles({ payload }) {
    yield put({ type: actionTypes.START_REQUEST });
    try {
      let apiEndpoint = "/v3/subpreguntasfilteredniveles/" + payload.idPregunta+"/"+payload.idCentro;
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({ type: actionTypes.SET_SUBPREGUNTAS, payload: res.data });
      } else {
        
        yield put({
          type: actionTypes.CLAVES_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
    }

    yield put({ type: actionTypes.END_REQUEST });
  }
  
  function* getSubpreguntasRespuestas({ payload }) {
    yield put({ type: actionTypes.START_REQUEST });
    try {
      let apiEndpoint =
        "/v3/subpreguntasRespuestas/" +
        payload.idSubpreguntas +
        "/" +
        payload.centroPreguntaId;
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.SET_SUBPREGUNTAS_RESPUESTAS,
          payload: res.data,
        });
      } else {
        
        yield put({
          type: actionTypes.CLAVES_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
    }

    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getRespuestasNivelCentro({ payload }) {

    yield put({ type: actionTypes.START_REQUEST });
    try {
      let apiEndpoint =
        "/v3/respuestasNivelCentro/"+payload.idPregunta+"/"+payload.idCentro
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          
          type: actionTypes.SET_RESPUESTAS_NIVEL_CENTRO,
          payload: res.data,
        });
      } else {
        
        yield put({
          type: actionTypes.CLAVES_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* saveTableRadioAndPregunta({ payload }) {
    try {
      let apiEndpoint = "/v3/respuestasCentro/saveTableRadioAndPregunta";
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.postToken(apiEndpoint, payload).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({ type: "PREGUNTA_GUARDADA", payload: "" });

        yield put({ type: "API_DONE", payload: res.data });
      } else {
        
        yield put({
          type: actionTypes.CLAVES_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
    }
  }
  function* saveTableCheckboxAndPregunta({ payload }) {
    try {
      let apiEndpoint = "/v3/respuestasCentro/saveTableCheckboxAndPregunta";
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.postToken(apiEndpoint, payload).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({ type: "PREGUNTA_GUARDADA", payload: "" });

        yield put({ type: "API_DONE", payload: res.data });
      } else {
        
        yield put({
          type: actionTypes.CLAVES_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
    }
  }
  
function* saveTableRadio({ payload }) {
  try {
    let apiEndpoint = "/v3/respuestasCentro/saveTableRadio";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.postToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: "PREGUNTA_GUARDADA", payload: "" });

      yield put({ type: "API_DONE", payload: res.data });
    } else {
      
      yield put({
        type: actionTypes.CLAVES_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
  }
}

function* saveTableCheckbox({ payload }) {
  try {
    let apiEndpoint = "/v3/respuestasCentro/savetablecheckbox";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.postToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: "PREGUNTA_GUARDADA", payload: "" });

      yield put({ type: "API_DONE", payload: res.data });
    } else {
      
      yield put({
        type: actionTypes.CLAVES_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
  }
}
}
