import actionTypes from "constants/actionTypes";
const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FINDDNI_DATA:
      return {
        ...state,
        dni: action.payload,
      };
    case actionTypes.FINDDNI_ERROR:
      return {
        ...state,
        dnierror: action.payload,
      };
    case actionTypes.FINDEMAIL_DATA:
      return {
        ...state,
        emailvalid: action.payload,
      };
    case actionTypes.FINDEMAIL_ERROR:
      return {
        ...state,
        emailerror: action.payload,
      };

    case actionTypes.CLEAN_EMAIL_VERIFICATION:
      return {
        ...state,
        emailValid: undefined,
        emailError: undefined,
      };

    case actionTypes.FINDCENTROBYCODIGO_DATA:
      return {
        ...state,
        centroByCodigoError: null,
        centroByCodigoData: action.payload,
      };
    case actionTypes.FINDCENTROBYCODIGO_ERROR:
      return {
        ...state,
        centroByCodigoData: null,
        centroByCodigoError: action.payload,
      };
    case actionTypes.FINDEMAILDIRECTOR_DATA:
      return {
        ...state,
        emailDirectorvalid: action.payload,
      };
    case actionTypes.FINDEMAILDIRECTOR_ERROR:
      return {
        ...state,
        emailDirectorerror: action.payload,
      };

    case actionTypes.LOGIN_DATA:
      return {
        ...state,
        user: action.payload,
      };
    case actionTypes.LOGIN_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.FORGOTPASSWORD_DATA:
      return {
        ...state,
        forgot: action.payload,
      };
    case actionTypes.FORGOTPASSWORD_ERROR:
      return {
        ...state,
        forgotError: action.payload,
      };
    case actionTypes.RESETPASSWORD_DATA:
      return {
        ...state,
        reset: action.payload,
      };
    case actionTypes.RESETPASSWORD_ERROR:
      return {
        ...state,
        resetError: action.payload,
      };
    case actionTypes.ALTAINVESTIGADOR_DATA:
      return {
        ...state,
        altaInvestigadorSuccess: action.payload,
      };
    case actionTypes.ALTAINVESTIGADOR_ERROR:
      return {
        ...state,
        altaInvestigadorError: action.payload,
      };
    case actionTypes.ALTAOTROS_CLEAR:
      return {
        ...state,
        altaOtrosSuccess: null,
        altaOtrosError: null,
      };
    case actionTypes.ALTAOTROS_DATA:
      return {
        ...state,
        altaOtrosSuccess: action.payload,
      };
    case actionTypes.ALTAOTROS_ERROR:
      return {
        ...state,
        altaOtrosError: action.payload,
      };
    case actionTypes.ALTADIRECTOR:
      return {
        ...state,
        emailvalid: null,
        emailDirectorvalid: null,
      };
    case actionTypes.ALTADIRECTOR_DATA:
      return {
        altaDirectorSuccess: action.payload,
      };
    case actionTypes.ALTADIRECTOR_ERROR:
      return {
        altaDirectorError: action.payload,
      };
    case actionTypes.CHANGEPASSWORD_DATA:
      return {
        chgpwdSuccess: action.payload,
      };
    case actionTypes.CHANGEPASSWORD_ERROR:
      return {
        chgpwdError: action.payload,
      };
    case actionTypes.FIRSTPASSWORD_DATA:
      return {
        firstpwdSuccess: action.payload,
      };
    case actionTypes.FIRSTPASSWORD_ERROR:
      return {
        firstpwdError: action.payload,
      };
    case actionTypes.END_REGISTER:
      
      return {
        emailvalid: null,
        emailDirectorvalid: null,
        emailerror: null,
        emailDirectorerror: null,
        altaOtrosSuccess: null,
        altaOtrosError: null,
        altaInvestigadorError: null,
        altaInvestigadorSuccess: null,
        altaDirectorSuccess: null,
        altaDirectorError: null,
      };
      case "CLEAR_CONTACT_DATA":
        return {
          contactData: null,
          contactDataError: null
        };
    case "CONTACT_DATA":
      return {
        contactData: "datos enviados",
      };
    case "CONTACT_ERROR":
      return {
        contactDataError: action.payload,
      };
    default:
      return state;
  }
};
