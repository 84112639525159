import actionTypes from "constants/actionTypes";
const initialState = {
  resultadosCentroClave: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PERIODOSALL_DATA:
      return {
        ...state,
        periodosAllData: action.payload,
      };

    case actionTypes.GET_LASTPERIODOS_DATA:
      return {
        ...state,
        lastPeriodo: action.payload,
      };

    case actionTypes.GETRESULTADOSGLOBALHIST_SUCCESS:
      return {
        ...state,
        allResultadosGlobalHist: action.payload,
      };
    case actionTypes.GET_PERIODOS_DATA:
      return {
        ...state,
        periodosData: action.payload,
      };
    case actionTypes.GET_RESULTADOS_CLAVE_CENTRO_PERIODOCO_SUCCESS:
      return {
        ...state,
        resultadosCentroPeriodoCompara: action.payload,
      };

    case actionTypes.GET_RESULTADOS_CLAVE_CENTRO_PERIODO_HIST_DATA:
      return {
        ...state,
        resultadosCentroPeriodoHistoricoClave: action.payload,
      };
    case actionTypes.GET_RESULTADOS_CLAVE_CENTRO_PERIODO_SUCCESS:
      return {
        ...state,
        resultadosCentroPeriodoClave: action.payload,
      };
    case actionTypes.GET_RESULTADOS_CLAVE_CENTRO_PERIODO_ERROR:
      return {
        ...state,
        resultadosCentroClavePeriodoError: action.payload,
      };

    case actionTypes.GET_RESULTADOS_CLAVE_CENTRO_SUCCESS:
      return {
        ...state,
        resultadosCentroClave: action.payload,
      };
    case actionTypes.GET_RESULTADOS_CLAVE_CENTRO_ERROR:
      return {
        ...state,
        resultadosCentroClaveError: action.payload,
      };
    case actionTypes.GET_RESULTADOS_PREGUNTAS_CLAVE_CENTRO_SUCCESS:
      return {
        ...state,
        resultadosPreguntaCentroClave: action.payload,
      };
    case actionTypes.GET_RESULTADOS_PREGUNTAS_CLAVE_CENTRO_ERROR:
      return {
        ...state,
        resultadosPreguntaCentroClaveError: action.payload,
      };
    case actionTypes.GETCENTROFULL_DATA:
      return {
        ...state,
        datosCentroFull: action.payload,
      };
    case actionTypes.GETCENTROFULL_ERROR:
      return {
        ...state,
        datosCentroFullError: action.payload,
      };
    case actionTypes.GETFULLRESULTADOS_DATA:
      return {
        ...state,
        fullDatos: action.payload,
      };
    case actionTypes.GETFULLRESULTADOS_ERROR:
      return {
        ...state,
        fullDatosError: action.payload,
      };
    case actionTypes.GETFILTROS_DATA:
      return {
        ...state,
        filtros: action.payload,
      };
    case actionTypes.GETFILTROS_ERROR:
      return {
        ...state,
        filtroError: action.payload,
      };

    case actionTypes.GETRESULTADOSGLOBAL_SUCCESS:
      return {
        ...state,
        allResultadosGlobal: action.payload,
      };
    case actionTypes.GETRESULTADOSGLOBAL_ERROR:
      return {
        ...state,
        allResultadosGlobalError: action.payload,
      };

    case actionTypes.GET_CUESTIONARIO_SUCCESS:
      return {
        ...state,
        getCuestionario: action.payload,
      };
    case actionTypes.GET_CUESTIONARIO_ERROR:
      return {
        ...state,
        getCuestionarioError: action.payload,
      };
    case actionTypes.GET_BBDD_SUCCESS:
      return {
        ...state,
        getBBDD: action.payload,
      };
    case actionTypes.GET_BBDD_ERROR:
      return {
        ...state,
        getBBDDError: action.payload,
      };
    case actionTypes.GET_BDDALL_DATA:
      return {
        ...state,
        getBBDDAll: action.payload,
      };
    case actionTypes.GET_BDDALL_ERROR:
      return {
        ...state,
        getBBDDAllError: action.payload,
      };  
    case actionTypes.GET_DATOSGRAFICO_DATA:
      return {
        ...state,
        datosGraficoData: action.payload,
      };
    default:
      return state;
  }
};
