import { Language } from "components/Language";
import React from "react";
import DashboardPage from "views/start/Dashboard.js";

const DashboardMessage = () => (
  <div>
    <div
      style={{
        background: "#000000",
        textAlign: "center",
        color: "#FFFFFF !important",
      }}
    >
      <div className="subh1home alignCenter">
        <Language langKey="message.thanks" />
      </div>
      <div className="txtHome marginSubTitle alignCenter">
        <Language langKey="message.review_inbox" />
        <br />
        <Language langKey="message.we_sent" />
      </div>
      <div className="txtHome marginSubTitle alignCenter">
        <Language langKey="message.support" />&nbsp;
        <a href="mailto:info@xcelence.es">info@xcelence.es</a>
      </div>
    </div>

    <DashboardPage />
  </div>
);

export default DashboardMessage;
