import actionTypes from "constants/actionTypes";

export const claveActions = {
  getClaves,
  getClavesContestadas,
  getNoObligatoriasClave,
  isContestada
};

function getClaves() {
  return {
    type: actionTypes.CLAVES,
  };
}
function isContestada(id) {
  return {
    type: actionTypes.GET_ISCLAVE_CONTESTADAS,
    payload: id
  };
}

function getClavesContestadas() {
  return {
    type: actionTypes.GET_CLAVES_CONTESTADAS,
  };
}

function getNoObligatoriasClave() {
  return {
    type: actionTypes.GET_NO_OBLIGATORIAS_CLAVE,
  };
}
