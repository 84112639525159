import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { preguntasActions } from "actions/preguntasActions";
import IntroPregunta from "../Components/IntroPreguntaComponent";

import * as _ from "lodash";

function PreguntasComponent(props) {
  const dispatch = useDispatch();
  var apiDONE = useSelector((state) => state.loading.APIactions);
  var clavesFullList = useSelector((state) => state.claveReducer.claveList);

  var clave = props.location.clave;

  useEffect(() => {
    dispatch(preguntasActions.getClavesFullList());
  }, [apiDONE]);

  return (
    <div>
      {clavesFullList && (
        <IntroPregunta
          clave={clave ? clavesFullList[clave] : clavesFullList[0]}
          clavePosition={clave}
          numeroPregunta={0}
        />
      )}
    </div>
  );
}
export default PreguntasComponent;