import actionTypes from "constants/actionTypes";

export const directorActions = {
  getColaboradores,
  addColaborador,
  delColaborador,
  sendColaborador,
  getHistorico,
  saveHistorico,
  getCentro,
  saveHistoricoIndicador,
  updateCentro,
  getNiveles,
  getNivelesAnt,
  updateNiveles,
  updateCursos,
  endTransaction,
  sendResultados,
  checkResultados,
  activateColaborador,
  getPreguntasDownload,
  saveResultados,
  borrarCentro,
  avisaDirector,
  getAnteriores
};

function getAnteriores() {
  return {
    type: actionTypes.GET_ANTERIORES,
  };
}


function avisaDirector() {
  return {
    type: actionTypes.AVISAR_DIRECTOR,
  };
}

function borrarCentro(resolve, reject) {
  return {
    type: actionTypes.BORRAR_CENTRO,
    payload: {
      resolve,
      reject
    }
  };
}

function saveResultados() {
  return {
    type: actionTypes.SAVE_RESULTADOS,
  };
}
function getPreguntasDownload(clave) {
  var payload = {
    clave,
  };
  return {
    type: actionTypes.GET_PREGUNTAS_DOWN,
    payload: payload,
  };
}

function sendResultados() {
  return {
    type: actionTypes.SEND_RESULTADOS,
  };
}
function checkResultados() {
  return {
    type: actionTypes.CHECK_RESULTADOS,
  };
}


function getNivelesAnt() {
  return {
    type: actionTypes.GET_NIVELESANT,
  };
}

function getNiveles() {
  return {
    type: actionTypes.GET_NIVELES,
  };
}
function endTransaction() {
  return {
    type: actionTypes.END_TRANSACTION_CENTRO,
  };
}
function updateCursos(lista) {
  var payload = {
    lista,
  };
  return {
    type: actionTypes.UPDATE_C_CURSOS,
    payload: payload,
  };
}
function updateNiveles(lista) {
  var payload = {
    lista,
  };
  return {
    type: actionTypes.UPDATENIVELES,
    payload: payload,
  };
}
function saveHistorico(accion, tipo, descripcion) {
  var payload = {
    accion,
    tipo,
    descripcion,
  };
  return {
    type: actionTypes.SAVEHISTORICO,
    payload: payload,
  };
}

function saveHistoricoIndicador(accion, tipo, descripcion, indicador) {
  var payload = {
    accion,
    tipo,
    descripcion,
    indicador,
  };
  return {
    type: actionTypes.SAVEHISTORICO,
    payload: payload,
  };
}

function updateCentro(
  nombre,
  direccion,
  cp,
  CIF,
  ownership,
  kind,
  nivelsocioeconomico,
  porcentajebeca,
  numalumnos
) {
  var payload = {
    nombre,
    direccion,
    cp,
    CIF,
    ownership,
    kind,
    nivelsocioeconomico,
    porcentajebeca,
    numalumnos,
  };
  return {
    type: actionTypes.UPDATECENTRO,
    payload: payload,
  };
}

function getCentro() {
  return {
    type: actionTypes.GETCENTRO,
  };
}

function getHistorico() {
  return {
    type: actionTypes.GETHISTORICO,
  };
}
function getColaboradores() {
  return {
    type: actionTypes.GETCOLABORADORES,
  };
}
function addColaborador(email, perfil) {
  var payload = { email, perfil };
  return {
    type: actionTypes.ADDCOLABORADOR,
    payload: payload,
  };
}
function delColaborador(id) {
  var payload = { id };
  return {
    type: actionTypes.DELCOLABORADOR,
    payload: payload,
  };
}
function sendColaborador(user) {
  var payload = { user };
  return {
    type: actionTypes.SENDCOLABORADOR,
    payload: payload,
  };
}
function activateColaborador(id) {
  var payload = { id };
  return {
    type: actionTypes.ACTIVATE_COLABORADOR,
    payload: payload,
  };
}
