import React, { useEffect, useRef, useState, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay } from "components/LoadingOverlay";
import { Box, Button, CircularProgress, Grid, Typography, makeStyles } from "@material-ui/core";
import { DescriptionOutlined } from "@material-ui/icons";
import { ReportBody } from "./components/ReportBody";
import { CenterDashboardFilter } from "./components/CenterDashboardFilter";
import html2canvas from "html2canvas";
import { dowloadSimplePdfReport, fetchCenterDashboardFilter, fetchResultsKeys } from "actions/reports.action";
import NavBarAuth from "views/NavBar/NavBarAuth";
import NavBar from "views/NavBar/NavBar";
import { Language } from "components/Language";

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    maxWidth: 1000,
    margin: '30px auto',
  },
  nodeForDownload: {
    position: 'fixed',
    zIndex: -10,
    top: 0,
    left: 0,
    width: theme.breakpoints.values.lg,
  }
}));

let _cache = {};
export function KeyReport() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const graph = useRef(null);
  const classes = useStyles();
  const locale = useSelector((state) => state.setting.locale);

  useEffect(() => {
    initialRequest();
  }, [locale]);

  const initialRequest = async () => {
    try {
      setLoading(true);

      await getResultsKeys();

      await new Promise((resolve, reject) => (
        dispatch(fetchCenterDashboardFilter({ reject, resolve }))
      ));

    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  }

  const getResultsKeys = (filter) => {
    return new Promise((resolve, reject) => (
      dispatch(fetchResultsKeys({
        reject,
        resolve,
        filter
      }))
    ));
  }

  const handleFilter = async (filter, filterLabels) => {
    _cache.filter = filter;
    _cache.filterLabels = filterLabels;

    try {
      setLoading(true);
      await getResultsKeys(filter);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  }

  const handleDownload = async () => {
    try {
      setDownloading(true);

      const canvas = await html2canvas(graph.current);
      const image = canvas.toDataURL();

      await new Promise((resolve, reject) => (
        dispatch(dowloadSimplePdfReport({
          reject,
          resolve,
          image,
          title: 'RESULTADOS XCELENCE POR CLAVE',
          ..._cache,
        }))
      ));

    } catch (error) {
      console.log(error);
    }

    setDownloading(false);
  }

  return (<>
    { localStorage.getItem("xcauth") === "true" 
        ? <NavBarAuth />
        : <NavBar />
    }
    <div className={classes.container}>
      <LoadingOverlay isLoading={loading} />
      <Grid
        container
        className="my-3"
        justifyContent="space-between"
      >
        <Box width={185}></Box>
        <Grid item>
          <Typography variant="h6" align="center">
            <Language langKey="key_report.label" />
          </Typography>
        </Grid>
        <Grid item>
          <Button
            fullWidth
            variant="outlined"
            color="default"
            disabled={downloading}
            onClick={handleDownload}
            startIcon={
              downloading
                ? <CircularProgress color="inherit" size={18} />
                : <DescriptionOutlined />
            }
          >
            <Language langKey="p_results.download_graphic" />
          </Button>
        </Grid>
      </Grid>
      <ReportBody />
      <Grid container className="mt-4">
        <Grid item xs={12}>
          <CenterDashboardFilter onFilter={handleFilter} />
        </Grid>
      </Grid>
      <div className={classes.nodeForDownload} ref={graph}>
        <ReportBody />
      </div>
    </div>
  </>);
}
