import actionTypes from "constants/actionTypes"



export const messageActions = {
    closeMessage,
    
   

}
function closeMessage(){
    return {
      type: "SET_MESSAGE",
      payload: {message: "", color: "", show:false}
    }
  }