import { combineReducers } from "redux";
import loading from "./loading";
import authReducer from "./authReducer";
import countReducer from "./countReducer";
import userReducer from "./userReducer";
import centroReducer from "./centroReducer";
import contenidoReducer from "./contenidoReducer";
import claveReducer from "./claveReducer";
import directorReducer from "./directorReducer";
import calculateReducer from "./calculateReducer";
import cargosReducer from "./cargosReducer";
import messageReducer from "./messageReducer";
import datosReducer from "./datosReducer";
import recursosReducer from "./recursosReducer";
import comunidadesReducer from "./comunidadesReducer";
import toolkitsReducer from "./toolkitsReducer";
import {setting} from "./settings.reducer";
import {reportsReducer} from "./reports.reducer";

const rootReducer = combineReducers({
  loading,
  authReducer,
  countReducer,
  userReducer,
  contenidoReducer,
  claveReducer,
  centroReducer,
  directorReducer,
  calculateReducer,
  cargosReducer,
  messageReducer,
  datosReducer,
  recursosReducer,
  comunidadesReducer,
  setting,
  reportsReducer,
  toolkitsReducer
});

export default rootReducer;