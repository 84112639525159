import { all } from "redux-saga/effects";
import watcherAuthentication from "./authentication_saga";
import watcherCalculate from "./calculation_saga";
import watcherDoubleCounter from "./doubleCounter_saga";
import watcherUser from "./user_saga";
import watcherCentros from "./centro_saga";
import watcherContenido from "./contenido_saga";
import watcherClave from "./clave_saga";
import watcherDirector from "./director_saga";
import watcherCargos from "./cargos_saga";
import watcherNewsletter from "./newsletter_saga";
import watcherDatos from './datos_saga';
import watcherRecursos from './recursos_saga';
import watcherComunidades from './comunidades_saga';
import watcherToolkits from './toolkits_saga';
import { settingsSaga } from './settings.saga';
import { watcherReports } from "./reports.saga";
import { watcherAwards } from "./awards_saga";

export default function* root_saga() {
  yield all([
    // register
    watcherReports(),
    watcherDoubleCounter(),
    watcherCalculate(),
    watcherAuthentication(),
    watcherUser(),
    watcherCentros(),
    watcherContenido(),
    watcherClave(),
    watcherDirector(),
    watcherCalculate(),
    watcherCargos(),
    watcherNewsletter(),
    watcherDatos(),    
    watcherRecursos(),
    watcherComunidades(),
    watcherToolkits(),
    settingsSaga(),
    watcherAwards(),
  ]);
}
