import React, { useState, useEffect } from "react";
import NavBarSimple from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import { useDispatch } from "react-redux";
import { cargosActions } from "actions/cargosActions";
import { messageActions } from "actions/messageActions";
import { Language } from "components/Language.js";

function Newsletter() {
  const dispatch = useDispatch();
  const [valid, setValid] = useState(false);

  const [nombre, setNombre] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [email, setEmail] = useState("");
  const [cargoSelected, setCargoSelected] = useState(-1);
  const [terminos, setTerminos] = useState(false);

  const checkStepOne = () => {
    var isValid = false;
    if (
      nombre !== "" &&
      nombre.length > 2 &&
      nombre.length < 40 &&
      apellidos !== "" &&
      apellidos.length > 3 &&
      apellidos.length < 60 &&
      validEmail(email) &&
      cargoSelected > -1 &&
      terminos
    ) {
      isValid = true;
    }
    setValid(isValid);
  };
  const validEmail = function(mEmail) {
    var isValid = true;
    var EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!mEmail.match(EMAIL_REGEX)) {
      isValid = false;
    }

    return isValid;
  };

  useEffect(() => {
    checkStepOne();
  });

  useEffect(() => {
    dispatch(cargosActions.getCargos());
  }, []);

  useEffect(() => {
    return function cleanup() {
      dispatch(messageActions.closeMessage());
    };
  }, []);


  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.72.2003.0";
    script.async = true;
  
    document.body.appendChild(script);

    const script2 = document.createElement('script');
  
    script2.src = "https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.72.2003.0";
    script2.async = true;
  
    document.body.appendChild(script2);
  
    return () => {
      document.body.removeChild(script);
      document.body.removeChild(script2);
    }
  }, []);

  return (
    <div className="fullPage">
      <NavBarSimple />
      <div className="defaultFull">
        <Grid container spacing={3} justifyContent="center" className="defaultgrid">
          <Grid item xs={12}>
            <div className="h1home">Newsletter</div>
            <div className="txtHome marginSubTitle">
              <Language langKey="message.subscribe"/>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div data-form-block-id="358507d9-0fc6-eb11-bacc-000d3ade5e2a"></div>
            <div id="dD0i1iw3GBud1z-w4T1u4zStj-Navzi0-EY4o0BV5bpU"></div>
            <div
              className="d365-mkt-config"
              style={{display:"none"}}
              data-website-id="D0i1iw3GBud1z-w4T1u4zStj-Navzi0-EY4o0BV5bpU"
              data-hostname="81c97973d1f14146af6fd4446b1deb14.svc.dynamics.com"
            ></div>
          </Grid>
        </Grid>
      </div>
      
    </div>
  );
}

export default Newsletter;
