/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import NavBar from "../NavBar/NavBar.js";
import NavBarAuth from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { contenidoActions } from "actions/contenidoActions";
import { claveActions } from "actions/claveActions";
import * as _ from "lodash";
function PracticaView(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  history.push("/user/start");
  
  // console.log(props.location.id);
  if (!props.location.id) {
    history.push("/user/practicas");
  }
  var apiDONE = useSelector((state) => state.loading.APIactions);
  var claves = useSelector((state) => state.claveReducer.claveList);
  var getPractica = useSelector((state) => state.contenidoReducer.getPractica);

  var getPracticaError = useSelector(
    (state) => state.contenidoReducer.getPracticaError
  );
  if (getPracticaError) {
    history.push("/user/error");
  }
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(4);

  var mLoading = useSelector((state) => state.loading.loading);
  const [claveSelected, setClaveSelected] = useState(-1);
  useEffect(() => {
    dispatch(claveActions.getClaves());
    dispatch(contenidoActions.getPractica(props.location.id));
  }, []);

  if (getPractica && getPractica.length > 0) {
  } else {
    history.push("/user/practicas");
  }
  // console.log(getPractica);

  return (
    <div>
      {localStorage.getItem("xcauth") &&
      localStorage.getItem("xcauth") === "true" ? (
        <NavBarAuth />
      ) : (
        <NavBar />
      )}
      <div className="defaultFull">
        {getPractica && getPractica.length > 0 && (
          <Grid container spacing={3} justifyContent="center" className="defaultgrid">
            <Grid item xs={12}>
              <div className="h1home">{getPractica[0].titulo}</div>

              <Grid container spacing={3} className="bgWhite">
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  className="imgCenter"
                >
                  <img
                    className="imgHomeXcelence"
                    src={getPractica[0].image}
                    sizes="100vw"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  className="itemHomeResp"
                >
                  <div className="txtHome padBottomHomeMore">
                    {getPractica[0].descripcion}
                    <br />
                    <div className="txtHome fontStrong">
                      {getPractica[0].nombre}
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="itemHomeResp"
                >
                  <div
                    style={{ marginTop: "43px" }}
                    className="menuSeparatorFull"
                  ></div>
                  <div
                    style={{ textAlign: "justify" }}
                    dangerouslySetInnerHTML={{ __html: getPractica[0].cuerpo }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}
export default withTranslation()(PracticaView);
