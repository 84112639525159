import React, { Component } from "react";
import * as _ from "lodash";
import Circle from "react-circle";
import { Language } from "components/Language";

const calcPos = (array, posicion) => {
  var pos = (500 - array[posicion]) / 2;

  return +pos.toFixed(0);
}
const calcValor = (array, posicion) => array[posicion];

const calcValorC = (array, posicion) => {
  if (array[posicion])
    return array[posicion]-15;

  return 0;
}

const PolarData = (props)  => {
    var mSalida = props.dataRes;
    var mData = [];
    var mValores = [];
    mSalida.map((val) => {
      var mSumaTotal = 0;
      if (val.suma) mSumaTotal += val.suma;
      mValores.push(mSumaTotal);
      var total = 176 + (224 * mSumaTotal) / 100;

      mData.push(+total.toFixed(0));
    });

    var mSalida2 = _.groupBy(props.todos, "clave");
    var mData2 = [];
    var mData3 = [];
    var mValores2 = [];
    var mSumaTotalT = 0;

    Object.keys(mSalida2).map((key) => {
      var mSumaI = 0;
      mSalida2[key].map((val) => {
        if (val.suma) mSumaI += val.suma;
      });
      mSumaTotalT = mSumaI / mSalida2[key].length;
      mValores2.push(mSumaTotalT);

      var total = 176 + (224 * mSumaTotalT) / 100;

      var total2 = 203.5 + (252 * mSumaTotalT) / 100;

      mData3.push(+total?.toFixed(0));

      
      mData2.push(+total2?.toFixed(0));
    });

    return (
      <div id="graficoCenter" className="graficoCenter">
        <div className="centerWrapper" id="centerWrapper">
          <ul className="sliceWrapper">
            <li className="liborder">
              <div className="slice"></div>
            </li>
            <li className="liborder">
              <div className="slice"></div>
            </li>
            <li className="liborder">
              <div className="slice"></div>
            </li>
            <li className="liborder">
              <div className="slice"></div>
            </li>
            <li className="liborder">
              <div className="slice"></div>
            </li>

            <li className="liborder">
              <div className="slice"></div>
            </li>
            <li className="liborder">
              <div className="slice"></div>
            </li>
            <li className="liborder">
              <div className="slice"></div>
            </li>
            <li className="liborder">
              <div className="slice"></div>
            </li>
            <li className="liborder">
              <div className="slice"></div>
            </li>
          </ul>

          <ul
            className="sliceWrapperClave"
            style={{
              width: calcValor(mData, 0),
              height: calcValor(mData, 0),
              top: calcPos(mData, 0),
              left: calcPos(mData, 0),
            }}
          >
            <li>
              <div className="slice slicecl1"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>

            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
          </ul>

          <ul
            className="sliceWrapperClave"
            style={{
              width: calcValor(mData, 1),
              height: calcValor(mData, 1),
              top: calcPos(mData, 1),
              left: calcPos(mData, 1),
            }}
          >
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slice slicecl2"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>

            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
          </ul>

          <ul
            className="sliceWrapperClave"
            style={{
              width: calcValor(mData, 2),
              height: calcValor(mData, 2),
              top: calcPos(mData, 2),
              left: calcPos(mData, 2),
            }}
          >
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slice slicecl3"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>

            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
          </ul>

          <ul
            className="sliceWrapperClave"
            style={{
              width: calcValor(mData, 3),
              height: calcValor(mData, 3),
              top: calcPos(mData, 3),
              left: calcPos(mData, 3),
            }}
          >
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slice slicecl4"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>

            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
          </ul>

          <ul
            className="sliceWrapperClave"
            style={{
              width: calcValor(mData, 4),
              height: calcValor(mData, 4),
              top: calcPos(mData, 4),
              left: calcPos(mData, 4),
            }}
          >
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slice slicecl5"></div>
            </li>

            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
          </ul>

          <ul
            className="sliceWrapperClave"
            style={{
              width: calcValor(mData, 5),
              height: calcValor(mData, 5),
              top: calcPos(mData, 5),
              left: calcPos(mData, 5),
            }}
          >
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slice slicecl6"></div>
            </li>

            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
          </ul>

          <ul
            className="sliceWrapperClave"
            style={{
              width: calcValor(mData, 6),
              height: calcValor(mData, 6),
              top: calcPos(mData, 6),
              left: calcPos(mData, 6),
            }}
          >
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>

            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slice slicecl7"></div>
            </li>

            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
          </ul>

          <ul
            className="sliceWrapperClave"
            style={{
              width: calcValor(mData, 7),
              height: calcValor(mData, 7),
              top: calcPos(mData, 7),
              left: calcPos(mData, 7),
            }}
          >
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>

            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slice slicecl8"></div>
            </li>

            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
          </ul>

          <ul
            className="sliceWrapperClave"
            style={{
              width: calcValor(mData, 8),
              height: calcValor(mData, 8),
              top: calcPos(mData, 8),
              left: calcPos(mData, 8),
            }}
          >
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>

            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slice slicecl9"></div>
            </li>

            <li>
              <div className="slicet"></div>
            </li>
          </ul>

          <ul
            className="sliceWrapperClave"
            style={{
              width: calcValor(mData, 9),
              height: calcValor(mData, 9),
              top: calcPos(mData, 9),
              left: calcPos(mData, 9),
            }}
          >
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>

            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slicet"></div>
            </li>
            <li>
              <div className="slice slicecl10"></div>
            </li>
          </ul>


          <div className="elipse elipseclave1">
            <span>1</span>
          </div>
          <div className="valor valorclave1">
            <span>{calcValor(mValores, 0)?.toFixed(0)}%</span>
          </div>

          <div className="elipse elipseclave2">
            <span>2</span>
          </div>
          <div className="valor valorclave2">
            <span>{calcValor(mValores, 1)?.toFixed(0)}%</span>
          </div>

          <div className="elipse elipseclave3">
            <span>3</span>
          </div>
          <div className="valor valorclave3">
            <span>{calcValor(mValores, 2)?.toFixed(0)}%</span>
          </div>

          <div className="elipse elipseclave4">
            <span>4</span>
          </div>
          <div className="valor valorclave4">
            <span>{calcValor(mValores, 3)?.toFixed(0)}%</span>
          </div>

          <div className="elipse elipseclave5">
            <span>5</span>
          </div>
          <div className="valor valorclave5">
            <span>{calcValor(mValores, 4)?.toFixed(0)}%</span>
          </div>

          <div className="elipse elipseclave6">
            <span>6</span>
          </div>
          <div className="valor valorclave6">
            <span>{calcValor(mValores, 5)?.toFixed(0)}%</span>
          </div>

          <div className="elipse elipseclave7">
            <span>7</span>
          </div>
          <div className="valor valorclave7">
            <span>{calcValor(mValores, 6)?.toFixed(0)}%</span>
          </div>

          <div className="elipse elipseclave8">
            <span>8</span>
          </div>
          <div className="valor valorclave8">
            <span>{calcValor(mValores, 7)?.toFixed(0)}%</span>
          </div>

          <div className="elipse elipseclave9">
            <span>9</span>
          </div>
          <div className="valor valorclave9">
            <span>{calcValor(mValores, 8)?.toFixed(0)}%</span>
          </div>

          <div className="elipse elipseclave10">
            <span>10</span>
          </div>
          <div className="valor valorclave10">
            <span>{calcValor(mValores, 9)?.toFixed(0)}%</span>
          </div>

          <div id="center" style={{ zIndex: "98" }}>
            <div className="mediaGrafico">
              <Language langKey="average" />
              <br />
              {props.media}%
            </div>
          </div>
        </div>

        <div className="medias" id="mediasClavesPolar">
          <Circle
            progress={9.5}
            size={calcValorC(mData2, 0)}
            lineWidth={2}
            progressColor="black"
            textColor="transparent"
            bgColor="transparent"
            roundedStroke={true}
            showPercentage={false}
            style={{ position: "absolute", zIndex: 100000 }}
            className="mediaClave mediaClave2"
          />
          <Circle
            progress={9.5}
            size={calcValorC(mData2, 1)}
            lineWidth={2}
            progressColor="black"
            textColor="transparent"
            bgColor="transparent"
            roundedStroke={true}
            showPercentage={false}
            style={{ position: "absolute", zIndex: 100000 }}
            className="mediaClave mediaClave2"
          />
          <Circle
            progress={9.3}
            size={calcValorC(mData2, 2)}
            lineWidth={2}
            progressColor="black"
            textColor="transparent"
            bgColor="transparent"
            roundedStroke={true}
            showPercentage={false}
            style={{ position: "absolute", zIndex: 100000 }}
            className="mediaClave mediaClave2"
          />

          <Circle
            progress={9.5}
            size={calcValorC(mData2, 3)}
            lineWidth={2}
            progressColor="black"
            textColor="transparent"
            bgColor="transparent"
            roundedStroke={true}
            showPercentage={false}
            style={{ position: "absolute", zIndex: 100000 }}
            className="mediaClave mediaClave2"
          />

          <Circle
            progress={10}
            size={calcValorC(mData2, 4)}
            lineWidth={2}
            progressColor="black"
            textColor="transparent"
            bgColor="transparent"
            roundedStroke={true}
            showPercentage={false}
            style={{ position: "absolute", zIndex: 100000 }}
            className="mediaClave mediaClave2"
          />

          <Circle
            progress={10}
            size={calcValorC(mData2, 5)}
            lineWidth={2}
            progressColor="black"
            textColor="transparent"
            bgColor="transparent"
            roundedStroke={true}
            showPercentage={false}
            style={{ position: "absolute", zIndex: 100000 }}
            className="mediaClave mediaClave2"
          />

          <Circle
            progress={10}
            size={calcValorC(mData2, 6)}
            lineWidth={2}
            progressColor="black"
            textColor="transparent"
            bgColor="transparent"
            roundedStroke={true}
            showPercentage={false}
            style={{ position: "absolute", zIndex: 100000 }}
            className="mediaClave"
          />

          <Circle
            progress={10}
            size={calcValorC(mData2, 7)}
            lineWidth={2}
            progressColor="black"
            textColor="transparent"
            bgColor="transparent"
            roundedStroke={true}
            showPercentage={false}
            style={{ position: "absolute", zIndex: 100000 }}
            className="mediaClave mediaClave2"
          />
          <Circle
            progress={10}
            size={calcValorC(mData2, 8)}
            lineWidth={2}
            progressColor="black"
            textColor="transparent"
            bgColor="transparent"
            roundedStroke={true}
            showPercentage={false}
            style={{ position: "absolute", zIndex: 100000 }}
            className="mediaClave mediaClave2"
          />
          <Circle
            progress={10}
            size={calcValorC(mData2, 9)}
            lineWidth={2}
            progressColor="black"
            textColor="transparent"
            bgColor="transparent"
            roundedStroke={true}
            showPercentage={false}
            style={{ position: "absolute", zIndex: 100000 }}
            className="mediaClave mediaClave2"
          />

          <div className="valorm valorclavem1">
            <span>
            {"\u00a0\u00a0"}
              {mValores2 &&
                mValores2.length > 0 &&                  
                calcValor(mValores2, 0)?.toFixed(0)}                   
              {mValores2 && mValores2.length > 0 && <span>%</span>}
            </span>
          </div>

          <div className="valorm valorclavem2">            
            <span>
            {"\u00a0\u00a0"}
              {mValores2 &&
                mValores2.length > 0 &&
                calcValor(mValores2, 1)?.toFixed(0)}
              {mValores2 && mValores2.length > 0 && <span>%</span>}
            </span>
          </div>

          <div className="valorm valorclavem3">
            <span>
            {"\u00a0\u00a0"}
              {mValores2 &&
                mValores2.length > 0 &&
                calcValor(mValores2, 2)?.toFixed(0)}
              {mValores2 && mValores2.length > 0 && <span>%</span>}
            </span>
          </div>

          <div className="valorm valorclavem4">            
            <span>
            {"\u00a0\u00a0"}
              {mValores2 &&
                mValores2.length > 0 &&
                calcValor(mValores2, 3)?.toFixed(0)}
              {mValores2 && mValores2.length > 0 && <span>%</span>}
            </span>
          </div>

          <div className="valorm valorclavem5">            
            <span>
            {"\u00a0\u00a0"}
              {mValores2 &&
                mValores2.length > 0 &&
                calcValor(mValores2, 4)?.toFixed(0)}
              {mValores2 && mValores2.length > 0 && <span>%</span>}
            </span>
          </div>

          <div className="valorm valorclavem6">            
            <span>
              {mValores2 &&
                mValores2.length > 0 &&
                calcValor(mValores2, 5)?.toFixed(0)}
              {mValores2 && mValores2.length > 0 && <span>%</span>}
              {"\u00a0\u00a0"}
            </span>              
          </div>

          <div className="valorm valorclavem7">
            <span>
              {mValores2 &&
                mValores2.length > 0 &&
                calcValor(mValores2, 6)?.toFixed(0)}
              {mValores2 && mValores2.length > 0 && <span>%</span>}
              {"\u00a0\u00a0"}
            </span>              
          </div>

          <div className="valorm valorclavem8">
            <span>
              {mValores2 &&
                mValores2.length > 0 &&
                calcValor(mValores2, 7)?.toFixed(0)}
              {mValores2 && mValores2.length > 0 && <span>%</span>}
              {"\u00a0\u00a0"}
            </span>              
          </div>

          <div className="valorm valorclavem9">
            <span>
              {mValores2 &&
                mValores2.length > 0 &&
                calcValor(mValores2, 8)?.toFixed(0)}
              {mValores2 && mValores2.length > 0 && <span>%</span>}
              {"\u00a0\u00a0"}
            </span>              
          </div>

          <div className="valorm valorclavem10">
            <span>
              {mValores2 &&
                mValores2.length > 0 &&
                calcValor(mValores2, 9)?.toFixed(0)}
              {mValores2 && mValores2.length > 0 && <span>%</span>}
              {"\u00a0\u00a0"}
            </span>              
          </div>
        </div>
      </div>
    );
  }

export default PolarData;
