import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import Footer from "components/Footer/Footer.js";
import routes from "routes.js";

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={'/:lang(es|ca)?' + prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/user" to="/user/login" />
  </Switch>
);

export default function Auth({ ...rest }) {
  // styles
  const mainPanel = React.createRef();
  // states and functions

  const getRoute = () => {
    return (
      window.location.pathname !== "/auth/preguntas" && 
      window.location.pathname !== "/auth/preguntas/contestar" & 
      window.location.pathname !== "/auth/contestar"
    );
  };


  return (
    <div ref={mainPanel} style={{backgroundColor: '#fff'}}>
      {switchRoutes}
      {getRoute() ? <Footer /> : <div></div>}
    </div>
  );
}
