import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";
import { history } from "helpers/history";

export default function* watcherComunidades() {

  yield takeEvery(actionTypes.GET_COMUNIDADES, getComunidades);



  function* getComunidades() {
    yield put({ type: actionTypes.START_REQUEST });
    try {
      let apiEndpoint = "/v7/comunidades";
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({ type: actionTypes.SET_COMUNIDADES, payload: res.data });
      } else {
        // console.log("ERROR");
      
      }
    } catch (e) {
    }
    yield put({ type: actionTypes.END_REQUEST });
  }


}
