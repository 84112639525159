/* eslint-disable react/prop-types */
import React from "react";
import Grid from "@material-ui/core/Grid";

import { Link } from "react-router-dom";
import NavBarSimple from "views/NavBar/NavBarSimple";
import * as _ from "lodash";
import "../preguntas.css";
import {Language} from 'components/Language';
import { useSelector } from "react-redux";

const messages = {
  5: <Language langKey="key_five_not_allowed" />,
  8: <Language langKey="key_eight_not_allowed" />,
}

function IntroPregunta(props) {
  const centerData = useSelector((state) => state.directorReducer.datosCentro?.[0]);
  const isFPCenter = centerData?.kind === 'FP';
  const isCEPACenter = centerData?.kind === 'CEPA';
  const isNotAllowed = (isFPCenter && props.clave.num === 8) || (isCEPACenter && props.clave.num === 5); 

  return (
    <div>
      <NavBarSimple auth={true} />
      <Grid container justifyContent="center">          
          <div className="ovalized" style={{backgroundColor:props.clave.color}}>
            <Grid container direction="column" alignItems="center">
              <Grid item xs={12} md={6} lg={6}>
                <label className="Clave customMt">
                  <Language langKey="key" />
                </label>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                  <label className="claveNumberText">{props.clave.num}</label>
              </Grid>
            </Grid>
          </div>
      </Grid>

      <Grid item xs={12} md={12} lg={12} className="gridTitulo">
        <Grid container justifyContent="center">
          <label className="tituloClave">
            {isNotAllowed 
              ? messages[props.clave.num]
              : props.clave.titulo
            }
          </label>
        </Grid>
        </Grid>
        {!isNotAllowed && (
          <Grid item xs={12} md={12} lg={12} className="gridDefinicion">
            <Grid container justifyContent="center">
              <label className="definicion">
                {props.clave.definicion}
              </label>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          className="gridDefinicion gridDefinicionResponsive"
          container
          justifyContent="center"
        >
          <Link
            to={{
              pathname: isNotAllowed
                ? '/auth/preguntas' : '/auth/contestar',
              clave: isNotAllowed 
                ? props.clavePosition + 1
                : props.clavePosition
            }}
            className="btn buttonIntro"
            style={{backgroundColor:props.clave.color, marginTop: 11}}
          >
            {isNotAllowed
              ? <Language langKey="questionary.go_to_next" />
              : <Language langKey="start" />
            }
          </Link>
      </Grid>
    </div>
  );
}

export default IntroPregunta;
