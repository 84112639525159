import { SNACKBAR_SETTING } from "constants/settings.constant";

const initialState = {
  loading: false,
  queue: 0,
  APIactions: 0,
  snackbar: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "API_DONE":
      return {
        ...state,
        APIactions: state.APIactions + 1
      };
    case SNACKBAR_SETTING:
      return {
        ...state,
        snackbar: action.payload
      };
    case "START_REQUEST":
     
      return {
        ...state,
        queue: state.queue + 1,
        loading: true
      };
    case "END_REQUEST":
      var nextQueue = state.queue - 1;
     
      if (nextQueue == 0) {
        return {
          ...state,
          queue: state.queue - 1,
          loading: false
        };
      } else {
        return {
          ...state,
          queue: state.queue - 1,
          loading: true
        };
      }
    case "RESET_LOADING":
      return {
        queue: 0,
        loading: false
      };

    default:
      return {
        ...state
      };
  }
};
