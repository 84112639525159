import React, { useState, useEffect } from "react";
import NavBarSimple from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "actions/userActions";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Language, getRawMessage } from "components/Language";
import { useForceUpdate } from "utils/custon-hooks.js";

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const SITE_KEY = "6LfiNx0bAAAAACDYXqLmr2wC_L-YhB4x5cSzx8H5";

function Contacto(props) {
  const dispatch = useDispatch();
  const [valid, setValid] = useState(false);

  const [nombre, setNombre] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [email, setEmail] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [loading, setLoading] = useState(false);
  const locale = useSelector((state) => state.setting.locale);
  const forceUpdate = useForceUpdate();

  const [terminos, setTerminos] = useState(false);
  const [errorTerminos, setErrorTerminos] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [openerror, setOpenError] = React.useState(false);

  var contactData = useSelector((state) => state.authReducer.contactData);
  var contactDataError = useSelector(
    (state) => state.authReducer.contactDataError
  );

  useEffect(() => {
    forceUpdate();
  }, [locale]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  useEffect(() => {
    if (contactData) {
      setLoading(false);
      setNombre("");
      setApellidos("");
      setEmail("");
      setMensaje("");
      setTerminos(false);
      setOpen(true);
    }
    if (contactDataError) {
      setLoading(false);
      setOpenError(true);
    }
  }, [contactData, contactDataError]);

  const setChangeTerminos = (event) => {
    setTerminos(event.target.checked);
  };
  const setChangeNombre = (event) => {
    setNombre(event.target.value);
  };
  const setChangeApellidos = (event) => {
    setApellidos(event.target.value);
  };
  const setChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const setChangeMensaje = (event) => {
    setMensaje(event.target.value);
  };

  const checkStepOne = () => {
    var isValid = false;

    if (
      nombre !== "" &&
      nombre.length > 2 &&
      nombre.length < 40 &&
      mensaje !== "" &&
      mensaje.length > 2 &&
      mensaje.length < 40 &&
      apellidos !== "" &&
      apellidos.length > 3 &&
      apellidos.length < 60 &&
      validEmail(email)
    ) {
      isValid = true;
    }
    setValid(isValid);
  };
  const validEmail = function(mEmail) {
    var isValid = true;
    var EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!mEmail.match(EMAIL_REGEX)) {
      isValid = false;
    }

    return isValid;
  };

  const saveForm = (e) => {
    if (terminos) {
      setErrorTerminos(false);
      setLoading(true);

      e.preventDefault();

      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(SITE_KEY, { action: "submit" })
          .then((token) => {
            dispatch(
              userActions.contact(nombre, apellidos, email, mensaje, token)
            );
          });
      });
    } else {
      setErrorTerminos(true);
    }
  };

  useEffect(() => {
    checkStepOne();
  });

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function() {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`
    );
  }, []);

  return (
    <div className="fullPage">
      <NavBarSimple />
      <div className="defaultFull">
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            <Language langKey="connection.success" />
          </Alert>
        </Snackbar>
        <Snackbar
          open={openerror}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={4000}
          onClose={handleCloseError}
        >
          <Alert onClose={handleCloseError} severity="warning">
            <Language langKey="errors.internal" />
          </Alert>
        </Snackbar>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          className="defaultgrid"
        >
          <Grid item xs={12}>
            <div className="h1home">
              <Language langKey="contact" />
            </div>
            <div className="txtHome marginSubTitle">
              <Language langKey="connection.detail" />
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
            <div className="marginGrid obtext strong">
              <p>* <Language langKey="required" /></p>
            </div>
          </Grid>
          <Grid item sm={6} md={6} lg={8} xl={8}></Grid>

          <Grid item xs={12}>
            <div className="formText">
              <Language langKey="signup.name" /> *
            </div>

            <TextField
              onChange={(e) => setChangeNombre(e)}
              id="nombre"
              name="nombre"
              className="inputMargin widthContactForm"
              variant="outlined"
              required
              value={nombre}
              placeholder={getRawMessage("signup.name_caption")}
            />
          </Grid>
          <Grid item xs={12}>
            <div className="formText">
              <Language langKey="signup.last_name" /> *
            </div>
            <TextField
              onChange={(e) => setChangeApellidos(e)}
              className="inputMargin widthContactForm"
              variant="outlined"
              required
              value={apellidos}
              id="apellidos"
              placeholder={getRawMessage("signup.last_name")}
            />
          </Grid>
          <Grid item xs={12}>
            <div className="formText">
              <Language langKey="signup.email" /> *
            </div>
            <TextField
              onChange={(e) => setChangeEmail(e)}
              className="inputMargin widthContactForm"
              variant="outlined"
              required
              value={email}
              id="email"
              placeholder={getRawMessage("signup.email_caption")}
            />
          </Grid>
          <Grid item xs={12}>
            <div className="formText">
              <Language langKey="signup.message" /> *
            </div>
            <TextareaAutosize
              onChange={(e) => setChangeMensaje(e)}
              value={mensaje}
              className="inputMargin widthContactForm"
              aria-label="minimum height"
              rowsMin={3}
              variant="outlined"
              required
              id="mensaje"
              placeholder={getRawMessage("signup.message_caption")}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              className="labelForm"
              control={
                <Checkbox
                  checked={terminos}
                  onChange={setChangeTerminos}
                  color="primary"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label={<Language parse langKey="signup.privacy" />}
            />
            {errorTerminos && (
              <div className="errorLayer">
                <i className="fa fa-exclamation-triangle marginerror"></i>
                <Language langKey="errors.terms" />
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={8} lg={4} xl={4}>
            <Grid container>
              <Button
                onClick={(e) => saveForm(e)}
                variant="contained"
                disabled={valid && !loading ? false : true}
                className={` ${
                  valid && !loading ? "btnHome" : "btnDisabled"
                } widthContact`}
              >
                <Language langKey="send" />
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className="centerLoading centerForm"
                />
              )}
            </Grid>
          </Grid>
          <Grid item sm={4} lg={8} xl={8}></Grid>
        </Grid>
      </div>
    </div>
  );
}
export default Contacto;
