import React from "react";
import {Language} from "components/Language";
import { Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";

export default function Rosco(props) {
  const centerData = useSelector((state) => state.directorReducer.datosCentro?.[0]);
  const isCEPACenter = centerData?.kind === 'CEPA';
  const isFPCenter = centerData?.kind === 'FP';

  return (
    <svg viewBox="0 0 728.61 727.22" version="1.1" id="svg202">
      <g>
        {/* ROSCO 1 */}
        <g id="RoscoStandard1" data-name="RoscoStandard1">
          <g
            id="Capa_1-2"
            data-name="Capa 1"
            style={{ cursor: "pointer" }}
            onClick={(e) => props.navigate(1)}
          >
            <path
              id="Trazado_4"
              data-name="Trazado 4"
              className="clsone-4"
              d="M562.86,68.09c-1.35-15.31-20.46-29.8-87.22-51.51C407.48-5.59,379-5.61,373.88,17.32a2.57,2.57,0,0,0-.06.4A344.21,344.21,0,0,1,559,78.06C562.78,72.34,563,69.53,562.86,68.09Z"
            />{" "}
            <path
              id="Trazado_14"
              data-name="Trazado 14"
              className="clsone-11"
              style={props.step1 === 1 ? { fill: "#4586c5" } : {}}
              d="M558.43,78.88c.2-.28.38-.55.55-.82A344,344,0,0,0,373.81,17.72c-.27,2.48-1.17,15.1-1.4,25.45q-.27,11.65-.53,23.32c-.21,9.06-.46,18.11-.68,27.17-.17,7.19-.29,14.38-.45,21.57q-.33,13.67-.68,27.33c-.16,7.13-.28,14.26-.45,21.39,0,.72,0,1.43-.05,2.14A196.44,196.44,0,0,1,475.3,200.21C503,159.42,528.81,121.63,558.43,78.88Z"
            />
            <path
              id="Trazado_24"
              data-name="Trazado 24"
              className="clsone-15"
              style={
                props.step1 === 1 ? { fill: "#367abc", opacity: "100%" } : {}
              }
              d="M368.65,206.74v.12a1.63,1.63,0,0,0,1.58,1.63,153.87,153.87,0,0,1,80.13,25.65,1.63,1.63,0,0,0,2.25-.44c7.78-11.47,15.31-22.58,22.71-33.46a196.47,196.47,0,0,0-105.74-34.12q-.3,12.78-.63,25.56C368.83,196.69,368.74,201.71,368.65,206.74Z"
            />
            <circle
              id="Elipse_10"
              data-name="Elipse 10"
              className="clsone-4"
              cx="414.9"
              cy="207.07"
              r="46.44"
              style={props.step1 === 1 ? { fill: "#4586c5" } : {}}
             
            />
            <g id="Xcelence" className="clsone-22">
              <text
                className="clsone-23"
                transform="translate(402.6 220.22)"
                id="text56"
              >
                1
              </text>
            </g>
            <g
              id="Autoevaluación_acadé"
              data-name="Autoevaluación acadé"
              className="clsone-22"
            >
              <switch>
                <foreignObject
                  className={`clsone-24 ${
                    props.step1 === 1 ? "txtRoscoComplete" : null
                  }`}
                  width='100'
                  height='100'
                  transform="translate(410 68)"
                >
                  <p
                    className="text-start"
                    xmlns="http://www.w3.org/1999/xhtml"
                  >
                    <Language langKey="key_label_1" />
                  </p>
                </foreignObject>
                <text>Your SVG viewer cannot display html.</text>
              </switch>
            </g>
          </g>
        </g>
        {/* ROSCO 2 */}
        <g id="RoscoStandard2" data-name="RoscoStandard2">
          <g
            id="Capa_1-2"
            data-name="Capa 1"
            style={{ cursor: "pointer" }}
            onClick={(e) => props.navigate(2)}
          >
            <path
              id="Trazado_9"
              data-name="Trazado 9"
              className="clstwo-9"
              style={props.step2 === 1 ? { fill: "#367bbc" } : {}}
              d="M690,248.75c.7-.3,1.15-.5,1.28-.58,20.23-12,11.45-39-30.63-97C619.38,94.29,599.72,80.57,584.73,84c-1.42.32-4,1.38-8.33,6.81A346.7,346.7,0,0,1,690,248.75Z"
            />
            <path
              id="Trazado_19"
              data-name="Trazado 19"
              className="clstwo-11"
              style={props.step2 === 1 ? { fill: "#4586c5" } : {}}
              d="M550.64,298.22l2.38-.83c6.73-2.36,13.48-4.67,20.21-7,8.6-3,17.2-6.05,25.81-9.06,6.78-2.37,13.59-4.69,20.38-7.07,8.55-3,17.09-6,25.64-9q11-3.85,22-7.67c8.83-3.08,19.26-7.24,22.88-8.79A346.67,346.67,0,0,0,576.39,90.82c-.18.24-.38.47-.57.73C544.27,132.89,516.25,169.08,486,208A197.84,197.84,0,0,1,550.64,298.22Z"
            />
            <path
              id="Trazado_29"
              data-name="Trazado 29"
              className="clstwo-20"
              style={
                props.step2 === 1 ? { fill: "#367abc", opacity: "100%" } : {}
              }
              d="M461.4,242.18a153.89,153.89,0,0,1,49.07,68.34,1.63,1.63,0,0,0,2,1l.12,0c4.75-1.64,9.51-3.27,14.25-4.93q11.89-4.16,23.76-8.33A197.84,197.84,0,0,0,486,208L461.13,239.9A1.63,1.63,0,0,0,461.4,242.18Z"
            />
            <circle
              id="Elipse_12"
              data-name="Elipse 12"
              className="clstwo-9"
              cx="494.95"
              cy="267.99"
              r="46.44"
            />
            <g id="Xcelence-2" className="clstwo-22">
              <text
                className="clstwo-23"
                transform="translate(483.52 282.35)"
                id="text323"
              >
                2
              </text>
            </g>
            <g
              id="Autoevaluación_acadé-2"
              data-name="Autoevaluación acadé-2"
              className="clstwo-22"
            >
              <switch>
                <foreignObject
                  className={`clsone-24 ${
                    props.step2 === 1 ? "txtRoscoComplete" : null
                  }`}
                  width='100'
                  height='100'
                  transform="translate(542 171)"
                >
                  <p
                    className="text-start"
                    xmlns="http://www.w3.org/1999/xhtml"
                  >
                    <Language langKey="key_label_2" />
                  </p>
                </foreignObject>
              </switch>
            </g>
          </g>
        </g>
      </g>
      {/* ROSCO 3 */}
      <g id="RoscoStandard3" data-name="RoscoStandard3">
        <g
          id="Capa_1-2"
          data-name="Capa 1"
          style={{ cursor: "pointer" }}
          onClick={(e) => props.navigate(3)}
        >
          <path
            id="Trazado_2"
            data-name="Trazado 2"
            className="clsthree-2"
            style={props.step3 === 1 ? { fill: "#54a564" } : {}}
            d="M696.43,265.5c-.13,0-.47.08-1,.2a348,348,0,0,1,.3,194.56c6.75,1.92,9.53,1.22,10.88.66,14.15-6,22-28.67,22-98.88C728.6,290.36,719.83,263.3,696.43,265.5Z"
          />
          <path
            id="Trazado_12"
            data-name="Trazado 12"
            className="clsthree-11"
            style={props.step3 === 1 ? { fill: "#5eb26b" } : {}}
            d="M695.41,265.7c-3.45.77-14.77,3.62-24,6.4l-22.34,6.71c-8.68,2.6-17.37,5.16-26,7.76-6.89,2.06-13.77,4.17-20.65,6.24-8.75,2.62-17.48,5.2-26.21,7.82-6.84,2.05-13.66,4.13-20.49,6.18l-2,.61a198.48,198.48,0,0,1,.31,111.12c47.35,13.73,91.25,26.57,141,41.51l.76.21a348,348,0,0,0-.31-194.56Z"
          />
          <path
            id="Trazado_22"
            data-name="Trazado 22"
            className="clsthree-13"
            style={
              props.step3 === 1 ? { fill: "#54a464", opacity: "100%" } : {}
            }
            d="M553.6,307.42l-24.49,7.31q-7.22,2.16-14.43,4.38l-.12,0a1.64,1.64,0,0,0-1.07,2,153.88,153.88,0,0,1,.41,84.13,1.63,1.63,0,0,0,1.11,2l38.91,11.25a198.48,198.48,0,0,0-.31-111.12Z"
          />
          <circle
            id="Elipse_14"
            data-name="Elipse 14"
            className="clsthree-2"
            cx="528.14"
            cy="364.08"
            r="46.44"
            style={props.step3 === 1 ? { fill: "#60b471" } : {}}
          />
          <g id="Xcelence-3" className="clsthree-22">
            <text
              className="clsthree-23"
              transform="translate(516.32 378.54)"
              id="text543"
            >
              3
            </text>
          </g>
          <g
            id="Autoevaluación_acadé-3"
            data-name="Autoevaluación acadé-3"
            className="clsthree-22"
          >
            <switch>
              <foreignObject
                className={`clsone-24 ${
                  props.step3 === 1 ? "txtRoscoComplete" : null
                }`}
                width='110'
                height='100'
                transform="translate(582 316)"
              >
                <p
                  className="text-start"
                  xmlns="http://www.w3.org/1999/xhtml"
                >
                  <Language langKey="key_label_3" />
                </p>
              </foreignObject>
            </switch>
          </g>
        </g>

        {/* ROSCO 4 */}
        <g
          id="RoscoStandard4"
          data-name="RoscoStandard4"
          style={{ cursor: "pointer" }}
          onClick={(e) => props.navigate(4)}
        >
          <g id="Capa_1-2" data-name="Capa 1">
            <path
              id="Trazado_7"
              data-name="Trazado 7"
              className="clsfour-7"
              style={props.step4 === 1 ? { fill: "#54a464" } : {}}
              d="M574.24,637.86c.42.48.7.79.8.88,17.62,15.56,40.66-1.13,82.89-59,41.37-56.73,48.37-79.65,40.48-92.85-.76-1.27-2.63-3.5-9.41-6A346.65,346.65,0,0,1,574.24,637.86Z"
            />
            <path
              id="Trazado_17"
              data-name="Trazado 17"
              className="clsfour-11"
              style={props.step4 === 1 ? { fill: "#5eb16b" } : {}}
              d="M484.13,520.4l1.54,2c4.31,5.69,8.59,11.39,12.9,17.06q8.26,10.9,16.57,21.77c4.35,5.72,8.66,11.48,13,17.21q8.22,10.81,16.47,21.61,7.07,9.29,14.09,18.59c5.76,7.59,13.1,16.42,15.54,19.22A346.74,346.74,0,0,0,689,480.88l-.5-.19C639.4,463.39,596.3,447.86,549.87,431A197.88,197.88,0,0,1,484.13,520.4Z"
            />
            <path
              id="Trazado_27"
              data-name="Trazado 27"
              className="clsfour-18"
              style={
                props.step4 === 1 ? { fill: "#54a464", opacity: "100%" } : {}
              }
              d="M510,418.23A153.93,153.93,0,0,1,460.05,486a1.63,1.63,0,0,0-.32,2.24.86.86,0,0,0,.07.1q4.54,6,9.09,12,7.62,10,15.25,20A197.94,197.94,0,0,0,549.86,431L512,417.27A1.63,1.63,0,0,0,510,418.23Z"
            />
            <circle
              id="Elipse_7"
              data-name="Elipse 7"
              className="clsfour-7"
              cx="497.32"
              cy="460.59"
              r="46.44"
              style={props.step4 === 1 ? { fill: "#75bb80" } : {}}
            />
            <g id="Xcelence-4" className="clsfour-22">
              <text
                className="clsfour-23"
                transform="translate(483.52 473.92)"
                id="text763"
              >
                4
              </text>
            </g>
            <g
              id="Autoevaluación_acadé-4"
              data-name="Autoevaluación acadé-4"
              className="clsfour-22"
            >
            <switch>
              <foreignObject
                className={`clsone-24 ${
                  props.step4 === 1 ? "txtRoscoComplete" : null
                }`}
                width='110'
                height='100'
                transform="translate(552 500)"
              >
                <p
                  className="text-start"
                  xmlns="http://www.w3.org/1999/xhtml"
                >
                  <Language langKey="key_label_4" />
                </p>
              </foreignObject>
            </switch>
            </g>
          </g>
        </g>
        {/* ROSCO 5 */}
        <Tooltip 
          open={!isCEPACenter ? false : undefined}
          title={isCEPACenter && <Language langKey="key_five_not_allowed" />}>
          <g
            id="RoscoStandard5"
            data-name="RoscoStandard5"
            style={{ cursor: "pointer" }}
            onClick={isCEPACenter ? undefined : props.navigate.bind(null, 5)}
          >
            <g id="Capa_1-2" data-name="Capa 1">
              <path
                id="Trazado_5"
                data-name="Trazado 5"
                className="clsfive-5"
                style={props.step5 === 1 ? { fill: "#88c493" } : {}}
                d="M372.75,709.84c5.08,23,33.53,23,101.76,1.09,66.84-21.49,86-35.91,87.39-51.22.13-1.45,0-4.29-3.9-10.08a344.18,344.18,0,0,1-185.32,59.7C372.71,709.59,372.74,709.75,372.75,709.84Z"
              />
              <path
                id="Trazado_15"
                data-name="Trazado 15"
                className="clsfive-11"
                style={props.step5 === 1 ? { fill: "#88c593" } : {}}
                d="M369,563.18c.14,7.13.24,14.26.38,21.39.18,9.12.4,18.24.58,27.35.14,7.19.24,14.38.38,21.57.19,9.06.41,18.12.59,27.17.17,7.77.3,15.55.45,23.32.19,10.2,1,22.6,1.31,25.35A344.11,344.11,0,0,0,558,649.62l-.48-.73C528,606,502.32,568.11,474.73,527.19a196.52,196.52,0,0,1-105.79,33.73C369,561.67,369,562.43,369,563.18Z"
              />
              <path
                id="Trazado_25"
                data-name="Trazado 25"
                className="clsfive-16"
                style={
                  props.step5 === 1 ? { fill: "#7ab186", opacity: "100%" } : {}
                }
                d="M450,493.28a154,154,0,0,1-80.22,25.37,1.62,1.62,0,0,0-1.58,1.62v.12c.08,5,.15,10.06.25,15.09q.25,12.72.54,25.44a196.52,196.52,0,0,0,105.79-33.73c-7.33-10.89-14.81-22-22.52-33.46A1.63,1.63,0,0,0,450,493.28Z"
              />
              <circle
                id="Elipse_9"
                data-name="Elipse 9"
                className="clsfive-5"
                cx="414.99"
                cy="522.19"
                r="46.44"
                style={props.step5 === 1 ? { fill: "#88c493" } : {}}
              />
              <g id="Xcelence-8" className="clsfive-22">
                <text
                  className="clsfive-23"
                  transform="translate(401.73 535.58)"
                  id="text4879"
                >
                  5
                </text>
              </g>
              <g
                id="Autoevaluación_acadé-5"
                data-name="Autoevaluación acadé-5"
                className="clsfive-22"
              >
              <switch>
                <foreignObject
                  className={`clsone-24 ${
                    props.step5 === 1 ? "txtRoscoComplete" : null
                  }`}
                  transform="translate(398 597)"
                  height='100'
                  width='110'
                >
                  <p
                    className="text-start"
                    xmlns="http://www.w3.org/1999/xhtml"
                  >
                    <Language langKey="key_label_5" />
                  </p>
                </foreignObject>
              </switch>
              </g>
            </g>
          </g>
        </Tooltip>
        {/* ROSCO 6 */}
        <g id="RoscoStandard6" data-name="RoscoStandard6">
          <g
            id="Capa_1-2"
            data-name="Capa 1"
            style={{ cursor: "pointer" }}
            onClick={(e) => props.navigate(6)}
          >
            <path
              id="Trazado_3"
              data-name="Trazado 3"
              className="clssix-3"
              style={props.step6 === 1 ? { fill: "#895f99" } : {}}
              d="M250.28,709.45c66.77,21.72,90.74,21.25,100.83,9.65.93-1.06,2.38-3.35,2.72-9.79a344.09,344.09,0,0,1-185.48-60.13C159.25,670.67,182.33,687.35,250.28,709.45Z"
            />
            <path
              id="Trazado_13"
              data-name="Trazado 13"
              className="clssix-11"
              style={props.step6 === 1 ? { fill: "#986ba8" } : {}}
              d="M251.25,527.9c-4.06,5.86-8.16,11.7-12.21,17.57-5.2,7.49-10.35,15-15.55,22.51-4.09,5.9-8.23,11.79-12.32,17.71q-7.72,11.17-15.43,22.37-6.63,9.6-13.29,19.17c-6.37,9.17-13.67,20.94-14,21.77a1.07,1.07,0,0,0-.07.19,344,344,0,0,0,185.47,60.13q0-.74.06-1.53c1.2-51.95,2.56-97.66,4.16-146.9a196.36,196.36,0,0,1-105.66-34.62Z"
            />
            <path
              id="Trazado_23"
              data-name="Trazado 23"
              className="clssix-14"
              style={
                props.step6 === 1 ? { fill: "#896099", opacity: "100%" } : {}
              }
              d="M357.82,518.64a153.9,153.9,0,0,1-79.89-26.4,1.63,1.63,0,0,0-2.23.39.86.86,0,0,1-.07.1q-4.32,6.18-8.63,12.38l-14.61,21.15a196.43,196.43,0,0,0,105.66,34.61c.42-13.2.87-26.65,1.33-40.54A1.64,1.64,0,0,0,357.82,518.64Z"
            />
            <circle
              id="Elipse_11"
              data-name="Elipse 11"
              className="clssix-3"
              cx="312.35"
              cy="520.49"
              r="46.44"
              style={props.step6 === 1 ? { fill: "#986ca8" } : {}}
            />
            <g id="Xcelence-7" className="clssix-22">
              <text
                className="clssix-23"
                transform="translate(298.93 535.58)"
                id="text5093"
              >
                6
              </text>
            </g>
            <g
              id="Autoevaluación_acadé-6"
              data-name="Autoevaluación acadé-6"
              className="clssix-22"
            >
              <switch>
                <foreignObject
                  className={`clsone-24 ${
                    props.step6 === 1 ? "txtRoscoComplete" : null
                  }`}
                  transform="translate(233 576)"
                  height='100'
                  width='110'
                >
                  <p
                    className="text-start"
                    xmlns="http://www.w3.org/1999/xhtml"
                  >
                    <Language langKey="key_label_6" />
                  </p>
                </foreignObject>
              </switch>
            </g>
          </g>
        </g>
        {/* ROSCO 7 */}
        <g id="RoscoStandard7" data-name="RoscoStandard7">
          <g
            id="Capa_1-2"
            data-name="Capa 1"
            style={{ cursor: "pointer" }}
            onClick={(e) => props.navigate(7)}
          >
            <path
              id="Trazado_10"
              data-name="Trazado 10"
              className="clsseven-10"
              style={props.step7 === 1 ? { fill: "#ca4655" } : {}}
              d="M37.94,480.77c-5.15,2.14-6.71,4-7.39,5.16-7.92,13.18-1,36.13,40.24,93,41.94,57.82,64.92,74.65,82.55,59.41A346.67,346.67,0,0,1,37.94,480.77Z"
            />
            <path
              id="Trazado_20"
              data-name="Trazado 20"
              className="clsseven-11"
              style={props.step7 === 1 ? { fill: "#db4b59" } : {}}
              d="M178.52,430.78c-46.19,16.62-89.13,32-138,49-.94.33-1.78.66-2.55,1a346.81,346.81,0,0,0,115.4,157.54l.18-.15c.68-.6,9.63-11.17,16.41-20l14.13-18.55q8.26-10.8,16.54-21.57c4.37-5.72,8.69-11.46,13.05-17.18q8.29-10.86,16.62-21.72c4.33-5.67,8.63-11.36,12.95-17l1.22-1.59A198,198,0,0,1,178.52,430.78Z"
            />
            <path
              id="Trazado_30"
              data-name="Trazado 30"
              className="clsseven-21"
              style={props.step7 === 1 ? { opacity: "100%" } : {}}
              d="M269.17,488.05a.86.86,0,0,1,.07-.1,1.64,1.64,0,0,0-.31-2.25,153.8,153.8,0,0,1-49.72-67.87,1.64,1.64,0,0,0-2.09-1l-38.6,13.92a197.94,197.94,0,0,0,65.91,89.7q7.8-10.22,15.63-20.41Q264.64,494.07,269.17,488.05Z"
            />
            <circle
              id="Elipse_13"
              data-name="Elipse 13"
              className="clsseven-10"
              cx="231.99"
              cy="461.25"
              r="46.44"
              style={props.step7 === 1 ? { fill: "#db4b59" } : {}}
            />
            <g id="Xcelence-6" className="clsseven-22">
              <text
                className="clsseven-23"
                transform="translate(220.2 473.92)"
                id="text5307"
              >
                7
              </text>
            </g>
            <g
              id="Autoevaluación_acadé-7"
              data-name="Autoevaluación acadé-7"
              className="clsseven-22"
            >
              <switch>
                <foreignObject
                  className={`clsone-24 ${
                    props.step7 === 1 ? "txtRoscoComplete" : null
                  }`}
                  transform="translate(93 481)"
                  height='100'
                  width='105'
                >
                  <p
                    className="text-start"
                    xmlns="http://www.w3.org/1999/xhtml"
                  >
                    <Language langKey="key_label_7" />
                  </p>
                </foreignObject>
              </switch>
            </g>
          </g>
        </g>
        {/* ROSCO 8 */}
        <g id="RoscoStandard8" data-name="RoscoStandard8">
          <Tooltip open={!isFPCenter ? false : undefined} title={isFPCenter && <Language langKey="key_eight_not_allowed" />}>
          <g
            id="Capa_1-2"
            data-name="Capa 1"
            style={{ cursor: "pointer" }}
            onClick={isFPCenter ? undefined : props.navigate.bind(null, 8)}
          >
            <path
              id="Trazado_1"
              data-name="Trazado 1"
              className="clsseight-1"
              style={props.step8 === 1 ? { fill: "#c65c5f" } : {}}
              d="M31.65,265.46C8.65,263.73,0,290.92,0,362c0,70.21,7.85,92.86,22,98.88,1.24.52,3.71,1.16,9.38-.26a348,348,0,0,1,.26-195.2Z"
            />
            <path
              id="Trazado_11"
              data-name="Trazado 11"
              className="clsseight-11"
              style={props.step8 === 1 ? { fill: "#df6868" } : {}}
              d="M174.86,307.38l-1.91-.57c-6.83-2.05-13.65-4.13-20.48-6.18-8.75-2.63-17.48-5.2-26.22-7.82-6.89-2.07-13.75-4.18-20.64-6.24-8.68-2.6-17.38-5.16-26-7.76q-11.18-3.33-22.34-6.71c-10.69-3.21-24.14-6.52-25-6.6l-.53,0a348,348,0,0,0-.26,195.2c.7-.17,1.45-.37,2.26-.61,49.74-14.93,93.61-27.76,140.9-41.47a198.4,198.4,0,0,1,.31-111.2Z"
            />
            <path
              id="Trazado_21"
              data-name="Trazado 21"
              className="clsseight-12"
              style={
                props.step8 === 1 ? { fill: "#c65c5f", opacity: "100%" } : {}
              }
              d="M214.71,405.28a153.88,153.88,0,0,1,.4-84.13,1.63,1.63,0,0,0-1.06-2l-.11,0q-7.23-2.21-14.44-4.38-12.31-3.68-24.64-7.35a198.44,198.44,0,0,0-.31,111.21c12.71-3.69,25.66-7.44,39-11.3A1.62,1.62,0,0,0,214.71,405.28Z"
            />
            <circle
              id="Elipse_15"
              data-name="Elipse 15"
              className="clsseight-1"
              cx="200.96"
              cy="364.22"
              r="46.44"
              style={props.step8 === 1 ? { fill: "#df6868" } : {}}
            />
            <g id="Xcelence-5" className="clsseight-22">
              <text
                className="clsseight-23"
                transform="translate(188.29 378.54)"
                id="text5521"
              >
                8
              </text>
            </g>
            <g
              id="Autoevaluación_acadé-8"
              data-name="Autoevaluación acadé-8"
              className="clsseight-22"
            >
              <switch>
                <foreignObject
                  className={`clsone-24 ${
                    props.step8 === 1 ? "txtRoscoComplete" : null
                  }`}
                  transform="translate(50 327)"
                  height='100'
                  width='105'
                >
                  <p
                    className="text-start"
                    xmlns="http://www.w3.org/1999/xhtml"
                  >
                    <Language langKey="key_label_8" />
                  </p>
                </foreignObject>
              </switch>
            </g>
          </g>
          </Tooltip>
        </g>
        {/* ROSCO 9 */}
        <g id="RoscoStandard9" data-name="RoscoStandard9">
          <g
            id="Capa_1-2"
            data-name="Capa 1"
            style={{ cursor: "pointer" }}
            onClick={(e) => props.navigate(9)}
          >
            <path
              id="Trazado_8"
              data-name="Trazado 8"
              className="clsnine-8"
              style={props.step9 === 1 ? { fill: "#c26f69" } : {}}
              d="M152.75,89.15c-3.75-4.48-6.1-5.42-7.43-5.73-15-3.47-34.67,10.19-76,66.92-41.76,57.25-50.88,84.32-31.57,96.55A346.67,346.67,0,0,1,152.75,89.15Z"
            />
            <path
              id="Trazado_18"
              data-name="Trazado 18"
              className="clsnine-11"
              style={props.step9 === 1 ? { fill: "#e38077" } : {}}
              d="M243.44,207.29c-30.06-38.85-57.89-75-89.22-116.3-.51-.67-1-1.28-1.47-1.84a346.8,346.8,0,0,0-115,157.74l.68.42c.77.46,13.58,5.72,24.12,9.42l22,7.74c8.54,3,17.08,6.07,25.62,9.08,6.78,2.4,13.58,4.74,20.36,7.13,8.59,3,17.18,6.1,25.78,9.13,6.73,2.37,13.47,4.71,20.19,7.08l1.62.57A198,198,0,0,1,243.44,207.29Z"
            />
            <path
              id="Trazado_28"
              data-name="Trazado 28"
              className="clsnine-19"
              style={
                props.step9 === 1 ? { fill: "#c26f69", opacity: "100%" } : {}
              }
              d="M216.82,311.09l.11,0a1.65,1.65,0,0,0,2-1,153.92,153.92,0,0,1,49.25-68.2,1.62,1.62,0,0,0,.28-2.28c-8.6-11.09-16.92-21.83-25.07-32.37a197.87,197.87,0,0,0-65.35,90.18q12.24,4.32,24.49,8.67Z"
            />
            <circle
              id="Elipse_6"
              data-name="Elipse 6"
              className="clsnine-8"
              cx="232.31"
              cy="268.47"
              r="46.44"
              style={props.step9 === 1 ? { fill: "#e38077" } : {}}
            />
            <g id="Xcelence-9" className="clsnine-22">
              <text
                className="clsnine-23"
                transform="translate(219.32 282.77)"
                id="text5750"
              >
                9
              </text>
            </g>
            <g
              id="Autoevaluación_acadé-9"
              data-name="Autoevaluación acadé-9"
              className="clsnine-22"
            >
              <switch>
                <foreignObject
                  className={`clsone-24 ${
                    props.step9 === 1 ? "txtRoscoComplete" : null
                  }`}
                  transform="translate(91 162)"
                  height='100'
                  width='110'
                >
                  <p
                    className="text-start"
                    xmlns="http://www.w3.org/1999/xhtml"
                  >
                    <Language langKey="key_label_9" />
                  </p>
                </foreignObject>
              </switch>
            </g>
          </g>
        </g>
        {/* ROSCO 10 */}

        <g
          id="RoscoStandard10"
          data-name="RoscoStandard10"
          transform="translate(1.3304584)"
        >
          <g
            id="Capa_1-2"
            data-name="Capa 1"
            style={{ cursor: "pointer" }}
            onClick={(e) => props.navigate(10)}
          >
            <path
              id="Trazado_6"
              data-name="Trazado 6"
              className="clsten-6"
              d="m 352.33,8 c -10.06,-11.63 -34,-12.17 -100.87,9.32 -67.91,21.84 -91.09,38.43 -82.18,59.89 A 344.19,344.19 0 0 1 355,17.67 C 354.67,11.32 353.25,9 352.33,8 Z"
              style={
                props.step10 === 1 ? { fill: "#c5763c" } : { fill: "#d27e40" }
              }
            />
            <path
              id="Trazado_16"
              data-name="Trazado 16"
              className="clsten-11"
              d="m 355.06,19.32 c 0,-0.59 0,-1.13 -0.06,-1.66 a 344.22,344.22 0 0 0 -185.72,59.52 2.88,2.88 0 0 1 0.13,0.31 c 0.35,0.82 7.61,12.62 14,21.81 q 6.62,9.61 13.23,19.21 L 212,140.94 c 4.07,5.92 8.18,11.82 12.25,17.74 q 7.76,11.26 15.47,22.56 c 4.05,5.88 8.12,11.73 12.15,17.61 0.36,0.51 0.72,1 1.07,1.55 a 196.43,196.43 0 0 1 105.84,-34.29 c -1.48,-49.2 -2.7,-94.88 -3.72,-146.79 z"
              style={
                props.step10 === 1 ? { fill: "#d27f40" } : { fill: "#f6f8f8" }
              }
            />
            <path
              id="Trazado_26"
              data-name="Trazado 26"
              className="clsten-17"
              d="m 276.08,234.1 a 0.61,0.61 0 0 1 0.07,0.1 1.63,1.63 0 0 0 2.23,0.4 153.9,153.9 0 0 1 80,-26.13 1.65,1.65 0 0 0 1.57,-1.68 c -0.42,-13.94 -0.82,-27.44 -1.21,-40.68 A 196.43,196.43 0 0 0 252.88,200.4 l 14.61,21.3 q 4.28,6.21 8.59,12.4 z"
              style={
                props.step10 === 1
                  ? { opacity: "100%", isolation: "isolate", fill: "#c5763c" }
                  : { opacity: "0.25", isolation: "isolate", fill: "#c5763c" }
              }
            />
            <circle
              id="Elipse_8"
              data-name="Elipse 8"
              className="clsten-6"
              cx="314.35001"
              cy="208.67"
              r="46.439999"
              style={
                props.step10 === 1 ? { fill: "#d27e40" } : { fill: "#d27e40" }
              }
            />
            <g
              id="Xcelence-10"
              className="clsten-22"
              style={{ isolation: "isolate" }}
            >
              <text
                className="clsten-23 clsten-23b"
                transform="translate(288.02,224.17)"
                id="text83"
              >
                10
              </text>
            </g>
            <g
              id="Autoevaluación_acadé-10"
              data-name="Autoevaluación acadé-10"
              className="clsten-22"
              style={{ isolation: "isolate" }}
            >
              <switch>
                <foreignObject
                  className={`clsone-24 ${
                    props.step10 === 1 ? "txtRoscoComplete" : null
                  }`}
                  transform="translate(234 60)"
                  height='100'
                  width='112'
                >
                  <p
                    className="text-start"
                    xmlns="http://www.w3.org/1999/xhtml"
                  >
                    <Language langKey="key_label_10" />
                  </p>
                </foreignObject>
              </switch>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
