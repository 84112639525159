import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";
import { history } from "helpers/history";

export default function* watcherCargos() {  
  yield takeEvery(actionTypes.GET_CARGOS, getCargos);
}

function* getCargos() {
  yield put({ type: "START_REQUEST"})

  try {
    let apiEndpoint = "/v4/cargos"
    const res = yield call(() => new Promise((resolve, reject) => { 
        xcService.get(apiEndpoint)
          .then((response) => {
            resolve(response)})
          .catch((err) => {
              reject(err)});
        }));
        if(res){
          yield put({ type:  actionTypes.SET_CARGOS_LIST, payload: res });        
        }
      } catch (e) {
        yield put({ type: "API_ERRORED", payload: e });
      }
      yield put({ type: "END_REQUEST"})
}
