import { useState } from "react";

export const delay = async (time) => {
  await new Promise((resolve) => {
    setTimeout(resolve, time);
  });
};

export const useStatus = (initialState) => {
  const [state, setState] = useState(initialState);

  const updateState = (updateValue) => {
    if (typeof updateValue === 'function')
      setState((prevState) => ({
        ...prevState,
        ...updateValue(prevState)
      }));
    else 
      setState((prevState) => ({...prevState, ...updateValue})); 
  };

  return [state, updateState];
};