import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  Image,
} from "@react-pdf/renderer";
import * as _ from "lodash";
import { v1 as uuidv1 } from "uuid";

export default function Multiple(props) {
  return (
    <View key={uuidv1()}>
      {props.pregunta.respuestas.map((itemResp) => {
        return itemResp.id !== null ? (
          <View
            style={{ flexDirection: "row", marginRight: 30 }}
            key={uuidv1()}
          >
            {props.pregunta.infocompleta == 1 && (
              <Image
                style={{
                  width: 15,
                  height: 15,
                  marginLeft: 5,
                  marginTop: 7,
                }}
                src="/images/check.png"
              />
            )}
            <Text
              style={{
                padding: 10,
                color: "#383b41",
                fontSize: 11,
                fontWeight: 400,
                textAlign: "justify",

                fontFamily: "Roboto-Bold",
              }}
              key={uuidv1()}
            >
              {itemResp?.respuesta?.replace("*", "")}
            </Text>
          </View>
        ) : (
          <View
            style={{ flexDirection: "row", marginRight: 30 }}
            key={uuidv1()}
          >
            {props.pregunta.infocompleta == 1 && (
              <Image
                style={{
                  width: 15,
                  height: 15,
                  marginLeft: 5,
                  marginTop: 7,
                }}
                src="/images/white.png"
              />
            )}
            <Text
              style={{
                padding: 10,
                color: "#707373",
                fontSize: 11,
                fontWeight: 300,
                textAlign: "justify",
                fontFamily: "Roboto-Light",
              }}
            >
              {itemResp?.respuesta?.replace("*", "")}
            </Text>
          </View>
        );
      })}
    </View>
  );
}
