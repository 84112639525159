import React, { useState } from "react";
import { Button, Chip, FormControl, Grid, InputLabel, MenuItem, Select, Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";
import * as PropTypes from 'prop-types';
import { useStatus } from "utils/custon-hooks";
import { Language, getRawMessage } from "components/Language";
import { Info } from "@material-ui/icons";

const initialState = {
  ownership: '',
  ccaa: '',
  province: '',
  municipality: '',
  group: [],
  nivelsocioeconomico: []
}

export const savingLevelMap = {
  1: 'low',
  2: 'middle_low',
  3: 'middle',
  4: 'middle_high',
  5: 'high',
}

const getMuiSelectedClass = (arr = []) => arr.length > 0 ? '' : 'Mui-selected';

export function CenterDashboardFilter(props) {
  const { onFilter } = props;
  const [state, setState] = useStatus(initialState);

  const dashboardFilterAssets = useSelector(
    state => state.reportsReducer.dashboardFilterAssets
  );

  const [listProvinces, setListProvinces] = useState([]);
  const [listMunicipalities, setListMunicipalities] = useState([]);

  const handleChange = (event) => {
    setState({
      [event.target.name]: event.target.value
    });
  }

  const AACCHandleChange = (event) => {
    let ccaa = '';
    let provincesList = [];

    if (event.target.value !== '0') {
      provincesList = dashboardFilterAssets.provinces.filter(p => p.comunidad_id == event.target.value);
      ccaa = event.target.value;
    }

    setListProvinces(provincesList);
    setListMunicipalities([]);

    setState({
      ccaa,
      province: '',
      municipality: '',
    });
  }

  const ProvinceHandleChange = (event) => {
    if (event.target.value) {
      let filterMunicipalities = dashboardFilterAssets.municipalities.filter(m => m.id_provincia == event.target.value);
      setListMunicipalities(filterMunicipalities);
    }

    setState({
      [event.target.name]: event.target.value,
      municipality: '',
    });
  }

  const handleSubmit = () => {
    const filterLabels = [];

    for(let key in state) {
      let value = state[key];

      if(key === 'ownership') {
        value = getRawMessage(value?.toLowerCase());
      } else if(key === 'ccaa') {
        value = dashboardFilterAssets.ccaa.find(el => el.id == value)?.nombre;
      } else if(key === 'province') {
        value = listProvinces.find(el => el.id == value)?.provincia;
        key = 'provice';
      } else if(key === 'municipality') {
        value = listMunicipalities.find(el => el.id == value)?.nombre;
      } else if(key === 'nivelsocioeconomico') {
        value = value.map(item => getRawMessage(savingLevelMap[item]));
        key = 'economy_grade'; 
      }
      
      filterLabels.push({ key: getRawMessage(key), value });
    }

    onFilter(state, filterLabels);
  }

  const handleReset = () => {
    const defaultValues = {
      ownership: '',
      ccaa: '',
      province: '',
      municipality: '',
      group: [],
      nivelsocioeconomico: []
    }
    setListProvinces([]);
    setListMunicipalities([]);

    setState(defaultValues);

    onFilter();
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel><Language langKey="ownership" /></InputLabel>
          <Select
            value={state.ownership}
            onChange={handleChange}
            name='ownership'
            label={<Language langKey="ownership" />}
          >
            <MenuItem value=""><Language langKey="all" /></MenuItem>
            {dashboardFilterAssets.ownerships.map(({ ownership }) =>
              <MenuItem key={ownership} value={ownership}>
                <Language langKey={ownership?.toLowerCase()} />
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6} md={3} xl={3}>
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel><Language langKey="ccaa" /></InputLabel>
          <Select
            value={state.ccaa}
            onChange={AACCHandleChange}
            name='ccaa'
            label={<Language langKey="ccaa" />}
          >
            <MenuItem value=""><Language langKey="all" /></MenuItem>
            {dashboardFilterAssets.ccaa.map(({ id, nombre }) =>
              <MenuItem key={id} value={id}>
                {nombre}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <FormControl
          fullWidth
          variant="outlined"
          size="small"
          disabled={listProvinces.length === 0}
        >
          <InputLabel><Language langKey="provice" /></InputLabel>
          <Select
            value={state.province}
            onChange={ProvinceHandleChange}
            name='province'
            label={<Language langKey="provice" />}
          >
            <MenuItem value="">
              <Language langKey="all" />
            </MenuItem>
            {listProvinces.map(({ id, provincia }) =>
              <MenuItem key={id} value={id}>
                {provincia}
              </MenuItem>
            )}
          </Select>
        </FormControl>

      </Grid>

      <Grid item xs={12} sm={6} md={3} lg={2}>
        <FormControl
          fullWidth
          variant="outlined"
          size="small"
          disabled={listMunicipalities.length === 0}
        >
          <InputLabel><Language langKey="municipality" /></InputLabel>
          <Select
            value={state.municipality}
            onChange={handleChange}
            name="municipality"
            label={<Language langKey="municipality" />}
          >
            <MenuItem value="">
              <Language langKey="all" />
            </MenuItem>
            {listMunicipalities.map(({ id, nombre }) =>
              <MenuItem key={id} value={id}>{nombre}</MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>

      <Grid style={{position: 'relative'}} item xs={12} sm={6} md={3} lg={2}>
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel><Language langKey="group" /></InputLabel>
          <Select
            value={state.group}
            onChange={handleChange}
            multiple
            name='group'
            label={<Language langKey="group" />}
            className="multi_group_selector"
            renderValue={
              selected => selected.map(item => (
                <Chip
                  key={item}
                  className="mr-1"
                  label={item ?? 'Sin grupo'}
                />
              ))
            }
          >
            <MenuItem value="" className={getMuiSelectedClass(state.group)}>
              <Language langKey="all" />
            </MenuItem>
            {dashboardFilterAssets.groups.map(item =>
              <MenuItem key={item} value={item}>
                {item ?? <Language langKey="without_group" />}
              </MenuItem>
            )}
          </Select>
        </FormControl>
        <Tooltip
          placement="top"
          title={getRawMessage('group_helper')}
        >
          <Info
            fontSize="small"
            style={{
              position: 'absolute',
              top: -2,
              right: 10,
              paddingTop: 1.5,
              paddingLeft: 1.5,
              color: '#757c7e',
              backgroundColor: "#FFF"
            }}
          />
        </Tooltip>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel><Language langKey="economy_grade" /></InputLabel>
          <Select
            value={state.nivelsocioeconomico}
            onChange={handleChange}
            multiple
            name='nivelsocioeconomico'
            label={<Language langKey="economy_grade" />}
            className="multi_group_selector"
            renderValue={selected => selected.map(id => (
              <Chip
                key={id}
                className="mr-1"
                label={dashboardFilterAssets.levels.find(el => el.id === id)?.label}
              />
            ))}
          >
            <MenuItem value="" className={getMuiSelectedClass(state.nivelsocioeconomico)}>
              <Language langKey="all" />
            </MenuItem>
            {dashboardFilterAssets.levels.map(({ id }) =>
              <MenuItem key={id} value={id}>
                <Language langKey={savingLevelMap[id]} />
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Button
        color="primary"
        onClick={handleReset}
      >
        <Language langKey="clean_filter" />
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        <Language langKey="apply_filter" />
      </Button>
    </Grid>
  );
}

CenterDashboardFilter.propTypes = {
  onFilter: PropTypes.func.isRequired
}
