import React from "react";
import NavBarSimple from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import { history } from "helpers/history";
import { Language } from "components/Language.js";
import Button from "@material-ui/core/Button";

function messageOk() {
  const goTo = () => {
    history.push("/user/start");
  }

  return (
    <div className="fullPage">
      <NavBarSimple />
      <div className="defaultFull">
        <Grid container spacing={3} justifyContent="center" className="defaultgrid">
          <Grid item xs={12}>
            <div className="subh1home">
              <Language langKey="message.thanks_executive" />
            </div>
            <div className="txtHome marginSubTitle">
              <Language langKey="message.saved_datas" />
            </div>
            <div className="txtHome marginSubTitle">
              <Language langKey="message.support" />&nbsp;
              <a href="mailto:info@xcelence.es">info@xcelence.es</a>
            </div>

            <div className="txtHome marginSubTitle">
             
            </div>
            <Button
              onClick={() => goTo()}
              variant="contained"
              className="btnHome marginSubTitle"
            >
              <Language langKey="message.go_web" />
            </Button>
          </Grid>

          <Grid item xs={12}></Grid>
        </Grid>
      </div>
    </div>
  );
}
export default messageOk;
