import { takeEvery, call } from "redux-saga/effects";
import { xcService } from "../services";
import { POST_AWARD_REGISTRATION } from "constants/award.constants";

export function* watcherAwards() {
  yield takeEvery(POST_AWARD_REGISTRATION, awardRegistration);
}

function* awardRegistration({ payload }) {
  const {resolve, reject, body} = payload;

  try {
    let apiEndpoint = "/v15/awards";

    yield call(xcService.post, apiEndpoint, body);

    yield call(resolve);
  } catch (e) {
    yield call(reject);
  }
}
