import actionTypes from "constants/actionTypes";
const initialState = { counter: 0 };

export default (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELUSUARIO_DATA:
      return {
        ...state,
        delUsuario: action.payload
      };
    case actionTypes.DELUSUARIO_ERROR:
      return {
        ...state,
        delUsuarioError: action.payload
      };

    case actionTypes.UPDATEUSUARIO_DATA:
      return {
        ...state,
        updateUsuario: action.payload
      };
    case actionTypes.UPDATEUSUARIO_ERROR:
      return {
        ...state,
        updateUsuarioError: action.payload
      };

    case actionTypes.TRASPASA_USUARIO_DATA:
      return {
        ...state,
        traspasaUsuario: action.payload
      };
    case actionTypes.TRASPASA_USUARIO_ERROR:
      return {
        ...state,
        traspasaUsuarioError: action.payload
      };
    case actionTypes.END_TRANSACTION:
      return {
        delUsuario: null,
        datosCentro: null,
        datosCentroError: null,
        delUsuarioError: null,
        updateUsuario: null,
        updateUsuarioError: null
      };

      
    default:
      return {};
  }
};
