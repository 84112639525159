import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";
import { history } from "helpers/history";

export default function* watcherContenido() {
  yield takeEvery(actionTypes.CONTENIDOSHOME, contenidosHome);
  yield takeEvery(actionTypes.GETPRACTICAS, getPracticas);
  yield takeEvery(actionTypes.GETPRACTICA, getPractica);
  yield takeEvery(actionTypes.GETCENTROSHOME, getCentrosHome);
}


function* getCentrosHome() {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v10/contenidos/centros";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.get(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.GETCENTROSHOME_DATA, payload: res.data });
    } else {
      // console.log("ERROR");
      yield put({
        type: actionTypes.GETCENTROSHOME_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GETCENTROSHOME_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
function* getPracticas() {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v10/contenidos/practicas";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.get(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.GETPRACTICAS_DATA, payload: res.data });
    } else {
      console.log("ERROR");
      yield put({
        type: actionTypes.GETPRACTICAS_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GETPRACTICAS_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* getPractica(codigo) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v10/contenidos/practica/" + codigo.codigo;
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.get(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.GETPRACTICA_DATA, payload: res.data });
    } else {
      // console.log("ERROR");
      yield put({
        type: actionTypes.GETPRACTICA_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GETPRACTICA_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
function* contenidosHome() {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v10/contenidos/home";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.get(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.CONTENIDOSHOME_DATA, payload: res.data });
    } else {
      // console.log("ERROR");
      yield put({
        type: actionTypes.CONTENIDOSHOME_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.CONTENIDOSHOME_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
