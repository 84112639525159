import {
 SET_LANGUAGE,
 SAVE_PERIODS
} from '../constants/settings.constant';

const supportedLangs = ['ES', 'CA'];

const reg = new RegExp('^/(es|ca)/') ;
let lang;

let matched = reg.exec(window.location.pathname);

if(matched) {
  lang = matched[1];
} else {
  const uri = new URL(window.location.href);

  lang = uri.searchParams.get('lang');
}

if(
    lang &&
    supportedLangs.includes(lang.toUpperCase())
  ) {
  localStorage.setItem('lang', lang.toUpperCase());
}

const initialState = {
  locale: localStorage.getItem('lang') ?? 'ES',
  periods: []
};

export const setting = (state = initialState, action) => {
  const {type, payload} = action; 
  let result = state;

  if(type === SET_LANGUAGE) 
    result = {...state, locale: payload};

  else if(type === SAVE_PERIODS) 
    result = {
      ...state,
      periods: action.payload,
    }

  return result;
};

export const settingsReducer = (state = initialState, action = {}) => {

  return state;
};
