import { takeEvery, call, put } from "redux-saga/effects";
import { xcService } from '../services';

import { savePeriods } from "actions/settings.action";

import {FETCH_PERIODS} from "../constants/settings.constant"

export function* settingsSaga() {
  yield takeEvery(FETCH_PERIODS, fetchPeriods);
}

function* fetchPeriods() {
  try {
    let apiEndpoint = "/v7/allperiodos"

    const resp = yield call(xcService.get, apiEndpoint);

    if(resp) yield put(savePeriods(resp.data));

  } catch (error) {
    console.log(error);
  }
}
