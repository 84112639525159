import React, { useState, useEffect } from "react";
import NavBarSimple from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { userActions } from "actions/userActions";
import { useSelector, useDispatch } from "react-redux";
import { getRawMessage, Language } from "components/Language.js";
import { useForceUpdate } from "utils/custon-hooks.js";

const SITE_KEY = "6LfiNx0bAAAAACDYXqLmr2wC_L-YhB4x5cSzx8H5";

function Recover() {
  const [username, setUsername] = useState("");
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [infoMessage, setInfoMessage] = useState(false);
  const forceUpdate = useForceUpdate();

  const dispatch = useDispatch();

  const locale = useSelector((state) => state.setting.locale);
  const resetUser = useSelector(state => state.authReducer.forgot);
  const resetError = useSelector(state => state.authReducer.forgotError);
  const mLoading = useSelector(state => state.loading.loading);

  useEffect(() => {
    forceUpdate();
  }, [locale]);

  const setChangeUsername = event => {
    setUsername(event.target.value);
  };

  useEffect(() => {
    if (resetError) {
      setError(true);
      setErrorCode(resetError);
    } else {
      setError(false);

      if (resetUser) setInfoMessage(true);
    }
  }, [mLoading, resetError, resetUser]);

  const validEmail = function(mEmail) {
    let isValid = true;
    let EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (!mEmail.match(EMAIL_REGEX)) isValid = false;

    return isValid;
  };

  const saveForm = (e) => {
    let isValid = false;
    setError(false);
    if (validEmail(username)) {
      isValid = true;
    } else {
      setErrorCode(400);
      setError(true);
    }

    if (isValid) {
      e.preventDefault();
     
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
          dispatch(userActions.forgotPassword(username, token));
        });
      });
    }
  };

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
   
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
   
      if (isScriptExist && callback) callback();
    }
   
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
    });
  }, []);

  return (
    <div className="fullPage">
      <NavBarSimple />
      <div className="defaultFull">
        <Grid container spacing={3} justifyContent="center" className="defaultgrid">
          <Grid item xs={12}>
            <div className="h1home">
              <Language langKey="forgot_title" />
            </div>
            <div className="txtHome marginSubTitle">
              <Language langKey="forgot_caption" />
              <br />
              <Language langKey="second_forgot_caption" />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Language langKey="your_email" /> *
            <br/>
            <TextField
              onChange={e => setChangeUsername(e)}
              id="username"
              name="username"
              error={error}
              className="inputMargin widthF"
              variant="outlined"
              required
              placeholder={getRawMessage("user_placeholder")}
            />
            {error && (
              <div className="errorLayer">
                <i className="fa fa-exclamation-triangle marginerror"></i>
                <Language langKey={'fotgot_error_' + errorCode} />
              </div>
            )}
            {infoMessage && (
              <div className="infoLayer">
                <i className="fa fa-info marginerror"></i>
                {infoMessage}
                <Language langKey="recover_info" />
              </div>
            )}
          </Grid>

          <Grid item xs={12}>
            <Button
              onClick={e => saveForm(e)}
              variant="contained"
              disabled={!mLoading ? false : true}
              className={`registerWidth ${
                !mLoading ? "btnHome" : "btnDisabled"
              }`}
            >
              <Language langKey="send" />
            </Button>
            {mLoading && (
              <CircularProgress size={24} className="centerLoading" />
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Recover;
