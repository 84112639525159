import actionTypes from "constants/actionTypes";
const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ANTERIORES_DATA:
      return {
        ...state,
        anterioresData: action.payload,
      };
      case actionTypes.GET_ANTERIORES_ERROR:
        return {
          ...state,
          anterioresDataError: action.payload,
        };
    case actionTypes.GETCOLABORADORES_DATA:
      return {
        ...state,
        listaColaboradores: action.payload,
      };
    case actionTypes.GETCOLABORADORES_ERROR:
      return {
        ...state,
        listaColaboradoresError: action.payload,
      };
    case actionTypes.ADDCOLABORADOR_DATA:
      return {
        ...state,
        addColaborador: action.payload,
      };
    case actionTypes.ADDCOLABORADOR_ERROR:
      return {
        ...state,
        addColaboradorError: action.payload,
      };
    case actionTypes.DELCOLABORADOR_DATA:
      return {
        ...state,
        delColaborador: action.payload,
      };
    case actionTypes.DELCOLABORADOR_ERROR:
      return {
        ...state,
        delColaboradorError: action.payload,
      };
    case actionTypes.GETHISTORICO_DATA:
      return {
        ...state,
        listHistorico: action.payload,
      };
    case actionTypes.GETHISTORICO_ERROR:
      return {
        ...state,
        listHistoricoError: action.payload,
      };
    case actionTypes.GETCENTRO_DATA:
      return {
        ...state,
        datosCentro: action.payload,
      };
    case actionTypes.GETCENTRO_ERROR:
      return {
        ...state,
        datosCentroError: action.payload,
      };

    case actionTypes.UPDATECENTRO_DATA:
      return {
        ...state,
        updateCentro: action.payload,
      };
    case actionTypes.UPDATECENTRO_ERROR:
      return {
        ...state,
        updateCentroError: action.payload,
      };
      case actionTypes.GET_NIVELESANT_DATA:
        return {
          ...state,
          getNivelesAnt: action.payload,
        };
      case actionTypes.GET_NIVELESANT_ERROR:
        return {
          ...state,
          getNivelesAntError: action.payload,
        };
    case actionTypes.GET_NIVELES_DATA:
      return {
        ...state,
        getNiveles: action.payload,
      };
    case actionTypes.GET_NIVELES_ERROR:
      return {
        ...state,
        getNivelesError: action.payload,
      };
    case actionTypes.UPDATENIVELES_DATA:
      return {
        ...state,
        updateNiveles: action.payload,
      };
    case actionTypes.UPDATENIVELES_ERROR:
      return {
        ...state,
        updateNivelesError: action.payload,
      };
    case actionTypes.UPDATE_C_CURSOS_DATA:
      return {
        ...state,
        updateCursos: action.payload,
      };
    case actionTypes.UPDATE_C_CURSOS_ERROR:
      return {
        ...state,
        updateCursosError: action.payload,
      };
    case actionTypes.SAVEHISTORICO_SUCCESS:
      return {
        ...state,
        saveHistorico: action.payload,
      };
    case actionTypes.SAVEHISTORICO_ERROR:
      return {
        ...state,
        saveHistoricoError: action.payload,
      };

    case actionTypes.SEND_RESULTADOS_SUCCESS:
      return {
        ...state,
        sendResultados: action.payload,
      };
    case actionTypes.SEND_RESULTADOS_ERROR:
      return {
        ...state,
        sendResultadosError: action.payload,
      };

    case actionTypes.CHECK_RESULTADOS_SUCCESS:
      return {
        ...state,
        checkResultdos: action.payload,
      };
    case actionTypes.CHECK_RESULTADOS_ERROR:
      return {
        ...state,
        checkResultdosError: action.payload,
      };
      case actionTypes.GET_PREGUNTAS_DOWN_DATA:
        return {
          ...state,
          preguntasDown: action.payload,
        };
      case actionTypes.GET_PREGUNTAS_DOWN_ERROR:
        return {
          ...state,
          preguntasDownError: action.payload,
        };

    case actionTypes.SAVE_RESULTADOS_DATA:
      return {
        ...state,
        saveResultadosData: action.payload,
      };
    case actionTypes.SAVE_RESULTADOS_ERROR:
      return {
        ...state,
        saveResultadosError: action.payload,
      };

    case actionTypes.BORRAR_CENTRO_DATA:
      return {
        ...state,
        borrarDatosData: action.payload,
      };
    case actionTypes.BORRAR_CENTRO_ERROR:
      return {
        ...state,
        borrarDatosError: action.payload,
      };
    case actionTypes.END_TRANSACTION_CENTRO:
      return {
        updateCentro: null,
        updateCentroError: null,
        datosCentro: null,
        datosCentroError: null,
        getNiveles: null,
        getNivelesError: null,
        updateNiveles: null,
        updateNivelesError: null,
      };
    default:
      return state;
  }
};
