import React from "react";
import { useSelector } from "react-redux";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { ProgressBar } from "./ProgressBar";
import { Legend } from "./Legend";
import { Language } from "components/Language";

const useStyles = makeStyles({
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 5px',
  },
  tableData: {
    padding: '5px 0',
    borderTop: '1px solid #bfbfbf',
    borderBottom: '1px solid #bfbfbf',
    '&:first-child': {
      paddingRight: 8,
      borderLeft: '1px solid #bfbfbf',
    },
    '&:last-child': {
      paddingLeft: 8,
      borderRight: '1px solid #bfbfbf',
    },
  },
  difference: {
    display: 'inline-block',
    minWidth: 87,
    textAlign: 'center',
    padding: '3px 10px',
    whiteSpace: 'nowrap',
    color: '#517e33',
    borderLeft: '1px solid #bfbfbf',
    borderTop: '1px solid #bfbfbf',
    borderBottom: '1px solid #bfbfbf',
  },
});

export function ReportBody() {
  const resultsKeys = useSelector((state) => state.reportsReducer.resultsKeys);

  const classes = useStyles();

  const getDifferenceLabel = (result = 0) => {
    let prefix = "+ ";

    if (result < 0) prefix = "- ";

    result = Math.abs(result);
    result = Math.round(result * 100) / 100;

    return prefix + result.toLocaleString("es-ES");
  };

  return (
    <>
      <table className={classes.table}>
        <tbody>
          {resultsKeys.map((r, i) => (
            <tr key={i}>
              <td className={`${classes.tableData} px-2`}>
                <Typography variant="body1" align="center">
                  {r.key + ". " + r.title}
                </Typography>
              </td>
              <td width="55%" className={classes.tableData}>
                <ProgressBar percentage={r.restKeysAvg} color={r.color} />
                <ProgressBar
                  className="mt-1"
                  percentage={r.firstKeysAvg}
                  color="#bfbfbf"
                />
              </td>
              <td className={classes.tableData} align="right">
                <Typography
                  variant="h6"
                  className={classes.difference}
                >
                  {getDifferenceLabel(r.restKeysAvg - r.firstKeysAvg)}
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Grid
        container
        justifyContent="center"
        spacing={2}
        className="mt-1"
      >
        <Grid item>
          <Legend
            color={["#4587c5", "#df6868", "#d27e40"]}
            label={<Language langKey="key_report.post_quiz" />}
          />
        </Grid>
        <Grid item>
          <Legend
            color="#bfbfbf"
            label={<Language langKey="key_report.first_quiz" />}
          />
        </Grid>
      </Grid>
    </>
  );
}
