import React, { useState } from 'react';
import {Language} from 'components/Language';
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CloudDownload, NavigateNext } from '@material-ui/icons';
import { useSelector } from 'react-redux';

const navItems = ['/auth/resultados', '/auth/resultado-claves'];

export const ResearcherDropdown = () => {
  const [expanded, setExpanded] = useState(false);
  const locale = useSelector((state) => state.setting.locale);

  const getClassName = () => {
    let res = 'menuonboard1 menu-item-navbar';
    const mRuta = window.location.pathname;

    if (navItems.includes(mRuta)) res += ' menu-item-navbar-selected';

    return res;
  }

  return (
    <NavDropdown
      show={expanded}
      onClick={() => setExpanded(!expanded)}
      onMouseOver={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
      className={getClassName()}
      title={<Language langKey="researcher" />}
    >
      <Link
        className="dropdown-item submenu-item-navbar"
        to={{pathname: "/auth/resultados"}}
      >
        <Language langKey="view_results" />
      </Link>

      <Link
        className="dropdown-item submenu-item-navbar"
        to={{pathname: "/auth/resultado-claves"}}
      >
        <Language langKey="key_report.label" />
      </Link>

      <NavDropdown.Item
        href={`/${locale}/Cuestionario_Xcelence.pdf`}
        className="submenu-item-navbar"
        download="Cuestionario_Xcelence.pdf"
        target="_blank"
      >
        <Language langKey="download" />&nbsp;
        <CloudDownload className='posMenu' fontSize="small" />
      </NavDropdown.Item>

      <NavDropdown.Item
        href="/Manual_Xcelence_investigador.pdf"
        className="submenu-item-navbar"
        download="Manual_Xcelence_investigador.pdf"
      >
        <Language langKey="researcher_manual" />
        <CloudDownload className='posMenu' fontSize="small" />
      </NavDropdown.Item>

      <NavDropdown.Item
        href="https://www.youtube.com/watch?v=Tux4vFNWups"
        className="submenu-item-navbar"
        target="_blank"
      >
        <Language langKey="video_tutorial" />
        <NavigateNext className="posMenu" />
      </NavDropdown.Item>
      <NavDropdown.Item
        href="https://www.fundacionbertelsmann.org/publicaciones/?type=&lang=&category=orientacion-profesional&q="
        className="submenu-item-navbar"
        target="_blank"
      >
        <Language langKey="posts" />
        <NavigateNext className="posMenu" />
      </NavDropdown.Item>
    </NavDropdown>
  )
}