import React, {useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import "../contestar.css";
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as _ from "lodash";
import { Language } from "components/Language";

function ShowClaveHeader(props) {
  var apiDONE = useSelector(state => state.loading.APIactions);
  var loading = useSelector(state => state.loading.loading);

  const ColorCircularProgress = withStyles({
    root: {
      color: props.clave.color,
    },
  })(CircularProgress);
  useEffect(() => {
  }, [apiDONE]);

  return (
    <div>
      <Grid
        container
        className="headerClave"
        style={{backgroundColor:props.clave.color}}
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={12} >
          <Grid item xs={12}  >
            <Language langKey="key" /> {props.clave.num}: {props.clave.titulo}
          </Grid>
        </Grid>
      </Grid>
    {loading && (
      <Grid item xs={12} style={{marginTop:"30px"}}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item>
            <ColorCircularProgress size={30} thickness={5} />
          </Grid>
        </Grid>
      </Grid>
    )}
  </div>);
}
export default ShowClaveHeader;
