import React, { useMemo } from "react";
import NavBar from "../NavBar/NavBar.js";
import NavBarAuth from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import {TermsES} from "assets/policies";
import {useSelector} from "react-redux";
import Footer from "components/Footer/Footer.js";

export default function Condiciones() {
  const locale = useSelector((state) => state.setting.locale);

  const Componet = useMemo(() =>{
    const dictionary = {
      ES: TermsES,
      CA: TermsES,
    }

    return dictionary[locale] ?? (() => <p>No have</p>)
  } , [locale]);

  return (
    <div>
      { localStorage.getItem("xcauth") &&
        localStorage.getItem("xcauth") === "true" 
          ? <NavBarAuth />
          : <NavBar />
      }
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} className="privacityp">
          <Componet />
        </Grid>
      </Grid>

      <Footer />
    </div>
  );
}
