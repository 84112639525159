import React from "react";
import {
  Text,
  View,
} from "@react-pdf/renderer";
import * as _ from "lodash";

export default function Normal(props) {
  return (
    <View>
      {props.pregunta.respuestas.map((itemResp, i) => {
        return (
          itemResp.id !== null && (
            <Text
              style={{
                padding: 10,
                color: "#383b41",
                fontSize: 11,
                fontWeight: 400,
                textAlign: "justify",
                fontFamily: "Roboto-Bold",
              }}
              key={i}
            >
              {itemResp.respuesta?.replace("*", "")}
            </Text>
          )
        );
      })}
    </View>
  );
}
