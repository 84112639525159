import {
  DOWNLOAD_SIMPLE_PDF_REPORT,
  FETCH_ANSWERS_REPORT,
  FETCH_CENTER_DASHBOARD_FILTER,
  FETCH_RESULTS_KEYS,
  SAVE_ANSWERS_REPORT,
  SAVE_CENTER_DASHBOARD_FILTER,
  SAVE_RESULTS_KEYS_REPORT
} from "constants/reports.constant";

export function fetchAnswersReport(payload = {}) {
  return {
    type: FETCH_ANSWERS_REPORT,
    payload,
  };
}

export function saveAnswersReport(payload = {}) {
  return {
    type: SAVE_ANSWERS_REPORT,
    payload,
  };
}

export function fetchCenterDashboardFilter(payload = {}) {
  return {
    type: FETCH_CENTER_DASHBOARD_FILTER,
    payload,
  };
}

export function fetchResultsKeys(payload = {}) {
  return {
    type: FETCH_RESULTS_KEYS,
    payload,
  };
}

export function saveResultsKeys(payload = {}) {
  return {
    type: SAVE_RESULTS_KEYS_REPORT,
    payload,
  };
}

export function saveCenterDashboardFilters(payload = {}) {
  return {
    type: SAVE_CENTER_DASHBOARD_FILTER,
    payload,
  };
}

export function dowloadSimplePdfReport(payload = {}) {
  return {
    type: DOWNLOAD_SIMPLE_PDF_REPORT,
    payload,
  };
}
