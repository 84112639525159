import React, { useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import * as _ from "lodash";
import { preguntasActions } from "actions/preguntasActions";
import { v1 as uuidv1 } from "uuid";
import { useHistory } from "react-router-dom";
import { directorActions } from "actions/directorActions";
import { Language } from "components/Language";

function TablaCheckBox(props) {
  const respuestas = props.respuestas;
  const history = useHistory();
  const loading = useSelector((state) => state.loading.loading);

  const [statusNextBackButton, setStatusNextBackButton] = React.useState(false);

  const [checked, setChecked] = React.useState(undefined);
  const [render, setRender] = React.useState(0);
  const [comentario, setComentario] = React.useState("");
  var counter = 0;
  const dispatch = useDispatch();
  useEffect(() => {
    var checkedProvisional = {};
    props.subPreguntas.map((item) => {
      checkedProvisional[item.id] = {};
    });

    if (props.subpreguntasRespuestas != null) {
      props.subpreguntasRespuestas.map((item) => {
        checkedProvisional[item.subpregunta_id][item.respuestas_id] = 1;
      });
    }
    setRender(render + 1);
    setChecked(checkedProvisional);
    setStatusNextBackButton(false);
  }, [props.subpreguntasRespuestas]);
  const handleChange = (subpregunta, subrespuesta, pondera) => {
    var checkedProvisional = checked;
    checkedProvisional[subpregunta][subrespuesta] =
    checkedProvisional[subpregunta][subrespuesta] == 1 ? 0 : 1;
    if (pondera === 0 && checkedProvisional[subpregunta][subrespuesta] == 1) {
      var queIndex = 0;
      props.subPreguntas.map((item) => {
         var mIndex = 0;
        
        props.subRespuestas[item.id].map((item2, idx) => {
          if (item2.id == subrespuesta) queIndex = idx;
          mIndex++;
        });
      });

      props.subPreguntas.map((item) => {
        var mIndex = 0;
        props.subRespuestas[item.id].map((item2) => {
          if (
            item2.id !== subrespuesta &&
            item2.pondera !== 0 &&
            mIndex === queIndex
          ) {
            checkedProvisional[item.id][item2.id] = 0;
          }
          mIndex++;
        });
      });
    }

    if (pondera !== 0 && checkedProvisional[subpregunta][subrespuesta] == 1) {
      var queIndex2 = -1;
      props.subPreguntas.map((item) => {
        var mIndex = 0;
       
        props.subRespuestas[item.id].map((item2, idx) => {
          if (item2.id == subrespuesta ) {
            queIndex2=idx;
          }
          mIndex++;
        });
      });

      props.subPreguntas.map((item) => {
        let mIndex = 0;
        props.subRespuestas[item.id].map((item2, idx) => {
          if (item2.pondera === 0 && idx === queIndex2) {
            checkedProvisional[item.id][item2.id] = 0;
          }
          mIndex++;
        });
      });
    }

    setChecked(checkedProvisional);
    setRender(render + 1);
  };

  const saveandexit = (e) => {
    dispatch(directorActions.saveHistorico(1, 1, "saved"));

    sendFormulario(e);
    history.push("/auth/start");
  }

  const sendFormulario = (e) => {
    var user = JSON.parse(localStorage.getItem("xcuserv2"));
    localStorage.setItem("hasContestado" + user.email, "true")

    if (Object.keys(checked).length >= 1) {
      if (props.isPreguntaCompleted) {
        var centro_pregunta_id;
        props.isPreguntaCompleted.map((item) => {
          if (item.preguntas_id == respuestas[0].preguntas_id) {
            centro_pregunta_id = item.id;
          }
        });
        if (centro_pregunta_id) {
          dispatch(
            preguntasActions.saveTableCheckbox(
              checked,
              centro_pregunta_id,
              props.respuestas[0].preguntas_id
            )
          );
        } else {
          dispatch(
            preguntasActions.saveTableCheckboxAndPregunta(
              checked,
              props.centro,
              props.respuestas[0].preguntas_id
            )
          );
        }
      } else {
        dispatch(
          preguntasActions.saveTableCheckboxAndPregunta(
            checked,
            props.centro,
            props.respuestas[0].preguntas_id
          )
        );
      }

      dispatch(
        directorActions.saveHistoricoIndicador(
          1,
          3,
          "saved",
          props.respuestas[0].preguntas_id
        )
      );
      setChecked(undefined);
      setComentario("");
    } else props.nextPregunta();
  };

  var theme = createTheme({
    overrides: {
      MuiInputBase: {
        root: {
          height: "40px",
          "&:hover": {
            color: props.color,
            borderColor: props.color,
          },
        },
        input: {
          color: props.color,
          borderColor: props.color,
          "&:hover": {
            color: props.color,
            borderColor: props.color,
          },
        },
      },
      MuiFormControlLabel: {
        label: {
          color: "#383b41",
          fontFamily: "Museo Sans",
          fontWeight: 700,
          fontSize: "17px",
        },
        root: {
          marginTop: "10px",
        },
      },
      MuiFormControl: {
        root: {
          marginTop: "25px",
        },
      },
    },
  });

  return (
    <div className="topeWidth">
      {loading ? (
        <div></div>
      ) : (
        <div>
          <MuiThemeProvider theme={theme}>
            <Grid container style={{ width: "700px", marginTop: "35px" }}>
              <Grid item xs={12} style={{ height: "55px" }}>
                <Grid container style={{ height: "55px" }}>
                  <Grid
                    item
                    xs={6}
                    style={{ borderRight: "solid 1px #a5adbb" }}
                  ></Grid>
                  {props.subRespuestas[props.subPreguntas[0].id].map((item) => {
                    return (
                      <Grid
                        key={uuidv1()}
                        item
                        xs={
                          props.subRespuestas[props.subPreguntas[0].id]
                            .length == 2
                            ? 3
                            : 2
                        }
                        style={{
                          borderRight: "solid 1px #a5adbb",
                          textAlign: "center",
                        }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <label style={{ color: "#383b41", marginTop: "0px" }}>
                          {item.respuesta}
                        </label>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              {props.subPreguntas != undefined && checked ? (
                props.subPreguntas.map((item, i) => {
                  counter = counter + 1;

                  return (
                    <Grid
                      key={i}
                      item
                      xs={12}
                      style={{ height: "55px" }}
                    >
                      <Grid container style={{ height: "55px" }}>
                        <Grid
                          item
                          xs={6}
                          style={{
                            backgroundColor: counter % 2 ? "#f4f5f7" : "white",
                            borderRight: "solid 1px #a5adbb",
                            borderTop: "solid 1px #a5adbb",
                          }}
                        >
                          <label
                            style={{
                              color: "#383b41",
                              marginTop: "0px",
                              fontSize: "12px",
                              marginLeft: "26px",
                            }}
                          >
                            {item.titulo}
                          </label>
                        </Grid>
                        {props.subRespuestas[item.id].map((item2, j) => {
                          return (
                            <Grid
                              key={j}
                              item
                              xs={
                                props.subRespuestas[item.id].length == 2 ? 3 : 2
                              }
                              style={{
                                backgroundColor:
                                  counter % 2 ? "#f4f5f7" : "white",
                                borderRight: "solid 1px #a5adbb",
                                borderTop: "solid 1px #a5adbb",
                                textAlign: "center",
                              }}
                            >
                              <Checkbox
                                checked={
                                  checked[item.id]
                                    ? checked[item.id][item2.id] == 1
                                    : false
                                }
                                onChange={(e) =>
                                  handleChange(item.id, item2.id, item2.pondera)
                                }
                                value={item2.id}
                                name="radio-button"
                                key={item2.respuesta}
                                style={{ color: props.color }}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  );
                })
              ) : (
                <div></div>
              )}
            </Grid>
          </MuiThemeProvider>
          <Grid container justifyContent="center">
            <div className="botonesAccion gridBotones paddingBotones">
              <Button
                disabled={statusNextBackButton}
                variant="outlined"
                onClick={props.previousPregunta}
                className="btn buttonContestarMasTarde"
                style={{ backgroundColor: "white", color: props.color }}
              >
                <Language langKey="questionary.back" />
              </Button>

              <Button
                disabled={statusNextBackButton}
                onClick={sendFormulario}
                className="btn buttonContestarContinuar marginLeftButton"
                style={{ backgroundColor: props.color }}
              >
                <Language langKey="next" />
              </Button>
              <Button
              disabled={statusNextBackButton}
              onClick={saveandexit}
              className="btn buttonContestarFooterDescargar butonBottomAdjust"
              style={{
                backgroundColor: "white",
                color: props.color,
                border: "1px solid",
              }}
            >
              <Language langKey="questionary.exit" />
            </Button>
            </div>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default TablaCheckBox;