import actionTypes from "constants/actionTypes";
const initialState = {
  result:null
};

export default (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.SET_PUNTUAR:      
      return {
        ...state,
        result: action.payload,
      };

    default:
      return state;

  }
};