import {
  SET_LANGUAGE,
  FETCH_PERIODS,
  SAVE_PERIODS,
  SNACKBAR_SETTING,
} from '../constants/settings.constant';

export const changeLanguge = (lang) => {
  document.documentElement.setAttribute("lang", lang.toLowerCase());

  return {
    type: SET_LANGUAGE,
    payload: lang,
  }
}

export function fetchPeriods(payload = {}) {
  return {
    type: FETCH_PERIODS,
    payload,
  };
}

export function savePeriods(payload = {}) {
  return {
    type: SAVE_PERIODS,
    payload,
  };
}

export const snackbarSetting = (payload) => {
  return {
    type: SNACKBAR_SETTING,
    payload,
  }
}
