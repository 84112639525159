import * as PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const MainComponent = (props) => {
  const location = useLocation();

  useEffect(() => {
    const lang = localStorage.getItem('lang') ?? 'ES';

    const reg = new RegExp('^/(es|ca)/') ;

    if(!reg.exec(location.pathname)) {
      const url = new URL(window.location.href);
      url.pathname = `/${lang.toLowerCase()}${location.pathname}`;

      window.history.replaceState({}, '', url);
    }

    window.scrollTo(0, 0);
  }, [location.pathname]);

  return <>{props.children}</>;
};

MainComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainComponent;
