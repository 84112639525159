import actionTypes from "constants/actionTypes";
 
export const recursosActions = {
  getRecursos, 
  getRecurso,
  getCursosRecursos,
  getComunidadesRecursos,
  setPreciosFilter,
  setClavesPreciosStart,
  setClavesFilter,
  setClavesFilterStart,
  setComunidadesFilter,
  setComunidadesFilterStart,
  setCursosFilter,
  setCursosFilterStart,getRecursosRel,
  resetFiltersReady,getPerspectivas,getTipoMaterial,
  createBestPractice,
};
function getRecursosRel(id) {
  let payload= {id};
  return {
    type: actionTypes.GET_RECURSOS_REL, payload: payload
  };
}

function getRecurso(id) {
  let payload= {id};
  return {
    type: actionTypes.GET_RECURSO, payload: payload
  };
}
 
function getPerspectivas() {
  return {
    type: actionTypes.GET_PERSPECTIVAS
  };
}
 
function getTipoMaterial() {
  return {
    type: actionTypes.GET_TIPOMATERIAL
  };
}
 
function getRecursos() {
  return {
    type: actionTypes.GET_RECURSOS
  };
}
 
function getCursosRecursos() {
  return {
    type: actionTypes.GET_CURSOS_RECURSOS
  };
}
  
function getComunidadesRecursos() {
  return {
    type: actionTypes.GET_COMUNIDADES_RECURSOS
  };
}

function setPreciosFilter(payload) {
  return {
    type: actionTypes.SET_PRECIOS_FILTER,
    payload: payload
  };
}
function setClavesFilter(payload) {
  return {
    type: actionTypes.SET_CLAVES_FILTER,
    payload: payload
  };
}

function setClavesFilterStart(payload) {
  return {
    type: actionTypes.SET_CLAVES_FILTER_START,
    payload: payload
  };
}

function setComunidadesFilter(payload) {
  return {
    type: actionTypes.SET_COMUNIDADES_FILTER,
    payload: payload
  };
}

function setComunidadesFilterStart(payload) {
return {
  type: actionTypes.SET_COMUNIDADES_FILTER_START,
  payload: payload
};
}

function setCursosFilter(payload) {
  return {
    type: actionTypes.SET_CURSOS_FILTER,
    payload: payload
  };
}

function setCursosFilterStart(payload) {
return {
  type: actionTypes.SET_CURSOS_FILTER_START,
  payload: payload
};
}



function setClavesPreciosStart(payload) {
  return {
    type: actionTypes.SET_CLAVES_PRECIOS_START,
    payload: payload
  };
}


function resetFiltersReady() {
  return {
    type: actionTypes.RESET_FILTERS_READY     
  };
}

function createBestPractice(payload) {
  return {
    type: actionTypes.CREATE_BEST_PRACTICE,
    payload
  };
}
