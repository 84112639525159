import React, {useState} from "react";
import logoXcelence from "assets/img/logoXcelence.png";
import {useDispatch, useSelector} from 'react-redux';
import { changeLanguge } from "actions/settings.action";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import {AccountCircleOutlined, Language as LanguageIcon} from '@material-ui/icons';
import { Link } from "react-router-dom";

import {Language} from 'components/Language';

const NavBar = () => {
  const dispatch = useDispatch();
  const [dropLang, setDropLang] = useState(false);
  const locale = useSelector((state) => state.setting.locale);

  const getClassName = (path) => {
    let res = 'menu-item-navbar nav-link';
    const mRuta = window.location.pathname;

    if (mRuta === path || mRuta === `/${lang}${path}`) {
      res += ' ' + 'menu-item-navbar-selected';
    } 

    return res;
  }

  const handleChangeLocale = (lang = 'ES') => {
    localStorage.setItem('lang', lang);

    const reg = new RegExp('^/(es|ca)/') ;
    const url = new URL(window.location.href);
    url.pathname = url.pathname.replace(reg, `/${lang.toLowerCase()}/`);

    window.history.pushState({}, '', url);

    dispatch(changeLanguge(lang));
  }

  const lang = locale.toLowerCase();

  return (
    <Navbar bg="light" expand="lg" className="menuPrincipal">
      <Link className="py-2 navbar-brand" to={{ pathname: "/" }}>
        <img height="45px" src={logoXcelence} />
      </Link>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
        <Nav className="justify-content-end">
          <Link
            className="btnRegisterMenu"
            to={{ pathname: `/${lang}/user/login` }}
          >
           <AccountCircleOutlined fontSize="small" /> <Language langKey="self_evaluation" />
          </Link>
          <NavDropdown
            show={dropLang}
            onMouseOver={() => setDropLang(true)}
            onMouseLeave={() => setDropLang(false)}
            className="menu-item-navbar lang-dropdown"
            title={<><LanguageIcon fontSize="small" /> {locale}</>}
          >
            <NavDropdown.Item
              onClick={handleChangeLocale.bind(null, 'ES')}
              className="submenu-item-navbar"
            >
              <Language langKey="lang.es" />
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={handleChangeLocale.bind(null, 'CA')}
              className="submenu-item-navbar"
            >
              <Language langKey="lang.ca" />
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBar;
