import rootReducer from "reducers/rootReducer";
import createSagaMiddleware from "redux-saga";
import root_saga from "sagas/root_saga";
import {
  createStore,
  applyMiddleware,
  compose
} from "redux";

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (!serializedState) return undefined;
    else {
      // dispatch(userActions.setFullInfo(localStorage.getItem("userDetails")))
      return JSON.parse(serializedState);
    }
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    console.log(err);
  }
};

// const persistedStore = loadState();
const initialiseSagaMiddleware = createSagaMiddleware();
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  storeEnhancers(applyMiddleware(initialiseSagaMiddleware))
);

initialiseSagaMiddleware.run(root_saga);

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
