import { makeStyles } from "@material-ui/core";
import React from "react";

export const useStyles = makeStyles({
  stacticHTML: {
    fontSize: '1rem',
    textAlign: 'justify',
    marginTop: 30,
    marginBottom: 30,

    '& td': {
      border: '1px solid #ccc',
      padding: '5px 10px',
      verticalAlign: 'baseline',
    },
    '& .bold': {
      fontWeight: '900'
    },
    '& ol': {
      listStyle: 'lower-roman',

      '& li': {
        paddingLeft: 8,
      }
    }
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
  }
});

export const PrivacyES = () => {
  const classes = useStyles();

  return (
    <div className={classes.stacticHTML}>
      <h2 className={classes.title}>Política de Privacidad</h2>

      <p>La Política de Privacidad regula las bases bajo las cuales FUNDACIÓN BERTELSMANN (en adelante, el <b>"Titular"</b> o <b>"FUNDACIÓN BERTELSMANN"</b>) rata los datos personales de los usuarios facilitados durante la navegación a través del sitio web <a href="http://evaluacion.xcelence.es ">evaluacion.xcelence.es</a> (en adelante, el <b>"Sitio Web"</b>).</p>

      <p>Cuando usted nos facilite datos personales ya sea al registrarse en el Sitio Web, contestar cuestionarios que le facilitemos en relación con nuestras iniciativas y proyectos, participar en cualquiera de ellos o simplemente para navegar por el Sitio Web, usted estará realizándolo de acuerdo con los términos de la Política de Privacidad y del Aviso Legal, vigentes en cada momento, por lo que deberá revisar dichos textos para comprobar su conformidad con los mismos.</p>

      <p>El Sitio Web del que es titular FUNDACIÓN BERTELSMANN puede contener enlaces a sitios web de terceros, cuyas políticas de privacidad y términos y condiciones de uso son ajenas a las del Sitio Web.  Al acceder a tales sitios web usted puede decidir si acepta las políticas de estos sitios web.</p>

      <p>A continuación, le informamos sobre la Política de Privacidad del Titular:</p>

      <p><b>1. Datos recogidos</b></p>

      <p>Recabamos la información que usted nos facilita para llevar a cabo la finalidad descrita en esta Política de Privacidad, ajustándose al principio de minimización de datos establecido en el artículo 5.1.c) Reglamento General de Protección de Datos.</p>

      <p></p>

      <p>A continuación, le informamos sobre los datos que podremos recabar sobre usted:</p>

      <ol style={{ listStyle: 'decimal' }}>
        <li>Datos identificativos: Nombre, apellidos, DNI</li>
        <li>Datos de contacto: Correo electrónico y código postal.</li>
        <li>Datos profesionales: Código del centro educativo y nombre de la organización.</li>
        <li>Cualquier otro dato personal que nos pueda proporcionar al navegar en el Sitio Web.</li>
      </ol>

      <p>Asimismo, se podrá recopilar información sobre el uso que usted haga del Sitio Web de conformidad con la Política de Cookies. Para más información sobre la Política de Cookies puede acceder a través del siguiente enlace <a href="https://xcelence.es/politica-de-cookies/">https://xcelence.es/politica-de-cookies/</a>.</p>

      <p>Para la cumplimentación de la evaluación los usuarios previamente registrados no deberán entregar datos personales adicionales propios ni de terceros.</p>

      <p><b>2. Responsable del tratamiento</b></p>

      <p>FUNDACIÓN BERTELSMANN es la entidad responsable del tratamiento de los datos personales de los usuarios del Sitio Web, con domicilio en Calle O’Donnell, nº 10, 28009 Madrid (España), CIF G- 07747686, teléfono +34 93 268 73 73. Inscrita en el Registro de Fundaciones del Ministerio de Educación, Cultura y Deporte con el número 268. La dirección electrónica de contacto en materia de protección de datos es: <a href="mailto:lopd@fundacionbertelsmann.org">lopd@fundacionbertelsmann.org</a>. </p>

      <p><b>3. Finalidades y Bases Jurídicas del Tratamiento</b></p>

      <table>
        <tbody>
          <tr>
            <td style={{ textAlign: 'center' }}>Datos personales</td>
            <td style={{ textAlign: 'center' }}><p>Finalidades</p></td>
            <td style={{ textAlign: 'center' }}><p>Bases jurídicas</p></td>
          </tr>
          <tr><td><b>Datos identificativos, de contacto y/o profesionales</b></td>
            <td>
              <p>Gestionar el uso de la herramienta de autoevaluación por parte del
                usuario, incluyendo la realización de encuestas y estudios a partir de la información facilitada por el usuario y los resultados
                arrojados por la herramienta de autoevaluación.</p>
              <p>En este sentido, la información proporcionada relativa a encuestas se podrá integrar en estudios con una finalidad de investigación científica y siempre de forma agregada o anonimizada de acuerdo con lo establecido en la legislación aplicable.</p>
            </td>
            <td><p>Consentimiento.</p></td>
          </tr>
          <tr>
            <td>
              <b>Datos identificativos, de contacto y/o profesionales</b>
            </td>
            <td>
              <p>Difundir la iniciativa «Xcelence» y comunicar la participación a través de medios de comunicación electrónicos (YouTube, LinkedIn o Instagram y páginas web, lo que supone una difusión potencialmente universal) y/o convencionales (papel, fotografías, vídeos y otros análogos) de FUNDACIÓN BERTELSMANN, tanto en el marco de las actividades de difusión de la iniciativa, como en otros espacios de comunicación de las actividades llevadas a cabo por FUNDACIÓN BERTELSMANN o en los que resulte de interés el testimonio de los participantes.</p>

              <p>Entrevistas con medios de comunicación que cubran la iniciativa «Xcelence» a fin de dar la mayor difusión posible a la misma.</p>
            </td>
            <td><p>Consentimiento.</p></td>
          </tr>
          <tr>
            <td><b>Datos identificativos, de contacto y/o profesionales</b></td>
            <td><p>Envío de informes y materiales sobre la iniciativa «Xcelence» que los usuarios puedan descargarse del Sitio Web.</p></td>
            <td><p>Consentimiento.</p></td>
          </tr>
          <tr>
            <td><b>Datos identificativos y de contacto</b></td>
            <td><p>Gestionar y contestar preguntas y comentarios enviados a través de formularios de contacto del Sitio Web.</p></td>
            <td><p>Consentimiento.</p></td>
          </tr>
          <tr>
            <td><b>Datos identificativos y datos de contacto</b></td>
            <td><p>FUNDACIÓN BERTELSMANN podrá dirigirse a usted a través de los datos de contacto facilitados para comunicarle otras iniciativas o proyectos que puedan ser de su interés. En todo caso, en estas comunicaciones se facilitará al destinatario la posibilidad de oponerse al tratamiento de sus datos para esta finalidad mediante un procedimiento sencillo y gratuito.</p></td>
            <td><p>Interés Legítimo, basado en el artículo 21.2 LSSI. </p></td>
          </tr>
        </tbody>
      </table>
      <p></p>

      <p><b>4. Procedencia y Legitimación</b></p>

      <p>Los datos que tratamos han sido facilitados por usted al Titular.</p>

      <p>La legitimación para las finalidades del tratamiento de sus datos personales descritas en esta Política de Privacidad, tienen como base legal el consentimiento, libre, específico, informado e inequívoco que usted nos facilita y, en el caso del envío de comunicaciones la base legal es el interés legítimo de FUNDACIÓN BERTLESMANN según lo establecido en el artículo 21.2 Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico. </p>

      <p>La aceptación de las casillas habilitadas al efecto supone que usted otorga su consentimiento expreso e inequívoco al Titular para el tratamiento de sus datos personales con la finalidad y características detalladas en la presente Política de Privacidad. Por lo tanto, la negativa a suministrar los datos personales solicitados, o la entrega de datos inexactos o incompletos, podría ocasionar la imposibilidad de prestarle los servicios solicitados de forma adecuada. </p>

      <p><b>5. Destinatarios de los Datos Personales</b></p>

      <p>Con base jurídica en el consentimiento que usted nos presta, sus datos personales podrán ser comunicados a los siguientes terceros: </p>
      <ol style={{ listStyle: 'decimal' }}>
        <li>Investigadores y otros profesionales del ámbito educativo.</li>
        <li>Socios colaboradores de FUNDACIÓN BERTELSMANN que colaboren en la realización y/o difusión de cualquiera de las iniciativas o proyectos vinculados a Xcelence. </li>
        <li>Otros encargados del tratamiento de acuerdo con el apartado 7 de esta Política de Privacidad. </li>
      </ol>

      <p><b>6. Transferencias Internacionales</b></p>

      <p>En relación con los destinatarios de los datos expuestos anteriormente, FUNDACIÓN BERTELSMANN le informa que, cuando los destinatarios de los datos se encuentren fuera del Espacio Económico Europeo o se encuentren fuera de un país o territorio que haya sido declarado de nivel de protección adecuado por la Comisión Europea o no se ofrezcan las garantías adecuadas de protección de los datos personales, FUNDACIÓN BERTELSMANN recabará su consentimiento para realizar dicha transferencia de datos.</p>

      <p><b>7. Encargados del Tratamiento</b></p>

      <p>Terceras empresas externas de FUNDACIÓN BERTELSMANN podrán tener acceso a los datos con el fin de prestar servicios necesarios para la actividad y tratamientos llevados por FUNDACIÓN BERTELSMANN. Los datos serán puestos a disposición de dichos encargados del tratamiento al amparo de un contrato de prestación de servicios y otro de encargo de tratamiento con la obligación de seguir las instrucciones de tratamiento proporcionadas por FUNDACIÓN BERTELSMANN, de guardar confidencialidad, devolver y/o destruir los datos a la finalización del servicio quedando prohibido el tratamiento de los datos para fines propios de los encargados del tratamiento. </p>

      <p><b>8. Plazo de conservación de los datos personales</b></p>

      <p>Los datos personales se conservarán, durante el tiempo necesario para cumplir con la finalidad para la que fueron recabados, en tanto los interesados no soliciten su supresión y durante los plazos de prescripción de las acciones aplicables para la reclamación de posibles responsabilidades y cumplimiento de las obligaciones legales de FUNDACIÓN BERTELSMANN.</p>

      <p><b>9. Ejercicio de Derechos</b></p>

      <p>Podrá ejercer sus derechos de acceso, rectificación, supresión, limitación al tratamiento, oposición, portabilidad, así como, en su caso, la revocación del consentimiento otorgado para el tratamiento en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada, mediante envío de una petición escrita a FUNDACIÓN BERTELSMANN junto con una copia de su DNI a la siguientes dirección: <a href="mailto:lopd@fundacionbertelsmann.org">lopd@fundacionbertelsmann.org</a>.</p>

      <p>Además, los interesados también tienen derecho a presentar una reclamación ante la autoridad de control Agencia Española de Protección de Datos. Con carácter previo a interponer la reclamación y de forma voluntaria, usted podrá contactar con FUNDACIÓN BERTELSMANN en la siguiente dirección: <a href="mailto:lopd@fundacionbertelsmann.org">lopd@fundacionbertelsmann.org</a>.</p>

      <p><b>10. Medidas de seguridad</b></p>

      <p>El Titular ha adoptado las medidas de seguridad de índole técnica y organizativa necesarias de conformidad con el estado de la tecnología para garantizar la seguridad de los datos de carácter personal, de acuerdo con los niveles de seguridad adecuados a los datos que se facilitan y, además, se han instalado todos los medios y medidas técnicas a su alcance para evitar la pérdida, mal uso, alteración y acceso no autorizado a los datos que nos facilitan.</p>

      <p>El Titular se compromete a cumplir con el deber de secreto y confidencialidad respecto de los datos personales proporcionados por el usuario de acuerdo con la legislación aplicable.</p>

      <p><b>11. Modificaciones</b></p>

      <p>El titular del Sitio Web podrá revisar y modificar la presente Política de Privacidad cuando se produzcan modificaciones en la legislación aplicable o en alguno de los procedimientos de tratamientos de los datos personales que usted ha facilitado al Titular</p>
    </div>
  );
} 
