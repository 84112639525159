import React, {useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Page,
  Text,
  View,
  Font,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";

import * as _ from "lodash";
import { v1 as uuidv1 } from "uuid";
import { datosActions } from "actions/datosActions";
import { useHistory } from "react-router-dom";

Font.register({
  family: "Roboto-Light",
  weight: "100,200,300, 400",
  src: "/fonts/Roboto-Light.ttf",
});
Font.register({
  family: "Roboto-Bold",
  weight: "400,500,700",
  src: "/fonts/Roboto-Bold.ttf",
});

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const styles = StyleSheet.create({
  page: {
    paddingBottom: 45,
    margin: 20,
    backgroundColor: "#ffffff",
  },
});
const MyDocument = (t, i18n, mSalida) => (
  <Document>
    <Page key={uuidv1()} size="A4" style={styles.page}>
      {Object.keys(mSalida).map((key) => {
        return (
          <View wrap={false} key={uuidv1()}>
            <Text>
              {mSalida[key][0].clavenum} {mSalida[key][0].clave}{" "}
            </Text>
          </View>
        );
      })}
    </Page>
  </Document>
);

function Cuestionario(props) {
  const dispatch = useDispatch();
  var apiDONE = useSelector((state) => state.loading.APIactions);

  var getCuestionario = useSelector(
    (state) => state.datosReducer.getCuestionario
  );

  if (getCuestionario) {
    var mSalida = _.groupBy(getCuestionario, "clavenum");
  }

  useEffect(() => {
    dispatch(datosActions.getCuestionario());
  }, [apiDONE]);

  return (
    <div>
    </div>
  );
}
export default Cuestionario;
