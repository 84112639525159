import React, { useState, useEffect } from "react";
import NavBarSimple from "../../NavBar/NavBarSimple.js";
import Grid from "@material-ui/core/Grid";
import CampoTextField from "./CampoTextField.js";
import PasswordTextField from "./PasswordTextField.js";

import SelectField from "./SelectField.js";
import { cargosActions } from "actions/cargosActions";
import DateField from "./DateField.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { userActions } from "actions/userActions";

import {Language, getRawMessage} from 'components/Language';

function RegistroCoordinadorContainer(props) {
  const dispatch = useDispatch();
  var emailvalid = useSelector((state) => state.authReducer.emailvalid);
  var loading = useSelector((state) => state.loading.loading);

  const cargosReducer = useSelector((state) => state.cargosReducer.cargos);

  const [email, setEmail] = React.useState("");
  const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [emailVerified, setEmailVerified] = React.useState(false);

  const [nombre, setNombre] = React.useState("");
  const [apellidos, setApellidos] = React.useState("");
  const [errorNombre, setErrorNombre] = React.useState(false);
  const [errorApellidos, setErrorApellidos] = React.useState(false);
  const [errorCargo, setErrorCargo] = React.useState(false);
  const [errorFecha, setErrorFecha] = React.useState(false);

  const [cargo, setCargo] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [saveError, setSaveError] = React.useState(false);

  const [fecha, setFecha] = React.useState(null);
  const [telefono, setTelefono] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorPassword, setErrorPassword] = React.useState(null);
  const [successPassword, setSuccessPassword] = React.useState(null);

  const [repeatPassword, setRepeatPassword] = React.useState("");
  const [errorRepeatPassword, setErrorRepeatPassword] = React.useState(null);
  const [successRepeatPassword, setSuccessRepeatPassword] = React.useState(
    null
  );
  const [newsletter, setNewsletter] = useState(false);
  const [terminos, setTerminos] = useState(false);

  useEffect(() => {
    dispatch(cargosActions.getCargos());
    dispatch(userActions.cleanEmailVerification());
  }, []);

  useEffect(() => {
    return function cleanup() {
      dispatch(userActions.cleanEmailVerification());
    };
  }, []);

  const saveFormPage1 = () => {
    var validated = true;
    if (nombre.length <= 2 || nombre.length >= 40) {
      validated = false;
      setErrorNombre(true);
    } else {
      setErrorNombre(false);
    }
    if (apellidos.length <= 2 || apellidos.length > 40) {
      validated = false;
      setErrorApellidos(true);
    } else {
      setErrorApellidos(false);
    }

    if (validated && emailVerified && !loading) {
      setPage(2);
    }
  };

  const saveFormPage2 = () => {
    var validated = true;

    if (!cargo) {
      validated = false;
      setErrorCargo(true);
    } else {
      setErrorCargo(false);
    }
    if (!fecha) {
      validated = false;
      setErrorFecha(true);
    } else {
      setErrorFecha(false);
    }

    if (validated) {
      setPage(3);
    }
  };

  const sendForm = () => {
    if (
      !loading &&
      newsletter &&
      terminos &&
      !errorNombre &&
      !errorApellidos &&
      !errorCargo &&
      !errorFecha &&
      !saveError &&
      successPassword &&
      successRepeatPassword
    ) {
      alert("enviado");
    }
  };
  const handleChangePassword = (entrada) => {
    setPassword(entrada);
    if (entrada.length < 8) {
      setErrorPassword(true);
      setSuccessPassword(false);
    } else {
      setErrorPassword(false);

      setSuccessPassword(true);
    }
  };

  const handleChangeRepeatPassword = (entrada) => {
    if (entrada.length >= 8 && password == entrada) {
      setErrorRepeatPassword(false);

      setSuccessRepeatPassword(true);
    } else {
      setErrorRepeatPassword(true);
      setSuccessRepeatPassword(false);
    }
  };

  const setChangeNewsletter = (event) => {
    setNewsletter(event.target.checked);
  };
  const setChangeTerminos = (event) => {
    setTerminos(event.target.checked);
  };
  const blurChangeEmail = (email) => {
    setEmailVerified(false);
    var mEmail = email.target.value;
    setEmail(mEmail);
    dispatch(userActions.findEmail(mEmail));
    var noValid = false;
    var EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!mEmail.match(EMAIL_REGEX)) {
      noValid = true;
    }
    if (noValid) {
      setEmailErrorMessage('invalid_email');
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  useEffect(() => {
    if (emailvalid == 0 && emailvalid != undefined) {
      setEmailVerified(true);
    } else {
      if (emailvalid != undefined) {
        setEmailError(true);
        setEmailErrorMessage('exist_email');
      }
    }
  }, [emailvalid]);

  return (
    <div className="">
      <NavBarSimple />
      <div className="defafullPageultFull">
        {page == 1 ? (
          <Grid container spacing={3} justifyContent="center" className="defaultgrid">
            <Grid item xs={12}>
              <div className="h1home"><Language langKey="signup.title" /></div>
              <div className="txtHome marginSubTitle">
                <Language langKey="signup.active_account" />
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <div className="marginGrid txtHome strong">
                <Language langKey="signup.datas" />
                <p className="obtext rightd">
                  * <Language langKey="required" />
                </p>
              </div>
            </Grid>

            <Grid item sm={6} md={6} lg={8} xl={8}></Grid>

            <CampoTextField
              InputProps={{onBlur: blurChangeEmail}}
              type="text"
              placeholder={getRawMessage("signup.email_caption")}
              titulo={<Language langKey="signup.email" />}
              required
              campo={email}
              setCampo={setEmail}
              error={emailError}
              errorMessage={<Language langKey={`errors.${emailErrorMessage}`} />}
            />
            <CampoTextField
              type="text"
              placeholder={getRawMessage("signup.name_caption")}
              titulo={<Language langKey="signup.name" />}
              required
              campo={nombre}
              setCampo={setNombre}
              error={errorNombre}
              errorMessage={<Language langKey="errors.required_name" />}
            />
            <CampoTextField
              type="text"
              placeholder={getRawMessage("signup.last_name")}
              titulo={<Language langKey="signup.last_name" />}
              required
              campo={apellidos}
              setCampo={setApellidos}
              error={errorApellidos}
              errorMessage={<Language langKey="errors.required_last" />}
            />
            <Grid item xs={12}>
              <Button
                onClick={() => saveFormPage1()}
                variant="contained"
                disabled={!loading ? false : true}
                className={`registerWidth ${
                  !loading ? "btnHome" : "btnDisabled"
                } btnHomePadding`}
              >
                <Language langKey="next" />
              </Button>
              {loading && (
                <CircularProgress size={24} className="centerLoading" />
              )}

              {saveError && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.internal" /> 
                </div>
              )}
            </Grid>
          </Grid>
        ) : (
          <div></div>
        )}
        {page == 2 ? (
          <Grid container spacing={3} justifyContent="center" className="defaultgrid">
            <Grid item xs={12}>
              <div className="h1home"><Language langKey="signup.title" /></div>
              <div className="txtHome marginSubTitle">
                <Language langKey="signup.active_account" />
              </div>
            </Grid>
            <Grid item sm={12}>
              <a
                className="mainLink"
                href="#"
                onClick={(e) => setPage(1)}
                style={{ cursor: "pointer" }}
              >
                {"<"} <Language langKey="back" />
              </a>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <div className="marginGrid2 txtHome strong">
                <Language langKey="signup.datas" />
                <p className="obtext rightd">* <Language langKey="required" /></p>
              </div>
            </Grid>

            <Grid item sm={6} md={6} lg={8} xl={8}></Grid>

            {cargosReducer && (
              <SelectField
                required
                titulo={<Language langKey="signup.center_position" />}
                label={<Language langKey="signup.center_position" />}
                placeholder={getRawMessage("signup.position")}
                lista={cargosReducer}
                campo={cargo}
                setCampo={setCargo}
                error={errorCargo}
                errorMessage={<Language langKey="errors.required_pos" />}
              />
            )}
            <DateField
              titulo={<Language langKey="signup.date" />}
              placeholder={getRawMessage("signup.year")}
              campo={fecha}
              setCampo={setFecha}
              error={errorFecha}
              errorMessage={<Language langKey="errors.required_date" />}
            />
            <CampoTextField
              type="number"
              titulo={<Language langKey="signup.phone" />}
              placeholder={getRawMessage("signup.phone_caption")}
              campo={telefono}
              setCampo={setTelefono}
            />

            <Grid item xs={12}>
              <Button
                onClick={(e) => saveFormPage2()}
                variant="contained"
                disabled={!loading ? false : true}
                className={`registerWidth ${
                  !loading ? "btnHome" : "btnDisabled"
                } btnHomePadding`}
              >
                <Language langKey="next" />
              </Button>
              {loading && (
                <CircularProgress size={24} className="centerLoading" />
              )}

              {saveError && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                 <Language langKey="errors.internal" /> 
                </div>
              )}
            </Grid>
          </Grid>
        ) : (
          <div></div>
        )}

        {page == 3 ? (
          <Grid container spacing={3} justifyContent="center" className="defaultgrid">
            <Grid item xs={12}>
              <div className="h1home"><Language langKey="signup.title" /></div>
              <div className="txtHome marginSubTitle">
                <Language langKey="signup.active_account" />
              </div>
            </Grid>
            <Grid item sm={12}>
              <a
                className="mainLink"
                href="#"
                onClick={(e) => setPage(2)}
                style={{ cursor: "pointer" }}
              >
                {"<"} <Language langKey="back" /> 
              </a>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <div className="marginGrid2 txtHome strong">
                <Language langKey="signup.datas" />
                <p className="obtext rightd">* <Language langKey="required" /></p>
              </div>
            </Grid>

            <Grid item sm={6} md={6} lg={8} xl={8}></Grid>

            <PasswordTextField
              type="password"
              placeholder={getRawMessage("signup.pwd_caption")}
              titulo={<Language langKey="pwd" />}
              campo={password}
              setCampo={handleChangePassword}
              success={successPassword}
              error={errorPassword}
            />
            <PasswordTextField
              type="password"
              placeholder={getRawMessage("repeat_pwd")}
              titulo={<Language langKey="repeat_pwd" />}
              campo={repeatPassword}
              setCampo={handleChangeRepeatPassword}
              success={successRepeatPassword}
              error={errorRepeatPassword}
            />

            <Grid item xs={12}>
              <FormControlLabel
                className="labelForm"
                control={
                  <Checkbox
                    checked={newsletter}
                    onChange={setChangeNewsletter}
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label={<Language langKey="signup.agree_news" />}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                className="labelForm"
                error="true"
                control={
                  <Checkbox
                    checked={terminos}
                    onChange={setChangeTerminos}
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label={<Language parse langKey="signup.terms" />}
              />
            </Grid>
            {loading && (
              <CircularProgress size={24} className="centerLoading" />
            )}

            {saveError && (
              <div className="errorLayer">
                <i className="fa fa-exclamation-triangle marginerror"></i>
                <Language langKey="errors.internal" />
              </div>
            )}

            <Grid item xs={12}>
              <Button
                onClick={(e) => sendForm()}
                variant="contained"
                className={`registerWidth ${
                  !loading &&
                  newsletter &&
                  terminos &&
                  !errorNombre &&
                  !errorApellidos &&
                  !errorCargo &&
                  !errorFecha &&
                  !saveError &&
                  successPassword &&
                  successRepeatPassword
                    ? "btnHome"
                    : "btnDisabled"
                } btnHomePadding`}
              >
                <Language langKey="finish" />
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </div>
    </div>
  );
}

export default RegistroCoordinadorContainer;