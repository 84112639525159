import actionTypes from "constants/actionTypes";

export const userActions = {
  login,
  logout,
  removeUser,
  forgotPassword,
  resetPassword,
  findDNI,
  findEmailDirector,
  findCentroByCodigo,
  findEmail,
  registerDirector,
  registerInvestigador,
  registerOtros,
  changepwd,
  firstpwd,
  endRegister,
  delUsuario,
  updateUsuario,
  traspasaUsuario,
  endTransaction,
  cleanEmailVerification,
  clearAlta,
  contact
};


function contact(nombre, apellidos, email, mensaje, token) {
  var payload = { nombre, apellidos, email, mensaje, token};
  return {
    type: "CONTACT",
    payload: payload,
  };
}
function endTransaction() {
  return {
    type: actionTypes.END_TRANSACTION,
  };
}

function clearAlta() {
  return {
    type: actionTypes.ALTAOTROS_CLEAR,
    payload: "",
  };
}

function updateUsuario(payload) {
  return {
    type: actionTypes.UPDATEUSUARIO,
    payload: payload,
  };
}

function traspasaUsuario(nombre, apellidos, email) {
  var payload = { nombre, apellidos, email };
  return {
    type: actionTypes.TRASPASA_USUARIO,
    payload: payload,
  };
}

function delUsuario() {
  return {
    type: actionTypes.DELUSUARIO,
  };
}
function endRegister() {
  return {
    type: actionTypes.END_REGISTER,
  };
}
function firstpwd(email, password) {
  var payload = { email, password };
  return {
    type: actionTypes.FIRSTPASSWORD,
    payload: payload,
  };
}

function changepwd(email, password, newpassword) {
  var payload = { email, password, newpassword };
  return {
    type: actionTypes.CHANGEPASSWORD,
    payload: payload,
  };
}

function registerDirector(payload) {
  return {
    type: actionTypes.ALTADIRECTOR,
    payload: payload,
  };
}

function registerInvestigador(payload) {
  return {
    type: actionTypes.ALTAINVESTIGADOR,
    payload: payload,
  };
}

function registerOtros(payload) {
  return {
    type: actionTypes.ALTAOTROS,
    payload: payload,
  };
}

function login(email, password) {
  var payload = { email, password };
  return {
    type: actionTypes.LOGIN_USER,
    payload: payload,
  };
}
function forgotPassword(email, token) {
  var payload = { email, token };
  return {
    type: actionTypes.FORGOTPASSWORD,
    payload: payload,
  };
}
function resetPassword(email, password, token) {
  var payload = { email, password };

  return {
    type: actionTypes.RESETPASSWORD,
    payload: payload,
    token: token,
  };
}
function logout() {
  return {
    type: actionTypes.LOGOUT,
    payload: "",
  };
}
function removeUser() {
  return {
    type: actionTypes.REMOVEUSER,
    payload: "",
  };
}

function findDNI(dni) {
  return {
    type: actionTypes.FINDDNI,
    dni: dni,
  };
}

function findEmail(email) {
  var payload = { email };
  return {
    type: actionTypes.FINDEMAIL,
    payload: payload,
  };
}

function findCentroByCodigo(codigo) {
  var payload = { codigo };
  return {
    type: actionTypes.FINDCENTROBYCODIGO,
    payload: payload,
  };
}

function findEmailDirector(email) {
  var payload = { email };
  return {
    type: actionTypes.FINDEMAILDIRECTOR,
    payload: payload,
  };
}
function cleanEmailVerification() {
  return {
    type: actionTypes.CLEAN_EMAIL_VERIFICATION,
    payload: "",
  };
}
