import React, { useState, useEffect } from "react";
import NavBarTool from "../../NavBar/NavBarTool.js";
import {
  Page,
  Text,
  View,
  Font,
  Image,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Link,
  BlobProvider,
} from "@react-pdf/renderer";
import moment from "moment";
import * as _ from "lodash";
import { v1 as uuidv1 } from "uuid";

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  col: {
    width: "10%",
  },
});

export default function Rating(props) {
  // console.log(props.pregunta.respuestas[0].respuesta);
  var mTotal = 0;
  if (
    props.pregunta &&
    props.pregunta.respuestas &&
    props.pregunta.respuestas.length > 0
  ) {
    mTotal = props.pregunta.respuestas[0].respuesta;
  }

  return (
    <View key={uuidv1()} style={{ marginLeft: 5, marginRight: 5 }}>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          {Array.apply(0, Array(10)).map(function(x, i) {
            return (
              <View
                key={uuidv1()}
                style={{
                  width: "7%",
                  marginLeft: 5,
                  backgroundColor:
                    i < mTotal ? "#3e7bbd" : "",
                }}
              >
                <Text
                  style={{
                    padding: 10,
                    color:
                      i <mTotal
                        ? "#FFFFFF"
                        : "#000000",
                    fontSize: 11,
                    fontWeight: 400,
                    textAlign: "justify",

                    fontFamily: "Roboto-Bold",
                  }}
                  key={uuidv1()}
                >
                  {i + 1}
                </Text>
              </View>
            );
          })}
        </View>
      </View>
    </View>
  );
}
