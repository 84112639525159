import React, { useState, useEffect } from "react";
import NavBarSimple from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";
import { directorActions } from "actions/directorActions";
import { centroActions } from "actions/centroActions";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import Create from "@material-ui/icons/Create";
import Lock from "@material-ui/icons/Lock";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as _ from "lodash";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ArrowAzul from "assets/css/ArrowAzul.js";
import { Modal } from "react-bootstrap";
import {CircularProgress, Tooltip} from "@material-ui/core";
import {CheckCircle, InfoRounded} from "@material-ui/icons";
import { getRawMessage, Language } from "components/Language.js";
import { useForceUpdate } from "../../utils/custon-hooks.js";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "auto",
    height: "auto",
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
  },
  rootCheck: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  iconCheck: {
    borderRadius: 3,
    width: 22,
    height: 22,
    boxShadow: "inset 0 0 0 1px #2b74c4, inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIconCheck: {
    backgroundColor: "#2b74c4",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 22,
      height: 22,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fillRule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 22,
    height: 22,
    boxShadow: "inset 0 0 0 1px #2b74c4, inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#2b74c4",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 22,
      height: 22,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
  customTooltip: {
    backgroundColor: "rgba(94, 94, 94, 0.89)",
    borderRadius: "18px",
    fontSize: "14px !important",
    padding: "12px",
  },
  customArrow: {
    color: "rgba(94, 94, 94, 0.89",
  },
}));

export const ownerships= [
  'public',
  'concerted',
  'private',
]

export const kindsOfCenter = [ 'CEPA', 'FP' ];

export const centerTypesMap = {
  3: 'cepa',
  4: 'etc',
}
const typesOrder = [ 1, 3, 2, 4 ];

function Centro() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  var user = JSON.parse(localStorage.getItem("xcuserv2"));
  const perfil = user ? + user.perfil : -1;
  const enviado_director_periodo = user ? user.enviado_director_periodo : 0;
  var xccompleto = localStorage.getItem("xccompleto");
  const resultado_completo = xccompleto ? xccompleto === '1' : false;

  if (perfil === 2) history.push("/auth/start");

  const [values, setValues] = React.useState({
    nombre: "",
    direccion: "",
    cp: "",
    cif: "",
    codigo: "",
    nivelsocioeconomico: "0",
    porcentajebeca: "",
    numalumnos: "",
    kind: null,
    ownership: null,
    disableNombre: true,
    disableDireccion: true,
    disableCP: true,
    disableCIF: true,
  });
  const [notiene, setNoTiene] = useState(false);  
  const [hacambiado, setHaCambiado] = useState(false);
  const [errorTabla, setErrorTabla] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [listaCursos, setListaCursos] = useState([]);
  const [deleteState, setDeleteState] = useState('TODO');
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [showEditModal, setShowEditModal] = React.useState(false);
  var apiDONE = useSelector((state) => state.loading.APIactions);
  var datosCentro = useSelector((state) => state.directorReducer.datosCentro);
  var getNiveles = useSelector((state) => state.directorReducer.getNiveles);
  const locale = useSelector((state) => state.setting.locale);

  var getNivelesAnt = useSelector(
    (state) => state.directorReducer.getNivelesAnt
  );

  const measureRanges = useSelector((state) => state.centroReducer.measureRanges);

  var borrarDatosData = useSelector(
    (state) => state.directorReducer.borrarDatosData
  );

  useEffect(() => {
    dispatch(directorActions.getNiveles());
  }, [locale]);

  useEffect(() => {
    setOpen(false);
  }, [borrarDatosData]);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    if(deleteState === 'DONE') {
      history.push("/user/start");
      window.scrollTo({top: 0});
    } else setOpen(false);
  };

  const [open_save, setOpenSave] = React.useState(false);

  const handleCloseSave = () => {
    setOpenSave(false);
    // history.push("/");
  }
  

  const handleCloseNoedit = () => {    
    history.push("/auth/centro");
  };

  const [open2, setOpen2] = React.useState(false);

  const handleClose2 = () => {
    setOpen2(false);
  };

  const [open3, setOpen3] = React.useState(false);

  const handleClose3 = () => {
    setOpen3(false);
    doSave();
  };

  const handleChangeCheck = (index) => (event) => {
    setHaCambiado(true);
    setListaCursos(
      listaCursos.map((item) =>
        item.id === index ? { ...item, active: event.target.checked } : item
      )
    );
  };

  const borrarCentro = async () => {
    setDeleteState('DOING');

    try {
      await new Promise((resolve, reject) =>
        dispatch(directorActions.borrarCentro(resolve, reject))
      );

      setDeleteState('DONE');
    } catch { }
  };

  const handleChangeCantidad = (index) => (event) => {
    setHaCambiado(true);
    setListaCursos(
      listaCursos.map((item) =>
        item.id === index
          ? { ...item, cantidadalumnos: event.target.value }
          : item
      )
    );
  };

  const handleChangeAbandono = (index) => (event) => {
    setHaCambiado(true);
    setListaCursos(
      listaCursos.map((item) =>
        item.id === index ? { ...item, abandono: event.target.value } : item
      )
    );
  };

  const handleChangeRepes = (index) => (event) => {
    setHaCambiado(true);
    setListaCursos(
      listaCursos.map((item) =>
        item.id === index ? { ...item, numrepes: event.target.value } : item
      )
    );
  };

  const showRangos = (courseId, measureId) => {
    const mSalida = measureRanges.filter(({course_id, measure_id}) => {
      let resp = false;

      if (
        [null, courseId].includes(course_id) &&
        measureId === measure_id
      ) resp = true;

      return resp;
    });

    return mSalida;
  };

  var updateCentro = useSelector((state) => state.directorReducer.updateCentro);

  var updateCentroError = useSelector(
    (state) => state.directorReducer.updateCentroError
  );

  var mLoading = useSelector((state) => state.loading.loading);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const handleClickEditName = () => {
    setValues({ ...values, disableNombre: !values.disableNombre });
  };

  const handleClickEditDireccion = () => {
    setValues({ ...values, disableDireccion: !values.disableDireccion });
  };

  const handleClickEditCP = () => {
    setValues({ ...values, disableCP: !values.disableCP });
  };

  const handleClickEditCIF = () => {
    setValues({ ...values, disableCIF: !values.disableCIF });
  };

  const handleChangeForm = (event) => {
    const name = event.target.name; 
    let value = event.target.value;

    if(!name) return;

    if(name === 'kind' && value === values.kind) {
      value = null; 
    }

    setValues({
      ...values,
      [name]: value,
    });
  }

  const handleChangeEcon = (event) => {
    setValues({ ...values, nivelsocioeconomico: event.target.value });
  };

  useEffect(() => {
    if (!loaded) {
      dispatch(centroActions.getMeasureRanges());
      dispatch(directorActions.getCentro());
      dispatch(directorActions.getNivelesAnt());
      setLoaded(true);
    } else {
      if (datosCentro && datosCentro[0]) {
        const center = datosCentro[0];

        setValues({
          ...values,
          nombre: center.nombre,
          direccion: center.direccion,
          cp: center.cp,
          cif: center.CIF,
          codigo: center.codigo,
          ownership: center.ownership ? center.ownership.toLowerCase() : null,
          kind: center.kind,
          numalumnos:
            "" + center.numalumnos === "null"
              ? 0
              : center.numalumnos,
          porcentajebeca:
            "" + center.porcentajebeca === "null"
              ? 0
              : center.porcentajebeca,
          nivelsocioeconomico: "" + center.nivelsocioeconomico,
        });
      }

      if (updateCentroError) {
        setSaveError(true);
      }
      if (updateCentro) {
        setSaveError(false);
        dispatch(directorActions.endTransaction());
        history.push("/auth/start");
      }
    }
  }, [apiDONE, loaded, datosCentro, updateCentroError, updateCentro]);

  const [periodo, setPeriodo] = React.useState(0);

  const isNotMarked = (item) => (
    item.cantidadalumnos === null &&
    item.abandono === null &&
    item.numrepes === null
  ) || (
    item.cantidadalumnos === -1 &&
    item.abandono === -1 &&
    item.numrepes === -1
  );

  useEffect(() => {
    if (getNiveles && getNivelesAnt) {
      let allActive = false;
      let listaCursosNiveles = [];

      getNiveles.forEach((item) => {
        let active = true;

        if (isNotMarked(item)) {
          active = false;
        } else {
          allActive = true;
        }

        listaCursosNiveles.push({
          id: item.idcurso,
          curso: item.nombrecurso,
          cantidadalumnos: item.cantidadalumnos === null ? -1 : item.cantidadalumnos,
          abandono: item.abandono === null ? -1 : item.abandono,
          numrepes: item.numrepes === null ? -1 : item.numrepes,
          active,
        });

        if (periodo == 0 && item.periodo != null){
          setPeriodo(item.periodo);
        }
      });

      if (!allActive && getNivelesAnt.length > 0) {
        listaCursosNiveles = [];
        let anteriores = false;

        getNiveles.forEach((item) => {
          let lastPeriod = getNivelesAnt.find(old => old.idcurso === item.idcurso); 
          console.log(lastPeriod)

          if(lastPeriod) item = lastPeriod;
          else anteriores = true;

          listaCursosNiveles.push({
            id: item.idcurso,
            curso: item.nombrecurso,
            cantidadalumnos:
              item.cantidadalumnos === null ? -1 : item.cantidadalumnos,
            abandono:  -1,
            numrepes: -1,
            active: !isNotMarked(item),
          });
        })

        if (anteriores) {
          setNoTiene(true);
          setIsNew(false);
        } else {
          setIsNew(true);
        }
      } else {
        setIsNew(false);
        setNoTiene(false);
      }

      setListaCursos(listaCursosNiveles);
    }
  }, [getNiveles, getNivelesAnt]);

  const saveForm = () => {
    setOpenSave(false);

    var isOnlyFp = true;
    var isEmpty = true;
    var faltanDatos = false;
    listaCursos.map((item) => {
      if (item.active) {
        isEmpty = false;
      }
      if (item.active) {
        if (
          item.abandono === -1 ||
          item.cantidadalumnos === -1 ||
          item.numrepes === -1
        ) {
          faltanDatos = true;
        }
      }
      if (!item.curso.includes("FP") && item.active) {
        isOnlyFp = false;
      }
    });

    if (!isEmpty && isOnlyFp) {
      setOpen3(true);
    } else {
      if (isNew) {
        if (isEmpty || faltanDatos) {
          setErrorTabla(true);
          setOpen5(true);
        } else {
          setErrorTabla(false);
          doSave();
        }
      } else {
        if (faltanDatos) {
          setErrorTabla(true);
          setOpen5(true);
        } else {
          setErrorTabla(false);
          doSave();
        }
      }
    }
  };

  const doSave = () => {
    var isValid = true;

    setSubmitted(true);
    if (
      values.nombre === '' ||
      values.direccion === '' ||
      values.cp === '' ||
      !values.ownership ||
      values.numalumnos === '' ||
      parseInt(values.numalumnos) < 10 ||
      values.nivelsocioeconomico === "0" ||
      values.porcentajebeca === '' ||
      parseFloat(values.porcentajebeca) < 0
    ) {
      isValid = false;
    }

    if (isValid) {
      if (notiene && !hacambiado) {
        setOpen4(true);
      } else {
        saveDataCentro();
      }
    } else {
      setOpen5(true);
    }
  };

  const saveDataCentro = () => {
    dispatch(directorActions.updateNiveles(listaCursos));

    dispatch(
      directorActions.updateCentro(
        values.nombre,
        values.direccion,
        values.cp,
        values.cif,
        values.ownership.toUpperCase(),
        values.kind,
        values.nivelsocioeconomico,
        values.porcentajebeca,
        values.numalumnos
      )
    );
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  const handleClose5 = () => {
    setOpen5(false);
  };

  const OpenSave = () => {
    if(
      (perfil == 3 && periodo == enviado_director_periodo) ||
      resultado_completo
    ) {
      setShowEditModal(true);
    } else if(!notiene) {
      setOpenSave(true);
    } else {
      saveForm();
    }
  }

  return (
    <div>
      <Dialog
        open={open4}
        onClose={handleClose4}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Language langKey="config.alert" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p><Language langKey="config.alert_param_one" /></p>
            <p><Language langKey="config.alert_param_two" /></p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose4} color="primary" autoFocus>
            <Language langKey="config.alert_action_two" />
          </Button>
          <Button onClick={saveDataCentro} color="primary">
            <Language langKey="config.alert_action_one" />
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open5}
        onClose={handleClose5}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Language langKey="important" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p><Language langKey="config.missing_data_one" /></p>
            <p><Language langKey="config.missing_data_two" /></p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose5} color="primary">
            <Language langKey="ok" />
          </Button>
        </DialogActions>
      </Dialog>

      <NavBarSimple auth={true} />

      <div className="defaultFull">
        <Modal show={open2} onHide={handleClose2}>
          <Modal.Header closeButton className="headerModal">
            <Modal.Title>
              <Language langKey="config.director_changed" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p><Language langKey="config.director_changed_one" /></p>
              <p>
                <Language langKey="config.director_changed_two" />
                <a
                  className='maindarkLink'
                  href='mailto:info@xcelence.es'
                  target='_blank'
                >info@xcelence.es</a>.
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className={` modalborrar btnHome btnCenter `} onClick={handleClose2}>
              <Language langKey="continue" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Grid container spacing={3} justifyContent="center" className="simpleGrid">
          <Grid item sm={12}>
            <div className="h1home">
              <Language langKey="center_info" />
            </div>

            <div style={{ marginTop: "25px" }}>
              <OutlinedInput
                className=" customInput formData"
                id="standard-adornment-password"
                type="text"
                disabled={values.disableNombre}
                value={values.nombre}
                error={submitted && !values.nombre}
                onChange={handleChange("nombre")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      className="iconHeadHover"
                      aria-label="toggle password visibility"
                      onClick={handleClickEditName}
                      onMouseDown={handleMouseDown}
                    >
                      {values.disableNombre ? (
                        <Create className="iconHover disableInputIcon" />
                      ) : (
                        <Create className="iconHover enableInputIcon" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {submitted && !values.nombre && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.required_cp" />
                </div>
              )}
            </div>
            <div
              className="formData"
              style={{ marginTop: "15px", display: "flex" }}
            >
              <div style={{ flex: "60%" }}>
                <OutlinedInput
                  className=" customInput "
                  id="standard-adornment-password"
                  type="text"
                  disabled={values.disableDireccion}
                  value={values.direccion}
                  error={submitted && !values.direccion}
                  onChange={handleChange("direccion")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        className="iconHeadHover"
                        aria-label="toggle password visibility"
                        onClick={handleClickEditDireccion}
                        onMouseDown={handleMouseDown}
                      >
                        {values.disableDireccion ? (
                          <Create className="iconHover disableInputIcon" />
                        ) : (
                          <Create className="iconHover enableInputIcon" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {submitted && !values.direccion && (
                  <div className="errorLayer">
                    <i className="fa fa-exclamation-triangle marginerror"></i>
                    <Language langKey="errors.required_dir" />
                  </div>
                )}
              </div>
              <div style={{ flex: "40%" }}>
                <OutlinedInput
                  className=" customInput "
                  id="standard-adornment-password"
                  type="text"
                  disabled={values.disableCP}
                  value={values.cp}
                  error={submitted && !values.cp}
                  onChange={handleChange("cp")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        className="iconHeadHover"
                        aria-label="toggle password visibility"
                        onClick={handleClickEditCP}
                        onMouseDown={handleMouseDown}
                      >
                        {values.disableCP ? (
                          <Create className="iconHover disableInputIcon" />
                        ) : (
                          <Create className="iconHover enableInputIcon" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {submitted && !values.cp && (
                  <div className="errorLayer">
                    <i className="fa fa-exclamation-triangle marginerror"></i>
                    <Language langKey="errors.required_cp" />
                  </div>
                )}
              </div>
            </div>

            <div
              className="formData"
              style={{ marginTop: "15px", display: "flex" }}
            >
              <div style={{ flex: "60%" }}>
                <OutlinedInput
                  className=" customInput "
                  id="standard-adornment-password"
                  type="text"
                  disabled={values.disableCIF}
                  value={values.cif}
                  onChange={handleChange("cif")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        className="iconHeadHover"
                        aria-label="toggle password visibility"
                        onClick={handleClickEditCIF}
                        onMouseDown={handleMouseDown}
                      >
                        {values.disableCIF ? (
                          <Create className="iconHover disableInputIcon" />
                        ) : (
                          <Create className="iconHover enableInputIcon" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              <div style={{ flex: "40%" }}>
                <div style={{ flex: "60%" }}>
                  <OutlinedInput
                    className=" customInput "
                    id="standard-adornment-password"
                    type="text"
                    disabled={true}
                    value={values.codigo}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          className="iconHeadHover"
                          aria-label="toggle password visibility"
                          onMouseDown={handleMouseDown}
                        >
                          <Lock className="iconHover disableInputIcon" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item sm={12}>
            <div className="txtHome fontStrong">
              <Language langKey="ownership" /> {isNew ? "*" : ""}
            </div>
            <div>
              <RadioGroup
                className={classes.formControl}
                name="ownership"
                value={values.ownership}
                onChange={handleChangeForm}
              >
                {ownerships.map(key => (
                  <FormControlLabel
                    key={key}
                    value={String(key)}
                    control={
                      <Radio
                        className={classes.root}
                        color="default"
                        checkedIcon={
                          <span
                            className={clsx(classes.icon, classes.checkedIcon)}
                          />
                        }
                        icon={<span className={classes.icon} />}
                      />
                    }
                    label={<Language langKey={key} />}
                  />
                ))}
              </RadioGroup>
              {submitted && !values.ownership && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.required" />
                </div>
              )}
            </div>
          </Grid>

          <Grid item sm={12}>
            <div className="txtHome fontStrong">
              <Language langKey="center_type" />&nbsp;
              <Tooltip
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
                arrow
                placement="top"
                title={
                  <span style={{ fontSize: "12px; !important" }}>
                    <Language langKey="cepa_info" />
                  </span>
                }
                style={{ maxWidth: 600 }}
              >
                <InfoRounded style={{paddingBottom: 4}} />
              </Tooltip>
            </div>
            <div>
              <RadioGroup
                className={classes.formControl}
                name="kind"
                value={values.kind}
              >
                {kindsOfCenter.map(key => (
                  <FormControlLabel
                    key={key}
                    value={key}
                    onClick={handleChangeForm}
                    control={
                      <Radio
                        className={classes.root}
                        color="default"
                        checkedIcon={
                          <span className={clsx(classes.icon, classes.checkedIcon)} />
                        }
                        icon={<span className={classes.icon} />}
                      />
                    }
                    label={<Language langKey={key} />}
                  />
                ))}
              </RadioGroup>
            </div>
          </Grid>
        </Grid>

        {/* tabla estudios */}
        <Grid container spacing={3} justifyContent="center" className="gridnoPadding">
          <Grid item sm={12} md={12} lg={9} xl={9}>
            {measureRanges.length > 0 &&
              listaCursos &&
              listaCursos.length > 0 && (
                <div>
                  <p className="txtHome">
                    <Language langKey="config.info_level" />
                    <b className="text-bold">
                      <Language langKey="config.info_level_one" />
                    </b>
                    <Language langKey="config.info_level_two" />
                    <b className="text-bold">
                      <Language langKey="config.info_level_three" />
                    </b>
                  </p>

                  <Table
                    hover
                    striped
                    style={
                      notiene || errorTabla
                        ? { marginTop: 20, border: "2px solid red" }
                        : { marginTop: 20 }
                    }
                  >
                    <thead>
                      <tr className="tableNiveles">
                        <th className="boderTable"></th>
                        <th className="boderTable">
                          <Language langKey="config.center_offer" /> {isNew ? "*" : ""}
                        </th>
                        <th className="boderTable">
                          <Language langKey="config.study_num" /> {isNew ? "*" : ""}
                        </th>
                        <th className="boderTable">
                          <Language langKey="config.dropout_past" /> {isNew ? "*" : ""}
                        </th>
                        <th>
                          <Language langKey="config.repeat_past" /> {isNew ? "*" : ""}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {listaCursos.map((item) => (
                        <tr key={item.id}>
                          <td className="tableNiveles boderTable">
                            {item.curso}
                          </td>

                          <td className="boderTable">
                            <Checkbox
                              onChange={handleChangeCheck(item.id)}
                              className={classes.rootCheck}
                              disableRipple
                              checked={item.active}
                              color="default"
                              checkedIcon={
                                <span
                                  className={clsx(
                                    classes.iconCheck,
                                    classes.checkedIconCheck
                                  )}
                                />
                              }
                              icon={<span className={classes.iconCheck} />}
                              inputProps={{
                                "aria-label": "decorative checkbox",
                              }}
                            />
                          </td>

                          <td className="boderTable">
                            <FormControl variant="outlined">
                              <Select
                                disabled={!item.active}
                                labelId="demo-simple-select-outlined-label"
                                className="inputMargin rangosSelect"
                                style={
                                  notiene && item.active
                                    ? { border: "1px solid red" }
                                    : undefined
                                }
                                IconComponent={ArrowAzul}
                                id="demo-simple-select-outlined"
                                onChange={handleChangeCantidad(item.id)}
                                value={item.cantidadalumnos}
                              >
                                <MenuItem value={-1}>
                                  <Language langKey="select" />
                                </MenuItem>
                                {showRangos(item.id, 1).map((item, i) => (
                                  <MenuItem
                                    key={i}
                                    style={{display: item.active ? 'block' : 'none'}}
                                    value={item.range_id} >
                                    {item.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </td>
                          <td className="boderTable">
                            <FormControl variant="outlined">
                              <Select
                                disabled={!item.active}
                                labelId="demo-simple-select-outlined-label"
                                className="inputMargin rangosSelect"
                                IconComponent={ArrowAzul}
                                id="demo-simple-select-outlined"
                                style={{
                                  border: notiene && item.active
                                    ? "1px solid red"
                                    : undefined 
                                }}
                                value={item.abandono}
                                onChange={handleChangeAbandono(item.id)}
                              >                                
                                <MenuItem value={-1}>
                                  <Language langKey="select" />
                                </MenuItem>
                                {showRangos(item.id, 2).map((item, i) =>
                                  <MenuItem
                                    key={i}
                                    value={item.range_id}
                                    style={{display: item.active ? 'block' : 'none'}}
                                  >
                                    {item.label}
                                  </MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </td>
                          <td>
                            <FormControl variant="outlined">
                              <Select
                                disabled={!item.active}
                                labelId="demo-simple-select-outlined-label"
                                className="inputMargin rangosSelect"
                                IconComponent={ArrowAzul}
                                id="demo-simple-select-outlined"
                                value={item.numrepes}
                                onChange={handleChangeRepes(item.id)}
                                style={
                                  notiene && item.active
                                    ? { border: "1px solid red" }
                                    : {}
                                }
                              >
                                <MenuItem value={-1}>
                                  <Language langKey="select" />
                                </MenuItem>
                                {showRangos(item.id, 3).map((item, i) =>
                                  <MenuItem
                                    key={i}
                                    value={item.range_id}
                                    style={{display: item.active ? 'block' : 'none'}}
                                  >
                                    {item.label}
                                  </MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {errorTabla && (
                    <div className="errorLayer">
                      <i className="fa fa-exclamation-triangle marginerror"></i>
                      <Language langKey="errors.select_ranges" />
                    </div>
                  )}
                </div>
              )}
          </Grid>
          <Grid item lg={3} xl={3}></Grid>
        </Grid>

        <Grid container spacing={3} justifyContent="center" className="simpleGrid">
          <Grid item sm={12} md={12} lg={9} xl={9}>
            <div style={{ marginTop: "15px" }} className="txtHome fontStrong">
              <Language langKey="config.total_students" /> {isNew ? "*" : ""}
            </div>
            <div style={{ marginTop: "15px" }} className="txtHome fontStrong">
              <OutlinedInput
                className="customInput"
                id="standard-adornment-password"
                type="number"
                placeholder={getRawMessage('config.write_total')}
                value={values.numalumnos}
                onChange={handleChange("numalumnos")}
              />
              {submitted && !values.numalumnos && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.required" />
                </div>
              )}

              {submitted &&
                (values.numalumnos && parseInt(values.numalumnos) < 10) && (
                  <div className="errorLayer">
                    <i className="fa fa-exclamation-triangle marginerror"></i>
                    <Language langKey="errors.gt_zero" />
                  </div>
                )}
            </div>
            <div style={{ marginTop: "15px" }} className="txtHome fontStrong">
              <Language langKey="config.percent_caption" /> {isNew ? "*" : ""}
            </div>
            <div style={{ marginTop: "15px" }} className="txtHome fontStrong">
              <OutlinedInput
                className=" customInput "
                id="standard-adornment-password"
                type="number"
                placeholder={getRawMessage("config.write_percent")}
                value={values.porcentajebeca}
                onChange={handleChange("porcentajebeca")}
                endAdornment={
                  <InputAdornment position="end">
                    <span className="blueLarge">%</span>
                  </InputAdornment>
                }
              />

              {submitted &&
                (values.porcentajebeca &&
                  (values.porcentajebeca && parseFloat(values.porcentajebeca)) <
                    0) && (
                  <div className="errorLayer">
                    <i className="fa fa-exclamation-triangle marginerror"></i>
                    <Language langKey="errors.gt_zero" />
                  </div>
                )}
            </div>
            <div style={{ marginTop: "25px" }} className="txtHome fontStrong">
              <Language langKey="economy_grade" />
            </div>
            <div>
              <RadioGroup
                className={classes.formControl}
                aria-label="gender"
                name="gender1"
                value={values.nivelsocioeconomico}
                onChange={handleChangeEcon}
              >
                <FormControlLabel
                  value="1"
                  control={
                    <Radio
                      className={classes.root}
                      color="default"
                      checkedIcon={
                        <span
                          className={clsx(classes.icon, classes.checkedIcon)}
                        />
                      }
                      icon={<span className={classes.icon} />}
                    />
                  }
                  label={<Language langKey="low" />}
                />
                <FormControlLabel
                  value="2"
                  control={
                    <Radio
                      className={classes.root}
                      color="default"
                      checkedIcon={
                        <span
                          className={clsx(classes.icon, classes.checkedIcon)}
                        />
                      }
                      icon={<span className={classes.icon} />}
                    />
                  }
                  label={<Language langKey="middle_low" />}
                />
                <FormControlLabel
                  value="3"
                  control={
                    <Radio
                      className={classes.root}
                      color="default"
                      checkedIcon={
                        <span
                          className={clsx(classes.icon, classes.checkedIcon)}
                        />
                      }
                      icon={<span className={classes.icon} />}
                    />
                  }
                  label={<Language langKey="middle" />}
                />
                <FormControlLabel
                  value="4"
                  control={
                    <Radio
                      className={classes.root}
                      color="default"
                      checkedIcon={
                        <span
                          className={clsx(classes.icon, classes.checkedIcon)}
                        />
                      }
                      icon={<span className={classes.icon} />}
                    />
                  }
                  label={<Language langKey="middle_high" />}
                />
                <FormControlLabel
                  value="5"
                  control={
                    <Radio
                      className={classes.root}
                      color="default"
                      checkedIcon={
                        <span
                          className={clsx(classes.icon, classes.checkedIcon)}
                        />
                      }
                      icon={<span className={classes.icon} />}
                    />
                  }
                  label={<Language langKey="high" />}
                />
              </RadioGroup>
              {submitted && values.nivelsocioeconomico === "0" && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.required" />
                </div>
              )}
            </div>
          </Grid>

          <Grid item lg={3} xl={3}></Grid>

          <Grid item sm={12} md={12} lg={9} xl={9}>
            <Modal show={open3} onHide={handleClose3}>
              <Modal.Header closeButton className="headerModal">
                <Modal.Title>
                  <Language langKey="alert.attention" />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="txtHome">
                  <Language langKey="config.fp_alert" />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className={`registerWidth btnHome btnCenter`}
                  onClick={handleClose3}
                >
                  <Language langKey="ok" />
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={open} onHide={handleClose}>
              <Modal.Header closeButton className="headerModal">
                <Modal.Title>{<Language langKey="are_you_sure" />}</Modal.Title>
              </Modal.Header>
              { ['TODO', 'DOING'].includes(deleteState) &&
                <>
                  <Modal.Body>
		    <Language langKey="config.delete_alert" />
		  </Modal.Body>
                  {deleteState === 'DOING'
                    ? <h5 
                        className="text-center"
                        style={{marginBottom: 20}}
                      >
                        <CircularProgress style={{color:"#000"}} size={22} />
                        &nbsp;Eliminado datos...
                      </h5>
                    : <Modal.Footer style={{justifyContent: 'center', columnGap: 20}}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={borrarCentro}
                      >
                        <Language langKey="config.delete" />
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClose}
                      >
                        <Language langKey="cancel" />
                      </Button>
                    </Modal.Footer>}
                </>
              }

              {deleteState === 'DONE' &&
                <>
                  <Modal.Body>
                    <h3
                      className="text-center"
                      style={{color: '#4caf50', marginTop: 20}}
                    >
                      <CheckCircle style={{color: '#4caf50'}} fontSize="large" />
                      &nbsp; <Language langKey="deleted.center_data"/>
                    </h3>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      fullWidth={true}
                      variant='contained'
                      color="primary"
                      onClick={handleClose}
                    >
                      <Language langKey="close" />
                    </Button>
                  </Modal.Footer>
                </>
              }
            </Modal>

            <Modal show={open_save} onHide={handleCloseSave}>
              <Modal.Header closeButton className="headerModal">
                <Modal.Title>
                  <Language langKey="alert.attention" />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Language langKey="config.save_content" />
              </Modal.Body>
              <Modal.Footer style={{gap: 10}}>
                <Button
                  className="modalborrar btnRemove btnCenter"
                  onClick={handleCloseSave}
                >
                  <Language langKey="cancel" />
                </Button>

                <Button
                  className="modalborrar btnHome btnCenter"
                  onClick={() => saveForm()}
                >
                  <Language langKey="ok" />
                </Button>
              </Modal.Footer>
            </Modal>

            <div style={{ marginTop: 15 }}>
              <Button
                onClick={() => OpenSave()}
                variant="contained"
                disabled={!mLoading ? false : true}
                className={`widthSimple ${
                  !mLoading ? "btnHome" : "btnDisabled"
                } btnHomePadding`}
              >
                <Language langKey="save_exit" />
                {mLoading && (
                  <i
                    style={{ marginLeft: 10 }}
                    className="fa fa-circle-o-notch fa-spin fa-2x fa-fw"
                  ></i>
                )}
              </Button>

              <Modal show={showEditModal} onHide={handleCloseNoedit}>
                <Modal.Header closeButton className="headerModal">
                  <Modal.Title>
                    <Language langKey="alert.attention" />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>{ resultado_completo
                  ? <Language langKey="config.complted_alert" />
                  : <Language langKey="config.no_editable" />
                }</Modal.Body>
                <Modal.Footer>
                  <Button
                    className="btnHome btnCenter"
                    onClick={() => handleCloseNoedit()}
                  >
                    <Language langKey="ok" />
                  </Button>
                </Modal.Footer>
              </Modal>

              {saveError && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.internal" />
                </div>
              )}
            </div>

            <div style={{ marginTop: 15 }}>
              <Button
                className="buttonLinkSimple"
                onClick={() => setOpen2(true)}
              >
                <Language langKey="config.director_changed" />
              </Button>
            </div>
            <div style={{ marginTop: 5, marginBottom: 30 }}>
              <Button
                onClick={(e) => setOpen(true)}
                className="buttonLinkSimple"
                disabled={!mLoading ? false : true}
              >
                <Language langKey="config.delete" />
              </Button>
            </div>

          </Grid>
          <Grid item lg={3} xl={3}></Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Centro;
