import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";
import { history } from "helpers/history";

export default function* watcherDatos() {
 
  yield takeEvery(actionTypes.GET_RESULTADOS_PREGUNTAS_CLAVE_CENTRO,getResultadosPreguntasClavesCentro);

  yield takeEvery(actionTypes.GET_DATOSGRAFICO, getDatosGrafico);

  yield takeEvery(actionTypes.GETCENTROFULL, getCentroFull);

  yield takeEvery(actionTypes.GETFULLRESULTADOS, getResultados);

  yield takeEvery(actionTypes.GETFILTROS, getFiltros);

  yield takeEvery(actionTypes.GETRESULTADOSGLOBAL, getResultadosCentroFiltro);
  
  yield takeEvery(actionTypes.GETRESULTADOSGLOBALHIST, getResultadosCentroFiltroHistorico);
  
  yield takeEvery(actionTypes.GET_CUESTIONARIO, getCuestionario);

  yield takeEvery(actionTypes.GET_BBDD, getBBDD);

  yield takeEvery(actionTypes.GET_BDDALL, getBBDDALL);

  yield takeEvery(actionTypes.GET_RESULTADOS_CLAVE_CENTRO,getResultadosClavesCentro);

  yield takeEvery(actionTypes.GET_RESULTADOS_CLAVE_CENTRO_PERIODO, getResultadosClavesCentroPeriodo);

  yield takeEvery(actionTypes.GET_RESULTADOS_CLAVE_CENTRO_PERIODOCO, getResultadosClavesCentroPeriodoCompara);

  yield takeEvery(actionTypes.GET_PERIODOS, getPeriodos);

  yield takeEvery(actionTypes.GET_PERIODOSALL, getAllPeriodos);
  
  yield takeEvery(actionTypes.GET_RESULTADOS_CLAVE_CENTRO_PERIODO_HIST, getResultadosClavesCentroPeriodoHistorico);

  yield takeEvery(actionTypes.GET_LASTPERIODOS, last);
  
  function* getDatosGrafico({ payload }) {
    yield put({ type: actionTypes.START_REQUEST });
    let attached = '/' 

    if(payload) attached += payload;

    try {
      let apiEndpoint = "/v7/getgraficos" + attached;

      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.GET_DATOSGRAFICO_DATA,
          payload: res.data,
        });
      } else {
        // console.log("ERROR");
        yield put({
          type: actionTypes.GET_DATOSGRAFICO_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({
        type: actionTypes.GET_DATOSGRAFICO_ERROR,
        payload: e,
      });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* last() {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint = "/v7/lastresultado";
      //console.log("Call => " + apiEndpoint);
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.GET_LASTPERIODOS_DATA,
          payload: res.data,
        });
      } else {
        // console.log("ERROR");
        yield put({
          type: actionTypes.GET_LASTPERIODOS_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({
        type: actionTypes.GET_LASTPERIODOS_ERROR,
        payload: e,
      });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getResultadosCentroFiltroHistorico({ payload }) {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint = "/v7/resultados/centro/periodo/" + payload.id;
      //console.log("Call => " + apiEndpoint);
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.postToken(apiEndpoint, payload).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.GETRESULTADOSGLOBALHIST_SUCCESS,
          payload: res.data,
        });
      } else {
        // console.log("ERROR");
        yield put({
          type: actionTypes.GETRESULTADOSGLOBALHIST_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({
        type: actionTypes.GETRESULTADOSGLOBALHIST_ERROR,
        payload: e,
      });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getAllPeriodos() {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint = "/v7/allperiodos";
      //console.log("Call => " + apiEndpoint);
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.GET_PERIODOSALL_DATA,
          payload: res.data,
        });
      } else {
        yield put({
          type: actionTypes.GET_PERIODOSALL_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.GET_PERIODOSALL_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getPeriodos() {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint = "/v7/historico/periodosenviados";
      //console.log("Call => " + apiEndpoint);
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.GET_PERIODOS_DATA,
          payload: res.data,
        });
      } else {
        yield put({
          type: actionTypes.GET_PERIODOS_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.GET_PERIODOS_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getCuestionario() {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint = "/v7/cuestionario";
      //console.log("Call => " + apiEndpoint);
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.GET_CUESTIONARIO_SUCCESS,
          payload: res.data,
        });
      } else {
        yield put({
          type: actionTypes.GET_CUESTIONARIO_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.GET_CUESTIONARIO_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getFiltros() {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint = "/v7/filtros";

      const res = yield call(xcService.getToken,apiEndpoint); 

      if (res.status === 200) {
        yield put({
          type: actionTypes.GETFILTROS_DATA,
          payload: res.data,
        });
      } else {
        yield put({
          type: actionTypes.GETFILTROS_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.GETFILTROS_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getResultados({ payload }) {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint = "/v7/resultados/informe";
      //console.log("Call => " + apiEndpoint);
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.postToken(apiEndpoint, payload).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.GETFULLRESULTADOS_DATA,
          payload: res.data,
        });
      } else {
        yield put({
          type: actionTypes.GETFULLRESULTADOS_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.GETFULLRESULTADOS_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getBBDDALL(){
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint = "/v7/bbddall";
      //console.log("Call => " + apiEndpoint);
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.GET_BDDALL_DATA,
          payload: res.data,
        });
      } else {
        // console.log("ERROR");
        yield put({
          type: actionTypes.GET_BDDALL_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({
        type: actionTypes.GET_BDDALL_ERROR,
        payload: e,
      });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getBBDD({ payload }) {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint = "/v7/bbdd";
      //console.log("Call => " + apiEndpoint);
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.postToken(apiEndpoint, payload).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.GET_BBDD_SUCCESS,
          payload: res.data,
        });
      } else {
        // console.log("ERROR");
        yield put({
          type: actionTypes.GET_BBDD_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({
        type: actionTypes.GET_BBDD_ERROR,
        payload: e,
      });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getResultadosCentroFiltro({ payload }) {
    yield put({ type: actionTypes.START_REQUEST });
    
    try {
      let apiEndpoint = "/v7/resultados/centro";
      //console.log("Call => " + apiEndpoint);
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.postToken(apiEndpoint, payload).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.GETRESULTADOSGLOBAL_SUCCESS,
          payload: res.data,
        });
      } else {
        // console.log("ERROR");
        yield put({
          type: actionTypes.GETRESULTADOSGLOBAL_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({
        type: actionTypes.GETRESULTADOSGLOBAL_ERROR,
        payload: e,
      });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getCentroFull({payload}) {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint = "/v7/datos/centro/" + payload.id;
      //console.log("Call => " + apiEndpoint);
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({ type: actionTypes.GETCENTROFULL_DATA, payload: res.data });
      } else {
        yield put({
          type: actionTypes.GETCENTROFULL_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({ type: actionTypes.GETCENTROFULL_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getResultadosClavesCentroPeriodoHistorico({payload}) {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint = "/v7/resultados/informe/periodo/" + payload.id;
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.GET_RESULTADOS_CLAVE_CENTRO_PERIODO_HIST_DATA,
          payload: res.data,
        });
      } else {
        // console.log("ERROR");
        yield put({
          type: actionTypes.GET_RESULTADOS_CLAVE_CENTRO_PERIODO_HIST_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({
        type: actionTypes.GET_RESULTADOS_CLAVE_CENTRO_PERIODO_HIST_ERROR,
        payload: e,
      });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getResultadosClavesCentroPeriodoCompara({payload}) {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      //let apiEndpoint = "/v7/resultados/centro";
      let apiEndpoint = "/v7/resultados/periodo/comparativa/" + payload.id;
      //console.log("Call => " + apiEndpoint);
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.GET_RESULTADOS_CLAVE_CENTRO_PERIODOCO_SUCCESS,
          payload: res.data,
        });
      } else {
        // console.log("ERROR");
        yield put({
          type: actionTypes.GET_RESULTADOS_CLAVE_CENTRO_PERIODOCO_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({
        type: actionTypes.GET_RESULTADOS_CLAVE_CENTRO_PERIODOCO_ERROR,
        payload: e,
      });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getResultadosClavesCentroPeriodo({payload}) {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      //let apiEndpoint = "/v7/resultados/centro";
      let apiEndpoint = "/v7/resultados/periodo/" + payload.id;
      //console.log("Call => " + apiEndpoint);
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.GET_RESULTADOS_CLAVE_CENTRO_PERIODO_SUCCESS,
          payload: res.data,
        });
      } else {
        // console.log("ERROR");
        yield put({
          type: actionTypes.GET_RESULTADOS_CLAVE_CENTRO_PERIODO_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({
        type: actionTypes.GET_RESULTADOS_CLAVE_CENTRO_PERIODO_ERROR,
        payload: e,
      });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getResultadosClavesCentro() {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      //let apiEndpoint = "/v7/resultados/centro";
      let apiEndpoint = "/v7/resultados/totales";
      //console.log("Call => " + apiEndpoint);
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.GET_RESULTADOS_CLAVE_CENTRO_SUCCESS,
          payload: res.data,
        });
      } else {
        // console.log("ERROR");
        yield put({
          type: actionTypes.GET_RESULTADOS_CLAVE_CENTRO_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({
        type: actionTypes.GET_RESULTADOS_CLAVE_CENTRO_ERROR,
        payload: e,
      });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getResultadosPreguntasClavesCentro() {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      //let apiEndpoint = "/v7/resultados/pregunta/centro";
      let apiEndpoint = "/v7/resultados/centro/informe";
      //console.log("Call => " + apiEndpoint);
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.GET_RESULTADOS_PREGUNTAS_CLAVE_CENTRO_SUCCESS,
          payload: res.data,
        });
      } else {
        // console.log("ERROR");
        yield put({
          type: actionTypes.GET_RESULTADOS_PREGUNTAS_CLAVE_CENTRO_ERROR,
          payload: res.data,
        });
      }
    } catch (e) {
      yield put({
        type: actionTypes.GET_RESULTADOS_PREGUNTAS_CLAVE_CENTRO_ERROR,
        payload: e,
      });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

}
