import { takeEvery, call, put } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";
import { fileToBase64 } from "utils/global_helper";

export default function* watcherRecursos() {

  yield takeEvery(actionTypes.GET_RECURSOS_REL, getRecursosSel);
  yield takeEvery(actionTypes.GET_RECURSO, getRecurso);
  yield takeEvery(actionTypes.GET_RECURSOS, getRecursos);
  yield takeEvery(actionTypes.GET_CURSOS_RECURSOS, getCursosRecursos);

  yield takeEvery(actionTypes.GET_COMUNIDADES_RECURSOS, getComunidadesRecursos);

  yield takeEvery(actionTypes.GET_PERSPECTIVAS, getPerspectivas);

  yield takeEvery(actionTypes.GET_TIPOMATERIAL, getTipoMaterial);
  yield takeEvery(actionTypes.CREATE_BEST_PRACTICE, createResource);

  function* getRecursosSel(payload) {
    yield put({ type: actionTypes.START_REQUEST });
    // console.log(payload);
    try {
      let apiEndpoint = "/v11/recursos/relacionados/" + payload.payload.id;
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({ type: actionTypes.GET_RECURSOS_REL_DATA, payload: res.data });
      } else {
        yield put({ type: actionTypes.GET_RECURSOS_REL_ERROR, payload: res.data });
      
      }
    } catch (e) {
    }
    yield put({ type: actionTypes.END_REQUEST });
  }
  function* getRecurso(payload) {
    yield put({ type: actionTypes.START_REQUEST });
    // console.log(payload);
    try {
      let apiEndpoint = "/v11/recurso/" + payload.payload.id;
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({ type: actionTypes.GET_RECURSO_DATA, payload: res.data });
      } else {
        yield put({ type: actionTypes.GET_RECURSO_ERROR, payload: res.data });
      
      }
    } catch (e) {
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getTipoMaterial() {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint = "/v11/tipomaterial";
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({ type: actionTypes.GET_TIPOMATERIAL_DATA, payload: res.data });
      } else {
        // console.log("ERROR");
      
      }
    } catch (e) {
    }
    yield put({ type: actionTypes.END_REQUEST });
  }
  function* getPerspectivas() {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint = "/v11/perspectivas";
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({ type: actionTypes.GET_PERSPECTIVAS_DATA, payload: res.data });
      } else {
        // console.log("ERROR");
      
      }
    } catch (e) {
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* createResource(arg) {
    yield put({ type: actionTypes.START_REQUEST });
    const {bestPractice, contact, resource, resolve, reject} = arg.payload; 

    try {
      let apiEndpoint = "/v11/recursos";

      if(bestPractice.centerLogo instanceof File) {
        resource.centerLogo = yield call(fileToBase64, bestPractice.centerLogo);
      } else {
        bestPractice.centerLogo = null;
      }
      delete bestPractice.centerLogo;

      if(bestPractice.document instanceof File) {
        resource.document = yield call(fileToBase64, bestPractice.document);
      } else {
        resource.documentPath = bestPractice.document;
        resource.document = null;
      }
      delete bestPractice.document;

      const _resource = {
        ...resource,
        titulo: resource.title,
        claves: resource.keys.map(id => ({id})),
        cursos: resource.courses.map(id => ({id})),
        resourceType: 'BEST_PRACTICE',
        perspectiva: resource.perspective,
        authors: resource.authors.map((name, i) => ({
          name,
          link: resource.authors[i],
        })),
        bestPractice,
        contact,
        keys: undefined,
        title: undefined,
        courses: undefined,
      }

      const res = yield call(xcService.post, apiEndpoint, _resource);

      if (res.status === 200) {
        yield call(resolve, "Create resource");
      } else {
        yield call(reject, "Interal Error");
      }

    } catch (e) {
      console.log(e);

      yield call(reject, "Interal Error");
    }
  }

  function* getRecursos() {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint = "/v11/recursos";
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({ type: actionTypes.SET_RECURSOS, payload: res.data });
      } else {
        // console.log("ERROR");
      
      }
    } catch (e) {
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

  function* getCursosRecursos() {
    yield put({ type: actionTypes.START_REQUEST });

    try {
      let apiEndpoint = "/v11/cursosrecursos";
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            xcService.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200) {
        yield put({ type: actionTypes.SET_CURSOS_RECURSOS, payload: res.data });

      } else {
        // console.log("ERROR");
      
      }
    } catch (e) {
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

}

function* getComunidadesRecursos() {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v11/comunidadesrecursos";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.getToken(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.SET_COMUNIDADES_RECURSOS, payload: res.data });

    } else {
      // console.log("ERROR");
    
    }
  } catch (e) {
  }
  yield put({ type: actionTypes.END_REQUEST });
}

