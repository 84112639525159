/* eslint-disable react/prop-types */
import React from "react";
import PreguntaContestarComponent from "../Components/PreguntaContestarComponent";
import "../preguntas.css";

import * as _ from "lodash";

function PreguntaContestarSingle(props) {
  const respuestas = props.respuestasXPreguntas

  return (
    <div>
      {respuestas != null ? (
        <PreguntaContestarComponent
          listaPreguntasContestadas={props.listaPreguntasContestadas}
          listaPreguntas={props.listaPreguntas}
          slideIn={props.slideIn}
          claveNum={props.claveNum}
          pregunta={props.pregunta}
          respuestas={respuestas}
          centro={props.centro}
          color={props.color}
          nextPregunta={props.nextPregunta}
          previousPregunta={props.previousPregunta}
          showPreguntaOpcional={props.showPreguntaOpcional}
        />
      ) : (
        <div></div>
      )}
      
    </div>
  );
}
export default PreguntaContestarSingle;