import actionTypes from "constants/actionTypes";
const initialState = {
  comunidades: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_COMUNIDADES:
      return {
        ...state,
        comunidades: action.payload,
      };  
    default:
      return state;
  }
};
