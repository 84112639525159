import React, { useState, useEffect } from "react";
import NavBarSimple from "../NavBar/NavBarAuth.js";
import {Grid, Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import InputAdornment from "@material-ui/core/InputAdornment";
import Create from "@material-ui/icons/Create";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { userActions } from "actions/userActions";
import { useHistory } from "react-router-dom";
import { cargosActions } from "actions/cargosActions";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ArrowAzul from "assets/css/ArrowAzul.js";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";


import { getRawMessage, Language } from "components/Language.js";
import { es, enUS, gl } from "date-fns/locale";

const locales = {
  es,
  en: enUS,
  gl
}

function Datos() {
  const history = useHistory();
  var user = JSON.parse(localStorage.getItem("xcuserv2"));
  const perfil = user ? +user.perfil : -1;
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    nombre: user.nombre === " " ? "" : user.nombre,
    apellidos: user.apellidos === " " ? "" : user.apellidos,
    language_id: user.language_id,
    dni: user.dni,
    email: user.email,
    telef: user.telefono,
    cargos_id: user.cargos_id,
    fechaInicioShow: new Date(user.fechaInicio),
    fechaInicio: user.fechaInicio,
    password: "",
    showPassword: false,
    disableName: true,
    disableApellidos: true,
    disableEmail: true,
    disableDni: true,
    disableTelef: true,
    disablePwd: true
  });

  const [errorDni, setErrorDni] = useState(false);
  const [errorMail, setErrorMail] = useState(false);
  const [errorMail2, setErrorMail2] = useState(false);
  const [errorMail3, setErrorMail3] = useState(false);
  const [errorTelef, setErrorTelef] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitted2, setSubmitted2] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [emailcentro, setEmailCentro] = useState("");

  var mLoading = useSelector(state => state.loading.loading);
  const locale = useSelector((state) => state.setting.locale);

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeFecha = prop => event => {
    const NewDate = moment(event).format("DD/MM/YYYY");
    setValues({
      ...values,
      fechaInicioShow: event,
      fechaInicio: NewDate
    });
    //  setValues({ ...values, [prop]: event.target.value });
  };

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const [nombreN, setNombreN] = useState("");
  const [apellidosN, setApellidosN] = useState("");
  const [emailcentroN, setEmailCentroN] = useState("");

  const setChangeNombreN = event => {
    setNombreN(event.target.value);
  };
  const setChangeApellidosN = event => {
    setApellidosN(event.target.value);
  };
  const setChangeEmailCentroN = event => {
    setEmailCentroN(event.target.value);
  };

  const newDirector = () => {
    setSubmitted2(true);
    var isValid = true;
    setErrorMail3(false);
    if (validEmail(emailcentroN)) {
      setErrorMail2(false);
    } else {
      isValid = false;
      setErrorMail2(true);
    }

    if (isValid) {
      // Gabramos

      dispatch(userActions.traspasaUsuario(nombreN, apellidosN, emailcentroN));
    }
  };

  const validEmail = function(mEmail) {
    var isValid = true;
    var EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!mEmail.match(EMAIL_REGEX)) {
      isValid = false;
    }

    var domain = emailcentro.replace(/.*@/, "").toLowerCase();
    if (
      !(
        domain !== "gmail.com" &&
        domain !== "hotmail.com" &&
        domain !== "yahoo.com" &&
        domain !== "outlook.com"
      )
    ) {
      isValid = false;
    }
    if (mEmail === "") {
      isValid = false;
    }

    return isValid;
  };

  const mantenercuenta = () => {
    setOpen(false);
    setOpen3(true);
  };
  const traspasarcuenta = () => {
    setOpen(false);
    setOpen2(true);
  };
  const deleteUser = () => {
    dispatch(userActions.delUsuario());
    //alert(t("tool.perfilelim"));
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickEditName = () => {
    setValues({ ...values, disableName: !values.disableName });
  };
  const handleClickEditApellidos = () => {
    setValues({ ...values, disableApellidos: !values.disableApellidos });
  };
  const handleClickEditEmail = () => {
    setValues({ ...values, disableEmail: !values.disableEmail });
  };
  const handleClickEditDni = () => {
    setValues({ ...values, disableDni: !values.disableDni });
  };
  const handleClickEditTelef = () => {
    setValues({ ...values, disableTelef: !values.disableTelef });
  };
  const handleClickEditPwd = () => {
    setValues({ ...values, disablePwd: !values.disablePwd });
  };
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  const handleMouseDownName = event => {
    event.preventDefault();
  };

  var apiDONE = useSelector(state => state.loading.APIactions);

  var traspasaUsuarioError = useSelector(
    state => state.userReducer.traspasaUsuarioError
  );

  var delUsuario = useSelector(state => state.userReducer.delUsuario);
  var delUsuarioError = useSelector(state => state.userReducer.delUsuarioError);
  var updateUsuario = useSelector(state => state.userReducer.updateUsuario);
  var updateUsuarioError = useSelector(
    state => state.userReducer.updateUsuarioError
  );
  var cargos = useSelector(state => state.cargosReducer.cargos);

  useEffect(() => {
    dispatch(cargosActions.getCargos());
  }, []);

  useEffect(() => {
    if (traspasaUsuarioError) {
     
      setErrorMail3(true);
    }
  }, [traspasaUsuarioError]);

  useEffect(() => {
    setSaveError(false);
    if (delUsuarioError || updateUsuarioError) {
      setSaveError(true);
    }
    if (delUsuario) {
      dispatch(userActions.endTransaction());
      dispatch(userActions.removeUser());
    }
    if (updateUsuario) {
      user.nombre = values.nombre;
      user.apellidos = values.apellidos;
      user.dni = values.dni;
      user.email = values.email;
      user.telef = values.telef;
      localStorage.setItem("xcuserv2", JSON.stringify(user));
      setValues({
        ...values,
        disableName: true,
        disablePwd: true,
        disableTelef: true,
        disableEmail: true,
        disableDni: true,
        disableApellidos: true
      });
      dispatch(userActions.endTransaction());
      history.push("/auth/start");
    }
  }, [
    apiDONE,
    delUsuario,
    delUsuarioError,
    updateUsuario,
    updateUsuarioError,
    cargos
  ]);

  var ValidateSpanishID = function(str) {
    // Ensure upcase and remove whitespace
    if (str && str !== "") {
      str = str.toUpperCase().replace(/\s/, "");

      var valid = false;
      var type = spainIdType(str);

      switch (type) {
        case "dni":
          valid = validDNI(str);
          break;
        case "none":
          valid = false;
          break;
        case "nie":
          valid = validNIE(str);
          break;
      }

      return {
        type: type,
        valid: valid
      };
    } else {
      return {
        type: "dni",
        valid: false
      };
    }
  };

  var spainIdType = function(str) {
    var DNI_REGEX = /^(\d{8})([A-Z])$/;
    var NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
    if (str.match(DNI_REGEX)) {
      return "dni";
    }
    if (str.match(NIE_REGEX)) {
      return "nie";
    }
    return "none";
  };

  var validDNI = function(dni) {
    var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
    var letter = dni_letters.charAt(parseInt(dni, 10) % 23);

    return letter == dni.charAt(8);
  };
  var validNIE = function(nie) {
    var nie_prefix = nie.charAt(0);

    switch (nie_prefix) {
      case "X":
        nie_prefix = 0;
        break;
      case "Y":
        nie_prefix = 1;
        break;
      case "Z":
        nie_prefix = 2;
        break;
    }

    return validDNI(nie_prefix + nie.substr(1));
  };

  const confirmDelete = event => {
    setOpen(true);
  };
  const saveForm = () => {
    setSubmitted(true);
    var isValid = true;
    if (!ValidateSpanishID(values.dni).valid) {
      setErrorDni(true);
      isValid = false;
    } else {
      setErrorDni(false);
    }
    var PHONE_REGEX = /\d{9}$/;
    if (validEmail(values.email)) {
      setErrorMail(false);
    } else {
      isValid = false;
      setErrorMail(true);
    }
    if (values.telef && values.telef.match(PHONE_REGEX)) {
      setErrorTelef(false);
    } else {
      isValid = false;
      setErrorTelef(true);
    }
    if (values.nombre === "" || values.apellidos === "") {
      isValid = false;
    }
    if (isValid) {
      dispatch(
        userActions.updateUsuario( {
          nombre: values.nombre,
          apellidos: values.apellidos,
          dni: values.dni,
          email: values.email,
          telef: values.telef,
          language_id: values.language_id,
          password: values.password
        })
      );
    }
  };
  return (
    <div>
      <NavBarSimple auth={true} />
      <div className="defaultFull">
        {perfil !== 1 && (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{textAlign: "center"}}
            >
              <i className="fa fa-exclamation-triangle alertIcon"></i>
            </DialogTitle>

            <DialogContent style={{ textAlign: "center" }}>
              <DialogContentText
                id="alert-dialog-description"
                className="txtHome fontStrong"
              >
                <Language langKey="set_user.delete.paragraph_one" />
              </DialogContentText>

              <DialogContentText
                id="alert-dialog-description"
                className="txtHome fontStrong"
              >
                <Language langKey="set_user.delete.question" />
              </DialogContentText>
            </DialogContent>
            <DialogActions className="iconsAlert">
              <Button onClick={deleteUser} className="btnAlerts btnAlertNo">
                <Language langKey="yes" />
              </Button>
              <Button
                onClick={handleClose}
                className="btnAlerts btnAlertOk"
                style={{ width: "280 !important" }}
                autoFocus
              >
                <Language langKey="set_user.delete.cancel" />
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {perfil === 1 && (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ textAlign: "center" }}
            >
              <i className="fa fa-exclamation-triangle alertIcon"></i>
            </DialogTitle>

            <DialogContent style={{ textAlign: "center" }}>
              <DialogContentText
                id="alert-dialog-description"
                className="txtHome fontStrong"
              >
                <p><Language langKey="set_user.transfer.helper" /></p>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="iconsAlert">
              <Button
                variant="contained"
                color="primary"
                onClick={mantenercuenta}
                className="btn-square"
                style={{ minWidth: 250 }}
                autoFocus
              >
                <Language langKey="set_user.transfer.cancel" />
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="btn-square btn-danger"
                style={{ minWidth: 250 }}
                onClick={traspasarcuenta}
              >
                <Language langKey="set_user.transfer.ok" />
              </Button>
            </DialogActions>
          </Dialog>
        )}

        <Dialog
          open={open2}
          onClose={handleClose2}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{textAlign: "center"}}>
            <i className="fa fa-exclamation-triangle alertIcon"></i>
          </DialogTitle>

          <DialogContent style={{ textAlign: "center" }}>
            <DialogContentText
              id="alert-dialog-description"
              className="txtHome fontStrong"
            >
              <p>
                <Language langKey="set_user.transfer.caption" parse />
              </p>
              <TextField
                onChange={e => setChangeNombreN(e)}
                id="nombre"
                name="nombre"
                error={submitted2 && !nombreN}
                defaultValue={nombreN}
                className="inputMargin widthF"
                variant="outlined"
                required
                placeholder={getRawMessage("set_user.new_name")}
              />
              {submitted2 && !nombreN && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.required_name" />
                </div>
              )}
              <TextField
                onChange={e => setChangeApellidosN(e)}
                className="inputMargin widthF"
                variant="outlined"
                error={submitted2 && !apellidosN}
                defaultValue={apellidosN}
                required
                id="apellidos"
                placeholder={getRawMessage("set_user.new_name")}
              />
              {submitted2 && !apellidosN && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.required_last" />
                </div>
              )}
              <TextField
                onChange={e => setChangeEmailCentroN(e)}
                className="inputMargin widthF"
                defaultValue={emailcentroN}
                type="email"
                variant="outlined"
                required
                error={submitted2 && errorMail2}
                id="emailcentro"
                placeholder={getRawMessage("set_user.new_email")}
              />
              {submitted2 && errorMail2 && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.center_email" />
                </div>
              )}
              {submitted2 && errorMail3 && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.exist_email" />
                </div>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="iconsAlert">
            <Button
              variant="contained"
              color="primary"
              onClick={newDirector}
              className="btn-square"
              style={{ minWidth: 250 }}
              autoFocus
            >
              <Language langKey="continue" />
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={open3}
          onClose={handleClose3}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
            <i className="fa fa-info-circle infoIcon"></i>
          </DialogTitle>

          <DialogContent style={{ textAlign: "center" }}>
            <DialogContentText
              id="alert-dialog-description"
              className="txtHome fontStrong"
            >
              <Language langKey="set_user.transfer.keep" />
            </DialogContentText>
          </DialogContent>
          <DialogActions className="iconsAlert">
            <Button
              variant="contained"
              color="primary"
              onClick={handleClose3}
              className="btn-square"
              style={{ minWidth: 250 }}
              autoFocus
            >
              <Language langKey="continue" />
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={3} justifyContent="center" className="defaultgrid">
          <Grid item sm={10} md={10} lg={9} xl={9}>
            <div className="h1home">
              <Language langKey="user_info" />
            </div>
            <div style={{ marginTop: "25px" }}>
              <OutlinedInput
                className=" customInput formData"
                id="standard-adornment-password"
                type="text"
                disabled={values.disableName}
                placeholder={getRawMessage("signup.name")}
                value={values.nombre}
                error={submitted && !values.nombre}
                onChange={handleChange("nombre")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      className="iconHeadHover"
                      aria-label="toggle password visibility"
                      onClick={handleClickEditName}
                      onMouseDown={handleMouseDownName}
                    >
                      {values.disableName
                        ? <Create className="iconHover disableInputIcon" />
                        : <Create className="iconHover enableInputIcon" />
                      }
                    </IconButton>
                  </InputAdornment>
                }
              />
              {submitted && !values.nombre && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.required_name" />
                </div>
              )}
            </div>
            <div style={{ marginTop: "15px" }}>
              <OutlinedInput
                className=" customInput formData"
                id="standard-adornment-password"
                type="text"
                error={submitted && !values.apellidos}
                disabled={values.disableApellidos}
                placeholder={getRawMessage("signup.last_name")}
                value={values.apellidos}
                onChange={handleChange("apellidos")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      className="iconHeadHover"
                      aria-label="toggle password visibility"
                      onClick={handleClickEditApellidos}
                      onMouseDown={handleMouseDownName}
                    >
                      {values.disableApellidos
                        ? <Create className="iconHover disableInputIcon" />
                        : <Create className="iconHover enableInputIcon" />
                      }
                    </IconButton>
                  </InputAdornment>
                }
              />
              {submitted && !values.apellidos && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.required_last" />
                </div>
              )}
            </div>
            <div style={{ marginTop: "15px" }}>
              <OutlinedInput
                className=" customInput formData"
                id="standard-adornment-password"
                type="text"
                disabled={values.disableDni}
                value={values.dni}
                placeholder={getRawMessage("signup.dni")}
                error={submitted && errorDni}
                onChange={handleChange("dni")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      className="iconHeadHover"
                      aria-label="toggle password visibility"
                      onClick={handleClickEditDni}
                      onMouseDown={handleMouseDownName}
                    >
                      {values.disableDni
                        ? <Create className="iconHover disableInputIcon" />
                        : <Create className="iconHover enableInputIcon" />
                      }
                    </IconButton>
                  </InputAdornment>
                }
              />
              {submitted && errorDni && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.invalid_dni" />
                </div>
              )}
            </div>
            <div style={{ marginTop: "15px" }}>
              <OutlinedInput
                className=" customInput formData"
                id="standard-adornment-password"
                type="email"
                disabled={values.disableEmail}
                value={values.email}
                error={submitted && errorMail}
                onChange={handleChange("email")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      className="iconHeadHover"
                      aria-label="toggle password visibility"
                      onClick={handleClickEditEmail}
                      onMouseDown={handleMouseDownName}
                    >
                      {values.disableDni ? (
                        <Create className="iconHover disableInputIcon" />
                      ) : (
                        <Create className="iconHover enableInputIcon" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {submitted && errorMail && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.center_email" />
                </div>
              )}
            </div>
            <div style={{ marginTop: "15px" }}>
              <OutlinedInput
                className=" customInput formData"
                id="standard-adornment-password"
                type="text"
                disabled={values.disableTelef}
                value={values.telef}
                placeholder={getRawMessage("signup.phone_caption")}
                error={submitted && errorTelef}
                onChange={handleChange("telef")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      className="iconHeadHover"
                      aria-label="toggle password visibility"
                      onClick={handleClickEditTelef}
                      onMouseDown={handleMouseDownName}
                    >
                      {values.disableTelef ? (
                        <Create className="iconHover disableInputIcon" />
                      ) : (
                        <Create className="iconHover enableInputIcon" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {submitted && errorTelef && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.invalid_phone" />
                </div>
              )}
            </div>
            {user.perfil === 3 && (
              <div style={{ marginTop: "15px" }}>
                {cargos ? (
                  <FormControl variant="outlined">
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      className="customInput formData"
                      IconComponent={ArrowAzul}
                      id="demo-simple-select-outlined"
                      value={values.cargos_id}
                      onChange={handleChange("cargos_id")}
                    >
                      <MenuItem value={0}>
                        <Language langKey="signup.position" />
                      </MenuItem>
                      {cargos.map(item => {
                        if (item.id !== 1){
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.nombre}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                ) : null}
                {submitted && values.cargos_id === 0 && (
                  <div className="errorLayer">
                    <i className="fa fa-exclamation-triangle marginerror"></i>
                    <Language langKey="errors.required" />
                  </div>
                )}
              </div>
            )}
            {user.perfil === 3 && (
              <div style={{ marginTop: "15px" }}>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={locales[locale.toLowerCase()]}
                >
                  <KeyboardDatePicker
                    disableToolbar
                    className=" customInput formData fechaSelect"
                    variant="inline"
                    inputVariant="outlined"
                    id="date-picker-inline"
                    format="dd/MM/yyyy"
                    placeholder={getRawMessage("signup.year")}
                    value={values.fechaInicioShow}
                    onChange={handleChangeFecha("fechaInicio")}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                  {submitted && !values.fechaInicio && (
                    <div className="errorLayer">
                      <i className="fa fa-exclamation-triangle marginerror"></i>
                      <Language langKey="errors.required" />
                    </div>
                  )}
                </MuiPickersUtilsProvider>
              </div>
            )}
            <div style={{ marginTop: "15px" }}>
              <OutlinedInput
                className=" customInput formData"
                id="standard-adornment-password"
                disabled={values.disablePwd}
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                placeholder={getRawMessage("pwd")}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    {values.disablePwd ? (
                      <IconButton
                        className="iconHeadHover"
                        aria-label="toggle password visibility"
                        onClick={handleClickEditPwd}
                        onMouseDown={handleMouseDownName}
                      >
                        {values.disablePwd
                          ? <Create className="iconHover disableInputIcon" />
                          : <Create className="iconHover enableInputIcon" />
                        }
                      </IconButton>
                    ) : (
                      <IconButton
                        className="iconHeadHover"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword
                          ? <Visibility />
                          : <VisibilityOff />
                        }
                      </IconButton>
                    )}
                  </InputAdornment>
                }
              />
            </div>
            <FormControl variant="outlined" className="mt-2">
              <Typography
                variant="body2"
                display="block"
                gutterBottom
                className="formData"
                style={{padding: '0 0.1rem'}}
                color="textSecondary"
              >
                <Language langKey="set_user.choose_lang" />
              </Typography>
              <Select
                labelId="demo-simple-select-outlined-label"
                className="customInput formData"
                IconComponent={ArrowAzul}
                id="demo-simple-select-outlined"
                value={values.language_id}
                onChange={handleChange("language_id")}
              >
                <MenuItem value={1}>
                  <Language langKey="lang.es" />
                </MenuItem>
                <MenuItem value={2}>
                  <Language langKey="lang.ca" />
                </MenuItem>
              </Select>
            </FormControl>
            <div style={{ marginTop: "15px" }}>
              {perfil === 1 ? (
                <Button
                  className="buttonLinkSimple"
                  onClick={e => confirmDelete()}
                >
                  <Language langKey="set_user.transfer.account" />
                </Button>
              ) : perfil === 2 ? (
                <Button
                  className="buttonLinkSimple"
                  onClick={e => confirmDelete()}
                >
                  <Language langKey="set_user.delete.account" />
                </Button>
              ) : (
                <Button
                  className="buttonLinkSimple"
                  onClick={e => confirmDelete()}
                >
                  <Language langKey="set_user.delete.profile" />
                </Button>
              )}
            </div>
            <div style={{ marginTop: "15px" }}>
              <Button
                onClick={e => saveForm()}
                variant="contained"
                disabled={!mLoading ? false : true}
                className={`registerWidth ${
                  !mLoading ? "btnHome" : "btnDisabled"
                } btnHomePadding`}
              >
                <Language langKey="save_exit" />
              </Button>
              {mLoading && (
                <CircularProgress size={24} className="centerLoading" />
              )}
              {saveError && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.internal" />
                </div>
              )}
            </div>
          </Grid>
          <Grid item sm={2} md={2} lg={3} xl={3}></Grid>
        </Grid>
      </div>
    </div>
  );
}
export default Datos;
