import React from "react";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

function PasswordTextField(props) {
  return (
    <Grid item xs={12}>
      <div className="formText">{props.titulo} *</div>

      <TextField
        onChange={(e) => {
          props.setCampo(e.target.value);
        }}
        id="nombre"
        name="nombre"
        type={props.type}
        className="inputMargin widthF"
        variant="outlined"
        required
        placeholder={props.placeholder}
        inputProps={props.inputProps}
        onBlur={props.handleChangePassword}
        InputProps={{
          endAdornment: props.error ? (
            <ClearIcon style={{ color: "red" }} />
          ) : props.success ? (
            <CheckIcon style={{ color: "rgb(43,116,196)" }} />
          ) : (
            <div></div>
          ),
        }}
      >
        {props.campo}
      </TextField>
    </Grid>
  );
}
export default withTranslation()(PasswordTextField);
