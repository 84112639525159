import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import logoXcelence from "assets/img/logoXcelence.png";
import home from "assets/img/home-blanc.png";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CameraIcon from "@material-ui/icons/Camera";
import { connect } from "react-redux";
import { compose } from "redux";
import { userActions } from "actions/userActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class NavBarTool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      open: false
    };
  }
  handleToggle(e) {
    e.preventDefault();
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  }

  handleClose(e) {
    e.preventDefault();
    this.setState({
      open: false,
    });
  }

  hanldeOpen(e) {
    e.preventDefault();
    this.setState({
      open: true,
    });
  }

  logout(e) {
    e.preventDefault();
    // console.log("LOGOUT");
    this.props.dispatch(userActions.logout());
  }

  render() {
    const { isExpanded, open } = this.state;
    const { t, i18n } = this.props;
    var user = JSON.parse(localStorage.getItem("xcuserv2"));
    // console.log(user);

    return (
      <div>
        <div className="navHeader">
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className="imgCenter">
              <div className=" hideTablet">
                <Link to="/" className="whiteLinkNoHover">
                  <img src={home} />
                </Link>
                <Link to="/" className="whiteLink">
                  {t("volverinicio")}
                </Link>
              </div>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className="imgCenter">
              <div className="logoHeader">
                <Link to="/">
                  <img src={logoXcelence} />
                </Link>
              </div>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className="imgCenter">
              <i
                className="fa fa-bars showbars"
                aria-hidden="true"
                onClick={(e) => this.handleToggle(e)}
              />
            </Grid>
          </Grid>
        </div>
        <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}  autoHideDuration={4000} onClose={(e) => this.handleClose(e)}>
          <Alert onClose={(e) => this.handleClose(e)} severity="warning">
            {t("resultados.acabarinforme")}
          </Alert>
        </Snackbar>
        {isExpanded && (
          <div className="menuDirector">
            <div className="txtHome">
              {t("hola")}, {user.nombre}
            </div>
            <div
              className="txtHome fontStrong ucase"
              style={{ marginTop: "25px" }}
            >
              {t("title")}
            </div>
            <div className="menuSeparator" style={{ marginTop: "10px" }}></div>
            {user.perfil === 1 && (
              <div style={{ marginTop: "15px" }}>
                <NavLink className="maindarkMenu" to="/auth/invita">
                  {t("menudirector.alta")}
                </NavLink>
                <i className="fa fa-chevron-right posMenu" aria-hidden="true" />
              </div>
            )}
            {user.perfil === 1 && (
              <div style={{ marginTop: "15px" }}>
                <NavLink className="maindarkMenu" to="/auth/historico">
                  {t("menudirector.historico")}
                </NavLink>
                <i className="fa fa-chevron-right posMenu" aria-hidden="true" />
              </div>
            )}
            
            <div style={{ marginTop: "15px" }}>
              <a
                className="maindarkMenu"
                target="_blank"
                href="https://www.youtube.com/watch?v=Tux4vFNWups"
              >
                {t("menudirector.ayuda")}
              </a>
              <i className="fa fa-chevron-right posMenu" aria-hidden="true" />
            </div>
            <div style={{ marginTop: "15px" }}>
              {localStorage.getItem("xccompleto") &&
                localStorage.getItem("xccompleto") === "1" ? (
                  <NavLink
                    className="maindarkMenu"
                    target="_blank"
                    to="/auth/informe"
                  >
                    {t("resultados.verinforme")}
                  </NavLink>
                ) : (
                  <NavLink
                    className="maindarkMenu"

                    to="#"
                    onClick={(e) => this.hanldeOpen(e)}
                  >
                    {t("resultados.verinforme")}
                  </NavLink>
                )}
              <i className="fa fa-download posMenu" aria-hidden="true" />
            </div>
            <div style={{ marginTop: "15px" }}>
              <NavLink
                className="maindarkMenu"
                target="_blank"
                to="/Cuestionario.docx"
              >
                {t("menudirector.descarga")}
              </NavLink>
              <i className="fa fa-download posMenu" aria-hidden="true" />
            </div>

            {user.perfil !== 2 && user.perfil !== 0 && (
              <div
                className="txtHome fontStrong ucase"
                style={{ marginTop: "35px" }}
              >
                {t("menudirector.centro")}
              </div>
            )}
            {user.perfil !== 2 && user.perfil !== 0 && (
              <div
                className="menuSeparator"
                style={{ marginTop: "10px" }}
              ></div>
            )}
            {user.perfil !== 2 && user.perfil !== 0 && (
              <div className="txtHome fontStrong" style={{ marginTop: "15px" }}>
                {this.props.nombreCentro}
              </div>
            )}
            {user.perfil !== 2 && user.perfil !== 0 && (
              <div style={{ marginTop: "15px" }}>
                <NavLink className="maindarkMenu" to="/auth/centro">
                  {t("menudirector.infoCentro")}
                </NavLink>
                <i className="fa fa-chevron-right posMenu" aria-hidden="true" />
              </div>
            )}
            <div
              className="txtHome fontStrong ucase"
              style={{ marginTop: "25px" }}
            >
              {user.nombre} {user.apellidos}
            </div>
            <div className="menuSeparator" style={{ marginTop: "10px" }}></div>
            <div style={{ marginTop: "15px" }}>
              <NavLink className="maindarkMenu" to="/auth/datos">
                {t("menudirector.datos")}
              </NavLink>
              <i className="fa fa-chevron-right posMenu" aria-hidden="true" />
            </div>

            <div className="menuSeparator" style={{ marginTop: "30px" }}></div>
            <div style={{ marginTop: "15px" }}>
              <NavLink
                className="maindarkMenu"
                to="#"
                onClick={(e) => this.logout(e)}
              >
                {t("menuitems.cerrars")}
              </NavLink>
              <i className="fa fa-power-off posMenu" aria-hidden="true" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (response) => ({ response });
export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(NavBarTool);
