import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";
import { history } from "helpers/history";

export default function* watcherCargos() {
  yield takeEvery(actionTypes.CREATE_SUSCRIPTION_NEWSLETTER, createSuscription);

}


function* createSuscription({payload}) {
          yield put({ type: "START_REQUEST"})
          
          try { 
            let apiEndpoint = "/v1/newsletter"
            const res = yield call(() => new Promise((resolve, reject) => { 
                xcService.postToken(apiEndpoint, payload)
                .then((response) => {
                  resolve(response)
                })
                .catch((err) => {
                  reject(err)
                });
                }));
                if(res){
                  yield put({ type: "SET_MESSAGE", payload: {message: "Datos modificados correctamente", color:"success", show:true}})
                  yield put({ type: "API_DONE", payload: "" });

            
            
                
                
                } 
            
            
              } catch (e) {
                yield put({ type: "SET_MESSAGE", payload: {message: "Error al guardar", color:"danger", show:true}})
                yield put({ type: "API_ERRORED", payload: e });
              }
              yield put({ type: "END_REQUEST"})


        
}
