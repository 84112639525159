import actionTypes from "constants/actionTypes";

export const datosActions = {
  getClavesContestadas,
  getDatosGrafico,
  getResultadosClavesCentro,
  getResultadosPreguntasClavesCentro,
  getCentroFull,
  getResultados,
  getFiltros,
  getResultadosCentroFiltro,
  getCuestionario,
  getBBDD,
  getBBDDALL,
  getResultadosClavesPeriodoCentro,
  getPeriodos,
  getResultadosCentroFiltroHist,
  getResultadosClavesPeriodoCentroHistorico,
  getResultadosClavesCentroPeriodoCompara,getAllPeriodos,
  last
};

function getDatosGrafico(id) {
  return {
    type: actionTypes.GET_DATOSGRAFICO,
    payload: id
  };
}

function getBBDDALL() {
  return {
    type: actionTypes.GET_BDDALL,
  };
}

function getAllPeriodos() {
  return {
    type: actionTypes.GET_PERIODOSALL,
  };
}

function last() {
  return {
    type: actionTypes.GET_LASTPERIODOS,
  };
}

function getPeriodos() {
  return {
    type: actionTypes.GET_PERIODOS,
  };
}

function getCuestionario() {
  return {
    type: actionTypes.GET_CUESTIONARIO,
  };
}

function getFiltros() {
  return {
    type: actionTypes.GETFILTROS,
  };
}

function getBBDD(
  comunidad,
  provincia,
  tipo,
  nivel,
  abandono,
  repe,
  curso,
  clave,
  grupo,
  mPeriodo 
) {
  
 let periodo = 2;
 if (mPeriodo)
    periodo = mPeriodo;

  var payload = {
    comunidad,
    provincia,
    tipo,
    nivel,
    abandono,
    repe,
    curso,
    clave,
    grupo, 
    periodo
  };

  // console.log("GETBBDD",payload);

  return {
    type: actionTypes.GET_BBDD,
    payload: payload,
  };
}

function getResultadosCentroFiltroHist(
  id,
  comunidad,
  provincia,
  ownership,
  nivel,
  abandono,
  repe,
  curso,
  clave
) {
  var payload = {
    id,
    comunidad,
    provincia,
    ownership,
    nivel,
    abandono,
    repe,
    curso,
    clave,
  };
  return {
    type: actionTypes.GETRESULTADOSGLOBALHIST,
    payload: payload,
  };
}

function getResultadosCentroFiltro(
  comunidad,
  provincia,
  tipo,
  nivel,
  abandono,
  repe,
  curso,
  clave
) {
  var payload = {
    comunidad,
    provincia,
    tipo,
    nivel,
    abandono,
    repe,
    curso,
    clave,
  };
  return {
    type: actionTypes.GETRESULTADOSGLOBAL,
    payload: payload,
  };
}

function getResultados(tipo, nivel, abandono, repe, curso, clave, size, grupo) {
  var payload = {
    tipo,
    nivel,
    abandono,
    repe,
    curso,
    clave,
    size,
    grupo
  };
  return {
    type: actionTypes.GETFULLRESULTADOS,
    payload: payload,
  };
}

function getCentroFull(payload) {
  return {
    type: actionTypes.GETCENTROFULL,payload
  };
}

function getResultadosClavesPeriodoCentroHistorico(payload) {
  return {
    type: actionTypes.GET_RESULTADOS_CLAVE_CENTRO_PERIODO_HIST,
    payload
  };
}

function getResultadosClavesCentroPeriodoCompara(payload) {
  return {
    type: actionTypes.GET_RESULTADOS_CLAVE_CENTRO_PERIODOCO,
    payload
  };
}

function getResultadosClavesPeriodoCentro(payload) {
  return {
    type: actionTypes.GET_RESULTADOS_CLAVE_CENTRO_PERIODO,
    payload
  };
}

function getResultadosClavesCentro() {
  return {
    type: actionTypes.GET_RESULTADOS_CLAVE_CENTRO,
  };
}

function getResultadosPreguntasClavesCentro() {
  return {
    type: actionTypes.GET_RESULTADOS_PREGUNTAS_CLAVE_CENTRO,
  };
}

function getClavesContestadas() {
  return {
    type: actionTypes.GET_CLAVES_CONTESTADAS,
  };
}
