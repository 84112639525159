import React from "react";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

function SelectField(props) {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 370,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <div className="formText">
        {props.titulo}{props.required ? ' *' : ''}
      </div>

      <FormControl
        variant="outlined"
        className={classes.formControl}
        style={{ marginTop: "3px" }}
      >
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={props.campo}
          onChange={(e) => props.setCampo(e.target.value)}
          label={props.label}
          displayEmpty={true}
          renderValue={() => {
            var returned = props.placeholder;

            props.lista.map((item) => {
              if (item.id == props.campo) {
                returned = item.nombre;
              }
            });
            return returned;
          }}
        >
          {props.lista.map((item) => {
            return <MenuItem value={item.id}>{item.nombre}</MenuItem>;
          })}
        </Select>
      </FormControl>
      {props.error && (
        <div className="errorLayer">
          <i className="fa fa-exclamation-triangle marginerror"></i>
          {props.errorMessage}
        </div>
      )}
    </Grid>
  );
}
export default withTranslation()(SelectField);
