import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as _ from "lodash";
import { recursosActions } from "actions/recursosActions";
import {
  List,
  Card,
  Avatar,
} from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import Button from "@material-ui/core/Button";
import logoXcelence from "assets/img/logoXcelence.png";
import close from "assets/img/navbar/close-blanc.png";
import close2 from "assets/img/navbar/close-blanc@2x.png";
import close3 from "assets/img/navbar/close-blanc@3x.png";
import {
  isMobile,
  isTablet,
} from "react-device-detect";

import "./Resources/ListRecursos.css";
import { Typography } from "antd";
import { Language } from "components/Language";

const { Paragraph } = Typography;

function ViewRecurso(props) {
  const [recursoSel, setRecursoSel] = useState(null);
  const [recursosRel, setRecursosRel] = useState([]);
  const [pageSize, setPageSize] = useState(3);
  const [expand, setExpand] = useState(false);
  const locale = useSelector((state) => state.setting.locale);
  const recursoData = useSelector((state) => state.recursosReducer.recursoData);
  const recursoListRel = useSelector(
    (state) => state.recursosReducer.recursoListRel
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(recursosActions.getRecurso(props.match.params.id));
    dispatch(recursosActions.getRecursosRel(props.match.params.id));
    if (isTablet || isMobile) {
      setPageSize(2);
    }
    window.scrollTo(0, 0);
  }, [props.match.params.id]);

  useEffect(() => {
    if (recursoListRel) {
      setRecursosRel(recursoListRel.slice(0, pageSize));
    }
  }, [recursoListRel]);

  useEffect(() => {
    if (recursoData && recursoData[0]) {
      setRecursoSel(recursoData[0]);
    }
  }, [recursoData]);

  const handleToggle = (e) => {
    props.history.push(`/${locale.toLowerCase()}/recursos`);
  };

  const isBestPractice = recursoSel?.resourceType === 'BEST_PRACTICE';

  return (
    <div>
      <div className="navHeader">
        <div className="logoHeader">
          <Link to="/user/start">
            <img src={logoXcelence} />
          </Link>
        </div>
        <nav className="nav navbarhome">
          <img
            onClick={(e) => handleToggle(e)}
            src={close}
            srcSet={`${close2} 2x, ${close3} 3x`}
            sizes="100vw"
            className="closeWindow"
          />
        </nav>
      </div>
      {recursoSel && (
        <div className="modalRecurso">
          <div className="lateralRecursoCollapse">
            <div className="tituloFichaT" onClick={() => setExpand(!expand)}>
              <img
                src={(recursoSel.centerLogo && recursoSel.resourceType === 'BEST_PRACTICE')
                  ? `${process.env.REACT_APP_API}/${recursoSel.centerLogo}`
                  : '/images/recursos/ordenador-img.png'
                }
                onError={(event) =>
                  event.target.src = '/images/recursos/ordenador-img.png'
                }
                className="imagenLateralRecursoImg"
              />
              <Language langKey="assets.chip" />&nbsp;
              <div style={{ float: "right", marginRight: 10 }}>
                {expand ? <UpOutlined /> : <DownOutlined />}
              </div>
            </div>
            {expand && (
              <>
                <div className="lateralTipo">
                  <Language langKey="education_grade" />
                </div>
                <div className="infoTipo">
                  {recursoSel.cursosnombre.replace(",", ", ")}
                </div>

                <div className="lateralTipo">
                  <Language langKey="typology" />
                </div>
                <div className="infoTipo">
                  {recursoSel.resourceType === 'BEST_PRACTICE'
                    ? <Language langKey="assets.bbpp" />
                    : recursoSel.tipom
                  }
                </div>

                <div className="lateralTipo">
                  <Language langKey="perspective" />
                </div>
                <div className="infoTipo">{recursoSel.pers}</div>

                <div className="lateralTipo">
                  <Language langKey="authors" />
                </div>
                <div className="infoTipo">
                  {recursoSel.authors.map((item, i) => (
                    <a key={i} href={item.link} target="_blank">
                      {item.name}
                    </a>
                  ))}
                </div>

                {recursoSel.resourceType !== 'BEST_PRACTICE' && (<>
                  <div className="lateralTipo">
                    <Language langKey="price" />
                  </div>
                  <div className="infoTipo">
                    {recursoSel.precio === 1
                      ?  <Language langKey="payment" />
                      :  <Language langKey="free" />
                    }
                  </div>
                </>)}
              </>
            )}
          </div>
          <div className="insideRecursos">
            <div className="lateralRecurso">
              <div className={isBestPractice ? 'imagenLateralRecurso logo' : 'imagenLateralRecurso'}>
                <img
                  src={isBestPractice
                    ? `${process.env.REACT_APP_API}/${recursoSel.centerLogo}`
                    : '/images/recursos/ordenador-img.png'
                  }
                  className="imagenLateralRecursoImg"
                />
              </div>
              <div className="lateralTipo">
                <Language langKey="education_grade" />:
              </div>
              <div className="infoTipo">
                <ul>
                  {recursoSel.cursosnombre?.split(",").map((curso, i) => {
                    return <li key={i}>{curso}</li>;
                  })}
                </ul>
              </div>

              <div className="lateralTipo">
                <Language langKey="typology" />:
              </div>
              <div className="infoTipo">
                {recursoSel.resourceType === 'BEST_PRACTICE'
                  ? <Language langKey="assets.bbpp" />
                  : recursoSel.tipom
                }
              </div>

              <div className="lateralTipo">
                <Language langKey="perspective" />:
              </div>
              <div className="infoTipo">
                {recursoSel.pers ? recursoSel.pers : "-"}
              </div>

              <div className="lateralTipo">
                <Language langKey="authors" />:
              </div>
              <div className="infoTipo">
                <ul>
                  {recursoSel.authors.map((item, i) => (
                    <li>
                      <a key={i} href={item.link} target="_blank">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              {recursoSel.resourceType !== 'BEST_PRACTICE' && (<>
                <div className="lateralTipo">
                  <Language langKey="price" />:
                </div>
                <div className="infoTipo">
                  {recursoSel.precio === 1
                    ?  <Language langKey="payment" />
                    :  <Language langKey="free" />
                  }
                </div>
              </>)}
            </div>

            <div className="showRecurso">
              <div>
                <div className="tipoRecurso cover">
                  <img
                    className="imgTipoRecurso"
                    src={isBestPractice
                      ? '/images/recursos/best_practice.png'
                      : '/images/recursos/t_' + recursoSel.tipo + '.png'
                    }
                  />
                </div>
                <span className="modalTituloRecurso">{recursoSel.titulo}</span>
              </div>
              <div className="queClaseEs">
                {recursoSel.claves?.split(",").map((clave, i) => {
                  return (
                    <div style={{ marginBottom: 3 }} key={i}>
                      <Avatar
                        style={{
                          marginLeft: 3,
                          marginRight: 3,
                          color: "#ffffff",
                          backgroundColor: recursoSel.colores?.split(",")[i],
                        }}
                      >
                        {clave}
                      </Avatar>
                      {recursoSel.claves_title?.split(",")[i]}
                    </div>
                  );
                })}
              </div>
              {isBestPractice 
                ? <Typography className="my-3" style={{textAlign: 'justify'}}>
                    <p><b>Datos clave: </b>{recursoSel.bestPractice.keydatas}</p>
                    <p><b>Descripción: </b>{recursoSel.description}</p>
                    <p><b>Objetivos: </b>{recursoSel.bestPractice.targets}</p>
                    <p><b>Impacto y resultados: </b>{recursoSel.bestPractice.results}</p>
                    <p><b>¿Por qué se recomienda a otros centros hacerlo?: </b>{recursoSel.bestPractice.recomendations}</p>
                    <p><b>Conclusión / Claves del éxito: </b>{recursoSel.bestPractice.conclusions}</p>
                  </Typography>
                : <Typography className="my-3" style={{textAlign: 'justify'}}>{recursoSel.description}</Typography>
              }
              <div style={{ textAlign: "center" }}>
                <Button
                  endIcon={<i className="fa fa-external-link marginerror"></i>}
                  onClick={() => window.open(
                    isBestPractice && recursoSel.document?.startsWith('public/') 
                      ? `${process.env.REACT_APP_API}/${recursoSel.document}`
                      : recursoSel.descargalink
                    , '_blank'
                  )}
                  className={`btnAccede`}
                  style={{ cursor: "pointer" }}
                >
                  {recursoSel.resourceType === 'BEST_PRACTICE'
                    ? 'Más información'
                    : <Language langKey="assets.access" />
                  }
                </Button>
              </div>
            </div>
          </div>
          {recursosRel && recursosRel.length > 0 ?
          <div className="showRels">
            <div className="relacionados">
              <Language langKey="assets.related" />
            </div>
            <List
              className="marginSubTitle"
              grid={{
                gutter: 8,
                xs: 1,
                sm: 2,
                md: 2,
                lg: 3,
                xl: 3,
                xxl: 3,
              }}
              pagination={({ pageSize: pageSize }, { hideOnSinglePage: true })}
              dataSource={recursosRel}
              renderItem={(item) => (
                <List.Item>
                  <Card
                    className="cardRecurso"
                    actions={[
                      <div style={{ height: 50 }}>
                        <div className="subtipoRecurso">
                          <Language langKey="perspective" />:
                        </div>
                        <div className="actRecurso">
                          {item.pers ? item.pers : "-"}
                        </div>
                      </div>,
                      <div style={{ height: 50 }}>
                        <div className="subtipoRecurso">
                          <Language langKey="key"/>
                        </div>
                        <div>
                          {item.claves?.split(",").map((clave, i) => {
                            return (
                              <Avatar
                                key={i}
                                style={{
                                  marginLeft: 3,
                                  color: "#ffffff",
                                  backgroundColor: item.colores?.split(",")[i],
                                }}
                              >
                                {clave}
                              </Avatar>
                            );
                          })}
                        </div>
                      </div>,
                      <div style={{ height: 50, textAlign: "center" }}>
                          <img
                            style={{
                              width: 30,
                              height: 30,
                              marginTop: 10,
                            }}
                            src={item.precio !== 0 ? "/images/recursos/pago.png" : "/images/recursos/free.png"}
                          />
                       
                      </div>,
                    ]}
                  >
                    <div className="contentRecurso">
                      {item.destacado === 1 && (
                        <div className="imgDestacado">
                          <img src={"/images/recursos/destacado.png"} />
                        </div>
                      )}
                      <div className="tipoRecurso cover">
                        <img className="imgTipoRecurso" src={"/images/recursos/t_" + item.tipo + ".png"} />
                      </div>
                      <div className="tipoRecurso">{item.tipom}</div>
                      <Paragraph
                        ellipsis={{
                          rows: 2,
                          expandable: false,
                          symbol: "more",
                        }}
                        className="descRecurso"
                      >
                        <div className="tituloRecurso">{item.titulo}</div>
                      </Paragraph>
                      <Paragraph
                        ellipsis={{
                          rows: 4,
                          expandable: false,
                          symbol: "more",
                        }}
                        className="descRecurso"
                      >
                        {item.cuerpo
                          ? item.cuerpo
                          : "Consectetur adipiscing elit. Nullam suscipit molestie feugiat. Donec malesuada velit faucibus Consectetur adipiscing elit."}
                      </Paragraph>
                      <div className="readMore" style={{ cursor: "pointer" }}>
                        <Link
                          className="linkaRecurso"
                          to={{ pathname: "/user/viewrecurso/" + item.id }}
                        >
                          <Language langKey="read_more" />
                        </Link>
                      </div>
                    </div>
                  </Card>
                </List.Item>
              )}
            />
          
          </div>
          : <div style={{ marginTop: 60 }}></div>}
        </div>
      )}
    </div>
  );
}
export default ViewRecurso;
