import React, { useState, useEffect } from "react";
import NavBarSimple from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "actions/userActions";
import { centroActions } from "actions/centroActions";
import masterData from "constants/masterData";

import { Modal } from "react-bootstrap";
import Box from "@material-ui/core/Box";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getRawMessage, Language } from "components/Language.js";
import { Link } from "react-router-dom";

import ArrowAzul from "assets/css/ArrowAzul.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function RegisterDirector() {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [valid, setValid] = useState(false);
  const [sending, setSending] = useState(false);

  const [nombre, setNombre] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [dni, setDni] = useState("");

  const [errorDni, setErrorDni] = useState(false);
  const [errorDniExiste, setErrorDniExiste] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const locale = useSelector((state) => state.setting.locale);

  const [codigocentro, setCodigoCentro] = useState("");
  const [emailcentro, setEmailCentro] = useState("");
  const [telefcentro, setTelefCentro] = useState("");
  const [errorMail, setErrorMail] = useState(false);
  const [errorTelef, setErrorTelef] = useState(false);
  const [mailExiste, setMailExiste] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [terminos, setTerminos] = useState(false);
  const [errorCodigo, setErrorCodigo] = useState(false);
  const [language, setLanguage] = useState(locale);

  const [openAviso,setOpenAviso] = useState(false);
  const [busca, setBusca] = useState("");

  const handleClose = () => {
    setOpen(false);
    setOpenAviso(false);
  };

  const [open, setOpen] = React.useState(false);
  const keypress = (event) => {
    if (event.charCode === 13 || event.keyCode === 13) {
      doSearch();
    }
  };
  const doSearch = () => {
    if (busca !== "") {
      dispatch(centroActions.searchCentroName(busca));
    }
  };

  const setChangeBusca = (event) => {
    setBusca(event.target.value);
  };

  var centrosSearchData = useSelector(
    (state) => state.centroReducer.centrosSearchData
  );

  const copyText = (e) => {
    setOpenAviso(true)
    setOpen(false);
    setCodigoCentro(e)
  };

  const buscaCodigo = () => {
    setOpen(true);
  };

  var dniData = useSelector((state) => state.authReducer.dni);
  var dniError = useSelector((state) => state.authReducer.dnierror);

  var emailvalid = useSelector((state) => state.authReducer.emailvalid);
  var emailerror = useSelector((state) => state.authReducer.emailerror);

  var codigoValid = useSelector((state) => state.centroReducer.codigovalid);
  var codigoError = useSelector((state) => state.centroReducer.codigoerror);

  const setChangeNombre = (event) => {
    setNombre(event.target.value);
  };
  const setChangeApellidos = (event) => {
    setApellidos(event.target.value);
  };
  const setChangeDni = (event) => {
    setDni(event.target.value);
  };

  const setChangeCodigoCentro = (event) => {
    setCodigoCentro(event.target.value);
  };
  const setChangeEmailCentro = (event) => {
    setEmailCentro(event.target.value);
  };
  const setChangeTelefCentro = (event) => {
    setTelefCentro(event.target.value);
  };
  const setChangeNewsletter = (event) => {
    setNewsletter(event.target.checked);
  };
  const setChangeTerminos = (event) => {
    setTerminos(event.target.checked);
  };
  var ValidateSpanishID = function(str) {
    str = str.toUpperCase().replace(/\s/, "");

    var valid = false;
    var type = spainIdType(str);

    switch (type) {
      case "dni":
        valid = validDNI(str);
        break;
      case "none":
        valid = false;
        break;
      case "nie":
        valid = validNIE(str);
        break;
    }

    return {
      type: type,
      valid: valid,
    };
  };

  var spainIdType = function(str) {
    var DNI_REGEX = /^(\d{8})([A-Z])$/;
    var NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
    if (str.match(DNI_REGEX)) {
      return "dni";
    }
    if (str.match(NIE_REGEX)) {
      return "nie";
    }
    return "none";
  };

  var validDNI = function(dni) {
    var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
    var letter = dni_letters.charAt(parseInt(dni, 10) % 23);

    return letter == dni.charAt(8);
  };
  var validNIE = function(nie) {
    var nie_prefix = nie.charAt(0);

    switch (nie_prefix) {
      case "X":
        nie_prefix = 0;
        break;
      case "Y":
        nie_prefix = 1;
        break;
      case "Z":
        nie_prefix = 2;
        break;
    }

    return validDNI(nie_prefix + nie.substr(1));
  };

  const validaStepOne = () => {
    setSubmitted(true);
    if (!ValidateSpanishID(dni).valid) {
      setErrorDni(true);
      setErrorDniExiste(false);
    } else {
      setErrorDni(false);
    }
    var isValid = false;
    if (
      nombre !== "" &&
      nombre.length > 1 &&
      nombre.length < 40 &&
      apellidos !== "" &&
      apellidos.length > 1 &&
      apellidos.length < 60 &&
      ValidateSpanishID(dni).valid
    ) {
      isValid = true;
    }
    setValid(true);

    if (isValid) dispatch(userActions.findDNI(dni));
  };
  const validEmail = function(mEmail) {
    var isValid = true;
   var EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!mEmail.match(EMAIL_REGEX)) {
      isValid = false;
    }

    var domain = emailcentro.replace(/.*@/, "").toLowerCase();
    if (
      !(
        domain !== "gmail.com" &&
        domain !== "hotmail.com" &&
        domain !== "yahoo.com" &&
        domain !== "outlook.com"
      )
    ) {
      isValid = false;
    }
    if (mEmail === "") {
      isValid = false;
    }

    return isValid;
  };
  const validaStepTwo = () => {
    setSubmitted(true);

    var isValid = false;
    var PHONE_REGEX = /\d{9}$/;
    if (validEmail(emailcentro)) {
      setErrorMail(false);
    } else {
      setErrorMail(true);
    }
    if (telefcentro.match(PHONE_REGEX)) {
      setErrorTelef(false);
    } else {
      setErrorTelef(true);
    }
    if (
      codigocentro !== "" &&
      codigocentro.length > 2 &&
      codigocentro.length < 15 &&
      emailcentro !== "" &&
      emailcentro.length > 5 &&
      telefcentro !== "" &&
      telefcentro.length > 5 &&
      validEmail(emailcentro) &&
      terminos &&
      telefcentro.match(PHONE_REGEX)
    ) {
      isValid = true;
    
    setValid(true);

    }else{
      if (codigocentro === "" ||codigocentro.length < 2 || codigocentro.length > 15 ) {
        setErrorCodigo(true);
      }
      setValid(false);
    }
    if (isValid) {
      dispatch(userActions.findEmail(emailcentro));
      dispatch(centroActions.searchCentro(codigocentro));
    }
  };
  useEffect(() => {
    var stepOk = true;
    if (step === 1 && valid) {
      if (dniData) {
        if (dniData === 1) {
          setErrorDniExiste(true);
          setValid(false);
          stepOk = false;
        } else {
          setErrorDniExiste(false);
          setValid(true);
        }
      } else {
        if (dniError) {
          setErrorDniExiste(true);
          setValid(false);
          stepOk = false;
        } else {
          setErrorDniExiste(false);
          setValid(true);
        }
      }

      if (stepOk) {
        setValid(false);
        setSubmitted(false);
        setStep(2);
      }
    }
    if (step === 2 && valid) {
      var emailOk = true;
      var codigoOk = true;
      if (emailvalid) {
        if (emailvalid === 1) {
          setMailExiste(true);
          setValid(false);
          setSending(false);
          emailOk = false;
        } else {
          setMailExiste(false);
          setValid(true);
        }
      } else {
        if (emailerror) {
          setMailExiste(true);
          setValid(false);
          setSending(false);
          emailOk = false;
        } else {
          setMailExiste(false);
          setValid(true);
        }
      }

      if (codigoError) {
        setErrorCodigo(true);
        setValid(false);
        setSending(false);
        codigoOk = false;
      } else {
        if (codigoValid === 0) {
          setErrorCodigo(true);
          setValid(false);
          setSending(false);
          codigoOk = false;
        } else {
          setErrorCodigo(false);
          setValid(true);
          codigoOk = true;
        }
      }

      if (codigoOk && emailOk && !sending) {
        setSending(true);
        dispatch(
          userActions.registerDirector({
            nombre: nombre,
            apellidos: apellidos,
            dni: dni,
            codigocentro: codigocentro,
            emailcentro: emailcentro,
            telefcentro: telefcentro,
            newsletter: newsletter,
            language: language,
            perfil: masterData.PROFILE_DIRECTOR
          })
        );
      }
    }
  }, [dniError, dniData, emailvalid, emailerror, codigoValid, codigoError]);

  return (
    <div className="fullPage">
      <NavBarSimple />
      <Snackbar open={openAviso} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}  autoHideDuration={4000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            <Language langKey="code_copied" />
          </Alert>
        </Snackbar>
        <Modal
          show={open}
          onHide={handleClose}
          style={{ width: "700px !important" }}
        >
          <Modal.Header closeButton className="headerModal">
            <Modal.Title>
              <Language langKey="search_center" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Box style={{ textAlign: "justify" }}>
              <TextField
                onKeyPress={(e) => keypress(e)}
                onChange={(e) => setChangeBusca(e)}
                className="inputMargin widthF"
                defaultValue={busca}
                id="busca"
                placeholder={getRawMessage("signup.search")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ cursor: "pointer" }}
                      onClick={() => doSearch()}
                    >
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box>
              {centrosSearchData && (
                <div className="containerCentros">
                  <div className="center-col">
                    {centrosSearchData.map((item, i) => (
                      <div key={i} className="itemCentro">
                        <div className="centroNameList">{item.nombre}</div>
                        <div className="centroAddressList">
                          {item.direccion}
                        </div>
                        <div className="centroAddressList">
                          {item.cp} {item.municipio}
                        </div>
                        <div className="centroCodigoList">{item.codigo}</div>
                        <div className="derecha">
                          <CopyToClipboard
                            className="maindarkLink .arrow-next"
                            text={item.codigo}
                            onCopy={(e) => copyText(e)}
                          >
                            <span className="maindarkLink .arrow-next">
                              <Language langKey="signup.copy_code" />
                            </span>
                          </CopyToClipboard>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Box>
          </Modal.Body>
          <Modal.Footer>
            <Button className={`registerWidth btnHome btnCenter`} onClick={() => doSearch()}>
              <Language langKey="search" />
            </Button>
          </Modal.Footer>
        </Modal>
      <div className="defaultFull">
        {step === 1 && (
          <Grid container spacing={3} justifyContent="center" className="defaultgrid">
            <Grid item xs={12}>
              <div className="h1home">
                <Language langKey="signup.director" />
              </div>
              <div className="subh1home marginSubTitle">
                <Language langKey="signup.i_am_director" />
              </div>
              <div className="txtHome marginSubTitle">
                <Language langKey="signup.director_caption" />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <div className="marginGrid txtHome strong">
                <Language langKey="signup.datas" />
                <p className="obtext rightd">
                  * <Language langKey="required" />
                </p>
              </div>
            </Grid>
            <Grid item sm={6} md={6} lg={8} xl={8}></Grid>

            <Grid item xs={12}>
              <div className="formText">
                <Language langKey="signup.name" /> *
              </div>
              <TextField
                onChange={(e) => setChangeNombre(e)}
                id="nombre"
                name="nombre"
                error={submitted && !nombre}
                defaultValue={nombre}
                className="inputMargin widthF"
                variant="outlined"
                required
                placeholder={getRawMessage("signup.name_caption")}
              />
              {submitted && !nombre && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.required_name" />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className="formText">
                <Language langKey="signup.last_name" /> *
              </div>
              <TextField
                onChange={(e) => setChangeApellidos(e)}
                className="inputMargin widthF"
                variant="outlined"
                error={submitted && !apellidos}
                defaultValue={apellidos}
                required
                id="apellidos"
                placeholder={getRawMessage("signup.last_name_caption")}
              />
              {submitted && !apellidos && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.required_last" />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className="formText">
                <Language langKey="signup.dni" /> *
              </div>
              <TextField
                onChange={(e) => setChangeDni(e)}
                className="inputMargin widthF"
                variant="outlined"
                error={submitted && (errorDni || errorDniExiste)}
                defaultValue={dni}
                required
                id="dni"
                placeholder={getRawMessage("signup.dni_caption")}
              />

              {submitted && errorDni && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.invalid_dni" />
                </div>
              )}
              {submitted && errorDniExiste && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.invalid_dni" />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className="formText">
                <Language langKey="lang.label" />
              </div>
              <FormControl variant="outlined" className="mt-3">
                <Select
                  labelId="demo-simple-select-outlined-label"
                  className="customInput widthF"
                  IconComponent={ArrowAzul}
                  style={{width: 370}}
                  id="demo-simple-select-outlined"
                  value={language}
                  onChange={(value) => setLanguage(value.target.value)}
                >
                  <MenuItem value="ES">
                    <Language langKey="lang.es" />
                  </MenuItem>
                  <MenuItem value="CA">
                    <Language langKey="lang.ca" />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={(e) => validaStepOne()}
                variant="contained"
                className={`registerWidth btnHome btnHomePadding`}
              >
                <Language langKey="next" />
              </Button>
              <p className="obtext">
                * <Language langKey="signup.confidential" />
              </p>
            </Grid>
          </Grid>
        )}
        {step === 2 && (
          <Grid container spacing={3} justifyContent="center" className="defaultgrid">
            <Grid item xs={12}>
              <div className="h1home">
                <Language langKey="signup.director" />
              </div>

              <div className="subh1home marginSubTitle">
                <Language langKey="signup.i_am_director" />
              </div>
              <div className="txtHome marginSubTitle">
                <Language langKey="signup.director_caption" />
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <div className="marginGrid txtHome strong">
                <Language langKey="center_info" />
                <p className="obtext rightd">
                  * <Language langKey="required" />
                </p>
              </div>
            </Grid>
            <Grid item sm={6} md={6} lg={8} xl={8}></Grid>
            <Grid item xs={12}>
              <div className="formText">
                <Language langKey="signup.center_code" /> *
              </div>

              <TextField
                onChange={(e) => setChangeCodigoCentro(e)}
                id="codigocentro"
                name="codigocentro"
                defaultValue={codigocentro}
                className="inputMargin widthF"
                variant="outlined"
                error={submitted && (!codigocentro || errorCodigo)}
                required
                placeholder={getRawMessage("signup.center_code_caption")}
              />
            <div>
              <a
                className="maindarkLink"
                style={{ cursor: "pointer" }}
                onClick={buscaCodigo}
              >
                <Language langKey="signup.search_code" />
              </a>
            </div>
              {submitted && !codigocentro && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.empty_code" />
                </div>
              )}
              {submitted && errorCodigo && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.code" />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className="formText">
                <Language langKey="signup.email" /> *
              </div>
              <TextField
                onChange={(e) => setChangeEmailCentro(e)}
                className="inputMargin widthF"
                defaultValue={emailcentro}
                type="email"
                variant="outlined"
                required
                error={submitted && (errorMail || mailExiste)}
                id="emailcentro"
                placeholder={getRawMessage("signup.email_caption")}
              />
              {submitted && errorMail && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.center_email" />
                </div>
              )}
              {submitted && mailExiste && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.exist_email" />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className="formText">
                <Language langKey="signup.phone" /> *
              </div>
              <TextField
                onChange={(e) => setChangeTelefCentro(e)}
                defaultValue={telefcentro}
                className="inputMargin widthF"
                variant="outlined"
                required
                error={submitted && errorTelef}
                id="teleftrabajo"
                placeholder={getRawMessage("signup.phone_caption")}
              />
              {submitted && errorTelef && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.invalid_phone" />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                className="labelForm"
                control={
                  <Checkbox
                    checked={newsletter}
                    onChange={setChangeNewsletter}
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label={<Language langKey="signup.agree_news" />}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                className="labelForm"
                error="true"
                control={
                  <Checkbox
                    checked={terminos}
                    onChange={setChangeTerminos}
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label={<Language parse langKey="signup.terms" />}
              />

              {submitted && !terminos && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.terms" />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() => validaStepTwo()}
                variant="contained"
                className={`registerWidth btnHome btnHomePadding`}
              >
                <Language langKey="next" />
              </Button>
              <br />
              
              <Link to={{pathname: "/politica-privacidad/"}} className="maindarkLink">
                <Language langKey="privicy_policies" />
              </Link>

              <p className="obtext">
                * <Language langKey="signup.confidential" />
              </p>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}

export default RegisterDirector;
