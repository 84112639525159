import React from "react";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import { v1 as uuidv1 } from "uuid";
import { NavLink, Link } from "react-router-dom";
const Posts = ({ props, getPracticas, loading }) => {
  const { t, i18n } = props;

  if (loading) {
    return <h2>Loading...</h2>;
  }

  return getPracticas.map((item) => (
    <div key={uuidv1()} className="listPracticas">
      <div className="titlePracticas">{item.titulo}</div>
      <Grid container spacing={3} style={{ paddingLeft: "40px" }}>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <img className="imgListadoPracticas" src={item.image} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={8}
          xl={8}
          style={{ paddingRight: "40px" }}
        >
          <div className="txtHome fontStrong">{item.nombre}</div>
          <div className="txtHome">
            {item.descripcion}&nbsp;
            <Link
              to={{
                pathname: "/user/practica/view",
                id: item.id,
              }}
              className="mainLink"
            >
              {t("readmore")}
            </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  ));
};
export default withTranslation()(Posts);
