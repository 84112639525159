/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import NavBar from "views/NavBar/NavBar.js";
import NavBarAuth from "views/NavBar/NavBarAuth.js";
import NavBarSimple from "views/NavBar/NavBarSimple.js";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { contenidoActions } from "actions/contenidoActions";
import Paper from "@material-ui/core/Paper";
import LabelIcon from "@material-ui/icons/Label";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import { NavLink, Link } from "react-router-dom";
import { v1 as uuidv1 } from "uuid";
import { history } from "helpers/history";

function ShowRecurso(props) {
  if (!props.location.item) {
    history.push("/recursos");
  }
  const { t, i18n } = props;
  var clave = props.location.clave;
  var cuerpo = props.location.cuerpo;
  var titulo = props.location.titulo;
  var comunidades = props.location.comunidades;
  var cursos = props.location.cursos;
  var item = props.location.item;

  var precio = props.location.precio;
  var color = props.location.color;
  var botonLink = props.location.botonLink;

  // console.log(item);

  const gotoRecurso = () => {
    window.open(item.descargalink, "_blank");
  };

  document.body.style.overflow = "auto";
  const useStyles = makeStyles((theme) => ({
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    document.body.style.overflow = "auto";
  }, []);

  return (
    <div>
      <NavBarSimple back={true} />
      <div className="defaultFull">
        {item && (
          <div>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              className="defaultgrid"
            >
              <Grid item xs={12}>
                <div className="h1home">{clave}</div>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={0}
              style={{ marginTop: "30px" }}
            >
              <Grid item xs={12} className="paddingRecursos">
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: "20px",
                    backgroundColor: "#fafbfc",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: props.location.color,
                      height: "20px",
                      borderTopLeftRadius: "6px",
                      borderTopRightRadius: "6px",
                    }}
                  />
                  <div
                    style={{
                      color: "#383b41",
                      fontSize: "20px",

                      lineHeight: "20px",
                      height: "65px",
                      padding: 15,
                    }}
                  >
                    {titulo}
                  </div>
                  <Grid container>
                    <Grid item xs={8}>
                      {cursos.map((item) => (
                        <label
                          key={uuidv1()}
                          style={{
                            color: "#808184",
                            fontSize: "16px",
                            padding: 15,
                            lineHeight: "20px",
                          }}
                        >
                          &nbsp;{item}{" "}
                          {item != cursos[cursos.length - 1] ? "|" : ""}
                        </label>
                      ))}
                    </Grid>

                    <Grid item xs={4}>
                      <div
                        style={{
                          color: "#383b41",
                          textAlign: "right",
                          fontSize: "20px",

                          lineHeight: "20px",
                          paddingRight: 10,
                        }}
                      >
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="linkAutor"
                          href={item.autorlink}
                        >
                          {item.autor}
                        </a>
                      </div>
                    </Grid>
                  </Grid>

                  {/*cuerpo */}
                  <div
                    className="cuerpoRecurso"
                    dangerouslySetInnerHTML={{ __html: cuerpo }}
                  />

                  <div className="bottomRecursos">
                    <Grid container>
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Button
                          className="btnDownloadAzul"
                          onClick={gotoRecurso}
                        >
                          {t("recursos.descargar")}{" "}
                          <i
                            style={{ marginLeft: "20px" }}
                            className="fa fa-download"
                          ></i>
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <div
                          style={{
                            padding: "11px",
                            width: "153px",
                            height: "50px",
                            borderRadius: 4,
                            backgroundColor: "#f4f5f7",
                          }}
                        >
                          {item.precio === 0
                            ? t("recursos.gratuito")
                            : t("recursos.pago")}
                          &nbsp;
                          <LabelIcon
                            style={{
                              marginTop: "-5px",
                              transform: "rotate(-180deg)",
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                <Grid item xs={12} style={{ marginTop: "30px" }}></Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
}
export default withTranslation()(ShowRecurso);
