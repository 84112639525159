export const FETCH_ANSWERS_REPORT = "FETCH_ANSWERS_REPORT";
export const SAVE_ANSWERS_REPORT = "SAVE_ANSWERS_REPORT";
export const POST_DOWNLOAD_REPORT = "POST_DOWNLOAD_REPORT";

export const SAVE_CENTER_DASHBOARD_FILTER = 'SAVE_CENTER_DASHBOARD_FILTER';

export const FETCH_CENTER_DASHBOARD_FILTER = 'FETCH_CENTER_DASHBOARD_FILTER';

export const DOWNLOAD_SIMPLE_PDF_REPORT = 'DOWNLOAD_SIMPLE_PDF_REPORT';

export const FETCH_RESULTS_KEYS = "FETCH_RESULTS_KEYS";
export const SAVE_RESULTS_KEYS_REPORT = "SAVE_RESULTS_KEYS_REPORT";
