import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { directorActions } from "actions/directorActions";

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
function DescargarPreguntasClave(props) {
  const dataSet1 = [
    {
      name: "Johson",
      amount: 30000,
      sex: "M",
      is_married: true,
    },
    {
      name: "Monika",
      amount: 355000,
      sex: "F",
      is_married: false,
    },
    {
      name: "John",
      amount: 250000,
      sex: "M",
      is_married: false,
    },
    {
      name: "Josef",
      amount: 450500,
      sex: "M",
      is_married: true,
    },
  ];

  const dataSet2 = [
    {
      name: "Johnson",
      total: 25,
      remainig: 16,
    },
    {
      name: "Josef",
      total: 25,
      remainig: 7,
    },
  ];

  const dispatch = useDispatch();
  var preguntasDown = useSelector(
    (state) => state.directorReducer.preguntasDown
  );
  
  var apiDONE = useSelector((state) => state.loading.APIactions);

  useEffect(() => {
    dispatch(directorActions.getPreguntasDownload(props.match.params.clave));
  }, [apiDONE]);
  return (
    <ExcelFile>
      <ExcelSheet data={dataSet1} name="Employees">
        <ExcelColumn label="Name" value="name" />
        <ExcelColumn label="Wallet Money" value="amount" />
        <ExcelColumn label="Gender" value="sex" />
        <ExcelColumn
          label="Marital Status"
          value={(col) => (col.is_married ? "Married" : "Single")}
        />
      </ExcelSheet>
      <ExcelSheet data={dataSet2} name="Leaves">
        <ExcelColumn label="Name" value="name" />
        <ExcelColumn label="Total Leaves" value="total" />
        <ExcelColumn label="Remaining Leaves" value="remaining" />
      </ExcelSheet>
    </ExcelFile>
  );
}
export default DescargarPreguntasClave;
