import { SAVE_ANSWERS_REPORT, SAVE_CENTER_DASHBOARD_FILTER, SAVE_RESULTS_KEYS_REPORT } from "constants/reports.constant";

const initialState = {
  communitiesReport: [],
  ownershipReport: [],
  dashboardFilterAssets: {
    periods: [],
    groups: [],
    ownerships: [],
    quizStates: [],
    ccaa: [],
    provinces: [],
    municipalities: [],
    levels: []
  },
  resultsKeys: []
};

export const reportsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SAVE_ANSWERS_REPORT:
      return {
      ...state,
      communitiesReport: action.payload.communities,
      ownershipReport: action.payload.ownership,
    }
    case SAVE_CENTER_DASHBOARD_FILTER:
      return {
        ...state,
        dashboardFilterAssets: action.payload,
      };
    case SAVE_RESULTS_KEYS_REPORT:      
      return {
        ...state,
        resultsKeys: action.payload
      }
    default: return state;
  }
};
