import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";
import { history } from "helpers/history";

export default function* watcherUser() {
  yield takeEvery(actionTypes.FINDDNI, findDNI);
  yield takeEvery(actionTypes.FINDEMAIL, findEmail);
  yield takeEvery(actionTypes.FINDEMAILDIRECTOR, findEmailDirector);
  yield takeEvery(actionTypes.DELUSUARIO, delUsuario);
  yield takeEvery(actionTypes.UPDATEUSUARIO, updateUsuario);
  yield takeEvery(actionTypes.TRASPASA_USUARIO, traspasaUsuario);
  yield takeEvery(actionTypes.FINDCENTROBYCODIGO, findCentroByCodigo);
  

  
}
function* traspasaUsuario({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/traspasa";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.postToken(apiEndpoint, payload).then(response => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
    //  yield put({ type: actionTypes.TRASPASA_USUARIO_DATA, payload: res.data });
      yield put({ type: actionTypes.START_REQUEST });
      localStorage.setItem("xcauth", "false");
      localStorage.setItem("xccompleto", "0");
      localStorage.removeItem("txcoken");
      localStorage.removeItem("xcrefreshToken");
      localStorage.removeItem("xcuserv2");
    
      history.push("/user/traspaso");
      yield put({ type: actionTypes.END_REQUEST });
    } else {
      yield put({
        type: actionTypes.TRASPASA_USUARIO_ERROR,
        payload: res.data
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.TRASPASA_USUARIO_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
function* delUsuario() {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/user";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.deleteToken(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.DELUSUARIO_DATA, payload: res.data });
    } else {
      yield put({
        type: actionTypes.DELUSUARIO_ERROR,
        payload: res.data
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.DELUSUARIO_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
function* updateUsuario({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/user";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.putToken(apiEndpoint, payload).then(response => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.UPDATEUSUARIO_DATA, payload: res.data });
    } else {
      yield put({
        type: actionTypes.UPDATEUSUARIO_ERROR,
        payload: res.data
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.UPDATEUSUARIO_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
function* findDNI({ dni }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/user/dni/" + dni;
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.get(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );

    if (res.status === 200) {
      yield put({ type: actionTypes.FINDDNI_DATA, payload: res.data });
    } else {
      yield put({ type: actionTypes.FINDDNI_ERROR, payload: res.data });
    }
  } catch (e) {
    yield put({ type: actionTypes.FINDDNI_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
function* findEmail({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/user/email";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.post(apiEndpoint, payload).then(response => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.FINDEMAIL_DATA, payload: res.data });
    } else {
      yield put({ type: actionTypes.FINDEMAIL_ERROR, payload: res.data });
    }
  } catch (e) {
    yield put({ type: actionTypes.FINDEMAIL_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* findEmailDirector({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/user/directoremail";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.post(apiEndpoint, payload).then(response => {
            resolve(response);
          });
        })
    );

    if (res.status === 200) {
      yield put({
        type: actionTypes.FINDEMAILDIRECTOR_DATA,
        payload: res.data
      });
    } else {
      yield put({
        type: actionTypes.FINDEMAILDIRECTOR_ERROR,
        payload: res.data
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.FINDEMAILDIRECTOR_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}


function* findCentroByCodigo({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/user/centro";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.post(apiEndpoint, payload).then(response => {
            resolve(response);
          });
        })
    );

    if (res.status === 200) {
      yield put({
        type: actionTypes.FINDCENTROBYCODIGO_DATA,
        payload: res.data
      });
    } else {
      yield put({
        type: actionTypes.FINDCENTROBYCODIGO_ERROR,
        payload: res.data
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.FINDCENTROBYCODIGO_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
