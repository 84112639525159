import React from "react";
import { Switch, Route } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import routes from "routes.js";

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/none") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
  </Switch>
);

export default function User() {
  const mainPanel = React.createRef();

  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };

  return (
    <div ref={mainPanel}>
      {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
      {getRoute() ? <div>{switchRoutes}</div> : <div>{switchRoutes}</div>}
    </div>
  );
}
