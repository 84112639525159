import React from "react";
import { Route, Redirect } from "react-router-dom";
import None from "layouts/None.js";
import Auth from "layouts/Auth.js";
import { userActions } from "actions/userActions";

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    component={(props) => {
      var user = JSON.parse(localStorage.getItem("xcuserv2"));
      const token = user ? user.token : null;

      if (!token ) {
        return <Redirect to="/user/login" />;
      } else {
        var path = props.location.pathname;

        if (path.includes("/auth")) {
          return <Auth {...props} />;
        }
        

        if (path.includes("/none")) {
          return <None {...props} />;
        }
      }

      return (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      );
    }}
  />
);
