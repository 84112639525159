import React, { useState, useEffect } from "react";
import NavBarSimple from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import { NavLink } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "actions/userActions";
import { Modal } from "react-bootstrap";
import { Language, getRawMessage } from "components/Language.js";
import { useForceUpdate } from "utils/custon-hooks.js";

function Login() {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [username, setUsername] = useState("");
  const [pwd, setPwd] = useState("");
  const [errorRepeat, setErrorRepeat] = useState(false);
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState();
  const [repeat, setRepeat] = useState("");
  const [errorPWd, setErrorPwd] = useState(false);
  const [open, setOpen] = React.useState(false);
  const forceUpdate = useForceUpdate();

  const locale = useSelector((state) => state.setting.locale);
  const errorLogin = useSelector((state) => state.authReducer.error);
  const chgpwdSuccess = useSelector((state) => state.authReducer.firstpwdSuccess);
  const chgpwdError = useSelector((state) => state.authReducer.firstpwdError);
  const mLoading = useSelector((state) => state.loading.loading);

  useEffect(() => {
    forceUpdate();
  }, [locale]);

  useEffect(() => {
    if (errorLogin) {
      if (errorLogin.code === 499) {
        setError(false);
        setStep(2);
      } else {
        setErrorCode(errorLogin.code)
        setError(true);
      }
    } else {
      setError(false);
    }

    if (chgpwdSuccess) {
      setOpen(true);
    }
  }, [mLoading, errorLogin, chgpwdSuccess, chgpwdError]);

  const handleClose = () => {
    setOpen(false);
    setStep(1);
  };

  const setChangeRepeat = (event) => {
    setRepeat(event.target.value);
  };

  const setChangeUsername = (event) => {
    setUsername(event.target.value);
  };

  const setChangePwd = (event) => {
    setPwd(event.target.value);
  };

  const saveForm = () => {
    setError(false);
    if (username && pwd)
      dispatch(userActions.login(username, pwd));
  };

  const changePwd = () => {
    setErrorPwd(false);
    setErrorRepeat(false);
    if (pwd.length > 7) {
      if (pwd !== repeat) {
        setErrorRepeat(true);
      } else {
        setErrorRepeat(false);
        dispatch(userActions.firstpwd(username, pwd));
      }
    } else {
      setErrorPwd(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.charCode === 13) saveForm();
  };

  return (
    <div className="fullPage">
      <NavBarSimple />
      <div className="defaultFull">
        {step === 1 && (
          <Grid container spacing={3} justifyContent="center" className="defaultgrid">
            <Grid item xs={12}>
              <div className="h1home">
                <Language langKey='login_title' />
              </div>
              <div className="txtHome marginSubTitle">
                <Language langKey='login_caption' />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="formText">
                <Language langKey='user' /> *
              </div>

              <TextField
                onKeyPress={(e) => handleKeyPress(e)}
                onChange={(e) => setChangeUsername(e)}
                id="username"
                name="username"
                error={error}
                className="inputMargin widthF"
                variant="outlined"
                required
                placeholder={getRawMessage('user_placeholder')}
              />
              {error && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey={'errors.login_' + errorCode} />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className="formText">
                <Language langKey='pwd' /> *
              </div>
              <TextField
                onKeyPress={(e) => handleKeyPress(e)}
                onChange={(e) => setChangePwd(e)}
                error={error}
                id="pwd"
                name="pwd"
                className="inputMargin widthF"
                variant="outlined"
                type="password"
                required
                placeholder={getRawMessage('pwd_placeholder')}
              />
              <div>
                <a className="maindarkLink" href="/user/forgot">
                  <Language langKey='forgot_pwd' />
                </a>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={(e) => saveForm()}
                variant="contained"
                disabled={!mLoading ? false : true}
                className={`registerWidth ${
                  !mLoading ? "btnHome" : "btnDisabled"
                } nopadding`}
              >
                <Language langKey="login_submit" />
              </Button>
              {mLoading && (
                <CircularProgress size={24} className="centerLoading" />
              )}
            </Grid>

            <Grid item xs={12} style={{ marginTop: "30px" }}>
              <div className="h2home">
                <Language langKey="no_registered" />
              </div>
              <NavLink
                style={{ marginTop: "10px" }}
                className="registerWidth btn btnHome"
                to="/user/register"
              >
                <Language langKey="create_account" />
              </NavLink>
            </Grid>
          </Grid>
        )}
        {step === 2 && (
          <Grid container spacing={3} justifyContent="center" className="defaultgrid">
            <Modal show={open} onHide={handleClose}>
              <Modal.Header closeButton className="headerModal">
                <Modal.Title>
                  <Language langKey="change_pwd" />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Language langKey="info_pwd_changed" />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className={`registerWidth btnHome btnCenter`}
                  onClick={handleClose}
                >
                  <Language langKey="login_title" />
                </Button>
              </Modal.Footer>
            </Modal>
            <Grid item xs={12}>
              <div className="h1home">
                <Language langKey="change_pwd" />
              </div>
              <div className="txtHome marginSubTitle">
                <Language langKey="login_first_access" />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="formText">
                <Language langKey='user' /> *
              </div>

              <TextField
                onKeyPress={(e) => handleKeyPress(e)}
                onChange={(e) => setChangeUsername(e)}
                value={username}
                disabled
                id="username"
                name="username"
                error={error}
                className="inputMargin widthF"
                variant="outlined"
                required
                placeholder={getRawMessage('user_placeholder')}
              />
              {error && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  {errorLogin.message}
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className="formText">
                <Language langKey="pwd" /> *
              </div>

              <TextField
                onKeyPress={(e) => handleKeyPress(e)}
                onChange={(e) => setChangePwd(e)}
                error={error}
                id="pwd"
                name="pwd"
                className="inputMargin widthF"
                variant="outlined"
                type="password"
                required
                placeholder={getRawMessage('pwd_placeholder')}
              />
              {errorPWd && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="min_pwd_error" />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className="formText">
                <Language langKey="repeat_pwd" /> *
              </div>
              <TextField
                onChange={(e) => setChangeRepeat(e)}
                className="inputMargin widthF passwordField"
                defaultChecked={repeat}
                error={errorRepeat}
                type="password"
                variant="outlined"
                required
                id="passwordRepeat"
                placeholder={getRawMessage('repeat_pwd')}
              />
              {errorRepeat && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="pwd_not_match" />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={(e) => changePwd()}
                variant="contained"
                disabled={!mLoading ? false : true}
                className={` ${
                  !mLoading ? "btnHome" : "btnDisabled"
                } registerWidth `}
              >
                <Language langKey="pwd_changed" />
              </Button>
              {mLoading && (
                <CircularProgress size={24} className="centerLoading" />
              )}
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}
export default Login;
