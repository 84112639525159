import actionTypes from "constants/actionTypes";
const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CONTENIDOSHOME_DATA:
      return {
        ...state,
        homeContent: action.payload
      };
    case actionTypes.CONTENIDOSHOME_ERROR:
      return {
        ...state,
        homeContentError: action.payload
      };
    case actionTypes.GETPRACTICAS_DATA:
      return {
        ...state,
        getPracticas: action.payload
      };
    case actionTypes.GETPRACTICAS_ERROR:
      return {
        ...state,
        getPracticasError: action.payload
      };
    case actionTypes.GETPRACTICA_DATA:
      return {
        ...state,
        getPractica: action.payload
      };
    case actionTypes.GETPRACTICA_ERROR:
      return {
        ...state,
        getPracticaError: action.payload
      };
      case actionTypes.GETCENTROSHOME_DATA:
        return {
          ...state,
          getCentrosHome: action.payload
        };
      case actionTypes.GETCENTROSHOME_ERROR:
        return {
          ...state,
          getCentrosHomeError: action.payload
        };
    default:
      return state;
  }
};
