/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import logoXcelence from "assets/img/logoXcelence.png";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import close from "assets/img/navbar/close-blanc.png";
import close2 from "assets/img/navbar/close-blanc@2x.png";
import close3 from "assets/img/navbar/close-blanc@3x.png";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";

class NavBarSimple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }
  handleToggle(e) {
    // console.log(this.props);
    e.preventDefault();

    if (this.props.back) {
      
      this.props.history.goBack();
    }

    if (this.props.auth) {
      this.props.history.push("/auth/start");
    } else {
      this.props.history.push("/user");
    }
  }
  render() {
    return (
      <div className="navHeader">
        <div className="logoHeader">
          {this.props.auth && (
            <Link to="/auth/start">
              <img src={logoXcelence} />
            </Link>
          )}
          {!this.props.auth && (
            <Link to="/">
              <img src={logoXcelence} />
            </Link>
          )}
        </div>
        <nav className="nav navbarhome">
          <img
            onClick={(e) => this.handleToggle(e)}
            src={close}
            srcSet={`${close2} 2x, ${close3} 3x`}
            sizes="100vw"
            className="closeWindow"
          />
        </nav>
      </div>
    );
  }
}

export default withRouter(NavBarSimple);
