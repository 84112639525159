import actionTypes from "constants/actionTypes";
import update from 'react-addons-update';

const initialState = {
  recursosList: null,
  cursosRecursos: null,
  comunidadesRecursos: null,
  precios: undefined,
  claves: undefined,
  comunidades: undefined,
  cursos: undefined,
  filtersReady: 0,
  recursoData:null,
  recursoDataError:null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_RECURSOS_REL_DATA:
      return {
        ...state,
        recursoListRel: action.payload
      };  
    case actionTypes.GET_RECURSO_DATA:
      return {
        ...state,
        recursoData: action.payload,
        recursoDataError: null
      };  
      case actionTypes.GET_RECURSO_ERROR:
        return {
          ...state,
          recursoData: null,
          recursoDataError: action.payload,
        };  
  
    case actionTypes.GET_PERSPECTIVAS_DATA:
      return {
        ...state,
        perspectivasList: action.payload,
      };  
      case actionTypes.GET_TIPOMATERIAL_DATA:
        return {
          ...state,
          tipoMaterialList: action.payload,
        };  
    case actionTypes.SET_RECURSOS:
      return {
        ...state,
        recursosList: action.payload,
      };  
    case actionTypes.SET_CURSOS_RECURSOS:
      return {
        ...state,
        cursosRecursos: action.payload,
      };  
    case actionTypes.SET_COMUNIDADES_RECURSOS:
      return {
        ...state,
        comunidadesRecursos: action.payload,
      };  
    case actionTypes.SET_PRECIOS_FILTER:
      return {
              ...state, 
            precios: { 
              ...state.precios,
              ["1"]: action.payload["1"],
              ["2"]: action.payload["2"],
              "-1": action.payload[-1]             
              }
            };
    case actionTypes.SET_CLAVES_PRECIOS_START:

      if(state.precios == undefined){
        return update(state, {
          precios: {
            
            $set: action.payload
          },
          filtersReady: {$set: state.filtersReady+1}
        })
      }else{
        return update(state, {
        
          filtersReady: {$set: state.filtersReady+1}
        })
      }
            
    case actionTypes.SET_CLAVES_FILTER:
      return update(state, {
        claves: {$merge: action.payload}
      })

    case actionTypes.SET_CLAVES_FILTER_START:
    
      if(state.claves == undefined){
        return update(state, {
          claves: {
            
            $set: action.payload
          },
          filtersReady: {$set: state.filtersReady+1}
        })
      }else{
        return update(state, {
        
          filtersReady: {$set: state.filtersReady+1}
        })
      }
      case actionTypes.SET_COMUNIDADES_FILTER:
        return update(state, {
          comunidades: {$merge: action.payload}
        })
  
      case actionTypes.SET_COMUNIDADES_FILTER_START:  
          
        if(state.comunidades == undefined){
          
          return update(state, {
            comunidades: {
              
              $set: action.payload
            },
            filtersReady: {$set: state.filtersReady+1}
          })
          
        }else{
          return update(state, {
          
            filtersReady: {$set: state.filtersReady+1}
          })
        }

        case actionTypes.SET_CURSOS_FILTER:
          return update(state, {
            cursos: {$merge: action.payload}
          })
    
        case actionTypes.SET_CURSOS_FILTER_START:  
            
          if(state.cursos == undefined){
            
            return update(state, {
              cursos: {
                
                $set: action.payload
              },
              filtersReady: {$set: state.filtersReady+1}
            })
            
          }else{
            return update(state, {
            
              filtersReady: {$set: state.filtersReady+1}
            })
          }
    case actionTypes.RESET_FILTERS_READY:
  
      return update(state, {
        filtersReady: {$set: 0}
        
      }) 

          
            
    default:
      return state;
  }
};
