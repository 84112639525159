/*
*  bold -> ** 
*  italic -> __
*  mark -> ==
*  link -> [label](uri) 
*  linebreak -> \n or \r
*/

// @TODO: add needed tags
// @TODO: add start tag with close tag
export const simpleParser = (input = '') => {
  let doubleMask = ['*', '_', '~', '='];
  let breakLine = ['\n', '\r'];
  let startRef = -1;
  let endRef = -1;
  let startTag = -1;

  const stack = [];

  for(let i=0; i < input.length; i++) {
    const letter = input[i]; 
    const next = input[i+1]; 

    if(doubleMask.includes(letter) && letter === next) {
      if(startTag >= 0 && stack.pop() === letter) {
        let opener = '';

        if(letter === '*') opener = '<b>'
        if(letter === '_') opener = '<i>';
        if(letter === '~') opener = '<s>';
        if(letter === '=') opener = '<mark>';

        let closer = `${opener.slice(0,1)}/${opener.slice(1)}`;

        input = input.slice(0, startTag) + opener + input.slice(startTag+2);

        i += opener.length - 2;
        input = input.slice(0,i) + closer + input.slice(i+2);

        startTag = -1;
      } else {
        stack.push(letter);
        startTag = i;
      }
    } else if(breakLine.includes(letter))
      input = input.slice(0, i) + '<br />' + input.slice(i+1);
    else if(letter === '[')
      startRef = i;
    else if(letter === ']' && startRef >= 0 && next === '(')
      endRef = i;
    else if(letter === ')' && endRef >= 0 && startRef >= 0) {
      const label = input.slice(startRef + 1, endRef);
      const angle = input.slice(endRef + 2, i);
      const target = angle.slice(0, 4) === 'http' ? '_blank' : '_self';
      const merged = `<a href="${angle}" target="${target}">${label}</a>`

      input = input.slice(0, startRef) + merged + input.slice(i+1);

      startRef = -1;
      endRef = -1;
    }
  } 

  return input;
}

// let input = "Ficha **descriptiva __data__ Acepto los [Términos y Condiciones](/user/condiciones) de esta página web";
// console.log(simpleParser(input));