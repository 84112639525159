import React from "react";
import {useSelector} from 'react-redux';

import reduxStore from '../store';
import languages from 'assets/locales';
import {simpleParser} from 'utils/parse-markdown'

export const Language = ({langKey = '', parse = false, tag = 'div'}) => {
  const {locale} = useSelector((storage) => storage.setting);

  return <>{getMessage(locale, langKey, parse, tag)}</>;
};

export const getRawMessage = (key) => {
  const {locale} = reduxStore.getState().setting;

  return key ? getMessage(locale, key) : '';
};

const getMessage = (locale = 'ES', pointer, parse = false, tag = 'div') => {
  let message;
  const [firstKey, secondKey, thirdKey] = pointer.split('.');

  if(thirdKey)
    message = languages[locale][firstKey]?.[secondKey]?.[thirdKey];
  else if(secondKey)
    message = languages[locale][firstKey]?.[secondKey];
  else
    message = languages[locale][firstKey];

  if(locale !== 'ES' && !message) message = getMessage('ES', pointer, parse);
  else message = message ?? 'No found message';

  let resp = message ?? 'No found message';

  if(parse) {
    message = simpleParser(message);

    resp = React.createElement(tag, {dangerouslySetInnerHTML: {__html: message}});
  }

  return resp;
}