import React from "react";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  ul: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex"
  }
});
const CustomPagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumbers = [];
  const classes = useStyles();
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  var totalPages = Math.ceil(totalPosts / postsPerPage);

  const handleChange = (event, value) => {
    paginate(value);
  };
  return (
    <div className={classes.root}>
      <Pagination
      className="itempagination"
        onChange={(event, page) => handleChange(event, page)}
        variant="outlined"
        shape="rounded"
        count={totalPages}
      />
    </div>
  );
};

export default CustomPagination;
