import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

function CampoTextField(props) {
  return (
    <Grid item xs={12}>
      <div className="formText">
        {props.titulo}{props.required ? ' *' : ''}
      </div>

      <TextField
        onChange={(e) => {props.setCampo(e.target.value)}}
        id="nombre"
        name="nombre"
        type={props.type}
        className="inputMargin widthF"
        variant="outlined"
        required
        placeholder={props.placeholder}
        value={props.campo}
        InputProps={props.InputProps}
      />
      {props.error && (
        <div className="errorLayer">
          <i className="fa fa-exclamation-triangle marginerror"></i>
          {props.errorMessage}
        </div>
      )}
    </Grid>
  );
}
export default CampoTextField;
