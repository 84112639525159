import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import * as _ from "lodash";
import { preguntasActions } from "actions/preguntasActions";
import Radio from "@material-ui/core/Radio";
import { directorActions } from "actions/directorActions";
import { useHistory } from "react-router-dom";
import { Language } from "components/Language";

function TablaRadioButton(props) {
  const respuestas = props.respuestas;
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading.loading);

  const [checked, setChecked] = React.useState(undefined);
  const [render, setRender] = React.useState(0);
  let counter = 0;

  useEffect(() => {
    console.log("TABLE RADIO BUTTON");
    var checkedProvisional = {};

    if (props.subpreguntasRespuestas != null) {
      props.subpreguntasRespuestas.map((item) => {
        checkedProvisional[item.subpregunta_id] = item.respuestas_id;
      });
      setRender(render + 1);
    }
    setChecked(checkedProvisional);
  }, [props.respuestasCentro]);

  const handleChange = (subpregunta, subrespuesta) => {
    var checkedProvisional = checked;
    checkedProvisional[subpregunta] = subrespuesta;
    setChecked(checkedProvisional);
    setRender(render + 1);
  };

  const saveandexit = (e) => {
    dispatch(directorActions.saveHistorico(1, 1, "saved"));
    sendFormulario(e);
    history.push("/auth/start");
  };

  const sendFormulario = (e) => {
    var user = JSON.parse(localStorage.getItem("xcuserv2"));
    localStorage.setItem("hasContestado" + user.email, "true");

    if (props.isPreguntaCompleted) {
      var centro_pregunta_id;
      props.isPreguntaCompleted.map((item) => {
        if (item.preguntas_id == respuestas[0].preguntas_id) {
          centro_pregunta_id = item.id;
        }
      });
      if (centro_pregunta_id) {
        dispatch(
          preguntasActions.saveTableRadio(
            checked,
            centro_pregunta_id,
            props.respuestas[0].preguntas_id
          )
        );
      } else {
        dispatch(
          preguntasActions.saveTableRadioAndPregunta(
            checked,
            props.centro,
            props.respuestas[0].preguntas_id
          )
        );
      }
    } else {
      dispatch(
        preguntasActions.saveTableRadioAndPregunta(
          checked,
          props.centro,
          props.respuestas[0].preguntas_id
        )
      );
    }

    dispatch(
      directorActions.saveHistoricoIndicador(
        1,
        3,
        "saved",
        props.respuestas[0].preguntas_id
      )
    );
    setChecked(undefined);
  };

  var theme = createTheme({
    overrides: {
      MuiInputBase: {
        root: {
          height: "40px",
          "&:hover": {
            color: props.color,
            borderColor: props.color,
          },
        },
        input: {
          color: props.color,
          borderColor: props.color,
          "&:hover": {
            color: props.color,
            borderColor: props.color,
          },
        },
      },
      MuiFormControlLabel: {
        label: {
          color: "#383b41",
          fontFamily: "Museo Sans",
          fontWeight: 700,
          fontSize: "17px",
        },
        root: {
          marginTop: "10px",
        },
      },
      MuiFormControl: {
        root: {
          marginTop: "25px",
        },
      },
    },
  });

  return (
    <div className="topeWidth">
      {loading ? (
        <div>Cargando...</div>
      ) : (
        <div>
          <MuiThemeProvider theme={theme}>
            <Grid container style={{ marginTop: "35px" }}>
              <Grid item xs={12} style={{ height: "55px" }}>
                <Grid container style={{ height: "55px" }}>
                  <Grid
                    item
                    xs={2}
                    style={{ borderRight: "solid 1px #a5adbb" }}
                  ></Grid>
                  {}
                  {props.subPreguntas &&
                    props.subPreguntas[0] &&
                    props.subPreguntas[0].id &&
                    props.subRespuestas[props.subPreguntas[0].id].map(
                      (item, i) => {
                        return (
                          <Grid
                            item
                            xs={2}
                            key={i}
                            style={{
                              maxWidth: "150px !important",
                              borderRight: "solid 1px #a5adbb",
                              textAlign: "center",
                            }}
                          >
                            <label
                              style={{ color: "#383b41", marginTop: "0px" }}
                            >
                              {item.respuesta}
                            </label>
                          </Grid>
                        );
                      }
                    )}
                </Grid>
              </Grid>
              {props.subPreguntas != undefined && checked ? (
                props.subPreguntas.map((item, i) => {
                  counter = counter + 1;
                  return (
                    <Grid
                      key={i}
                      item
                      xs={12}
                      style={{ height: "55px" }}
                    >
                      <Grid container style={{ height: "55px" }}>
                        <Grid
                          item
                          xs={2}
                          style={{
                            backgroundColor: counter % 2 ? "#f4f5f7" : "white",
                            borderRight: "solid 1px #a5adbb",
                            borderTop: "solid 1px #a5adbb",
                          }}
                        >
                          <label
                            style={{
                              color: "#383b41",
                              marginTop: "0px",
                              fontSize: "12px",
                              marginLeft: "26px",
                            }}
                          >
                            {item.titulo}
                          </label>
                        </Grid>
                        {props.subRespuestas[item.id].map((item2, i) => {
                          return (
                            <Grid
                              key={i}
                              item
                              xs={2}
                              style={{
                                maxWidth: "150px !important",
                                backgroundColor:
                                  counter % 2 ? "#f4f5f7" : "white",
                                borderRight: "solid 1px #a5adbb",
                                borderTop: "solid 1px #a5adbb",
                                textAlign: "center",
                              }}
                            >
                              <Radio
                                checked={checked[item.id] == item2.id}
                                onChange={(e) =>
                                  handleChange(item.id, item2.id)
                                }
                                value={item2.id}
                                name="radio-button"
                                key={item2.respuesta}
                                style={{ color: props.color }}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  );
                })
              ) : null}
            </Grid>
          </MuiThemeProvider>

          <Grid container justifyContent="center">
            <div className="botonesAccion gridBotones paddingBotones">
              <Button
                variant="outlined"
                onClick={props.previousPregunta}
                className="btn buttonContestarMasTarde"
                style={{ backgroundColor: "white", color: props.color }}
              >
                <Language langKey="questionary.back" />
              </Button>

              <Button
                onClick={sendFormulario}
                className="btn buttonContestarContinuar marginLeftButton"
                style={{ backgroundColor: props.color }}
              >
                <Language langKey="next" />
              </Button>
              <Button
                onClick={saveandexit}
                className="btn buttonContestarFooterDescargar butonBottomAdjust"
                style={{
                  backgroundColor: "white",
                  color: props.color,
                  border: "1px solid",
                }}
              >
                <Language langKey="questionary.exit" />
              </Button>
            </div>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default TablaRadioButton;
