import React from "react";
import NavBarSimple from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import { NavLink } from "react-router-dom";
import { Language } from "components/Language.js";

const RegisterOkOtros = () => (
  <div className="fullPage">
    <NavBarSimple />
    <div className="defaultFull">
      <Grid
        container
        spacing={3}
        justifyContent="center"
        className="defaultgrid"
      >
        <Grid item xs={12}>
          <div className="h1home">
            <Language langKey="signup.thanks" />
          </div>
          <div className="txtHome marginSubTitle">
            <Language parse langKey="signup.success_reseacher" />
          </div>
        </Grid>
        <Grid item xs={12}>
          <NavLink to="/user/login" className="btn btnHome">
            <Language langKey="signup.go_home" />
          </NavLink>
        </Grid>
      </Grid>
    </div>
  </div>
);

export default RegisterOkOtros;
