import React, { useEffect } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { useSelector, useDispatch } from "react-redux";
import { contenidoActions } from "actions/contenidoActions";
import estrella from "assets/css/estrella.png";
import Grid from "@material-ui/core/Grid";
import { Language } from "components/Language.js";

const Menu = (list) =>
  list.map((el, i) => {
  const { name } = el;

  return (
    <div key={i} className={`menu-item-scroll`}>
      <div className="menu-item-img">
        <img src={estrella} className="estrella" />
      </div>
      <div className="menu-item-scroll-name">{name}</div>
    </div>
  );
});

export const CentersAffilated = () => {
  const dispatch = useDispatch();

  const getCentrosHome = useSelector(
    (state) => state.contenidoReducer.getCentrosHome
  );

  useEffect(() => {
    if (!getCentrosHome?.length)
      dispatch(contenidoActions.getCentrosHome());
  }, []);

  const slidesColegios = [];

  if (getCentrosHome?.length > 0) {
    getCentrosHome.forEach((item) => {
      slidesColegios.push({name: item.nombre});
    });
  }

  const menuItems = Menu(slidesColegios.slice(0, slidesColegios.length));

  return (
    <div className="bgWhite">
      <Grid
        container
        spacing={3}
        className="bgGrey cornerHomeLeft homeContainer"
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="alignCenter"
        >
          <div className="h1home">
            <Language langKey="dashboard.already_particiting" />&nbsp;
            <span className="corpoColor">380</span>&nbsp;
            <Language langKey="centers" />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="marginRightHome marginLeftHome">
            <ScrollMenu
              data={menuItems}
              arrowLeft={<i className="arrow-prev" ></i>}
              arrowRight={<i className="arrow-next" ></i>}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}