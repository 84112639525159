import React, { useState, useEffect } from "react";
import NavBarAuth from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import ReactPlayer from "react-player";
import RoscoS from "assets/img/roscos/RoscoS.js";
import Box from "@material-ui/core/Box";
import moment from "moment";
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";
import { claveActions } from "actions/claveActions";
import { directorActions } from "actions/directorActions";
import { preguntasActions } from "actions/preguntasActions";
import * as _ from "lodash";
import Tour from "reactour";
import { useHistory } from "react-router-dom";
import ClavesContestadasInfo from "./singleComponents/ClavesContestadasInfo.js";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Modal } from "react-bootstrap";
import CloseButton from 'react-bootstrap/CloseButton'
import { Language } from "components/Language.js";

function StartUser(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = props;
  var user = JSON.parse(localStorage.getItem("xcuserv2"));
  const perfil = user ? +user.perfil : -1;
  var showPreguntaOpcional = useSelector(
    (state) => state.claveReducer.showOpcional
  );

  if (perfil === 2) {
    history.push("/auth/resultados");
  }

  const [step, setStep] = useState(1);
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [expired, setExpired] = useState(false);

  var datosCentro = useSelector((state) => state.directorReducer.datosCentro);
  const loading = useSelector((state) => state.loading.loading);
  var apiDONE = useSelector((state) => state.loading.APIactions);
  var clavesContestadas = useSelector(
    (state) => state.claveReducer.clavesContestadas
  );
  var respuestasNoObligatoriasClave = useSelector(
    (state) => state.claveReducer.respuestasNoObligatoriasClave
  );

  const [esPrimer, setEsPrimer] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [openInfo, setOpenInfo] = React.useState(false);
  const [hayAnteriores, setHayAnteriores] = React.useState(false);
  const forceUpdate = React.useState()[1].bind(null, {}); //

  const [enable, setEnable] = React.useState(false);
  var getNiveles = useSelector((state) => state.directorReducer.getNiveles);

  var [numClavesContestadas, setNumClavesContestadas] = React.useState(null);
  var [clavesCompleted, setClavesCompleted] = React.useState(null);
  const [modalAdvertenciaOpened, setModalAdvertenciaOpened] = React.useState(
    true
  );
  var [doTour, setDoTour] = useState("false");

  const anterioresData = useSelector(
    (state) => state.directorReducer.anterioresData
  );

  useEffect(() => {
    let newUser = user.newUser;
    let lastLogin = user.lastlogin;
    var a = moment(lastLogin);
    var b = moment();
    let dif = b.diff(a, "days");
    
    if (anterioresData) {      
      if (anterioresData[0] && anterioresData[0].total > 0) {
        setHayAnteriores(true);
        if (newUser && !localStorage.getItem("newUserv4" + user.email)) {
          setDoTour("true");
        } else {
          
          if (dif > 180 && !localStorage.getItem("showVideo" + user.email)) {
            setDoTour("true");
            setStep(1);
            setExpired(true);
            localStorage.setItem("showVideo" + user.email, "true");
          } else {
            if (numClavesContestadas === 0) setOpenInfo(true);
          }
        }
      } else {
        if (newUser && !localStorage.getItem("newUserv4" + user.email)) {
          setDoTour("true");
        } else {
          if (numClavesContestadas === 0) setOpenInfo(true);
        }
      }
      localStorage.setItem("newUserv4" + user.email, "true");
    }
  }, [anterioresData]);

  const nextStep = () => {
    if (!expired) {
      let nStep = step + 1;

      if (nStep === 2 && doTour === "true") {
        setStep(2);
      } else {
        setStep(3);
      }
    } else {
      setStep(2);
      setDoTour("false");
      var esta = localStorage.getItem("avisoXCsalida");

      if (!esta) {
        setOpenInfo(true);
        localStorage.setItem("avisoXCsalida", "1");
      } else {
        setOpenInfo(false);
      }
    }
  };

  var mNombreCentro = "";
  if (datosCentro && datosCentro[0]) {
    mNombreCentro = datosCentro[0].nombre;
  }
  var saveResultadosData = useSelector(
    (state) => state.directorReducer.saveResultadosData
  );
  var checkResultdos = useSelector(
    (state) => state.directorReducer.checkResultdos
  );

  localStorage.setItem("xccompleto", "0");
  if (checkResultdos) {
    if (checkResultdos.periodo) {
      localStorage.setItem("xccompleto", "1");
      history.push("/auth/resultado");
    }
    if (checkResultdos[0] && checkResultdos[0].periodo) {
      localStorage.setItem("xccompleto", "1");
      history.push("/auth/resultado");
    }
  }
  useEffect(() => {
    forceUpdate();
  }, [step]);

  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
    if (saveResultadosData) {
      localStorage.setItem("xccompleto", "1");
      history.push("/auth/resultado");
    }
  }, [saveResultadosData]);

  const continueTest = () => {
    localStorage.setItem("esPrimer", false);
    setOpenInfo(false);
    setEsPrimer(false);    
  };

  const handleClose = () => {
    localStorage.setItem("esPrimer", false);
    setOpen(false);
    setOpenInfo(false);
    setEsPrimer(false);
    setStep(1);    
  };

  const handleClose2 = () => {
    // setEsPrimer(false);
    setOpen2(false);
    localStorage.setItem("esPrimer", false);
  };

  useEffect(() => {
    dispatch(preguntasActions.showOpcional());
    dispatch(directorActions.checkResultados());
    dispatch(directorActions.getNiveles());
    dispatch(directorActions.getCentro());
    dispatch(claveActions.getClaves());
    dispatch(claveActions.getClavesContestadas());
    dispatch(claveActions.getNoObligatoriasClave());
  }, [apiDONE]);

  const closeTour = () => {
    setDoTour("false");
    localStorage.setItem("tourX" + user.email, "false");
    setIsTourOpen(false);

    if (abre === 1) {
      setOpen(true);
      setEnable(false);
      setAbre(0);
    } else {
      if (hayAnteriores) {
        var esta = localStorage.getItem("avisoXCsalida");

        if (!esta) {
          setOpenInfo(true);
          localStorage.setItem("avisoXCsalida", "1");
        } else {
          setOpenInfo(false);
        }
      }
    }
  };

  const [abre, setAbre] = useState(0);

  useEffect(() => {
    if (getNiveles && getNiveles.length > 0) {
      var nc = 0
      getNiveles.map((item) => {
        if (item.id !== null) nc += 1;
      });
      
      if (nc === 0) {
        setAbre(1);
      } else {
        setAbre(0);
        setEnable(true);
        if (hayAnteriores) {
          var esta = localStorage.getItem("avisoXCsalida");
          if (!esta) {
            setOpenInfo(true);
            localStorage.setItem("avisoXCsalida", "1");
          } else {
            setOpenInfo(false);
          }

          var esPrimer = localStorage.getItem("esPrimer");
          if(esPrimer == 'true') setEsPrimer(true);
          else setEsPrimer(false);
        }
      }
    }
  }, [getNiveles]);

  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
    if (
      clavesContestadas &&
      respuestasNoObligatoriasClave &&
      showPreguntaOpcional
    ) {
      var tempClavesContestadasNum = 0;
      var tempclavesCompleted = [];
      tempclavesCompleted.push({});
      clavesContestadas.map((item) => {
        tempclavesCompleted.push(item);
        if (item.clavecompleted) {
          tempClavesContestadasNum = tempClavesContestadasNum + 1;
        }
      });

      setClavesCompleted(tempclavesCompleted);
      setNumClavesContestadas(tempClavesContestadasNum);
      dispatch(directorActions.getAnteriores());
    }
  }, [clavesContestadas, respuestasNoObligatoriasClave, showPreguntaOpcional]);

  useEffect(() => {
    if (checkResultdos) {
      if (checkResultdos.periodo) {
        history.push("/auth/resultado");
      }
      if (checkResultdos[0] && checkResultdos[0].periodo) {
        history.push("/auth/resultado");
      }
    }
  }, [checkResultdos]);

  useEffect(() => {        
    var esPrimer = localStorage.getItem("esPrimer");

    if(esPrimer == 'true') setEsPrimer(true)    
    else setEsPrimer(false)

  }, [esPrimer]);
  
  const steps = [
    {
      selector: ".rosco",
      content: ({ goTo }) => (
        <div>
          <div className="titleOnBoard">
            <Language langKey="keys_single" />
          </div>
          <div className="subtitleOnBoard">
            <p><Language langKey="tour.keys_param_one" /></p>
            <p><Language langKey="tour.keys_param_two" /></p>
          </div>
          <div>
            <button className="btn btnHome btnBoard" onClick={() => goTo(1)}>
              <Language langKey="next" />
            </button>
          </div>
        </div>
      ),
    },
    {
      selector: ".menuonboard1",
      content: ({ goTo }) => (
        <div>
          <div className="titleOnBoard">
            <Language langKey="menu" />
          </div>
          <div className="subtitleOnBoard">
            <p><Language langKey="take_autoquiz" /></p>
            <p><Language langKey="tour.quiz_param" /></p>
          </div>
          <div>
            <button className="btn btnHome btnBoard" onClick={() => goTo(2)}>
              <Language langKey="next" />
            </button>
          </div>
        </div>
      ),
    },
    {
      selector: ".menuonboard2",
      content: () => (
        <div>
          <div className="titleOnBoard"><Language langKey="menu" /></div>
          <div className="subtitleOnBoard">
            <p><Language langKey="tour.centrer_param_one" /></p>
            <p><Language langKey="tour.centrer_param_two" /></p>
          </div>
        </div>
      ),
    },
  ];

  const enviaMailDirector = () => {
    dispatch(directorActions.avisaDirector());
  };

  const enviarResultados = () => {
    dispatch(directorActions.sendResultados());
    dispatch(directorActions.saveResultados());
  };
  const goToDatos = () => {
    history.push("/auth/centro");
  };

  const goToDatosAnts = () => {
    history.push("/auth/resultado");
  };
  const goToPreguntas = (num) => {
    if (enable) {
      history.push({
        pathname: "/auth/preguntas",
        clave: num - 1,
      });
    } else {
      setOpen(true);
    }
  };

  return (
    <div className="fullPage">
      <NavBarAuth nombreCentro={mNombreCentro} />
      <div>
        {loading ? (
          <LinearProgress />
        ) : (
          <Grid
            container
            spacing={3}
            justifyContent="center"
            className="bgWhite homeContainer homeContainerXcelence"
            alignItems="center"
          >            
            <Modal show={esPrimer} onHide={continueTest}>
              <Modal.Header closeButton className="headerModal">
                <Modal.Title>                  
                  {hayAnteriores
                    ? <Language langKey="welcome_again"/>
                    : <Language langKey="welcome"/>
                  }
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Box style={{ textAlign: "justify" }}>
                  {hayAnteriores ? (
                    <div>
                      <p><Language langKey="welcome_modal.param_one"/></p>
                      <p><Language langKey="welcome_modal.param_two"/></p>
                      <p><Language langKey="welcome_modal.param_three"/></p>
                      <p  className="text-bold">
                        <Language langKey="welcome_modal.param_four"/>
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p><Language langKey="welcome_modal.intro_param_one"/></p>
                      <p><Language langKey="welcome_modal.intro_param_two"/></p>
                      <p><Language langKey="welcome_modal.intro_param_three"/></p>
                      <p><Language langKey="welcome_modal.intro_param_four"/></p>
                    </div>
                  )}
                  {hayAnteriores ? (
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Button
                          disabled={hayAnteriores ? false : true}
                          className={hayAnteriores ? "btnHomeSup2" : "deactiveButton2"}
                          onClick={goToDatosAnts}
                        >
                          <Language langKey="welcome_modal.action_one" />*
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          className="btnHomeSup"
                          onClick={continueTest}
                        >
                          <Language langKey="welcome_modal.action_two" />
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <span className="greytext">
                          *<Language langKey="welcome_modal.caption" />
                        </span>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{textAlign: "center"}}
                      >
                        <Button
                          className="tnHomeSup"
                          onClick={continueTest}
                        >
                          <Language langKey="welcome_modal.action_two" />
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Modal.Body>
            </Modal>

            <Modal show={open} onHide={handleClose}>
              <Modal.Header closeButton className="headerModal">
                <Modal.Title>
                  <Language langKey="m_missing_datas.title" />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Box style={{ textAlign: "justify" }}>
                  <p><Language langKey="m_missing_datas.param_one"/></p>
                  <p><Language langKey="m_missing_datas.param_two"/></p>
                  <p><Language langKey="m_missing_datas.param_three"/></p>
                </Box>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className={`registerWidth btnHome btnCenter`}
                  onClick={goToDatos}
                >
                  <Language langKey="center_info"/>
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={open2} onHide={handleClose2}>
              <Modal.Header closeButton className="headerModal">
                <Modal.Title><Language langKey="m_send_form.title"/></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Box style={{ textAlign: "justify" }}>
                  <p>
                    <Language
                      langKey={ perfil !== 3
                        ? 'm_send_form.param_one'
                        : 'm_send_form.guess_param_one'
                      }
                    />
                  </p>
                  <p>
                    <Language
                      langKey={ perfil !== 3
                        ? 'm_send_form.param_two'
                        : 'm_send_form.guess_param_two'
                      }
                    />
                  </p>
                </Box>
              </Modal.Body>
              <Modal.Footer>
                {perfil !== 3 ? (
                  <Button
                    className={`registerWidth btnHome btnCenter`}
                    onClick={(e) => {
                      setOpen2(false);
                      enviarResultados();
                    }}
                  >
                    <Language langKey="m_send_form.action"/>
                  </Button>
                ) : (
                  <Button
                    className={`registerWidth btnHome btnCenter`}
                    onClick={(e) => {
                      setOpen2(false);
                      enviaMailDirector();
                    }}
                  >
                    <Language langKey="home"/>
                  </Button>
                )}
              </Modal.Footer>
            </Modal>

            <Grid item xs={12} className="imgCenter">
              {/* PASO 1 ONBOARDING */}
              {((step !== 1 && doTour === "true") || doTour == "false") && (
                <div>
                  <div className="h1home padBottomHome text-upper">
                    <Language langKey="question_page_title" />
                  </div>
                  <div className="rosco" id="rosco">
                    <div>
                      {clavesCompleted && (
                        <RoscoS
                          navigate={goToPreguntas}
                          step1={clavesCompleted[1].clavecompleted}
                          step2={clavesCompleted[2].clavecompleted}
                          step3={clavesCompleted[3].clavecompleted}
                          step4={clavesCompleted[4].clavecompleted}
                          step5={clavesCompleted[5].clavecompleted}
                          step6={clavesCompleted[6].clavecompleted}
                          step7={clavesCompleted[7].clavecompleted}
                          step8={clavesCompleted[8].clavecompleted}
                          step9={clavesCompleted[9].clavecompleted}
                          step10={clavesCompleted[10].clavecompleted}
                        />
                      )}
                      {clavesCompleted && <span></span>}
                    </div>
                  </div>

                  <div className="mIndicadores" id="mIndicadores">
                    <div className="showIndicadores withMargin">
                      <div className="totalClaves">
                        {numClavesContestadas ? (
                          <div>
                            {numClavesContestadas}&nbsp;
                            <Language langKey="of" /> 10&nbsp;
                            <Language langKey="keys_completed" />
                          </div>
                        ) : null}
                        <br />
                        {!modalAdvertenciaOpened &&
                          clavesCompleted &&
                          clavesCompleted[1].clavecompleted &&
                          clavesCompleted[2].clavecompleted &&
                          clavesCompleted[3].clavecompleted &&
                          clavesCompleted[4].clavecompleted &&
                          clavesCompleted[5].clavecompleted &&
                          clavesCompleted[6].clavecompleted &&
                          clavesCompleted[7].clavecompleted &&
                          clavesCompleted[8].clavecompleted &&
                          clavesCompleted[9].clavecompleted &&
                          clavesCompleted[10].clavecompleted && (
                            <>
                              {[1,3].includes(perfil) ? (
                                <Button
                                  onClick={() => {
                                    enviarResultados();
                                    setModalAdvertenciaOpened(false);
                                  }}
                                  className="btn buttonContestarEnviar"
                                  style={{ backgroundColor: "#2b74c4" }}
                                >
                                  <Language langKey="m_send_form.action" />
                                </Button>
                              ) : (
                                <Button
                                  onClick={(e) => {
                                    setOpen2(true);
                                    setModalAdvertenciaOpened(false);
                                  }}
                                  className="btn buttonContestarEnviar"
                                  style={{ backgroundColor: "#2b74c4" }}
                                >
                                  <Language langKey="m_send_form.send_to_director" />
                                </Button>
                              )}
                            </>
                          )}
                      </div>
                    </div>
                    {clavesContestadas && (
                      <ClavesContestadasInfo
                        navigate={goToPreguntas}
                        clavesContestadas={clavesContestadas}
                      />
                    )}
                  </div>
                  {step === 3 && (
                    <Tour
                      steps={steps}
                      isOpen={isTourOpen == true}
                      showNavigation={false}
                      showNavigationNumber={false}
                      onRequestClose={() => closeTour()}
                      lastStepNextButton={
                        <button className="btn btnHome btnBoard">
                          <Language langKey="start"/>
                        </button>
                      }
                    />
                  )}
                  {step === 2 && doTour == "true" ? (
                    <div id="myModal" className="modalOnBoard">
                      <div className="centerModal">
                        <div className="subh1home padBottomHome">
                          <Language langKey="welcome" />
                        </div>
                        <div
                          className="txtHome padBottomHomeMore"
                          style={{ textAlign: "center" }}
                        >
                          <p><Language langKey="welcome_modal.intro_param_one"/></p>
                          <p><Language langKey="welcome_modal.intro_param_two"/></p>
                          <p><Language langKey="welcome_modal.intro_param_three"/></p>
                          <p><Language langKey="welcome_modal.intro_param_four"/></p>
                        </div>
                        <div className="btnCenterResponsive">
                          <Button
                            onClick={(e) => nextStep()}
                            variant="contained"
                            className="btn btnHome btnFullButton"
                          >
                            <Language langKey="start"/>
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {modalAdvertenciaOpened &&
                  clavesCompleted &&
                  clavesCompleted[1].clavecompleted &&
                  clavesCompleted[2].clavecompleted &&
                  clavesCompleted[3].clavecompleted &&
                  clavesCompleted[4].clavecompleted &&
                  clavesCompleted[5].clavecompleted &&
                  clavesCompleted[6].clavecompleted &&
                  clavesCompleted[7].clavecompleted &&
                  clavesCompleted[8].clavecompleted &&
                  clavesCompleted[9].clavecompleted &&
                  clavesCompleted[10].clavecompleted ? (
                    <div id="myModal" className="modalOnBoardCompletado">
                      <div className="centerModal">
                        <div className="subh1home padBottomHome">
                          <Language langKey="congratulations" />
                        </div>
                        {perfil === 1 ? (
                          <div
                            className="txtHome padBottomHomeMore"
                            style={{ textAlign: "justify" }}
                          >
                            <p><Language langKey="keys.completed_param_one"/></p>
                            <p><Language langKey="keys.completed_param_two"/></p>
                          </div>
                        ) : (
                          <div
                            className="txtHome padBottomHomeMore"
                            style={{ textAlign: "justify" }}
                          >
                            <p><Language langKey="keys.completed_message_one"/></p>
                            <p><Language langKey="keys.completed_message_two"/></p>
                            <p><Language langKey="keys.completed_message_three"/></p>
                          </div>
                        )}
                        <div className="txtAdvertencia modalAdvertenciaSection">
                          <div style={{ paddingTop: "13px" }}>
                            <Language langKey="keys.completed_alert"/>
                          </div>
                        </div>
                        <Grid container justifyContent="center">
                          <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className="gridBotonesAdvertencia"
                          >
                            <div className="botonesAccionModalAdvertencia">
                              <Grid container>
                                <Grid item xs={5} md={5} lg={5}>
                                  <Button
                                    variant="outlined"
                                    onClick={(e) =>
                                      setModalAdvertenciaOpened(false)
                                    }
                                    className="btn buttonContestarRevisar"
                                    style={{
                                      backgroundColor: "white",
                                      color: "#2b74c4",
                                    }}
                                  >
                                    <Language langKey="m_send_form.send_later"/>
                                  </Button>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={5} md={5} lg={5}>
                                {[1,3].includes(perfil) ? (
                                    <Button
                                      onClick={(e) => {
                                        enviarResultados();
                                        setModalAdvertenciaOpened(false);
                                      }}
                                      className="btn buttonContestarEnviar"
                                      style={{ backgroundColor: "#2b74c4" }}
                                    >
                                      <Language langKey="m_send_form.action"/>
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={(e) => {
                                        setOpen2(true);

                                        setModalAdvertenciaOpened(false);
                                      }}
                                      className="btn buttonContestarEnviar"
                                      style={{ backgroundColor: "#2b74c4" }}
                                    >
                                      <Language langKey="m_send_form.send_to_director"/>
                                    </Button>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}

              {/* PASO 3 ONBOARDING */}
            </Grid>
          </Grid>
        )}
        {step === 1 && doTour == "true" && (
          <div className="modalvideo">
            <div className="modalViewVideo">
            <div style={{ position: "relative"}}>
              <ReactPlayer url="https://www.youtube.com/watch?v=Tux4vFNWups"/>
              <CloseButton variant="white" onClick={() => nextStep()} 
              style={{
                position: "absolute", color:"red", top: "1rem",  right: "1rem"
               
              }}
            />
            </div>
            </div>
            <div className="btnCenterResponsive underVideo">
              <Button
                onClick={() => nextStep()}
                variant="contained"
                className="btn btnHome btnFullButton"
              >
                <Language langKey="close_view_letter"/>
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default StartUser;