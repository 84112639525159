import { takeEvery, call, put } from "redux-saga/effects";
import { postDownloadFile, xcService } from '../services';
import { POST_DOWNLOAD_REPORT, FETCH_ANSWERS_REPORT, FETCH_RESULTS_KEYS, FETCH_CENTER_DASHBOARD_FILTER, DOWNLOAD_SIMPLE_PDF_REPORT } from "constants/reports.constant";
import { saveAnswersReport, saveCenterDashboardFilters, saveResultsKeys } from "actions/reports.action";
import { buildQueryParams } from "utils/uri_parser.util";

export function* watcherReports() {
  yield takeEvery(FETCH_ANSWERS_REPORT, fetchAnswersReport);
  yield takeEvery(POST_DOWNLOAD_REPORT, downloadReport);
  yield takeEvery(FETCH_RESULTS_KEYS, fetchResultsKeys);
  yield takeEvery(FETCH_CENTER_DASHBOARD_FILTER, fetchCenterDashboardFilter)
  yield takeEvery(DOWNLOAD_SIMPLE_PDF_REPORT, dowloadSimplePdfReport);
}

function* fetchResultsKeys({ payload }) {
  const { resolve, reject, filter } = payload;

  try {
    const path = '/v3/results-keys-report';
    const uri = buildQueryParams(path, filter);

    const res = yield call(xcService.get, uri);

    if (res) yield put(saveResultsKeys(res.data));

    yield call(resolve);
  } catch (error) {
    yield call(reject, error);
  }
}

function* fetchCenterDashboardFilter({ payload }) {
  const { resolve, reject } = payload;

  try {
    let endpoint = '/v2/centers/dashboard-filters';
    const res = yield call(xcService.get, endpoint);    
    if (res) yield put(saveCenterDashboardFilters(res.data));

    yield call(resolve);
  } catch (error) {
    yield call(reject, error);
  }
}

function* fetchAnswersReport({payload: {resolve, reject, period}}) {
  try {
    let apiEndpoint = `/v15/reports/results?period=${period}`;

    const resp = yield call(xcService.get, apiEndpoint);

    if(resp) yield put(saveAnswersReport(resp.data));

    if(resolve) yield call(resolve);
  } catch (error) {
    console.log(error);

    if(reject) yield call(reject);
  }
}

function* downloadReport({ payload }) {
  const {resolve, reject, body} = payload;

  try {
    let apiEndpoint = "/v15/reports/download";

    const resp = yield call(xcService.post, apiEndpoint, body);

    if(resp.status === 200) yield call(resolve);
    else yield call(reject);
  } catch (e) {
    yield call(reject);
  }
}

function* dowloadSimplePdfReport({ payload }) {
  let { resolve, reject, ...other } = payload;

  try {
    const uri = '/v3/keys/results-report/download';

    yield call(postDownloadFile, uri, other);

    yield call(resolve);
  } catch (error) {
    yield call(reject, error);
  }
}
