import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import Grid from "@material-ui/core/Grid";
import { simpleParser } from "utils/parse-markdown";
import { Link } from "react-router-dom";

class Carousel_Personal extends Component {
  render() {
    return (
      <Carousel
        interval={5000}
        controls={this.props.data.length === 1 ? false : true}
      >
        {this.props.data.map((item, index) => {
          let isExternal = false;

          if (item.link?.match(/^http(s?):\/\//)) {
            const url = new URL(item.link);
            if (url.origin !== window.location.origin) {
              isExternal = true;
            } else {
              item.replace(url.origin, '');
            }
          }

          return (
            <Carousel.Item key={index} >
              <Grid
                container
                spacing={3}
                className="bgWhite cornerHomeRight homeContainer"
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  className="imgCenter imgCaruselHomeResponsive"
                >
                  <img className="imgCarrusel" src={item.imagen} sizes="100vw" />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  className="itemHomeResp"
                >
                  <div className="marginLeftHome">
                    <div className="h1home padBottomHome">{item.titulo}</div>
                    <div
                      className="subh1home padBottomHome"
                      dangerouslySetInnerHTML={{ __html: simpleParser(item.subtitulo ?? '') }}
                    >
                    </div>

                    <div
                      className="txtHome padBottomHomeMore "
                      dangerouslySetInnerHTML={{ __html: simpleParser(item.cuerpo ?? '') }}
                    >
                    </div>
                    <div className="btnCenterResponsive">
                      {isExternal
                        ? <a className="btn btnHome" target="_blank" href={item.link}>
                          {item.boton}
                        </a>
                        : <Link className="btn btnHome" to={{ pathname: item.link }}>
                          {item.boton}
                        </Link>
                      }
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  className="imgCenter imgCaruselHomeNoResponsive"
                >
                  <img className="imgCarrusel" src={item.imagen} sizes="100vw" />
                </Grid>
              </Grid>
            </Carousel.Item>
          )
        }
        )}
      </Carousel>
    );
  }
}

export default Carousel_Personal;
