import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import * as _ from "lodash";
import { history } from "helpers/history";
import { v1 as uuidv1 } from "uuid";

function ClavesContestadas(props) {
  const [listaClavesContestadas, setListaClavesContestadas] = React.useState(
    new Array()
  );
  var apiDONE = useSelector((state) => state.loading.APIactions);

  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
    var tempClavesContestadas = new Array();
    var añadir;
    for (var x = 1; x <= 10; x++) {
      añadir = true;
      props.clavesContestadas.map((item) => {
        if (item.num == x) {
          añadir = false;
          tempClavesContestadas[x] = item;
        }
      });
      if (añadir == true) {
        tempClavesContestadas[x] = { num: x };
      }
    }

    setListaClavesContestadas(tempClavesContestadas);
  }, [apiDONE, props.clavesContestadas]);

  const goToPreguntas = (num) => {
    history.push({
      pathname: "/auth/preguntas",
      clave: num - 1,
    });
  };

  return (
    <div className="showIndicadores" id="showIndicadores" style={{display: "none"}}>
      <ul className="claveIndicadores">
        {listaClavesContestadas &&
          listaClavesContestadas.map((item, index) => {

            if (index > 0) {
              return (
                <li  key={uuidv1()}
                  onClick={(e) => props.navigate(item.num)}
                  className={item.clavecompleted === 1 ? "active" : ""}
                  style={{ cursor: "pointer" }}
                ></li>
              );
            }
          })}
      </ul>
    </div>
  );
}

export default ClavesContestadas;
