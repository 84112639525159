import React from "react";
import NavBarSimple from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { Language } from "components/Language.js";
import { useSelector } from "react-redux";

function Register() {
  const history = useHistory();
  const locale = useSelector((state) => state.setting.locale);

  const director = () => {
    history.push("/user/register/director");
  };

  const investigador = () => {
    history.push("/user/register/investigador");
  };

  const otros = () => {
    history.push("/user/register/otros");
  };

  return (
    <div className="fullPage">
      <NavBarSimple />
      <div className="defaultFull">
        <Grid container spacing={3} justifyContent="center" className="defaultgrid">
          <Grid item xs={12}>
            <div className="h1home">
              <Language langKey="signup.title" />
            </div>
            <div
              className="txtHome marginSubText text-center"
            >
              <Language langKey="signup.description" parse />
            </div>

            <Grid container spacing={3} style={{ paddingTop: 35 }}>
              <Grid
                item
                xs={12}
                md={4}
                style={{ textAlign: "center" }}
              >
                <div className="containerImageRegister">
                  <figure><img
                    onClick={(e) => director()}
                    className="botonRegistroNuevo"
                    src={require(`assets/img/${locale}/register/director_profile.png`)}
                  /></figure>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{ textAlign: "center" }}
              >
                <div className="containerImageRegister">
                  <figure><img
                    onClick={(e) => otros()}
                    className="botonRegistroNuevo"
                    src={require(`assets/img/${locale}/register/team_profile.png`)}
                  /></figure>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{ textAlign: "center" }}
              >
                <div className="containerImageRegister">
                  <figure>
                    <img
                      onClick={(e) => investigador()}
                      className="botonRegistroNuevo"
                      src={require(`assets/img/${locale}/register/researcher.png`)}
                    />
                  </figure>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <img
                  style={{ width: "100%", marginTop: 35 }}
                  src={require(`assets/img/${locale}/register/process.png`)}
                />
              </Grid>
            </Grid>

            <div className="txtHome marginSubText text-center">
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default Register;
