import { makeStyles, Typography } from "@material-ui/core";
import { number, string } from "prop-types";
import React from "react";

const useStyles = makeStyles({
  rankingItem: ({percentage, color}) => ({
    width: '100%',
    padding: 3,
    borderRadius: 5,
    border: '1px solid #0000' ,
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
      position: 'absolute',
      content: "''",
      inset: `0 ${100 - percentage}% 0 0`,
      backgroundColor: color,
      zIndex: 0,
    }
  })
});

export const ProgressBar = (props) => {
  const {
    className = '',
    percentage = 50,
    color = '#b4b8d0'
  } = props;

  const clasess = useStyles({percentage, color});

  return (
    <div className={`${clasess.rankingItem} ${className}`}>
      <Typography variant="subtitle1" align="right">
        {percentage.toLocaleString('es-ES')}%
      </Typography>
    </div>
  )
}

ProgressBar.propTypes = {
  percentage: number,
  background: string,
  className: string,
};
