import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";
import { history } from "helpers/history";

export default function* watcherDirector() {
  yield takeEvery(actionTypes.GETCOLABORADORES, getColaboradores);
  yield takeEvery(actionTypes.ADDCOLABORADOR, addColaborador);
  yield takeEvery(actionTypes.DELCOLABORADOR, delColaborador);
  yield takeEvery(actionTypes.SENDCOLABORADOR, sendColaborador);
  yield takeEvery(actionTypes.ACTIVATE_COLABORADOR, activateColaborador);

  yield takeEvery(actionTypes.GETHISTORICO, getHistorico);
  yield takeEvery(actionTypes.GETCENTRO, getCentro);
  yield takeEvery(actionTypes.UPDATECENTRO, updateCentro);
  yield takeEvery(actionTypes.GET_NIVELES, getNiveles);
  yield takeEvery(actionTypes.UPDATENIVELES, updateNiveles);
  yield takeEvery(actionTypes.UPDATE_C_CURSOS, updateCursos);
  yield takeEvery(actionTypes.SAVEHISTORICO, saveHistorico);

  yield takeEvery(actionTypes.SEND_RESULTADOS, sendResultados);
  yield takeEvery(actionTypes.CHECK_RESULTADOS, checkResultados);

  yield takeEvery(actionTypes.CHECK_RESULTADOS, checkResultados);
  yield takeEvery(actionTypes.SAVE_RESULTADOS, saveResultados);
  yield takeEvery(actionTypes.BORRAR_CENTRO, borrarCentro);
  yield takeEvery(actionTypes.AVISAR_DIRECTOR, avisaDirector);

  
  yield takeEvery(actionTypes.GET_ANTERIORES, getAnteriores);

  yield takeEvery(actionTypes.GET_NIVELESANT, getNivelesAnt);
}

function* getNivelesAnt() {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v8/director/centro/niveles/anterior";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.getToken(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.GET_NIVELESANT_DATA, payload: res.data });
    } else {
      yield put({
        type: actionTypes.GET_NIVELESANT_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.VGET_NIVELESANT_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* getAnteriores() {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v8/director/centro/anteriores";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.getToken(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.GET_ANTERIORES_DATA, payload: res.data });
    } else {
      yield put({
        type: actionTypes.GET_ANTERIORES_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GET_ANTERIORES_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* avisaDirector({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v8/director/aviso";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.getToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );

    if (res.status === 200) {
      yield put({
        type: actionTypes.AVISAR_DIRECTOR_DATA,
        payload: res.data,
      });
    } else {
      yield put({
        type: actionTypes.AVISAR_DIRECTOR_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.AVISAR_DIRECTOR_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
function* borrarCentro({ payload: {resolve, reject} }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v8/director/centro/delete";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.deleteToken(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );

    if (res.status === 200) {
      yield put({ type: actionTypes.START_REQUEST });
      localStorage.setItem("xcauth", "false");
      localStorage.setItem("xccompleto", "0");
      localStorage.removeItem("txcoken");
      localStorage.removeItem("xcrefreshToken");
      localStorage.removeItem("xcuserv2");
    
      resolve(true);
      yield put({ type: actionTypes.END_REQUEST });
    } else {
      yield put({
        type: actionTypes.BORRAR_CENTRO_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.BORRAR_CENTRO_ERROR, payload: e });
    reject(false);
  }
  yield put({ type: actionTypes.END_REQUEST });
}

//SAVE RESULTS REQUEST
function* saveResultados({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v7/results/current-period";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.postToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    ); 

    if (res.status === 200) {
      //console.log(res.data);
      yield put({
        type: actionTypes.SAVE_RESULTADOS_DATA,
        payload: res.data,
      });
    } else {
      yield put({
        type: actionTypes.SAVE_RESULTADOS_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.SAVE_RESULTADOS_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* checkResultados({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v8/director/send/actual";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.getToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );

    if (res.status === 200) {
      yield put({
        type: actionTypes.CHECK_RESULTADOS_SUCCESS,
        payload: res.data,
      });
    } else {
      yield put({
        type: actionTypes.CHECK_RESULTADOS_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.CHECK_RESULTADOS_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* getPreguntasDownload({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v3/preguntas/num/" + payload.clave;
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.getToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );

    if (res.status === 200) {
      yield put({
        type: actionTypes.GET_PREGUNTAS_DOWN_DATA,
        payload: res.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_PREGUNTAS_DOWN_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.SEND_RESULTADOS_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
function* sendResultados({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v8/director/send";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.getToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );

    if (res.status === 200) {
      yield put({
        type: actionTypes.SEND_RESULTADOS_SUCCESS,
        payload: res.data,
      });
    } else if(res.status === 400 && window) {
      window.location.reload(); 
    } else {
      yield put({
        type: actionTypes.SEND_RESULTADOS_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.SEND_RESULTADOS_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* saveHistorico({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v8/director/historico";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.postToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );

    if (res.status === 200) {
      yield put({ type: actionTypes.SAVEHISTORICO_SUCCESS, payload: res.data });
    } else {
      yield put({
        type: actionTypes.SAVEHISTORICO_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.SAVEHISTORICO_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* updateCursos({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v8/director/centro/cursos";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.putToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );

    if (res.status === 200) {
      yield put({ type: actionTypes.UPDATE_C_CURSOS_DATA, payload: res.data });
    } else {
      yield put({
        type: actionTypes.UPDATE_C_CURSOS_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.UPDATE_C_CURSOS_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* updateNiveles({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v8/director/centro/niveles";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.putToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );

    if (res.status === 200) {
      yield put({ type: actionTypes.UPDATENIVELES_DATA, payload: res.data });
    } else {
      yield put({
        type: actionTypes.UPDATENIVELES_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.UPDATENIVELES_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* getNiveles() {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v8/director/centro/niveles";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.getToken(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.GET_NIVELES_DATA, payload: res.data });
    } else {
      yield put({
        type: actionTypes.GET_NIVELES_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GET_NIVELES_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
function* updateCentro({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v8/director/centro";
    const res = yield call(xcService.putToken, apiEndpoint, payload);

    if (res.status === 200) {
      yield put({ type: actionTypes.UPDATECENTRO_DATA, payload: res.data });
    } else {
      yield put({
        type: actionTypes.UPDATECENTRO_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.UPDATECENTRO_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
function* getCentro() {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v8/director/centro";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.getToken(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.GETCENTRO_DATA, payload: res.data });
    } else {
      yield put({
        type: actionTypes.GETCENTRO_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GETCENTRO_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* getHistorico() {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v8/director/historico";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.getToken(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.GETHISTORICO_DATA, payload: res.data });
    } else {
      yield put({
        type: actionTypes.GETHISTORICO_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GETHISTORICO_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
function* getColaboradores() {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v8/director/colaboradores";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.getToken(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
     
      yield put({ type: actionTypes.GETCOLABORADORES_DATA, payload: res.data });
    } else {
      // console.log("ERROR");
      yield put({
        type: actionTypes.GETCOLABORADORES_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GETCOLABORADORES_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* addColaborador({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v8/director/colaboradores";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.postToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );

    if (res.status === 200) {
      yield put({ type: actionTypes.ADDCOLABORADOR_DATA, payload: res.data });
    } else {
      yield put({
        type: actionTypes.ADDCOLABORADOR_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    // console.log("ERROR");
    yield put({ type: actionTypes.ADDCOLABORADOR_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
function* delColaborador({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v8/director/colaboradores/activo";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.patchToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );

    if (res.status === 200) {
      yield put({ type: actionTypes.DELCOLABORADOR_DATA, payload: res.data });
    } else {
      yield put({
        type: actionTypes.DELCOLABORADOR_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.DELCOLABORADOR_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
function* sendColaborador({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v8/director/colaboradores/send";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.postToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );

    if (res.status === 200) {      
      alert(res.data);
      // yield put({ type: actionTypes.DELCOLABORADOR_DATA, payload: res.data });
    } else {
      console.log(res.data);
      // yield put({
      //   type: actionTypes.DELCOLABORADOR_ERROR,
      //   payload: res.data,
      // });
    }
  } catch (e) {
    yield put({ type: actionTypes.DELCOLABORADOR_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* activateColaborador({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v8/director/colaboradores/activate";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.patchToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );

    if (res.status === 200) {
      yield put({ type: actionTypes.DELCOLABORADOR_DATA, payload: res.data });
    } else {
      yield put({
        type: actionTypes.DELCOLABORADOR_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.DELCOLABORADOR_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}