import React, {useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import * as _ from "lodash";
import { preguntasActions } from "actions/preguntasActions";
import { directorActions } from "actions/directorActions";
import { useHistory } from "react-router-dom";
import { createTheme } from '@material-ui/core/styles'
import { Language } from "components/Language";

function CampoAbierto(props) {
  const history = useHistory();
  const respuestas = props.respuestas;
  const [checked, setChecked] = React.useState(undefined);
  const [render, setRender] = React.useState(0);
  const [comentario, setComentario] = React.useState("");
  const loading = useSelector((state) => state.loading.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.respuestas != undefined) {
      var checkedState = {};
      props.respuestas.map((item) => {
        checkedState[item.id] = false;
      });
    }

    setChecked(checkedState);
    props.respuestasCentro.map((item) => {
      checkedState[item.respuestas_id] = true;
      if (item.comentario != null) {
        setComentario(item.comentario);
      }
    });
    setChecked(checkedState);
    setRender(render + 1);
  }, [props.respuestas, props.respuestasCentro]);

  const handleChange = (e) => {
    if (props.isnivel) {
      var checkedState = checked;
      checked[e.target.value] = !checkedState[e.target.value];
      setChecked(checkedState);

      setRender(render + 1);
    } else {
      var checkedState = checked;
      var oldsChecked = [];
      respuestas.map((item) => {
        oldsChecked[item.id] = checked[item.id];
      });
      var itemValue = null;
      var itemZero = null;
      var only = 0;
      respuestas.map((item) => {

          if (item.hasOwnProperty("selectedOnly") && item.selectedOnly.data[0] === 1 && only === 0) {
            only = item.id;
          }
          if (item.pondera === 0) {
            itemZero = item;
          }
          if (+item.id === +e.target.value) {
            itemValue = item;
          }
      });

      if (itemValue.pondera === 0) {
        if (!checkedState[e.target.value]) {
          checked[e.target.value] = true;
          respuestas.map((item) => {
            if (+item.id !== +e.target.value)
              checked[item.id] = false;
          });
        } else {
          checked[e.target.value] = !checkedState[e.target.value];
        }
      } else {
        if (itemZero) checked[itemZero.id] = false;

        checked[e.target.value] = !checkedState[e.target.value];
      }
      
      oldsChecked[e.target.value] = !oldsChecked[e.target.value];
      let reset = false;
      if (e.target.value == only && oldsChecked[e.target.value] === true){
        reset = true;
      }
      if (reset === true){
        respuestas.map((item) => {
          if (e.target.value != item.id){
            oldsChecked[item.id] = false;
          }
        });
      } else {
        oldsChecked[only] = false;
      }
      respuestas.map((item) => {
        checked[item.id] = oldsChecked[item.id];
      });

      setChecked(checked);
      setRender(render + 1);
    }
  };

  const saveandexit = (e) => {
    dispatch(directorActions.saveHistorico(1, 1, "saved"));

    sendFormulario(e);
      history.push("/auth/start");
  }

  const sendFormulario = (e) => {
    var user = JSON.parse(localStorage.getItem("xcuserv2"));
    localStorage.setItem("hasContestado" + user.email, "true")
    var listaRespuestasActivas = new Array();
    var añadirComentario = null;
    var checkedKeys = _.keys(checked);
    checkedKeys.map((item) => {
      if (checked[item]) {
        listaRespuestasActivas.push(item);
      }
    });

    añadirComentario = comentario;

    if (props.isPreguntaCompleted) {
      let centro_pregunta_id;
      props.isPreguntaCompleted.map((item) => {
        if (item.preguntas_id == respuestas[0].preguntas_id) {
          centro_pregunta_id = item.id;
        }
      });

      if (centro_pregunta_id) {
        console.log(1000, "aqui 2b");
        dispatch(
          preguntasActions.saveCampoAbierto(
            listaRespuestasActivas,
            añadirComentario,
            centro_pregunta_id,
            props.respuestas[0].preguntas_id
          )
        );
      } else {
        dispatch(
          preguntasActions.savePreguntaANDCampoAbierto(
            listaRespuestasActivas,
            añadirComentario,
            props.centro,
            props.respuestas[0].preguntas_id
          )
        );
      }
    } else {
      dispatch(
        preguntasActions.savePreguntaANDCampoAbierto(
          listaRespuestasActivas,
          añadirComentario,
          props.centro,
          props.respuestas[0].preguntas_id
        )
      );
    }

    dispatch(
      directorActions.saveHistoricoIndicador(
        1,
        3,
        "saved",
        props.respuestas[0].preguntas_id
      )
    );
    setChecked(undefined);
    setComentario("");
  };

  const changeComentarioAndSelectCheckbox = (e) => {
    setComentario(e.target.value);
  }

  var theme = createTheme({
    overrides: {
      MuiInputBase: {
        root: {
          height: "40px",
          "&:hover": {
            color: props.color,
            borderColor: props.color,
          },
        },
        input: {
          color: props.color,
          borderColor: props.color,
          "&:hover": {
            color: props.color,
            borderColor: props.color,
          },
        },
      },
      MuiFormControlLabel: {
        label: {
          color: "#383b41",
          fontFamily: "Museo Sans",
          fontWeight: 700,
          fontSize: "17px",
        },
        root: {
          marginTop: "10px",
        },
      },
      MuiFormControl: {
        root: {
          marginTop: "25px",
        },
      },
    },
  });
  return (
    <div className="topeWidth">
      {loading ? (
        <div></div>
      ) : (
        <div>
          <FormControl component="fieldset">
            <FormGroup>
              {respuestas != undefined && checked != undefined ? (
                respuestas.map((item, i) => {
                  if (item.id != respuestas[respuestas.length - 1].id) {
                    return (
                      <MuiThemeProvider key={i} theme={theme}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              key={item.id}
                              value={item.id}
                              checked={checked[item.id]}
                              onChange={handleChange}
                              name={item.respuesta}
                              style={{ color: props.color }}
                            />
                          }
                          label={item.respuesta}
                        />
                      </MuiThemeProvider>
                    );
                  } else {
                    return (
                      <div key={i}>
                        <MuiThemeProvider theme={theme}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                key={item.id}
                                value={item.id}
                                checked={checked[item.id]}
                                onChange={handleChange}
                                name={item.respuesta}
                                style={{ color: props.color }}
                              />
                            }
                            label={item.respuesta}
                          />

                          <TextField
                            onChange={(e) => changeComentarioAndSelectCheckbox(e)}
                            id={"comentario"+item.id}
                            name="comentario"
                            className="inputCampoAbierto"
                            variant="outlined"
                            value={comentario}
                            checkboxId="item.id"
                          />
                        </MuiThemeProvider>
                      </div>
                    );
                  }
                })
              ) : null}
            </FormGroup>
          </FormControl>
          <Grid container justifyContent="center">
            <div className="botonesAccion gridBotones paddingBotones">
              <Button
                variant="outlined"
                onClick={props.previousPregunta}
                className="btn buttonContestarMasTarde"
                style={{ backgroundColor: "white", color: props.color }}
              >
                <Language langKey="questionary.back" />
              </Button>

              <Button
                onClick={sendFormulario}
                className="btn buttonContestarContinuar marginLeftButton"
                style={{ backgroundColor: props.color }}
              >
                <Language langKey="next" />
              </Button>

              <Button
              onClick={saveandexit}
              className="btn buttonContestarFooterDescargar butonBottomAdjust"
              style={{
                backgroundColor: "white",
                color: props.color,
                border: "1px solid",
              }}
            >
              <Language langKey="questionary.exit" />
            </Button>
            </div>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default CampoAbierto;
