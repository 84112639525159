import React from "react";
import Grid from "@material-ui/core/Grid";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import * as _ from "lodash";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import CheckIcon from "@material-ui/icons/Check";

function LateralBar(props) {
  const theme = createTheme({
    overrides: {
      MuiInputBase: {
        root: {
          height: "40px",
          "&:hover": {
            color: props.color,
            borderColor: props.color,
          },
        },
        input: {
          color: props.color,
          borderColor: props.color,
          "&:hover": {
            color: props.color,
            borderColor: props.color,
          },
        },
      },
      MuiFormControlLabel: {
        label: {
          color: "#383b41",

          fontFamily: "MuseoSans",
          fontWeight: 700,
          fontSize: "17px",
        },
        root: {
          marginTop: "10px",
        },
      },
      MuiFormControl: {
        root: {
          marginTop: "25px",
        },
      },
    },
  });

  // console.log(props.listaPreguntasContestadas);
  return (
    <div className="lateralBar" style={{ color: props.color }}>
      <MuiThemeProvider theme={theme}>
        <Grid container direction="column">
          {props.listaPreguntas.map((item, i) => {
            let mostrar = true;
            if (props.showPreguntaOpcional?.[item.id] === 0)
              mostrar = false;

            if (props.pregunta && item.id == props.pregunta.id) {
              return (
                mostrar && (
                  <Grid key={i} item xs style={{ marginTop: "15px" }}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item>
                        <Brightness1Icon className="activeLateralBar" />
                      </Grid>
                    </Grid>
                  </Grid>
                )
              );
            } else {
              let contestada = false;

              props.listaPreguntasContestadas.forEach((item2) => {
                if (item2.preguntas_id == item.id) contestada = true;
              });

              if (contestada) {
                return (
                  mostrar && (
                    <Grid
                      key={i}
                      item
                      xs
                      style={{ marginTop: "15px" }}
                      onClick={(e) => props.setPregunta(item.id)}
                    >
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                          <CheckIcon className="tickLateralBar" />
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                );
              } else {
                return (
                  mostrar && (
                    <Grid
                      key={i}
                      item
                      xs
                      style={{ marginTop: "15px" }}
                      onClick={(e) => props.setPregunta(item.id)}
                    >
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                          <Brightness1Icon className="circleLateralBar" />
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                );
              }
            }
          })}
        </Grid>
      </MuiThemeProvider>
    </div>
  );
}

export default LateralBar;
