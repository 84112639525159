import React, { useEffect } from "react";
import NavBarSimple from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import { directorActions } from "actions/directorActions";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { v1 as uuidv1 } from "uuid";
import {Language} from 'components/Language';
import 'moment/locale/es';
import 'moment/locale/ca';

function Historico() {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = JSON.parse(localStorage.getItem("xcuserv2"));

  const perfil = user ? +user.perfil : -1;
  const locale = useSelector((state) => state.setting.locale);

  if (perfil !== 1) history.push("/auth/start");

  const listHistorico = useSelector(
    (state) => state.directorReducer.listHistorico
  );

  useEffect(() => {
    moment.locale(locale.toLowerCase());

    dispatch(directorActions.getHistorico());
  }, [locale]);

  const writeInfo = (item) => {

    var a = moment().utc();
    var b = moment(item.fecha).utc();

    var diff = moment.duration(a.diff(b));

    var mdays = parseInt(diff.asDays());
    var mhours = parseInt(diff.asHours());

    var minutes = parseInt(diff.asMinutes()); 

    minutes = minutes - (mdays * 24 * 60 + mhours * 60);
    mhours = mhours - mdays * 24;

    return (
      <span>
        {moment(item.fecha).fromNow()}&nbsp;
        {/* {texto}{" "} */}
        <span className="formatDate">
          {moment(item.fecha)
            .utc()
            .format("DD/MM/YYYY HH:mm:ss")}
        </span>
      </span>
    );
  };
  const saved = (item) => {
    if (item.tipo == 2) {
      return <span>[<Language langKey="auto_saved" />]</span>;
    } else {
      if (item.tipo == 3 && item.titulo !== null) {
        return (
          <span>
            [<Language langKey="question_changed" />: {item.numclave}.{item.num}{" "}
            {item.titulo.replace("*", "")}]
          </span>
        );
      } else {
        return <span></span>;
      }
    }
  };

  return (
    <div>
      <NavBarSimple auth={true} />
      <div className="defaultFull">
        <Grid container spacing={3} justifyContent="center" className="defaultgrid">
          <Grid item sm={10} md={10} lg={9} xl={9}>
            <div className="h1home">
              <Language langKey="historical_answer" />
            </div>

            <div className="listHistorico">
              <div
                className="txtHome fontStrong"
                style={{ marginTop: "10px", marginLeft: "10px" }}
              >
                <Language langKey="reviews" />
              </div>
              <div
                className="menuSeparatorFull"
                style={{ marginTop: "10px" }}
              ></div>
              <div className="showList">
                <div>
                  {listHistorico &&
                    listHistorico.map((item) => (
                      <div
                        key={uuidv1()}
                        style={{
                          marginTop: "5px",
                          marginLeft: "10px",
                          fontSize: "14px",
                        }}
                      >
                        {item.nombre} {item.apellidos}, {writeInfo(item)}{" "}
                        {saved(item)}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item sm={2} md={2} lg={3} xl={3}></Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Historico;
