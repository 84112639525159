import actionTypes from "constants/actionTypes";

export const contenidoActions = {
  contenidosHome,
  getPracticas,
  getPractica,
  getCentrosHome
};

function getCentrosHome() {
  return {
    type: actionTypes.GETCENTROSHOME
  };
}
function contenidosHome() {
  return {
    type: actionTypes.CONTENIDOSHOME
  };
}


function getPracticas() {
  return {
    type: actionTypes.GETPRACTICAS
  };
}


function getPractica(codigo) {
  return {
    type: actionTypes.GETPRACTICA,
    codigo: codigo
  };
}
