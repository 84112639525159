import React, { useState, useEffect } from "react";
import NavBarSimple from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";
import { directorActions } from "actions/directorActions";
import masterData from "constants/masterData";
import { useHistory } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { getRawMessage, Language } from "components/Language.js";
import { useForceUpdate } from "utils/custon-hooks.js";

function InvitaColaboradores() {
  const dispatch = useDispatch();
  const history = useHistory();
  const forceUpdate = useForceUpdate();

  var user = JSON.parse(localStorage.getItem("xcuserv2"));
  const perfil = user ? +user.perfil : -1;

  if (perfil !== 1) history.push("/auth/start");

  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [saveExistsError, setExistsError] = useState(false);
  const setChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  var listacols = useSelector(
    (state) => state.directorReducer.listaColaboradores
  );

  var apiDONE = useSelector((state) => state.loading.APIactions);

  const {
    addColaborador,
    addColaboradorError,
    delColaborador,
    delColaboradorError,
  } = useSelector((state) => state.directorReducer);

  const locale = useSelector((state) => state.setting.locale);

  useEffect(() => {
    forceUpdate();
  }, [locale]);

  useEffect(() => {
    dispatch(directorActions.getColaboradores());

    if (addColaborador) {
      if (addColaborador.code === 200) {
        setMailError(false);
        setEmail("");
        setSaveError(false);
        setSubmitted(false);
        dispatch(directorActions.getColaboradores());
      } 
      else if(addColaborador.code === 201)
        setExistsError(true);
      else setSaveError(true);
    }
    if (addColaboradorError) {      
      if (addColaboradorError.code === 201)
        setExistsError(true);
      else setSaveError(true);
    }

    if (delColaborador?.code === 200)
      dispatch(directorActions.getColaboradores());
  }, [
    apiDONE,
    addColaborador,
    addColaboradorError,
    delColaborador,
    delColaboradorError,
  ]);

  const deActivateUser = (user) => {
    if (window.confirm('Confirmar eliminar')){
      dispatch(directorActions.delColaborador(user.user.id));
    }
  };
  const activateUser = (user) => {
    dispatch(directorActions.activateColaborador(user.user.id));
  };
  const inviteUser = () => {
    setSubmitted(true);
    var ok = false;
    if (email) {
      setMailError(noMailGene(email));
      if (email && !noMailGene(email)) {
        dispatch(
          directorActions.addColaborador(email, masterData.PROFILE_OTROS)
        );
      }
    } else {
      setMailError(true);
    }
  };
  const hayAprobados = function(lista) {
    var ret = false;
    if (lista) {
      lista.map((item) => {
        if (item.aprobado == 1) {
          ret = true;
        }
      });
    }

    return ret;
  };
  const hayPendientes = function(lista) {
    var ret = false;
    if (lista) {
      lista.map((item) => {
        if (item.aprobado == 0) {
          ret = true;
        }
      });
    }

    return ret;
  };
  const noMailGene = function(mEmail) {
    var noValid = false;
   var EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!mEmail.match(EMAIL_REGEX)) {
      noValid = true;
    }

    var domain = mEmail.replace(/.*@/, "").toLowerCase();
    if (
      !(
        domain !== "gmail.com" &&
        domain !== "hotmail.com" &&
        domain !== "yahoo.com" &&
        domain !== "outlook.com"
      )
    ) {
      noValid = true;
    }

    return noValid;
  };

  const SendButton = () => (
    <Button onClick={() => inviteUser()} className="btnInside">
      <Language langKey="send" />
    </Button>
  );
  const DeleteButton = (user) => (
      <IconButton className="iconHeadHover" onClick={() => deActivateUser(user)}>
        <DeleteIcon className="iconHover" />
      </IconButton>
  );

  const Access = (user) => (
    <Grid container xs={3}>
      <Grid item xs={4}>
        <IconButton
          className="iconHeadHover"
          onClick={() => activateUser(user)}
        >
          <CheckIcon
            className="iconHover"
            style={{ color: "rgb(43,116,196)" }}
          />
        </IconButton>
      </Grid>
      <Grid item xs={6}>
        <IconButton
          className="iconHeadHover"
          onClick={() => deActivateUser(user)}
        >
          <ClearIcon className="iconHover" style={{ color: "red" }} />
        </IconButton>
      </Grid>
    </Grid>
  ); 

  return (
    <div>
      <NavBarSimple auth={true} />
      <div className="defaultFull">
        <Grid container spacing={3} className="defaultgrid">
          <Grid item sm={8} md={8} lg={9} xl={9}>
            <div className="h1home">
              <Language langKey="manage_collaborator" />
            </div>
            <div className="txtHome marginSubTitle fontStrong">
              <p><Language langKey="helper.info_one" /></p>
              <p><Language langKey="helper.info_two" /></p>
              <p><Language langKey="helper.info_three" /></p>
            </div>
          </Grid>
          <Grid item sm={8} md={8} lg={6} xl={6}>
            <div
              className="txtHome fontStrong ucase"
              style={{ marginTop: "25px" }}
            >
              <Language langKey="helper.invite" />
            </div>
            <div className="obtext" style={{ marginTop: "15px" }}>
              <Language langKey="helper.email" />
            </div>
            <div>
              <TextField
                id="standard-name"
                className="inputMargin widthF"
                variant="outlined"
                required
                value={email}
                onChange={(e) => setChangeEmail(e)}
                placeholder={getRawMessage("helper.email_example")}
                InputProps={{ endAdornment: <SendButton /> }}
              />
              {submitted && mailError && (
                <div className="errorLayer">
                  <span>
                    <i className="fa fa-exclamation-triangle marginerror"></i>
                    <Language langKey="errors.center_email" />
                  </span>
                </div>
              )}
              {submitted && saveError && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.internal" />
                </div>
              )}
              {submitted && saveExistsError && (
                <div className="errorLayer">
                  <i className="fa fa-exclamation-triangle marginerror"></i>
                  <Language langKey="errors.exist_email" />
                </div>
              )}
            </div>

            {hayAprobados(listacols) && (
              <div
                className="txtHome fontStrong ucase"
                style={{ marginTop: "45px" }}
              >
                <Language langKey="helper.with_access" />
              </div>
            )}
            {listacols &&
              listacols.map(
                (item) =>
                  item.aprobado == 1 && (
                    <div key={item.id}>
                      <TextField
                        value={item.email}
                        id="standard-name"
                        className="inputMargin widthF"
                        variant="outlined"
                        required
                        placeholder={getRawMessage("helper.email_example")}
                        InputProps={{
                          endAdornment: <DeleteButton user={item} />,
                        }}
                      />
                    </div>
                  )
              )}
            {hayPendientes(listacols) && (
              <div
                className="txtHome fontStrong ucase"
                style={{ marginTop: "45px" }}
              >
                <Language langKey="helper.without_access" />
              </div>
            )}
            {listacols &&
              listacols.map(
                (item) =>
                  item.aprobado == 0 && (
                    <div key={item.id}>
                      <TextField
                        value={item.email}
                        id="standard-name"
                        className="inputMargin widthF"
                        variant="outlined"
                        required
                        placeholder={getRawMessage("helper.email_example")}
                        InputProps={{ endAdornment: <Access user={item} /> }}
                      />
                    </div>
                  )
              )}
          </Grid>
          <Grid item sm={4} md={4} lg={6} xl={6}></Grid>
        </Grid>
      </div>
    </div>
  );
}
export default InvitaColaboradores;
