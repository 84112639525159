import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";

export default function* watcherCentros() {
  yield takeEvery(actionTypes.SEARCHCENTRO_NAME, searchCentroName);
  yield takeEvery(actionTypes.SEARCHCENTRO, searchCentro);
  yield takeEvery(actionTypes.GET_CURSOS, getCursos);
  yield takeEvery(actionTypes.GET_MEASURE_RANGES, getMeasureRanges);
  yield takeEvery(actionTypes.SHOW_OPCIONAL, showOpcional);
}

function* getMeasureRanges() {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v2/given-measures";
    const res = yield call(xcService.getToken, apiEndpoint);

    if (res.status === 200) {
      yield put({
        type: actionTypes.GET_MEASURE_RANGES_SUCCESS,
        payload: res.data
      });
    } else {
      yield put({
        type: actionTypes.GETRANGOS_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GETRANGOS_ERROR, payload: e });
  }

  yield put({ type: actionTypes.END_REQUEST });
}

function* getCursos() {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v2/cursos/activos";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.getToken(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.GET_CURSOS_DATA, payload: res.data });
    } else {
      yield put({
        type: actionTypes.GET_CURSOS_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GET_CURSOS_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* searchCentroName({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v2/centro/search";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.post(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.SEARCHCENTRO_NAME_DATA,
        payload: res.data,
      });
    } else {
      yield put({
        type: actionTypes.SEARCHCENTRO_NAME_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.SEARCHCENTRO_NAME_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* searchCentro({ codigo }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v2/centro/codigo/" + codigo;
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.getToken(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.SEARCHCENTRO_DATA, payload: res.data });
    } else {
      yield put({
        type: actionTypes.SEARCHCENTRO_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.SEARCHCENTRO_ERROR, payload: e });
  }

  yield put({ type: actionTypes.END_REQUEST });
}

function* showOpcional() {
  yield put({ type: actionTypes.START_REQUEST });
  try {
    const apiEndpoint = "/v3/preguntasOpcionales/showOpcional";
    const res = yield call( () =>
        new Promise((resolve, reject) => {
          xcService.getToken(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );

    if (res.status === 200) {
      yield put({ type: actionTypes.SET_SHOW_OPCIONAL, payload: res.data });
    } else {
      yield put({
        type: actionTypes.GET_CURSOS_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GET_CURSOS_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
