import actionTypes from "constants/actionTypes";

export const cargosActions = {
  getCargos
};

function getCargos() {
  return {
    type: actionTypes.GET_CARGOS,
    payload: "",
  };
}
