import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import Footer from "components/Footer/Footer.js";
import routes from "routes.js";

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/user") {
        return (
          <Route
            path={'/:lang(es|ca)?' + prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/:lang(es|ca)?/user" to="/user/login" />
  </Switch>
);

export default function User({ ...rest }) {
  const mainPanel = React.createRef();

  const getRoute = () => {
    return true;
  };

  return (
    <div ref={mainPanel}>
      {getRoute() ? <div>{switchRoutes}</div> : <div>{switchRoutes}</div>}
      {getRoute() && <Footer />}
    </div>
  );
}
