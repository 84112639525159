import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PolarData from "./PolarGraf";
import * as html2canvas from "html2canvas";
import { Row, Col } from "antd";

function DownloadMapa() {
  if (localStorage["dataRes"]) {
    var dataRes = JSON.parse(localStorage["dataRes"]);
  }

  if (localStorage["todos"]) {
    var todos = JSON.parse(localStorage["todos"]);
  }
  if (localStorage["media"]) {
    var media = localStorage["media"];
  }

  var apiDONE = useSelector((state) => state.loading.APIactions);

  useEffect(() => {
    html2canvas(document.getElementById('root')).then(function(canvas) {
      const link = document.createElement("a");
      link.href = canvas.toDataURL();
      link.download = "grafico.png";

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    });
  }, [apiDONE]);

  return (
    <div>
      <div
        id="roscod"
        className="roscod"
        style={{
          background: "#f4f5f7",
          minHeight: "100vh",
          paddingTop: "80px",
          paddingLeft: "100px"
        }}>
        <Row>
          <Col span={12}>
          <PolarData
            dataRes={dataRes}
            todos={todos}
            media={media}
           />
          </Col>
       
          <Col span={12}>
          <div
            dangerouslySetInnerHTML={{ __html: localStorage["mFiltros"] }}
            style={{marginTop: 50 }}
          />
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default DownloadMapa;
