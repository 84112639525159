import actionTypes from "constants/actionTypes";

export const preguntasActions = {
  getClavesFullList,
  getClaveInfo,
  getPreguntasXClave,
  getPreguntasContestadas,
  getRespuestasByPregunta,
  restartClavePreguntas,
  ObtenerRespuestasByPreguntaCentro,
  cambioPregunta,
  saveCampoAbierto,
  savePreguntaANDCampoAbierto,
  isPreguntaCompleted,
  saveRadioButton,
  saveRadioButtonAndPregunta,
  getPreguntasContestadasClave,
  getSubpreguntas,
  getsubpreguntaRespuestas,
  saveTableRadioAndPregunta,
  saveTableRadio,
  getRespuestasNivelCentro,
  getSubpreguntasFilterNivel,
  showOpcional,
  resetOpcional,
  saveTableCheckbox,
  saveTableCheckboxAndPregunta,
  cambioPreguntaRealizado,
};

function getClavesFullList() {
  return {
    type: actionTypes.CLAVES,
    payload: ""
  };
}

function getClaveInfo(payload) {
  return {
    type: actionTypes.GET_CLAVE_INFO,
    payload: payload,
  };
}


function getPreguntasXClave(payload) {
  return {
    type: actionTypes.GET_PREGUNTAS_CLAVE,
    payload: payload,
  };
}

function getPreguntasContestadas(payload) {
  return {
    type: actionTypes.GET_PREGUNTAS_CONTESTADAS,
    payload: payload,
  };
}

function getRespuestasByPregunta(payload) {
  return {
    type: actionTypes.GET_RESPUESTAS_BY_PREGUNTA,
    payload: payload,
  };
}

function restartClavePreguntas(payload) {
  return {
    type: actionTypes.RESTART_PREGUNTAS_CLAVES,
    payload: payload,
  };
}

function ObtenerRespuestasByPreguntaCentro(payload) {
  return {
    type: actionTypes.GET_RESPUESTAS_PREGUNTAS_CENTRO,
    payload: payload,
  };
}

function cambioPregunta(payload) {
  return {
    type: actionTypes.CAMBIO_PREGUNTA,
    payload: payload,
  };
}

function saveRadioButton(respuestas,centro_pregunta_id, respuestas_id) {
  return {
    type: actionTypes.SAVE_RADIO_BUTTON,
    payload: {respuestas:respuestas, centro_pregunta_id: centro_pregunta_id, respuestas_id: respuestas_id}
  };
}
function saveRadioButtonAndPregunta(respuestas,centro,pregunta_id) {
  return {
    type: actionTypes.SAVE_RADIO_BUTTON_AND_PREGUNTA,
    payload: {respuestas:respuestas, centro: centro, pregunta_id:pregunta_id}
  };
}

function saveCampoAbierto(respuestas,comentario,centro_pregunta_id, respuestas_id) {
  return {
    type: actionTypes.SAVE_CAMPO_ABIERTO,
    payload: {respuestas:respuestas, comentario:comentario, centro_pregunta_id: centro_pregunta_id, respuestas_id: respuestas_id}
  };
}

function savePreguntaANDCampoAbierto(respuestas,comentario,centro,pregunta_id) {
  return {
    type: actionTypes.SAVE_CAMPO_ABIERTO_AND_PREGUNTA,
    payload: {respuestas:respuestas, comentario:comentario, centro: centro, pregunta_id:pregunta_id}
  };
}

function isPreguntaCompleted(payload) {
  return {
    type: actionTypes.IS_PREGUNTA_COMPLETED,
    payload: payload
  };
}

function getPreguntasContestadasClave(payload) {
  return {
    type: actionTypes.GET_PREGUNTAS_CONTESTADAS_CLAVE,
    payload: payload
  };
}

function getSubpreguntas(payload) {
  return {
    type: actionTypes.GET_SUBPREGUNTAS,
    payload: payload
  };
}
function getSubpreguntasFilterNivel(payload) {
  return {
    type: actionTypes.GET_SUBPREGUNTAS_FILTERED_NIVELES,
    payload: payload
  };
}

function getsubpreguntaRespuestas(payload) {
  return {
    type: actionTypes.GET_SUBPREGUNTAS_RESPUESTAS,
    payload: payload
  };
}

function saveTableRadioAndPregunta(respuestas,centro,pregunta_id) {
  return {
    type: actionTypes.SAVE_TABLE_RADIO_AND_PREGUNTA,
    payload: {respuestas:respuestas, centro: centro, pregunta_id:pregunta_id}
  };
}

function saveTableRadio(respuestas,centro_pregunta_id, respuestas_id) {
  return {
    type: actionTypes.SAVE_TABLE_RADIO,
    payload: {respuestas:respuestas, centro_pregunta_id: centro_pregunta_id, respuestas_id: respuestas_id}
  };
}

function saveTableCheckbox(respuestas,centro_pregunta_id, respuestas_id) {
  return {
    type: actionTypes.SAVE_TABLE_CHECKBOX,
    payload: {respuestas:respuestas, centro_pregunta_id: centro_pregunta_id, respuestas_id: respuestas_id}
  };
}

function saveTableCheckboxAndPregunta(respuestas,centro,pregunta_id) {
  return {
    type: actionTypes.SAVE_TABLE_CHECKBOX_AND_PREGUNTA,
    payload: {respuestas:respuestas, centro: centro, pregunta_id:pregunta_id}
  };
}

function getRespuestasNivelCentro(payload) {
  return {
    type: actionTypes.GET_RESPUESTAS_NIVEL_CENTRO,
    payload: payload
  };
}

function showOpcional() {
  
  return {
    type: actionTypes.SHOW_OPCIONAL,
    payload: ""
  };
}
  function resetOpcional() {
  
    return {
      type: actionTypes.RESET_OPCIONAL,
      payload: ""
    };
  }

  function cambioPreguntaRealizado() {
  
    return {
      type: actionTypes.CAMBIO_PREGUNTA_REALIZADO,
      payload: ""
    };
  }