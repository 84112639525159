export function buildQueryParams(uri, filter, setup) {
  const params = [];

  if (setup) {
    setup = JSON.stringify(setup);
    params.push(`setup=${setup}`)
  }

  if (filter) {
    filter = JSON.stringify(filter);
    params.push(`filter=${filter}`)
  }

  if (params.length > 0) {
    uri += '?' + encodeURI(params.join('&')) 
  }

  return uri;
}
