import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import * as _ from "lodash";
import { preguntasActions } from "actions/preguntasActions";
import Tooltip from "@material-ui/core/Tooltip";
import { v1 as uuidv1 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import { directorActions } from "actions/directorActions";
import { useHistory } from "react-router-dom";
import { Language } from "components/Language";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "rgba(94, 94, 94, 0.89)",
    borderRadius: "18px",
    fontSize: "14px !important",
    padding: "12px",
  },
  customArrow: {
    color: "rgba(94, 94, 94, 0.89",
  },
}));

function CheckBox(props) {  
  const history = useHistory();
  const respuestas = props.respuestas;
  const respuestasExclude = {
    "9": [10],
    "10": [9]
  };
  const [statusNextBackButton, setStatusNextBackButton] = useState(false);
  const classes = useStyles();
  const [checked, setChecked] = useState(undefined);
  const [disabled, setDisabled] = useState(undefined);
  const [render, setRender] = useState(0);
  const [comentario, setComentario] = useState("");
  const loading = useSelector((state) => state.loading.loading);

  const dispatch = useDispatch();

  useEffect(() => {    
    if (props.respuestas != undefined) {
      var checkedState = {};
      var disabledState = {};
      props.respuestas.map((item) => {
        checkedState[item.id] = false;  
        disabledState[item.id] = false;
      });
    }
    setDisabled(disabledState);
    setChecked(checkedState);
    props.respuestasCentro.map((item) => {
      checkedState[item.respuestas_id] = true;
      if (item.comentario != null) {
        setComentario(item.comentario);
      }
    });

    setChecked(checkedState);
    setRender(render + 1);
  }, [props.respuestas, props.respuestasCentro]);

  const handleChange = (e) => {
    var checkedState = checked;
    var itemValue = null;
    var itemZero = null;

    respuestas.forEach((item) => {
      if (item.pondera === 0)
        itemZero = item;
      if (+item.id === +e.target.value)
        itemValue = item;
    });

    if (itemValue.pondera === 0) {
      if (!checkedState[e.target.value]) {
        checked[e.target.value] = true;
        respuestas.map((item) => {
          if (+item.id !== +e.target.value) {
            checked[item.id] = false;
          }
        });
      } else {
        checked[e.target.value] = !checkedState[e.target.value];
      }
    } else {
      if (itemZero) checked[itemZero.id] = false;

      checked[e.target.value] = !checkedState[e.target.value];
    }

    setChecked(checked);

    respuestas.map((item) => {
      disabled[item.id] = false;
    });

    respuestas.map((item) => {
      if (checked.hasOwnProperty(item.id)){
        if (checked[item.id] == true && respuestasExclude.hasOwnProperty(item.id)){
          respuestasExclude[item.id].map((index) => {
            disabled[index] = true;
          });
        }
      }
    });

    setDisabled(disabled);

    if (props.claveContestada.clavecompleted === 1){
      setStatusNextBackButton(true);
      Object.keys(checked).forEach(e => {
        if (checked[e] === true){
          setStatusNextBackButton(false);
          return;
        }
      });
    }
    
    setRender(render + 1);
  };

  const enviaP = () => {
    var user = JSON.parse(localStorage.getItem("xcuserv2"));
    localStorage.setItem("hasContestado" + user.email, "true")
    var listaRespuestasActivas = new Array();
    var añadirComentario = null;
    var checkedKeys = _.keys(checked);

    checkedKeys.map((item) => {
      if (checked[item]) {
        listaRespuestasActivas.push(item);
      }
    });

    if (checked[checkedKeys[checkedKeys.length - 1]]) {
      añadirComentario = comentario;
    }

    if (props.isPreguntaCompleted) {
      let centro_pregunta_id;

      props.isPreguntaCompleted.map((item) => {
        if (item.preguntas_id === respuestas[0].preguntas_id) {
          centro_pregunta_id = item.id;
        }
      });

      if (centro_pregunta_id) {
        dispatch(
          preguntasActions.saveCampoAbierto(
            listaRespuestasActivas,
            añadirComentario,
            centro_pregunta_id,
            props.respuestas[0].preguntas_id
          )
        );
      } else {
        dispatch(
          preguntasActions.savePreguntaANDCampoAbierto(
            listaRespuestasActivas,
            añadirComentario,
            props.centro,
            props.respuestas[0].preguntas_id
          )
        );
      }
    } else {
      dispatch(
        preguntasActions.savePreguntaANDCampoAbierto(
          listaRespuestasActivas,
          añadirComentario,
          props.centro,
          props.respuestas[0].preguntas_id
        )
      );
    }

    dispatch(
      directorActions.saveHistoricoIndicador(
        1,
        3,
        "saved",
        props.respuestas[0].preguntas_id
      )
    );
    setChecked(undefined);
    setComentario("");
  }
  
  const saveandexit = (e) => {
    dispatch(directorActions.saveHistorico(1, 1, "saved"));
    enviaP();
    history.push("/auth/start");
  }

  const sendFormulario = (e) => {
    enviaP();
  };

  var theme = createTheme({
    overrides: {
      MuiInputBase: {
        root: {
          height: "40px",
          "&:hover": {
            color: props.color,
            borderColor: props.color,
          },
        },
        input: {
          color: props.color,
          borderColor: props.color,
          "&:hover": {
            color: props.color,
            borderColor: props.color,
          },
        },
      },
      MuiFormControlLabel: {
        label: {
          color: "#383b41",
          fontFamily: "Museo Sans",
          fontWeight: 700,
          fontSize: "17px",
        },
        root: {
          marginTop: "10px",
        },
      },
      MuiFormControl: {
        root: {
          marginTop: "25px",
        },
      },
    },
  });
  return (
    <div className="topeWidth">
      {loading ? (
        <div></div>
      ) : (
        <div>
        
          <FormControl component="fieldset">
            <FormGroup>
              {respuestas != undefined && checked != undefined ? (
                respuestas.map((item) => {
                  if (item.tooltip !== "" && item.tooltip !== null) {
                    return (
                      <MuiThemeProvider key={uuidv1()} theme={theme}>
                        <Tooltip
                          classes={{
                            tooltip: classes.customTooltip,
                            arrow: classes.customArrow,
                          }}
                          arrow
                          placement="top"
                          title={
                            <span style={{ fontSize: "12px; !important;" }}>
                              {item.tooltip}
                            </span>
                          }
                          style={{ maxWidth: 600 }}
                        >
                          <FormControlLabel
                            key={uuidv1()}
                            control={
                              <Checkbox
                                key={item.id}
                                value={item.id}
                                checked={checked[item.id]}
                                onChange={handleChange}
                                name={item.respuesta}
                                style={{ color: props.color }}
                                disabled={disabled[item.id]}
                              />
                            }
                            label={item.respuesta}
                          />
                        </Tooltip>
                      </MuiThemeProvider>
                    );
                  } else {
                    return (
                      <MuiThemeProvider key={uuidv1()} theme={theme}>
                        <FormControlLabel
                          key={uuidv1()}
                          control={
                            <Checkbox
                              key={item.id}
                              value={item.id}
                              checked={checked[item.id]}
                              onChange={handleChange}
                              name={item.respuesta}
                              style={{ color: props.color }}
                              disabled={disabled[item.id]}
                            />
                          }
                          label={item.respuesta}
                        />
                      </MuiThemeProvider>
                    );
                  }
                })
              ) : (
                <div></div>
              )}
            </FormGroup>
          </FormControl>
          
          <Grid container justifyContent="center">            
            <div className="botonesAccion gridBotones paddingBotones">
              <Button
                variant="outlined"
                disabled={statusNextBackButton}
                onClick={props.previousPregunta}
                className="btn buttonContestarMasTarde"
                style={{ backgroundColor: "white", color: props.color }}
              >
                <Language langKey="questionary.back" />
              </Button>

              <Button
                disabled={statusNextBackButton}
                onClick={sendFormulario}
                className="btn buttonContestarContinuar marginLeftButton"
                style={{ backgroundColor: props.color }}
              >
                <Language langKey="next" />
              </Button>

              <Button
              disabled={statusNextBackButton}
              onClick={saveandexit}
              className="btn buttonContestarFooterDescargar butonBottomAdjust"
              style={{
                backgroundColor: "white",
                color: props.color,
                border: "1px solid",
              }}
            >
              <Language langKey="questionary.exit" />
            </Button>
            </div>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default CheckBox;
