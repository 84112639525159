import React, { useMemo } from "react";
import Chart from "react-apexcharts";
import * as html2canvas from "html2canvas";
import { getRawMessage } from "components/Language";

const colors = [
  "#3E7BBD",
  "#4587C5",
  "#60B471",
  "#75BB80",
  "#88C493",
  "#986CA8",
  "#DB4B59",
  "#DF6868",
  "#E38077",
  "#D27E40",
];

const KeysChar = ({ dataRes = [], onMounted }) => {
  const state = useMemo(() => {
    let sum = 0;
    const series = [];
    const labels = [];

    dataRes.forEach((item, index) => {
      series.push(item?.suma);
      labels.push(`${getRawMessage('key')} ${index + 1} - ${Math.round(item?.suma)}%`);
      sum += item?.suma;
    });

    const media = Math.round(sum / 10);
    const response = {
      series: series,
      options: {
        labels,
        colors,
        chart: {
          animations: { enabled: false },
          events: {
            mounted: () => {
              if(onMounted) {
                const node = document.getElementById("mRosco");

                html2canvas(node).then(canvas => {
                  const url = canvas.toDataURL();
                  onMounted(url)
                });
              }
            }
          },
          type: "polarArea"
        },
        stroke: { colors: ["#fff"] },
        fill: { opacity: 0.9, colors },
        responsive: [{
          options: {
            chart: {width: "100%" },
            legend: {position: "bottom" },
          },
        }],
        subtitle: {
          text: `${getRawMessage('average')} ${media}%`,
          style: {
            fontSize: "28px",
            fontWeight: "bold",
            color: "#000000",
          },
        },
        plotOptions: {
          polarArea: {
            rings: {
              strokeWidth: 40,
              strokeColor: "#fdfdfd",
            },
            spokes: {
              strokeWidth: 1,
              strokeColor: "#e8e8e8",
            },
          },
        },
        tooltip: { enabled: false },
        dataLabels: { enabled: false },
        legend: {
          show: true,  
          fontSize: "19px",
          markers: {
            width: 15,
            height: 15,
          },
          itemMargin: {horizontal: 10}
        },
        stroke: { show: true },
        yaxis: { show: false },
      },
    };

    return response;
  }, [dataRes]);

  return (
    <Chart options={state.options} series={state.series} type="polarArea" />
  );
};

export default KeysChar;
