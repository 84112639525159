import React, {
  useState,
  useEffect,
} from "react";
import NavBarAuth from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { datosActions } from "actions/datosActions";
import * as _ from "lodash";
import * as html2canvas from "html2canvas";
import Tour from "reactour";
import { isTablet } from "react-device-detect";
import { Modal } from "react-bootstrap";
import { withOrientationChange } from "react-device-detect";
import Tooltip from "@material-ui/core/Tooltip";

import Polar2 from "./Polar2"
import PolarData from "./Polar"
import { delay } from "utils.js";
import { useStatus } from "utils.js";
import { getRawMessage, Language } from "components/Language.js";
import { ownerships } from "./Centro.js";
import { Description, GetApp } from "@material-ui/icons";

const { Option } = Select;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  customTooltip: {
    backgroundColor: "rgba(94, 94, 94, 0.89)",
    borderRadius: "18px",
    fontSize: "14px !important",
    padding: "12px",
  },
  customArrow: {
    color: "rgba(94, 94, 94, 0.89",
  },
}));

const mEconomicoList = [
  {value: 1, label: <Language langKey="low" />},
  {value: 2, label: <Language langKey="middle_low" />},
  {value: 3, label: <Language langKey="middle" />},
  {value: 4, label: <Language langKey="middle_high" />},
  {alue: 5, label: <Language langKey="high" />},
];

const mPaisesList = [
  { value: -1, label: <Language langKey="spain" />},
];


function Resultado(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  var user = JSON.parse(localStorage.getItem("xcuserv2"));
  const perfil = user ? +user.perfil : -1;

  if (perfil === 2 || perfil == 0) history.push("/auth/resultados");

  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [isTourOpen, setIsTourOpen] = useState(true);

  var mTour = "1";
  if (localStorage.getItem("xctourRes" + user.email))
    mTour = localStorage.getItem("xctourRes" + user.email);
  else mTour = "1";

  var [doTour, setDoTour] = useState(mTour);
  const [applyFiltros, setApplyFiltros] = useState(false);
  const [ccaaSel, setccaaSel] = useState(-1);
  const [provSel, setProvSel] = useState(-1);
  const [tipoSel, setTipoSel] = useState([]);
  const [economicoSel, setEconomicoSel] = useState([]);
  const [abandonoSel, setAbandonoSel] = useState([]);

  const [repeSel, setRepeSel] = useState([]);
  const [cursoSel, setCursoSel] = useState([]);
  const [mProvinciasList, setProvinciasList] = useState([]);
  const [mComunidadesList, setComunidadesList] = useState([]);
  const [mRangos, setRangos] = useState([]);
  const [mClaves, setClaves] = useState([]);
  const [mAllRangos, setAllRangos] = useState([]);
  const [mCursos, setCursos] = useState([]);
  const [periodos, setPeriodos] = useState([]);
  const [mPeriodosSel, setPeriodosSel] = useState(null);
  const [mediaT, setMediaT] = useState(0);
  const [periodoHistoricoSel, setPeriodosHistoricoSel] = useState(null);

  const [status, setStatus]  = useStatus({mounted: 0, loading: false});

  const [mTrata, setTrata] = useState([]);
  const [media, setMedia] = useState(0);
  const [allResultadosGlobalPro, setAllResultadosGlobalPro] = useState([]);

  const [mCCAASel, setCCAASel] = useState(null);
  const [mProvinciaSel, setProvinciaSel] = useState(null);
  const locale = useSelector((state) => state.setting.locale);

  var filtros = useSelector((state) => state.datosReducer.filtros);

  var lastPeriodo = useSelector((state) => state.datosReducer.lastPeriodo);

  var resultadosCentroClave = useSelector(
    (state) => state.datosReducer.resultadosCentroPeriodoClave
  );

  var allResultadosGlobal = useSelector(
    (state) => state.datosReducer.allResultadosGlobalHist
  );

  var resultadosCentroPeriodoHistoricoClave = useSelector(
    (state) => state.datosReducer.resultadosCentroPeriodoCompara
  );

  var periodosData = useSelector((state) => state.datosReducer.periodosData);

  const getSumaOpcional = (array, suma, id) => {
    var ret = suma;
    var mSuma = 0;
    var result = _.filter(array, { opcionalpreguntarel: id });

    result.forEach((val) => {
      mSuma += getSumaOpcional(array, val.suma, val.idpregunta);
    });
    return ret + mSuma;
  };

  useEffect(() => {
    var media = 0;
    setTrata([]);
    if (resultadosCentroClave && resultadosCentroClave.length > 0) {
      var mSumaTotal = 0;
      resultadosCentroClave.forEach((valor) => {
        mSumaTotal += valor.suma;
      });

      media = mSumaTotal / 10;

      setTrata(resultadosCentroClave);
      setMedia(media);

      if (applyFiltros) applyFilter();
    }
  }, [resultadosCentroClave]);

  useEffect(() => {
    var mediaT = 0;
    setAllResultadosGlobalPro([]);
    if (
      applyFiltros &&
      resultadosCentroPeriodoHistoricoClave &&
      resultadosCentroPeriodoHistoricoClave.length > 0
    ) {
      var mSalidaT = _.groupBy(resultadosCentroPeriodoHistoricoClave, "clave");
      var mSumaTotalT = 0;
      Object.keys(mSalidaT).map((key) => {
        var mSumaI = 0;
        mSalidaT[key].map((val) => {
          mSumaI += val.suma;
        });
        mSumaTotalT += mSumaI / mSalidaT[key].length;
      });
      mediaT = mSumaTotalT / 10;
      setMediaT(mediaT);
      setAllResultadosGlobalPro(resultadosCentroPeriodoHistoricoClave);
    }
  }, [resultadosCentroPeriodoHistoricoClave]);

  useEffect(() => {
    var mediaT = 0;

    setAllResultadosGlobalPro([]);

    if (applyFiltros && allResultadosGlobal && allResultadosGlobal.length > 0) {
      var mSalidaT = _.groupBy(allResultadosGlobal, "clave");
      var mSumaTotalT = 0;
      Object.keys(mSalidaT).map((key) => {
        var mSumaI = 0;
        mSalidaT[key].map((val) => {
          mSumaI += val.suma;
        });
        mSumaTotalT += mSumaI / mSalidaT[key].length;
      });
      mediaT = mSumaTotalT / 10;
      setMediaT(mediaT);
      setAllResultadosGlobalPro(allResultadosGlobal);
    }
  }, [allResultadosGlobal]);

  var apiDONE = useSelector((state) => state.loading.APIactions);

  const changeComunidad = (casel) => {
    setCCAASel(casel);
    setProvSel(-1);
    setProvinciaSel(-1);
    if (casel !== -1) {
      const tProvinciasList = [];
      tProvinciasList.push({
        value: -1,
        label: <Language langKey="provice" /> 
      });

      filtros.provincias.map((item) => {
        if (+item.comunidad_id === +casel) {
          tProvinciasList.push({
            value: item.id_provincia,
            label: item.provincia,
            comunidad: item.comunidad_id,
          });
        }
      });

      setProvinciasList(tProvinciasList);
    }
    setccaaSel(casel);
  };

  const changeProvincia = (pSel) => {
    setProvSel(pSel);
    setProvinciaSel(pSel);
  };

  useEffect(() => {
    dispatch(datosActions.getFiltros());
  }, [locale]);

  useEffect(() => {
    dispatch(datosActions.last());
    dispatch(datosActions.getDatosGrafico());
    dispatch(datosActions.getPeriodos());
  }, [apiDONE]);

  useEffect(()=> {
    setStatus({mounted: Date.now()});
  }, [])

  const changePeriodoHistorico = (periodo) => {
    setPeriodosHistoricoSel(periodo);
  };

  useEffect(() => {
    let mTemp = [];
    if (periodosData && lastPeriodo) {
      let qId = null;
      if (lastPeriodo && lastPeriodo[0] && lastPeriodo[0].id) {
        qId = lastPeriodo[0].id;
      }
      periodosData.map((item) => {
        if (!qId && item.activo === 1) {
          qId = item.id;
        }
        mTemp.push({ value: item.id, label: item.periodo });
      });
      setPeriodosSel(qId);
      changePeriodo(qId);
      setPeriodos(mTemp);
    }
  }, [periodosData, lastPeriodo]);

  useEffect(() => {
    var tRangos = [];
    var tComunidadesList = [];
    var tCursos = [];

    if (filtros && filtros.comunidades) {
      setCCAASel(-1);
      tComunidadesList.push({
        value: -1,
        label: <Language langKey="ccaa" /> 
      });

      filtros.comunidades.map((item) => {
        tComunidadesList.push({ value: item.id, label: item.nombre });
      });

      setComunidadesList(tComunidadesList);
    }

    if (filtros && filtros.rangos) {
      filtros.rangos.map((item) => {
        tRangos.push({
          value: `${item.id},${item.idcurso}`,
          label: `${item.curso} ${item.rango}`,
          idcurso: item.idcurso,
        });
      });

      setRangos(tRangos);
      setAllRangos(tRangos);
    }

    if (filtros && filtros.cursos) {
      filtros.cursos.map((item) => {
        tCursos.push({ value: item.id, label: item.curso });
      });

      setCursos(tCursos);
    }

    if (filtros && filtros.claves) setClaves(filtros.claves);
  }, [filtros]);

  const handleChangeTab = (event, newValue) => {
    setAllResultadosGlobalPro([]);
    setTabValue(newValue);
  };

  const changePeriodo = (periodo) => {
    setTrata([]);
    setPeriodosSel(periodo);
    dispatch(datosActions.getResultadosClavesPeriodoCentro({id: periodo}));
  };

  const resetFilter = () => {
    setccaaSel(-1);
    setTipoSel([]);
    setCursoSel([]);
    setEconomicoSel([]);
    setAbandonoSel([]);
    setRepeSel([]);
    setApplyFiltros(false);
    setProvSel(-1);
    setProvinciaSel(null);
    setCCAASel(-1);
    var tProvinciasList = [];
    setPeriodosHistoricoSel(null);

    setProvinciasList(tProvinciasList);
    dispatch(
      datosActions.getResultadosCentroFiltroHist(
        mPeriodosSel,
        -1,
        -1,
        [],
        [],
        [],
        [],
        [],
        -1
      )
    );
  };

  const resetFilterHist = () => {
    setApplyFiltros(false);
    setAllResultadosGlobalPro([]);
    setPeriodosHistoricoSel(null);
  };

  const applyFilterHist = () => {
    setApplyFiltros(true);

    dispatch(
      datosActions.getResultadosClavesCentroPeriodoCompara({
        id: periodoHistoricoSel,
      })
    );
  };

  const applyFilter = () => {
    setPeriodosHistoricoSel(null);
    setApplyFiltros(true);
    dispatch(
      datosActions.getResultadosCentroFiltroHist(
        mPeriodosSel,
        ccaaSel,
        provSel,
        tipoSel.map(item => item.toUpperCase()),
        economicoSel,
        abandonoSel,
        repeSel,
        cursoSel,
        -1
      )
    );
  };

  const closeTour = () => {
    setDoTour("0");
    localStorage.setItem("xctourRes" + user.email, "0");
    setIsTourOpen(false);
  };

  const cambiaCurso = (e) => {
    setCursoSelection(e);
    if (e.length > 0) {
      var pRangos = [];

      e.map((item) => {
        var mSalida = _.filter(mAllRangos, {
          idcurso: item,
        });
        mSalida.map((sal) => {
          pRangos.push(sal);
        });
      });
      setRangos(pRangos);
    } else {
      setRangos(mAllRangos);
    }
  };

  const setCursoSelection = (e) => {
    setCursoSel(e);
  };

  const steps = [
    {
      selector: ".comparativa",
      content: ({goTo}) => (
        <div>
          <div className="titleOnBoard">
           <Language langKey="compare" />
          </div>
          <div className="subtitleOnBoard">
            <Language langKey="tour.compare_average" />
            <ul>
              <li>{<Language langKey="tour.compare_param_one" />}</li>
              <li>{<Language langKey="tour.compare_param_two" />}</li>
            </ul>
          </div>
          <div>
            <button className="btn btnHome btnBoard" onClick={() => goTo(1)}>
              <Language langKey="next" />
            </button>
          </div>
        </div>
      ),
    },
    {
      selector: ".centerWrapper",
      content: ({ goTo }) => (
        <div>
          <div className="titleOnBoard">
            <Language langKey="keys_single" />
          </div>
          <div className="subtitleOnBoard">
            <Language langKey="tour.graphic" />
          </div>
          <div>
            <button className="btn btnHome btnBoard" onClick={() => goTo(2)}>
              <Language langKey="next" />
            </button>
          </div>
        </div>
      ),
    },
    {
      selector: ".descargarInforme",
      content: ({ goTo }) => (
        <div>
          <div className="titleOnBoard">
            <Language langKey="report" />
          </div>
          <div className="subtitleOnBoard">
            <Language langKey="tour.report" />
          </div>
          <div>
            <button className="btn btnHome btnBoard" onClick={() => goTo(3)}>
              <Language langKey="next" />
            </button>
          </div>
        </div>
      ),
    },
    {
      selector: ".menuonboard1",
      content: ({ goTo }) => (
        <div>
          <div className="titleOnBoard">
            <Language langKey="menu" />
          </div>
          <div className="subtitleOnBoard">
            <p><Language langKey="take_autoquiz" /></p>
            <p><Language langKey="tour.quiz_param" /></p>
          </div>
          <div>
            <button className="btn btnHome btnBoard" onClick={() => goTo(4)}>
              <Language langKey="next" />
            </button>
          </div>
        </div>
      ),
    },
    {
      selector: ".menuonboard2",
      content: () => (
        <div>
          <div className="titleOnBoard">
            <Language langKey="menu" />
          </div>
          <div className="subtitleOnBoard">
            <p><Language langKey="tour.centrer_param_one" /></p>
            <p><Language langKey="tour.centrer_param_two" /></p>
          </div>
        </div>
      ),
    },
  ];

  const getYearPeriodo = (periodoHistoricoSel) => {
    let ret = "";

    periodos.map((item) => {
      if (item.value === periodoHistoricoSel) ret = item.label;
    });

    return ret;
  };

  const onClickDw = async () => {
    const timeMounted = (Date.now() - status.mounted);

    if(timeMounted < 5000) {
      setStatus({loading: true});

      await delay(5000 - timeMounted);

      setStatus({loading: false});
    }

    const node = document.getElementById("mRosco");

    html2canvas(node).then(canvas => {
      localStorage["fileBase64"] = canvas.toDataURL();
      window.open("/auth/informe/" + mPeriodosSel, '_blank').focus();
    });
  };

  const getElement = (item, array) => {
    let ret = "";
    array.forEach((sel) => {
      if (sel.value === item) ret = sel.label;
    });

    return ret;
  };

  const downLoadImage = (dataRes, todos, media) => {
    var mFiltros = "";
    mFiltros += `<b>${getRawMessage('study_grade')}</b><br />`;
    cursoSel.forEach((item) => {
      mFiltros += "&nbsp;&nbsp;- " + getElement(item, mCursos) + "<br />";
    });

    if (cursoSel.length === 0)
      mFiltros += `&nbsp;&nbsp;- ${getRawMessage('all')}<br />`;

    mFiltros += `<b>${getRawMessage('ownership')}</b><br />`;

    tipoSel.forEach((item) => {
      mFiltros += "&nbsp;&nbsp;- " + getRawMessage(item) + "<br />";
    });

    if (tipoSel.length === 0)
      mFiltros += `&nbsp;&nbsp;- ${getRawMessage('all')}<br />`;

    mFiltros += `<b>${getRawMessage('economy_grade')}</b><br />`;
    economicoSel.map((item) => {
      mFiltros +=
        "&nbsp;&nbsp;- " + getElement(item, mEconomicoList) + "<br />";
    });
    if (economicoSel.length === 0)
      mFiltros += `&nbsp;&nbsp;- ${getRawMessage('all')}<br />`;

    mFiltros += `<b>${getRawMessage('dropout_rate')}</b><br />`;
    abandonoSel.map((item) => {
      mFiltros += "&nbsp;&nbsp;- " + getElement(item, mRangos) + "<br />";
    });
    if (abandonoSel.length === 0)
      mFiltros += `&nbsp;&nbsp;- ${getRawMessage('all')}<br />`;

    mFiltros += `<b>${getRawMessage('repeater_rate')}</b><br />`;
    repeSel.map((item) => {
      mFiltros += "&nbsp;&nbsp;- " + getElement(item, mRangos) + "<br />";
    });

    if (repeSel.length === 0) 
      mFiltros += `&nbsp;&nbsp;- ${getRawMessage('all')}<br />`;

    localStorage["dataRes"] = JSON.stringify(dataRes);
    localStorage["todos"] = JSON.stringify(todos);
    localStorage["media"] = media;

    localStorage["mFiltros"] = mFiltros;
    window.open("/none/mapainv", "_blank");
  };

  return (
    <div className="fullPage">
      <NavBarAuth />
      <div>
        <Modal
          show={isTablet && props.isPortrait}
          className="modalTablet"
        >
          <Modal.Body>
            <div className="subh1home" style={{ marginTop: "35px" }}>
              <Language langKey="alert.attention" />
            </div>
            <div style={{ marginTop: "15px" }} className="txtHome">
              <Language langKey="alert.rotate_screen" />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className={`registerWidth btnHome btnCenter`}>
              <Language langKey="start" />
            </Button>
          </Modal.Footer>
        </Modal>

        {doTour == "1" && (
          <Tour
            steps={steps}
            isOpen={isTourOpen == true}
            showNavigation={false}
            showNavigationNumber={false}
            lastStepNextButton={
              <button className="btn btnHome btnBoard">
                <Language langKey="start" />
              </button>
            }
            onRequestClose={() => closeTour()}
          />
        )}
        <Grid container className="bgWhite" spacing={3}>
          <Grid
            className="comparativa"
            item
            md={3}
            lg={2}
            id="comparativa"
          >
            {/* menu izquiera */}
            <div className="txtResultadosTitle toUpperCase fontStrong">
              <Language langKey="results" />
            </div>
            <div className="txtInfoExplica">
              <Language langKey="p_results.report" />
            </div>
            <Select
              className="selectFilters"
              value={mPeriodosSel}
              placeholder={getRawMessage("p_results.select_period")}
              onChange={(newValue) => changePeriodo(newValue)}
            >
              {periodos.map((item, i) =>
                (<Option key={i} value={item.value}>{item.label}</Option>)
              )}
            </Select>

            <div className="txtResultadosTitle toUpperCase fontStrong customMt2">
              <Language langKey="compare" />
            </div>
            <div className="txtInfoExplica">
              <Language langKey="p_results.info_compare" />
            </div>
            <AppBar className="tabsRes" position="static">
              <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                aria-label="simple tabs example"
              >
                <Tab
                  label={<Language langKey="geographic" />}
                  {...a11yProps(0)}
                />
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip,
                    arrow: classes.customArrow,
                  }}
                  arrow
                  placement="top"
                  title={
                    <span style={{ fontSize: "12px; !important" }}>
                      <Language langKey="p_results.history" />
                    </span>
                  }
                  style={{ maxWidth: 600 }}
                >
                  <Tab
                    label={<Language langKey="historical" />}
                    {...a11yProps(1)}
                  />
                </Tooltip>
              </Tabs>
            </AppBar>
            <TabPanel value={tabValue} index={0} className="tabPadding">
              <div style={{ width: 220 }}>
                <Select className="selectFilters" value={mPaisesList[0].value}>
                  {mPaisesList.map((item, i) => {
                    return <Option key={i} value={item.value}>{item.label}</Option>;
                  })}
                </Select>
              </div>
              <div style={{ width: 220, marginTop: 15 }}>
                <Select
                  onChange={(newValue) => changeComunidad(newValue)}
                  value={mCCAASel}
                  placeholder={getRawMessage("ccaa")}
                  className="selectFilters"
                >
                  {mComunidadesList.map((item, i) => {
                    return <Option key={i} value={item.value}>{item.label}</Option>;
                  })}
                </Select>
              </div>
              <div style={{ width: 220, marginTop: 15 }}>
                <Select
                  value={mProvinciaSel}
                  onChange={(newValue) => changeProvincia(newValue)}
                  placeholder={getRawMessage("provice")}
                  className="selectFilters"
                >
                  {mProvinciasList.map((item, i) => {
                    return <Option key={i} value={item.value}>{item.label}</Option>;
                  })}
                </Select>
              </div>
              <div className="tabTextTitle" style={{ marginTop: "25px" }}>
                <Language langKey="study_grade" />
              </div>
              <div style={{ width: 220, marginTop: 3, zIndex: 9999 }}>
                <Select
                  mode="multiple"
                  allowClear
                  value={cursoSel}
                  showArrow={true}
                  style={{ width: "190px" }}
                  onChange={(e) => cambiaCurso(e)}
                  placeholder={getRawMessage("all")}
                >
                  {mCursos.map((item, i) =>(
                    <Option key={i} value={item.value}>{item.label}</Option>
                  ))}
                </Select>
              </div>
              <div className="tabTextTitle" style={{ marginTop: "15px" }}>
                <Language langKey="ownership" />
              </div>
              <div style={{ width: 220, marginTop: 10 }}>
                <Select
                  mode="multiple"
                  value={tipoSel}
                  allowClear
                  showArrow={true}
                  style={{width: "190px"}}
                  onChange={(e) => setTipoSel(e)}
                  placeholder={getRawMessage('all')}
                >
                {ownerships.map(key => (
                  <Option key={key} value={key}>
                    <Language langKey={key} />
                  </Option>
                ))}
                </Select>
              </div>
              <Tooltip
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
                arrow
                placement="top"
                title={
                  <span style={{ fontSize: "12px; !important" }}>
                    <Language langKey="p_results.grade_info" />
                  </span>
                }
                style={{ maxWidth: 600 }}
              >
                <div className="tabTextTitle" style={{ marginTop: "15px" }}>
                  <Language langKey="economy_grade" />*
                </div>
              </Tooltip>
              <div style={{ width: 220, marginTop: 10 }}>
                <Select
                  mode="multiple"
                  value={economicoSel}
                  allowClear
                  showArrow={true}
                  style={{ width: "190px" }}
                  onChange={(e) => setEconomicoSel(e)}
                  placeholder={getRawMessage('all')}
                >
                  {mEconomicoList.map((item, i) => {
                    return <Option key={i} value={item.value}>{item.label}</Option>;
                  })}
                </Select>
              </div>
              <Tooltip
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
                arrow
                placement="top"
                title={
                  <span style={{ fontSize: "12px; !important" }}>
                  <Language langKey="p_results.grade_info" />
                  </span>
                }
                style={{ maxWidth: 600 }}
              >
                <div className="tabTextTitle" style={{ marginTop: "15px" }}>
                  <Language langKey="dropout_rate" />*
                </div>
              </Tooltip>
              <div style={{ width: 220, marginTop: 10 }}>
                <Select
                  mode="multiple"
                  value={abandonoSel}
                  allowClear
                  showArrow={true}
                  style={{ width: "190px" }}
                  onChange={(e) => setAbandonoSel(e)}
                  placeholder={getRawMessage('all')}
                >
                  {mRangos.map((item, i) => {
                    return <Option key={i} value={item.value}>{item.label}</Option>;
                  })}
                </Select>
              </div>
              <Tooltip
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
                arrow
                placement="top"
                title={
                  <span style={{ fontSize: "12px; !important" }}>
                    <Language langKey="p_results.grade_info" />
                  </span>
                }
                style={{ maxWidth: 600 }}
              >
                <div className="tabTextTitle" style={{ marginTop: "15px" }}>
                  <Language langKey="repeater_rate" />
                </div>
              </Tooltip>
              <div style={{ width: 220, marginTop: 10 }}>
                <Select
                  mode="multiple"
                  value={repeSel}
                  allowClear
                  showArrow={true}
                  style={{ width: "190px" }}
                  onChange={(e) => setRepeSel(e)}
                  placeholder={getRawMessage('all')}
                >
                  {mRangos.map((item, i) => {
                    return <Option key={i} value={item.value}>{item.label}</Option>;
                  })}
                </Select>
              </div>
              <div style={{ marginTop: 15 }}>
                <Button
                  onClick={applyFilter}
                  disabled={mPeriodosSel ? false : true}
                  color="primary"
                  variant="contained"
                >                  
                  <Language langKey="apply_filter" />
                </Button>
              </div>
              <div style={{ paddingTop: "15px" }}>
                {mPeriodosSel && (
                  <a
                    className="mainLink"
                    onClick={resetFilter}
                    style={{ cursor: "pointer" }}
                  >
                    <Language langKey="clean_filter" />
                  </a>
                )}
              </div>
            </TabPanel>
            <TabPanel value={tabValue} index={1} className="tabPadding">
              <div style={{ width: 220 }}>
                <div className="tabTextTitle">
                  <Language langKey="course" />
                </div>
                <div className="txtInfoExplica">
                  <Language langKey="p_results.compare_with" />
                </div>
                <Select
                  className="selectFilters"
                  value={periodoHistoricoSel}
                  placeholder={getRawMessage("p_results.select_period")}
                  onChange={(newValue) => changePeriodoHistorico(newValue)}
                >
                  {periodos.map((item, i) =>
                    item.value !== mPeriodosSel && (
                      <Option key={i} value={item.value}>{item.label}</Option>
                    )
                  )}
                </Select>

                <div style={{ marginTop: "15px" }}>
                  <Button
                    disabled={mPeriodosSel ? false : true}
                    onClick={applyFilterHist}
                    className={`btnFilter ${
                      mPeriodosSel ? "btnHome" : "btnDisabled"
                    }`}
                    style={{ cursor: "pointer" }}
                  >
                    <Language langKey="apply_filter" />
                  </Button>
                </div>
                {mPeriodosSel && (
                  <div style={{ paddingTop: "15px" }}>
                    <a
                      onClick={resetFilterHist}
                      className="mainLink"
                      style={{ cursor: "pointer" }}
                    >
                      <Language langKey="clean_filter" />
                    </a>
                  </div>
                )}
                <div style={{ fontSize: 11, marginRight: 10, marginTop: 15 }}>
                  * <Language langKey="p_results.restrict_feature" />
                </div>
              </div>
            </TabPanel>
          </Grid>

          <Grid className="bgGrey pt-4" item md={9} lg={10}>
            <Grid container spacing={2}>
              <Grid item md={9}>
                <Grid
                  container
                  justifyContent="center"
                  spacing={2}
                  style={{ minHeight: 550 }}
                >
                  <Grid item sm={12} className="mb-3">
                    <Typography variant="h5" align="center">
                      <Language langKey="p_results.rate_getted" />
                    </Typography>
                  </Grid>
                  <Grid item>
                    {mTrata?.length > 0
                      ? (<PolarData
                          dataRes={mTrata}
                          todos={allResultadosGlobalPro}
                          media={media.toFixed(0)}
                        />) 
                      : (<div className="sindatos">
                          <Language langKey="empty_result" />
                        </div>)
                    }
                  </Grid>
                  {mTrata?.length > 0 && (
                    <Grid
                      item
                      container
                      direction="column"
                      spacing={1}
                      style={{maxWidth: 230}}
                    >
                      <Grid item>
                        <Button 
                          endIcon={ 
                            status.loading
                              ? <CircularProgress size={15} />
                              : <Description />
                          }
                          disabled={status.loading}
                          color="primary"
                          fullWidth
                          onClick={onClickDw}
                          variant="contained"
                        >
                          <Language langKey="p_results.download_report" />
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="secondary"
                          fullWidth
                          onClick={() =>
                            downLoadImage(
                              mTrata,
                              allResultadosGlobalPro,
                              media.toFixed(0)
                            )
                          }
                          endIcon={<GetApp />}
                        >
                          <Language langKey="p_results.download_graphic" />
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <div 
                  id="mRosco"
                  style={{
                    width: '100%',
                    maxWidth: 900,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: -1,
                    backgroundColor: '#fff',
                    overflow: 'hidden',
                  }}
                >
                  {mTrata?.length > 0 && (
                    <Polar2 dataRes={mTrata} />
                  )}
                </div>
              </Grid>
              <Grid
                className="bgGrey"
                style={{ zIndex: 10 }}
                item
                md={3}
              >
                <div className="txtResultadosTitle borderTitle toUpperCase fontStrong">
                  {tabValue === 0
                    ? <Language langKey="results" />
                    : <Language langKey="center_results" />
                  }
                </div>

                <div className="subTitleRes">
                  <div className="mediaRainwow"></div>
                  {tabValue === 0 ? (
                    <Language langKey="p_results.center_average" />
                  ) : (
                    <>
                      &nbsp;
                      <Language langKey="p_results.past_average" />&nbsp;
                      {getYearPeriodo(mPeriodosSel)}
                    </>
                  )}{" "}
                  <span className="txtmedia">{+media.toFixed(0)}%</span>
                </div>

                {allResultadosGlobalPro && allResultadosGlobalPro.length > 0 && (
                  <div className="subTitleRes marginSubTitle">
                    <div className="blackMedia"></div>
                    {periodoHistoricoSel ? (
                      <>
                        <Language langKey="p_results.past_average" />&nbsp;
                        {getYearPeriodo(periodoHistoricoSel)}
                      </>
                    ) : (
                      <Language langKey="p_results.compared_average" />
                    )}{" "}
                    <span className="txtmedia">{+mediaT.toFixed(0)}%</span>
                  </div>
                )}

                <div
                  style={{ marginTop: "45px" }}
                  className="txtResultadosTitle borderTitle toUpperCase fontStrong"
                >
                  <Language langKey="keys_single" />
                </div>
                <div>
                  {mClaves &&
                    mClaves.length > 0 &&
                    mClaves.map((item, i) => (
                      <div className="listaClaves" key={i}>
                        {item.num}. {item.titulo}
                      </div>
                    ))}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="bgWhite" item sm={3} md={3} lg={2} xl={2}></Grid>
          <Grid className="bgWhite" item sm={9} md={9} lg={10} xl={10}>
            <div className="rowFilter" style={{ display: "none" }}>
              <div
                className="columnFilter"
                style={{ paddingTop: "5px", width: "75%" }}
              >
                <span className="txtHome ">
                  <Language langKey="p_results.report_info" />
                </span>
              </div>
              <div className="columnFilter " style={{ width: "25%" }}>
                <Link
                  target="_blank"
                  to={{pathname: "/auth/informe"}}
                  id="mInforme"
                  style={{ cursor: "pointer", paddingTop: "15px !important" }}
                  className="btn btnHome btnFilter mInforme"
                >
                  <Language langKey="view_report" />
                </Link>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default withOrientationChange(Resultado);