import {useState} from 'react';

export const useStatus = (initialState) => {
  const [state, setState] = useState(initialState);
  const prevStatus = state;

  const isSame = (updateValue) => {
    let sameState = true;

    for (let key in updateValue) {
      if (updateValue[key] !== prevStatus[key]) {
        prevStatus[key] = updateValue[key];
        sameState = false;
      }
    }

    return sameState;
  };

  const updateState = (updateValue) => {
    if (typeof updateValue === 'function') {
      setState((prev) => {
        let resp;
        const newState = updateValue(prev);

        if (isSame(newState)) resp = prev;
        else resp = newState;

        return resp;
      });
    } else {
      if (!isSame(updateValue)) setState({...prevStatus, ...updateValue});
    }
  };

  return [prevStatus, updateState];
};

export const useForceUpdate = () => {
  const [value, setValue] = useState(false);

  const refreshComponent = () => {
    setValue(!value);
  };

  return refreshComponent;
};