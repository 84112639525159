import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "constants/actionTypes"
import { xcService } from '../services';
import SelectInput from "@material-ui/core/Select/SelectInput";

 import {history} from 'helpers/history';


export default function* watcherToolkits() {
    
  yield takeEvery(actionTypes.GET_TOOLKITS, getToolkits);

  

}


function* getToolkits() {
  yield put({ type: "START_REQUEST"})

  try { 
    let apiEndpoint = "/v3/toolkits"
    const res = yield call(() => new Promise((resolve, reject) => { 
        xcService.getToken(apiEndpoint)
          .then((response) => {
            resolve(response)
          });
        }));
        if(res){
          yield put({ type: "SET_TOOLKITS_LIST", payload: res });

    
    
         
        
        } 
    
    
      } catch (e) {
        yield put({ type: "API_ERRORED", payload: e });
      }
  
        
          yield put({ type: "END_REQUEST"})

}

function* createToolkits({payload}) {
  yield put({ type: "START_REQUEST"})

  try { 
    let apiEndpoint = "/v3/toolkits"
    const res = yield call(() => new Promise((resolve, reject) => { 
        xcService.postToken(apiEndpoint,payload)
          .then((response) => {
            resolve(response)
          });
        }));
        if(res){
          yield put({ type: "API_DONE", payload: res });

    
    
         
        
        } 
    
    
      } catch (e) {
        yield put({ type: "API_ERRORED", payload: e });
      }
  
        
          yield put({ type: "END_REQUEST"})

}

function* updateToolkits({payload}) {
  yield put({ type: "START_REQUEST"})
  try { 
    let apiEndpoint = "/v3/toolkits"
    const res = yield call(() => new Promise((resolve, reject) => { 
        xcService.patch(apiEndpoint,payload)
          .then((response) => {
            resolve(response)
          });
        }));
        if(res){
          yield put({ type: "API_DONE", payload: res });

    
    
         
        
        } 
    
    
      } catch (e) {
        yield put({ type: "API_ERRORED", payload: e });
      }
  
        
          yield put({ type: "END_REQUEST"})

}

function* deleteToolkits({payload}) {
  yield put({ type: "START_REQUEST"})
  try { 
    let apiEndpoint = "/v3/deleteToolkits/"+payload.id
    const res = yield call(() => new Promise((resolve, reject) => { 
        xcService.deleteDetail(apiEndpoint)
          .then((response) => {
            resolve(response)
          });
        }));
        if(res){
          
          yield put({ type: "API_DONE", payload: res });

    
    
         
        
        } 
    
    
      } catch (e) {
        yield put({ type: "API_ERRORED", payload: e });
      }
  
        
          yield put({ type: "END_REQUEST"})

}