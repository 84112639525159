import actionTypes from "constants/actionTypes";
const initialState = {
  measureRanges: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCHCENTRO_NAME_DATA:
      return {
        ...state,
        centrosSearchData: action.payload,
      };
    case actionTypes.SEARCHCENTRO_NAME_ERROR:
      return {
        ...state,
        centrosSearchError: action.payload,
      };
    case actionTypes.SEARCHCENTRO_DATA:
      return {
        ...state,
        codigovalid: action.payload,
      };
    case actionTypes.SEARCHCENTRO_ERROR:
      return {
        ...state,
        codigoerror: action.payload,
      };
    case actionTypes.GET_CURSOS_DATA:
      return {
        ...state,
        getCursos: action.payload,
      };
    case actionTypes.GET_CURSOS_ERROR:
      return {
        ...state,
        getCursosError: action.payload,
      };
    case actionTypes.GET_MEASURE_RANGES_SUCCESS:
      return {
        ...state,
        measureRanges: action.payload,
      };
    case actionTypes.GETRANGOS_ERROR:
      return {
        ...state,
        getRangosError: action.payload,
      };
    default:
      return state;
  }
};
