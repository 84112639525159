import React from "react";
import {
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import * as _ from "lodash";

const BORDER_COLOR = "#bfbfbf";
const BORDER_STYLE = "solid";
const COL1_WIDTH = 20;
const COLN_WIDTH = (100 - COL1_WIDTH) / 6;
const styles = StyleSheet.create({
  body: {
    padding: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol1Header: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColHeader: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
    fontSize: 11,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    color: "#383b41",
  },
  tableCol1: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 11,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    color: "#383b41",
  },
  tableCol: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
});

export default function MatrizMas(props) {
  const createMatrix = (fila, header) => {
    var esta = false;
    props.pregunta.respuestas.map((itemResp) => {
      if (
        itemResp.titulo === fila &&
        itemResp.respuesta === header &&
        itemResp.id !== null
      ) {
        esta = true;
      }      
    });
    if (esta) {
      return (
        <Image
          style={{
            width: 15,
            height: 15,
            marginLeft: 5,
            marginTop: 7,
          }}
          src="/images/check.png"
        />
      );
    } else {
      return "";
    }
  };

  return (
    <View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol1Header}>
            <Text style={styles.tableCellHeader}></Text>
          </View>
          {props.headers.map((header, i) => {
            return (
              <View key={i} style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>
                  {header.respuesta?.replace("*", "")}
                </Text>
              </View>
            );
          })}
        </View>
        {props.subpreguntas.map((itemResp, i) => {
          return (
            <View key={i} style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}>
                  {itemResp.titulo.replace("*", "")}
                </Text>
              </View>
              {props.headers.map((header, j) => {
                return (
                  <View key={j} style={styles.tableCol}>
                    <Text style={styles.tableCell}>                      
                      {createMatrix(itemResp.titulo, header.respuesta)}
                    </Text>
                  </View>
                );
              })}
            </View>
          );
        })}
      </View>
    </View>
  );
}
