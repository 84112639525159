import React, { Component} from "react";
import * as _ from "lodash";
import {Language} from 'components/Language';

class PolarData extends Component {
  constructor(props) {
    super(props);
    this.childRef = React.createRef();
  }

  calcPos(array, posicion) {
    var pos = (500 - array[posicion]) / 2;

    return +pos.toFixed(0);
  }
  calcValor(array, posicion) {
    return array[posicion];
  }

  calcValorC(array, posicion) {
    if (array[posicion]) return array[posicion] - 15;

    return 0;
  }

  render() {
    var mSalida = this.props.dataRes;
    var mData = [];
    var mValores = [];
    mSalida.map((val) => {
      var mSumaTotal = 0;
      if (val.suma) mSumaTotal += val.suma;
      mValores.push(mSumaTotal);
      var total = 176 + (224 * mSumaTotal) / 100;

      mData.push(+total.toFixed(0));
    });

    var mSalida2 = _.groupBy(this.props.todos, "clave");
    var mData2 = [];
    var mData3 = [];
    var mValores2 = [];
    var mSumaTotalT = 0;

    Object.keys(mSalida2).map((key) => {
      var mSumaI = 0;
      mSalida2[key].map((val) => {
        if (val.suma) mSumaI += val.suma;
      });
      mSumaTotalT = mSumaI / mSalida2[key].length;
      mValores2.push(mSumaTotalT);

      var total = 176 + (224 * mSumaTotalT) / 100;

      mData3.push(+total.toFixed(0));

      mData2.push(+(total + 50).toFixed(0));
    });

    return (
      <div>
        <div id="graficoCenter" className="graficoCenter">
          <div className="centerWrapper centerWrapperimg" >
            <ul className="sliceWrapper">
              <li className="liborder">
                <div className="slice"></div>
              </li>
              <li className="liborder">
                <div className="slice"></div>
              </li>
              <li className="liborder">
                <div className="slice"></div>
              </li>
              <li className="liborder">
                <div className="slice"></div>
              </li>
              <li className="liborder">
                <div className="slice"></div>
              </li>

              <li className="liborder">
                <div className="slice"></div>
              </li>
              <li className="liborder">
                <div className="slice"></div>
              </li>
              <li className="liborder">
                <div className="slice"></div>
              </li>
              <li className="liborder">
                <div className="slice"></div>
              </li>
              <li className="liborder">
                <div className="slice"></div>
              </li>
            </ul>

            <ul
              className="sliceWrapperClave"
              style={{
                width: this.calcValor(mData, 0),
                height: this.calcValor(mData, 0),
                top: this.calcPos(mData, 0),
                left: this.calcPos(mData, 0),
              }}
            >
              <li>
                <div className="slice slicecl1"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
            </ul>
            <ul
              className="sliceWrapperClave"
              style={{
                width: this.calcValor(mData3, 0),
                height: this.calcValor(mData3, 0),
                top: this.calcPos(mData3, 0),
                left: this.calcPos(mData3, 0),
              }}
            >
              <li>
                <div className="slice slicecl1media"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
            </ul>

            <ul
              className="sliceWrapperClave"
              style={{
                width: this.calcValor(mData, 1),
                height: this.calcValor(mData, 1),
                top: this.calcPos(mData, 1),
                left: this.calcPos(mData, 1),
              }}
            >
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slice slicecl2"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
            </ul>


            <ul
              className="sliceWrapperClave"
              style={{
                width: this.calcValor(mData3, 1),
                height: this.calcValor(mData3, 1),
                top: this.calcPos(mData3, 1),
                left: this.calcPos(mData3, 1),
              }}
            >
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slice slicecl1media"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
            </ul>

            <ul
              className="sliceWrapperClave"
              style={{
                width: this.calcValor(mData, 2),
                height: this.calcValor(mData, 2),
                top: this.calcPos(mData, 2),
                left: this.calcPos(mData, 2),
              }}
            >
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
              </li>
                <div className="slice slicecl3"></div>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
            </ul>

            <ul
              className="sliceWrapperClave"
              style={{
                width: this.calcValor(mData3, 2),
                height: this.calcValor(mData3, 2),
                top: this.calcPos(mData3, 2),
                left: this.calcPos(mData3, 2),
              }}
            >
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slice slicecl1media"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
            </ul>

            <ul
              className="sliceWrapperClave"
              style={{
                width: this.calcValor(mData, 3),
                height: this.calcValor(mData, 3),
                top: this.calcPos(mData, 3),
                left: this.calcPos(mData, 3),
              }}
            >
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slice slicecl4"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
            </ul>

            <ul
              className="sliceWrapperClave"
              style={{
                width: this.calcValor(mData3, 3),
                height: this.calcValor(mData3, 3),
                top: this.calcPos(mData3, 3),
                left: this.calcPos(mData3, 3),
              }}
            >
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slice slicecl1media"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
            </ul>
            <ul
              className="sliceWrapperClave"
              style={{
                width: this.calcValor(mData, 4),
                height: this.calcValor(mData, 4),
                top: this.calcPos(mData, 4),
                left: this.calcPos(mData, 4),
              }}
            >
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slice slicecl5"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
            </ul>

            <ul
              className="sliceWrapperClave"
              style={{
                width: this.calcValor(mData3, 4),
                height: this.calcValor(mData3, 4),
                top: this.calcPos(mData3, 4),
                left: this.calcPos(mData3, 4),
              }}
            >
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slice slicecl1media"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
            </ul>

            <ul
              className="sliceWrapperClave"
              style={{
                width: this.calcValor(mData, 5),
                height: this.calcValor(mData, 5),
                top: this.calcPos(mData, 5),
                left: this.calcPos(mData, 5),
              }}
            >
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slice slicecl6"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
            </ul>

            <ul
              className="sliceWrapperClave"
              style={{
                width: this.calcValor(mData3, 5),
                height: this.calcValor(mData3, 5),
                top: this.calcPos(mData3, 5),
                left: this.calcPos(mData3, 5),
              }}
            >
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slice slicecl1media"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
            </ul>

            <ul
              className="sliceWrapperClave"
              style={{
                width: this.calcValor(mData, 6),
                height: this.calcValor(mData, 6),
                top: this.calcPos(mData, 6),
                left: this.calcPos(mData, 6),
              }}
            >
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slice slicecl7"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
            </ul>

            <ul
              className="sliceWrapperClave"
              style={{
                width: this.calcValor(mData3, 6),
                height: this.calcValor(mData3, 6),
                top: this.calcPos(mData3, 6),
                left: this.calcPos(mData3, 6),
              }}
            >
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slice slicecl1media"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
            </ul>

            <ul
              className="sliceWrapperClave"
              style={{
                width: this.calcValor(mData, 7),
                height: this.calcValor(mData, 7),
                top: this.calcPos(mData, 7),
                left: this.calcPos(mData, 7),
              }}
            >
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slice slicecl8"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
            </ul>

            <ul
              className="sliceWrapperClave"
              style={{
                width: this.calcValor(mData3, 7),
                height: this.calcValor(mData3, 7),
                top: this.calcPos(mData3, 7),
                left: this.calcPos(mData3, 7),
              }}
            >
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slice slicecl1media"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
            </ul>

            <ul
              className="sliceWrapperClave"
              style={{
                width: this.calcValor(mData, 8),
                height: this.calcValor(mData, 8),
                top: this.calcPos(mData, 8),
                left: this.calcPos(mData, 8),
              }}
            >
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slice slicecl9"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
            </ul>

            <ul
              className="sliceWrapperClave"
              style={{
                width: this.calcValor(mData3, 8),
                height: this.calcValor(mData3, 8),
                top: this.calcPos(mData3, 8),
                left: this.calcPos(mData3, 8),
              }}
            >
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slice slicecl1media"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
            </ul>

            <ul
              className="sliceWrapperClave"
              style={{
                width: this.calcValor(mData, 9),
                height: this.calcValor(mData, 9),
                top: this.calcPos(mData, 9),
                left: this.calcPos(mData, 9),
              }}
            >
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slice slicecl10"></div>
              </li>
            </ul>

            <ul
              className="sliceWrapperClave"
              style={{
                width: this.calcValor(mData3, 9),
                height: this.calcValor(mData3, 9),
                top: this.calcPos(mData3, 9),
                left: this.calcPos(mData3, 9),
              }}
            >
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>

              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slicet"></div>
              </li>
              <li>
                <div className="slice slicecl1media"></div>
              </li>
            </ul>

            <div className="elipse elipseclave1">
              <span>1</span>
            </div>
            <div className="valor valorclave1">
              <span>{this.calcValor(mValores, 0).toFixed(0)}%</span>
            </div>

            <div className="elipse elipseclave2">
              <span>2</span>
            </div>
            <div className="valor valorclave2">
              <span>{this.calcValor(mValores, 1).toFixed(0)}%{"\u00a0\u00a0"}</span>
            </div>

            <div className="elipse elipseclave3">
              <span>3</span>
            </div>
            <div className="valor valorclave3">
              <span>{this.calcValor(mValores, 2).toFixed(0)}%{"\u00a0\u00a0"}</span>
            </div>

            <div className="elipse elipseclave4">
              <span>4</span>
            </div>
            <div className="valor valorclave4">
              <span>{this.calcValor(mValores, 3).toFixed(0)}%{"\u00a0\u00a0"}</span>
            </div>

            <div className="elipse elipseclave5">
              <span>5</span>
            </div>
            <div className="valor valorclave5">
              <span>{this.calcValor(mValores, 4).toFixed(0)}%{"\u00a0\u00a0"}</span>
            </div>

            <div className="elipse elipseclave6">
              <span>6</span>
            </div>
            <div className="valor valorclave6">
              <span>{this.calcValor(mValores, 5).toFixed(0)}%</span>
            </div>

            <div className="elipse elipseclave7">
              <span>7</span>
            </div>
            <div className="valor valorclave7">
              <span>{this.calcValor(mValores, 6).toFixed(0)}%</span>
            </div>

            <div className="elipse elipseclave8">
              <span>8</span>
            </div>
            <div className="valor valorclave8">
              <span>{this.calcValor(mValores, 7).toFixed(0)}%</span>
            </div>

            <div className="elipse elipseclave9">
              <span>9</span>
            </div>
            <div className="valor valorclave9">
              <span>{this.calcValor(mValores, 8).toFixed(0)}%</span>
            </div>

            <div className="elipse elipseclave10">
              <span>10</span>
            </div>
            <div className="valor valorclave10">
              <span>{this.calcValor(mValores, 9).toFixed(0)}%</span>
            </div>

            <div id="center" style={{ zIndex: "98" }}>
              <div className="mediaGrafico">
                <Language langKey="average" />
                <br />
                {this.props.media}%
              </div>
            </div>
          </div>

          <div className="medias" id="mediasClavesPolar">
            <div className="valorm valorclavem1">
              <span>
                {mValores2 &&
                  mValores2.length > 0 &&
                  this.calcValor(mValores2, 0).toFixed(0)}
                {mValores2 && mValores2.length > 0 && <span>%</span>}
              </span>
            </div>

            <div className="valorm valorclavem2">
              <span>
                {mValores2 &&
                  mValores2.length > 0 &&
                  this.calcValor(mValores2, 1).toFixed(0)}
                {mValores2 && mValores2.length > 0 && <span>%</span>}
              </span>
            </div>

            <div className="valorm valorclavem3">
              <span>
                {mValores2 &&
                  mValores2.length > 0 &&
                  this.calcValor(mValores2, 2).toFixed(0)}
                {mValores2 && mValores2.length > 0 && <span>%</span>}
              </span>
            </div>

            <div className="valorm valorclavem4">
              <span>
                {mValores2 &&
                  mValores2.length > 0 &&
                  this.calcValor(mValores2, 3).toFixed(0)}
                {mValores2 && mValores2.length > 0 && <span>%</span>}
              </span>
            </div>

            <div className="valorm valorclavem5">
              <span>
                {mValores2 &&
                  mValores2.length > 0 &&
                  this.calcValor(mValores2, 4).toFixed(0)}
                {mValores2 && mValores2.length > 0 && <span>%</span>}
              </span>
            </div>

            <div className="valorm valorclavem6">
              <span>
                {mValores2 &&
                  mValores2.length > 0 &&
                  this.calcValor(mValores2, 5).toFixed(0)}
                {mValores2 && mValores2.length > 0 && <span>%</span>}
              </span>
            </div>

            <div className="valorm valorclavem7">
              <span>
                {mValores2 &&
                  mValores2.length > 0 &&
                  this.calcValor(mValores2, 6).toFixed(0)}
                {mValores2 && mValores2.length > 0 && <span>%</span>}
              </span>
            </div>

            <div className="valorm valorclavem8">
              <span>
                {mValores2 &&
                  mValores2.length > 0 &&
                  this.calcValor(mValores2, 7).toFixed(0)}
                {mValores2 && mValores2.length > 0 && <span>%</span>}
              </span>
            </div>

            <div className="valorm valorclavem9">
              <span>
                {mValores2 &&
                  mValores2.length > 0 &&
                  this.calcValor(mValores2, 8).toFixed(0)}
                {mValores2 && mValores2.length > 0 && <span>%</span>}
              </span>
            </div>

            <div className="valorm valorclavem10">
              <span>
                {mValores2 &&
                  mValores2.length > 0 &&
                  this.calcValor(mValores2, 9).toFixed(0)}
                {mValores2 && mValores2.length > 0 && <span>%</span>}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PolarData;