import React, {useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Language } from "components/Language";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import * as _ from "lodash";
import { preguntasActions } from "actions/preguntasActions";
import { v1 as uuidv1 } from "uuid";
import { directorActions } from "actions/directorActions";
import { useHistory } from "react-router-dom";

function EscalaNota(props) {
  const respuestas = props.respuestas;
  const history = useHistory();
  const loading = useSelector((state) => state.loading.loading);

  const [checked, setChecked] = React.useState(undefined);
  const [render, setRender] = React.useState(0);
  const [comentario, setComentario] = React.useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.respuestasCentro.length > 0)
      setChecked(props.respuestasCentro[0].respuestas_id);

    setRender(render + 1);
  }, [props.respuestasCentro]);

  const saveandexit = (e) => {
    dispatch(directorActions.saveHistorico(1, 1, "saved"));
  
    sendFormulario(e);
    history.push("/auth/start");
  }

  const sendFormulario = (e) => {
    var user = JSON.parse(localStorage.getItem("xcuserv2"));
    localStorage.setItem("hasContestado" + user.email, "true")

    if (props.isPreguntaCompleted) {
      var centro_pregunta_id;
      props.isPreguntaCompleted.map((item) => {
        if (item.preguntas_id == respuestas[0].preguntas_id) {
          centro_pregunta_id = item.id;
        }
      });
      if (centro_pregunta_id && props.respuestasCentro && props.respuestasCentro[0]) {
        dispatch(
          preguntasActions.saveRadioButton(
            checked,
            centro_pregunta_id,
            props.respuestasCentro[0].preguntas_id
          )
        );
      } else {
        dispatch(
          preguntasActions.saveRadioButtonAndPregunta(
            checked,
            props.centro,
            props.respuestas[0].preguntas_id
          )
        );
      }
    } else {
      dispatch(
        preguntasActions.saveRadioButtonAndPregunta(
          checked,
          props.centro,
          props.respuestas[0].preguntas_id
        )
      );
    }
    dispatch(
      directorActions.saveHistoricoIndicador(
        1,
        3,
        "saved",
        props.respuestas[0].preguntas_id
      )
    );
    setChecked(undefined);
    setComentario("");
  };

  var theme = createTheme({
    overrides: {
      MuiInputBase: {
        root: {
          height: "40px",
          "&:hover": {
            color: props.color,
            borderColor: props.color,
          },
        },
        input: {
          color: props.color,
          borderColor: props.color,
          "&:hover": {
            color: props.color,
            borderColor: props.color,
          },
        },
      },
      MuiFormControlLabel: {
        label: {
          color: "#383b41",
          fontFamily: "Museo Sans",
          fontWeight: 700,
          fontSize: "17px",
        },
        root: {
          marginTop: "10px",
        },
      },
      MuiFormControl: {
        root: {
          marginTop: "25px",
        },
      },
    },
  });
  return (
    <div className="topeWidth">
      {!loading && (
        <div>
          <MuiThemeProvider theme={theme}>
            <Grid
              container
              className="containerEscala"
              style={{ border: "solid 2px " + props.color, marginTop: "20px" }}
            >
              <Grid item xs={12}>
                <Grid container>
                  {respuestas != undefined ? (
                    respuestas.map((item) => {
                      var primerElemento = false;
                      var seleccionado = false;
                      if (item.id == respuestas[0].id) {
                        primerElemento = true;
                      }
                      if (item.id == checked) {
                        seleccionado = true;
                      }
                      return (
                        <Grid
                          key={uuidv1()}
                          item
                          xs
                          className="escalaNota"
                          onClick={(e) => setChecked(item.id)}
                          style={{
                            borderLeft: !primerElemento
                              ? "solid 2px " + props.color
                              : "",
                            backgroundColor:
                              item.id <= checked ? props.color : "",
                            color: item.id <= checked ? "#FFFFFF" : props.color,
                          }}
                        >
                          {item.respuesta}
                        </Grid>
                      );
                    })
                  ) : (
                    <div></div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </MuiThemeProvider>

          <Grid container justifyContent="center">
            <div className="botonesAccion gridBotones paddingBotones">
              <Button
                variant="outlined"
                onClick={props.previousPregunta}
                className="btn buttonContestarMasTarde"
                style={{ backgroundColor: "white", color: props.color }}
              >
                <Language langKey="questionary.back" />
              </Button>

              <Button
                onClick={sendFormulario}
                className="btn buttonContestarContinuar marginLeftButton"
                style={{ backgroundColor: props.color }}
              >
                <Language langKey="next" />
              </Button>
              <Button
                onClick={saveandexit}
                className="btn buttonContestarFooterDescargar butonBottomAdjust"
                style={{
                  backgroundColor: "white",
                  color: props.color,
                  border: "1px solid",
                }}
              >
              <Language langKey="questionary.exit" />
            </Button>
            </div>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default EscalaNota;