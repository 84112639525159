import React, { useEffect } from "react";
import NavBar from "../NavBar/NavBar.js";
import NavBarAuth from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import logoBertelsmann from "assets/img/logoBertelsmann.png";
import Carousel_Personal from "./Carousel_Personal.js";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { contenidoActions } from "actions/contenidoActions";
import Carousel_Testimonios from "./Carousel_Testimonios.js";
import * as _ from "lodash";
import ReactPlayer from "react-player";
import { Language } from "components/Language.js";
import { CentersAffilated } from "./components/CentersAffilated.js";

function Dashboard() {
  const dispatch = useDispatch();

  const contenidoHome = useSelector(
    (state) => state.contenidoReducer.homeContent
  );
  const getCentrosHome = useSelector(
    (state) => state.contenidoReducer.getCentrosHome
  );

  const locale = useSelector((state) => state.setting.locale);

  useEffect(() => {
    dispatch(contenidoActions.contenidosHome());
  }, [locale]);

  useEffect(() => {
    dispatch(contenidoActions.getCentrosHome());

    return () => {
      window.scrollTo({top: 0});
    }
   }, []);

  const getContent = (bloque) => {
    if(!contenidoHome) return {};

    const mSalida = _.filter(contenidoHome, {
      home: 1,
      carrusel: 0,
      bloque: bloque,
    });

    return mSalida[0];
  };

  const getCarrusel = () => {
    var mSalida = _.filter(contenidoHome, {
      home: 1,
      carrusel: 1,
    });
    
    let mSalidaOrder = _.sortBy(mSalida, (o) => o.orden);

    return mSalidaOrder;
  };

  const slidesColegios = [];

  if (getCentrosHome && getCentrosHome.length > 0) {
    getCentrosHome.forEach((item) => {
      slidesColegios.push({ name: item.nombre, title: "" });
    });
  }

  return (
    <div>
      { localStorage.getItem("xcauth") &&
        localStorage.getItem("xcauth") === "true" 
          ? <NavBarAuth />
          : <NavBar />
      }

      <div>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          className="bgGrey"
          alignItems="center"
        >
          <Grid item xs={12} className="hPaddingCarrusel">
            {contenidoHome && (
              <Carousel_Personal data={contenidoHome ? getCarrusel() : []} />
            )}
          </Grid>
        </Grid>
        {/*
        Xcelence
      */}
        <div className="bgWhite">
          <Grid
            container
            spacing={3}
            className="bgGrey cornerHomeLeft homeContainer"
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className="imgCenter"
            >
              <div className="imgHomeVideo">
                <ReactPlayer
                  width="100%"
                  height="100%"
                  url="https://www.youtube.com/watch?v=Tux4vFNWups"
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className="itemHomeResp"
            >
              <div className="marginRightHome">
                <div className="h1home padBottomHome">
                  {contenidoHome && getContent("1").titulo}
                </div>
                <div className="subh1home padBottomHome">
                  {contenidoHome && getContent("1").subtitulo}
                </div>
                <div className="txtHome padBottomHomeMore">
                  {contenidoHome && getContent("1").cuerpo}
                </div>
                <div className="btnCenterResponsive">
                  <a
                    className="btn btnHome"
                    href={contenidoHome && getContent("1").link}
                  >
                    {contenidoHome && getContent("1").boton}
                  </a>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        {/*
        Recursos
      */}
        <div className="bgGrey">
          <Grid
            container
            spacing={3}
            className="bgWhite cornerHomeRight homeContainer"
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className="imgCenter imgCaruselHomeResponsive"
            >
              <img
                className="imgHome"
                src={contenidoHome ? getContent("2").imagen : ""}

              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className="itemHomeResp"
            >
              <div className="marginLeftHome">
                <div className="h1home padBottomHome">
                  {contenidoHome ? getContent("2").titulo : ""}
                </div>
                <div className="subh1home padBottomHome">
                  {contenidoHome ? getContent("2").subtitulo : ""}
                </div>
                <div className="txtHome padBottomHomeMore">
                  {contenidoHome ? getContent("2").cuerpo : ""}
                </div>
                <div className="btnCenterResponsive">
                  <a
                    className="btn btnHome"
                    href={contenidoHome ? getContent("2").link : ""}
                  >
                    {contenidoHome ? getContent("2").boton : ""}
                  </a>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className="imgCenter imgCaruselHomeNoResponsive"
            >
              <img
                className="imgHome"
                src={contenidoHome ? getContent("2").imagen : ""}

              />
            </Grid>
          </Grid>
        </div>
        {/*
        Practicas
      */}
        <div className="bgWhite">
          <Grid
            container
            spacing={3}
            className="bgGrey cornerHomeLeft homeContainer"
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className="imgCenter"
            >
              <img
                className="imgHome"
                src={contenidoHome ? getContent("3").imagen : ""}
                sizes="100vw"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className="itemHomeResp"
            >
              <div className="marginRightHome">
                <div className="h1home padBottomHome">
                  {contenidoHome ? getContent("3").titulo : ""}
                </div>
                <div className="subh1home padBottomHome">
                  {contenidoHome ? getContent("3").subtitulo : ""}
                </div>
                <div className="txtHome padBottomHomeMore">
                  {contenidoHome ? getContent("3").cuerpo : ""}
                </div>
                <div className="btnCenterResponsive">
                  <a
                    className="btn btnHome"
                    href={contenidoHome ? getContent("3").link : ""}
                  >
                    {contenidoHome ? getContent("3").boton : ""}
                  </a>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* cuarto bloque */}
        <div className="bgGrey">
          <Grid
            container
            spacing={3}
            className="bgWhite cornerHomeRight homeContainer"
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className="imgCenter imgCaruselHomeResponsive"
            >
              <img
                className="imgHome"
                src={contenidoHome ? getContent("4").imagen : ""}
                sizes="100vw"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className="itemHomeResp"
            >
              <div className="marginLeftHome">
                <div className="h1home padBottomHome">
                  {contenidoHome ? getContent("4").titulo : ""}
                </div>
                <div className="subh1home padBottomHome">
                  {contenidoHome ? getContent("4").subtitulo : ""}
                </div>
                <div className="txtHome padBottomHomeMore">
                  {contenidoHome ? getContent("4").cuerpo : ""}
                </div>
                <div className="btnCenterResponsive">
                  {getContent("4").link?.startsWith('http')
                    ? (
                      <a
                        className="btn btnHome"
                        target="_blank"
                        href={getContent("4").link}
                      >
                        {getContent("4").boton}
                      </a>
                    )
                    : (
                      <Link
                        className="btn btnHome"
                        to={{pathname: getContent("4").link}}
                      >
                        {getContent("4").boton}
                      </Link>
                    )
                  }
                  
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className="imgCenter imgCaruselHomeNoResponsive"
            >
              <img
                className="imgHome"
                src={contenidoHome ? getContent("4").imagen : ""}
                sizes="100vw"
              />
            </Grid>
          </Grid>
        </div>

        <CentersAffilated />

        <div className="bgGrey">
          <Grid
            container
            spacing={3}
            style={{paddingBottom: 5}}
            className="bgWhite  homeContainer"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="alignCenter"
            >
              <div className="h1home padBottomHome">
                <Language langKey="dashboard.impact" />
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="alignCenter"
            >
              <div className="marginRightHome marginLeftHome">
                <Carousel_Testimonios />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="bgWhite">
          <Grid
            container
            spacing={3}
            className="bgWhite homeContainer homeContainerLogo"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="subh1home">
                <Language langKey="dashboard.owner" />
              </div>
              <img src={logoBertelsmann} className="logoBertelsHome" />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
