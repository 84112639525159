import React, { useState, useEffect } from "react";
import NavBarSimple from "../NavBar/NavBarAuth.js";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "actions/userActions";
import masterData from "constants/masterData";
import { useHistory } from "react-router-dom";
import { Input } from 'antd';
import {Language, getRawMessage} from 'components/Language';

import ArrowAzul from "assets/css/ArrowAzul.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

const { TextArea } = Input;

function RegisterInvestigador() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [sending, setSending] = useState(false);
  const [valid, setValid] = useState(false);
  const [terminos, setTerminos] = useState(false);
  const [nombre, setNombre] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [email, setEmail] = useState("");
  const [mensaje, setMensaje] = useState("");

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [mailError, setMailError] = useState(false);

  const [mailExiste, setMailExiste] = useState(false);

  const [saveError, setSaveError] = useState(false);
  const locale = useSelector((state) => state.setting.locale);

  const [language, setLanguage] = useState(locale);

  const setChangeTerminos = (event) => {
    setTerminos(event.target.checked);
  };
  const setChangeNombre = (event) => {
    setNombre(event.target.value);
  };
  const setChangeApellidos = (event) => {
    setApellidos(event.target.value);
  };
  const setChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const setChangeMensaje = (event) => {
    setMensaje(event.target.value);
  };

  var emailvalid = useSelector((state) => state.authReducer.emailvalid);
  var emailerror = useSelector((state) => state.authReducer.emailerror);

  var altaInvestigadorSuccess = useSelector(
    (state) => state.authReducer.altaInvestigadorSuccess
  );
  var altaInvestigadorError = useSelector(
    (state) => state.authReducer.altaInvestigadorError
  );

  const noValidEmail = function(mEmail) {
    var noValid = false;
    var EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (!mEmail.match(EMAIL_REGEX)) noValid = true;

    return noValid;
  };

  const saveForm = () => {
    setSubmitted(true);

    if (email) {
      setMailError(noValidEmail(email));
      if (
        nombre !== "" &&
        nombre.length > 1 &&
        nombre.length < 40 &&
        mensaje !== "" &&
        mensaje.length > 2 &&
        mensaje.length < 40 &&
        apellidos !== "" &&
        apellidos.length > 1 &&
        apellidos.length < 60 &&
        !noValidEmail(email)
      ) {
        setValid(true);
      }
    } else {
      setValid(false);
      setMailError(true);
    }
  };
  useEffect(() => {
    if (valid) {
      dispatch(userActions.findEmail(email));
    }

    if (emailerror) {
      setMailError(true);
    }

    if (emailvalid === 0) {
      setMailError(false);
      setMailExiste(false);
      if (!sending) {
        dispatch(
          userActions.registerInvestigador({
            nombre,
            apellidos,
            email,
            perfil: masterData.PROFILE_INVESTIGADOR,
            cargo: 0,
            language,
            mensajealta: mensaje
          })
        );
        setSending(true);
      }
    }

    if (emailvalid === 1) {
      setMailError(true);
      setMailExiste(true);
      setValid(false);
    }

    if (altaInvestigadorSuccess) {
      if (altaInvestigadorSuccess.code === 200) {
        setSaveError(false);
        dispatch(userActions.endRegister());
        history.push("/user/register/okinvestigador");
      }
    }
    if (altaInvestigadorError) {
      setSaveError(true);
    }
  }, [
    valid,
    emailvalid,
    emailerror,
    altaInvestigadorSuccess,
    altaInvestigadorError,
    sending,
  ]);

  return (
    <div className="fullPage">
      <NavBarSimple />
      <div className="defaultFull">
        <Grid container spacing={3} justifyContent="center" className="defaultgrid">
          <Grid item xs={12}>
            <div className="h1home">
              <Language langKey="signup.reseacher" />
            </div>
            <div className="subh1home marginSubTitle">
              <Language langKey="signup.i_am_reseacher" />
            </div>
            <div className="txtHome marginSubTitle">
              <Language langKey="signup.reseacher_caption" />
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
            <div className="marginGrid txtHome strong">
              <Language langKey="signup.datas" />
              <p className="obtext rightd">
                * <Language langKey="required" />
              </p>
            </div>
          </Grid>
          <Grid item sm={6} md={6} lg={8} xl={8}></Grid>

          <Grid item xs={12}>
            <div className="formText"><Language langKey="signup.name" /> *</div>
            <TextField
              onChange={(e) => setChangeNombre(e)}
              id="nombre"
              name="nombre"
              defaultValue={nombre}
              error={submitted && !nombre}
              className="inputMargin widthF"
              variant="outlined"
              required
              placeholder={getRawMessage("signup.name_caption")}
            />
            {submitted && !nombre && (
              <div className="errorLayer">
                <i className="fa fa-exclamation-triangle marginerror"></i>
                <Language langKey="errors.required_name" />
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <div className="formText"><Language langKey="signup.last_name" /> *</div>
            <TextField
              onChange={(e) => setChangeApellidos(e)}
              className="inputMargin widthF"
              variant="outlined"
              defaultValue={apellidos}
              error={submitted && !apellidos}
              required
              id="apellidos"
              placeholder={getRawMessage("signup.last_name_caption")}
            />
            {submitted && !apellidos && (
              <div className="errorLayer">
                <i className="fa fa-exclamation-triangle marginerror"></i>
                <Language langKey="errors.required_last" />
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <div className="formText">
              <Language langKey="signup.email" /> *
            </div>
            <TextField
              onChange={(e) => setChangeEmail(e)}
              className="inputMargin widthF"
              variant="outlined"
              error={submitted && mailError}
              defaultValue={email}
              required
              id="email"
              placeholder={getRawMessage("signup.email_caption")}
            />
            {submitted && mailError && (
              <div className="errorLayer">
                {mailExiste ? (
                  <span>
                    <i className="fa fa-exclamation-triangle marginerror"></i>
                    <Language langKey="errors.exist_email" />
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-exclamation-triangle marginerror"></i>
                    <Language langKey="errors.center_email" />
                  </span>
                )}
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <div className="formText">
              <Language langKey="lang.label" />
            </div>
            <FormControl variant="outlined" className="mt-3">
              <Select
                labelId="demo-simple-select-outlined-label"
                className="customInput widthF border-0"
                IconComponent={ArrowAzul}
                id="demo-simple-select-outlined"
                value={language}
                onChange={(value) => setLanguage(value.target.value)}
              >
                <MenuItem value="ES">
                  <Language langKey="lang.es" />
                </MenuItem>
                <MenuItem value="CA">
                  <Language langKey="lang.ca" />
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div className="formText">
              <Language langKey="signup.reason" /> *
            </div>
            <TextArea
              onChange={(e) => setChangeMensaje(e)}
              className="inputMargin widthF cTextArea"
              rows={3}
              defaultValue={mensaje}
              id="mensaje"
              placeholder={getRawMessage("signup.reason_caption")}
            />
            {submitted && !mensaje && (
              <div className="errorLayer">
                <i className="fa fa-exclamation-triangle marginerror"></i>
                <Language langKey="errors.required" />
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              className="labelForm"
              error="true"
              control={
                <Checkbox
                  checked={terminos}
                  onChange={setChangeTerminos}
                  color="primary"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label={<Language langKey="signup.terms" parse />}
            />

            {submitted && !terminos && (
              <div className="errorLayer">
                <i className="fa fa-exclamation-triangle marginerror"></i>
                <Language langKey="errors.terms" />
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={() => saveForm()}
              variant="contained"
              disabled={!loading ? false : true}
              className={`registerWidth ${
                !loading ? "btnHome" : "btnDisabled"
              } btnHomePadding`}
            >
              <Language langKey="next" />
            </Button>
            {loading && (
              <CircularProgress size={24} className="centerLoading" />
            )}

            {saveError && (
              <div className="errorLayer">
                <i className="fa fa-exclamation-triangle marginerror"></i>
                <Language langKey="errors.internal" />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default RegisterInvestigador;
