import React from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import {getRawMessage, Language} from 'components/Language';
import * as _ from "lodash";

const MenuItem = ({ nombre, opinion, cargo, puntos }) => {
  return (
    <div>
      <div className="bubbleTestimonial">
        <div className="nombreTestimonio">{nombre}</div>
        <div className="cargoTestimonio">{cargo}</div>
        <div className="stars">
          <i
            className={`fa fa-star ${puntos > 0 ? "starok" : "starno"}`}
            style={{ marginLeft: 5 }}
          ></i>
          <i
            className={`fa fa-star ${puntos > 1 ? "starok" : "starno"}`}
            style={{ marginLeft: 5 }}
          ></i>
          <i
            className={`fa fa-star ${puntos > 2 ? "starok" : "starno"}`}
            style={{ marginLeft: 5 }}
          ></i>
          <i
            className={`fa fa-star ${puntos > 3 ? "starok" : "starno"}`}
            style={{ marginLeft: 5 }}
          ></i>
          <i
            className={`fa fa-star ${puntos > 4 ? "starok" : "starno"}`}
            style={{ marginLeft: 5 }}
          ></i>
        </div>
        <div className="opinionTestimonio">"{opinion}"</div>
      </div>
    </div>
  );
};

export const Menu = (list, selected) =>
  list.map((el, i) => {
    const { nombre, opinion, cargo, puntos } = el;

    return (
      <MenuItem
        opinion={opinion}
        puntos={puntos}
        nombre={nombre}
        cargo={cargo}
        key={i}
        selected={selected}
      />
    );
  });

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};

export const ArrowLeft = Arrow({ text: "", className: "arrow-prevc" });
export const ArrowRight = Arrow({ text: "", className: "arrow-nextc" });

function Carousel_Testimonios() {
  const content = [
    {
      pos: 1,
      puntos: 5,
      nombre: "Paloma Mateos Aguado",
      cargo: <Language langKey="testimony.one.position" />,
      opinion: <Language langKey="testimony.one.comment" />
    },
    {
      pos: 2,
      puntos: 4,
      nombre: "Eva Díaz",
      cargo: <Language langKey="testimony.two.position" />,
      opinion: <Language langKey="testimony.two.comment" />
    },
    {
      pos: 3,
      puntos: 5,
      nombre: "José Luis Barrejón",
      cargo: <Language langKey="testimony.three.position" />,
      opinion: <Language langKey="testimony.three.comment" />
    },
    {
      pos: 4,
      puntos: 4,
      nombre: "Manuel Alpañés",
      cargo: <Language langKey="testimony.four.position" />,
      opinion: <Language langKey="testimony.four.comment" />
    },
    {
      pos: 5,
      puntos: 4,
      nombre: "Jesús Abenza",
      cargo: <Language langKey="testimony.five.position" />,
      opinion: <Language langKey="testimony.five.comment" />
    },
    {
      pos: 6,
      puntos: 5,
      nombre: "Elvira Congosto",
      cargo: <Language langKey="testimony.six.position" />,
      opinion: <Language langKey="testimony.six.comment" />
    },
    {
      pos: 7,
      puntos: 4,
      nombre: "Coral Regi",
      cargo: <Language langKey="testimony.seven.position" />,
      opinion: <Language langKey="testimony.seven.comment" />
    },
    {
      pos: 8,
      puntos: 4,
      nombre: "Genís Barnosell Jordà",
      cargo: <Language langKey="testimony.eight.position" />,
      opinion: <Language langKey="testimony.eight.comment" />
    },
    {
      pos: 9,
      puntos: 4,
      nombre: "Ignasi Martí",
      cargo: <Language langKey="testimony.nine.position" />,
      opinion: <Language langKey="testimony.nine.comment" />
    },
    {
      pos: 10,
      puntos: 4,
      nombre: "Meritxell Ruiz",
      cargo: <Language langKey="testimony.ten.position" />,
      opinion: <Language langKey="testimony.ten.comment" />
    },
    {
      pos: 11,
      puntos: 4,
      nombre: "Aleida Lorenzo",
      cargo: <Language langKey="testimony.eleven.position" />,
      opinion: <Language langKey="testimony.eleven.comment" />
    },
  ];

  const testim = _.shuffle(content);
  const menuItemsS = Menu(testim.slice(0, 6));

  return (
    <ScrollMenu
      data={menuItemsS}
      arrowLeft={ArrowLeft}
      arrowRight={ArrowRight}
    />
  );
}

export default Carousel_Testimonios;
