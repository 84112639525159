import React, {useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import * as _ from "lodash";
import { preguntasActions } from "actions/preguntasActions";
import Radio from "@material-ui/core/Radio";
import { directorActions } from "actions/directorActions";
import { Language } from "components/Language";
import { useHistory } from "react-router-dom";

function RadioButton(props) {
  const respuestas = props.respuestas;
  const history = useHistory();
  const loading = useSelector((state) => state.loading.loading);

  const [checked, setChecked] = React.useState(undefined);
  const [render, setRender] = React.useState(0);
  const [comentario, setComentario] = React.useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    //console.log("RADIO BUTTON");
    if (props.respuestasCentro.length > 0) {
      setChecked(props.respuestasCentro[0].respuestas_id);
    }

    setRender(render + 1);
  }, [props.respuestasCentro]);
  const handleChange = (e) => {
    setChecked(e.target.value);

    setRender(render + 1);
  };



  const saveandexit = (e) => {
    dispatch(directorActions.saveHistorico(1, 1, "saved"));

  
    sendFormulario(e);
      history.push("/auth/start");
  }


  const sendFormulario = (e) => {
    var user = JSON.parse(localStorage.getItem("xcuserv2"));
    localStorage.setItem("hasContestado" + user.email, "true")
    //console.log("lista a enviar: " + listaRespuestasActivas)
    if (props.isPreguntaCompleted) {
      var centro_pregunta_id;
      props.isPreguntaCompleted.map((item) => {
        if(item.preguntas_id==respuestas[0].preguntas_id){
          centro_pregunta_id = item.id
        }
      })
      if(centro_pregunta_id){
        dispatch(
          preguntasActions.saveRadioButton(
            checked,
            centro_pregunta_id,
            props.respuestas[0].preguntas_id
          )
        );
      }else{
        dispatch(
          preguntasActions.saveRadioButtonAndPregunta(
            checked,
            props.centro,
            props.respuestas[0].preguntas_id
          )
        );
      }

    } else {
      dispatch(
        preguntasActions.saveRadioButtonAndPregunta(
          checked,
          props.centro,
          props.respuestas[0].preguntas_id
        )
      );
    }

    dispatch(
      directorActions.saveHistoricoIndicador(
        1,
        3,
        "saved",
        props.respuestas[0].preguntas_id
      )
    );
    setChecked(undefined);
    setComentario("");
  };

  var theme = createTheme({
    overrides: {
      MuiInputBase: {
        root: {
          height: "40px",
          "&:hover": {
            color: props.color,
            borderColor: props.color,
          },
        },
        input: {
          color: props.color,
          borderColor: props.color,
          "&:hover": {
            color: props.color,
            borderColor: props.color,
          },
        },
      },
      MuiFormControlLabel: {
        label: {
          color: "#383b41",
          fontFamily: "Museo Sans",
          fontWeight: 700,
          fontSize: "17px",
        },
        root: {
          marginTop: "10px",
        },
      },
      MuiFormControl: {
        root: {
          marginTop: "25px",
        },
      },
    },
  });

  return (
    <div className="topeWidth">
      {loading ? (
       <div></div>
      ) : (
        <div>
          <MuiThemeProvider theme={theme}>
            <FormControl component="fieldset">
              <FormGroup>
                {respuestas != undefined ? (
                  respuestas.map((item, i) => {
                    return (
                      <FormControlLabel key={i}
                        control={
                          <Radio
                            checked={checked == item.id}
                            onChange={handleChange}
                            value={item.id}
                            name="radio-button"
                            style={{ color: props.color }}
                          />
                        }
                        label={item.respuesta}
                      />
                    );
                  })
                ) : (
                  <div></div>
                )}
              </FormGroup>
            </FormControl>
          </MuiThemeProvider>

          <Grid container justifyContent="center">
            <div className="botonesAccion gridBotones paddingBotones">
              <Button
                variant="outlined"
                onClick={props.previousPregunta}
                className="btn buttonContestarMasTarde"
                style={{ backgroundColor: "white", color: props.color }}
              >
                <Language langKey="questionary.back" />
              </Button>

              <Button
                onClick={sendFormulario}
                className="btn buttonContestarContinuar marginLeftButton"
                style={{ backgroundColor: props.color }}
              >
                <Language langKey="next" />
              </Button>
              <Button
              onClick={saveandexit}
              className="btn buttonContestarFooterDescargar butonBottomAdjust"
              style={{
                backgroundColor: "white",
                color: props.color,
                border: "1px solid",
              }}
            >
              <Language langKey="questionary.exit" />
            </Button>
            </div>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default RadioButton;