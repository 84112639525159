import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import { v1 as uuidv1 } from "uuid";
import { NavLink, Link } from "react-router-dom";
import * as _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { toolkitsActions } from "actions/toolkitsActions";
import NavBar from "../NavBar/NavBar.js";
import { claveActions } from "actions/claveActions";
import NavBarAuth from "../NavBar/NavBarAuth.js";
function ViewToolkit(props) {
  const dispatch = useDispatch();
  const [printToolkit, setPrintToolkit] = React.useState(null);
  const [toolkit, setToolkit] = React.useState(null);
  var getToolkits = useSelector((state) => state.toolkitsReducer.toolkits);
  const {
    match: { params },
  } = props;
  var claves = useSelector((state) => state.claveReducer.claveList);
  var clavesId = [];
  if (claves && claves.length > 0) clavesId = _.groupBy(claves, "id");
  useEffect(() => {
    dispatch(claveActions.getClaves());
    dispatch(toolkitsActions.getToolkits());
  }, []);

  useEffect(() => {
    if (getToolkits) {
      getToolkits.map((item) => {
        if (item.id == props.match.params.id) {
          // console.log(item);
          setToolkit(item);
          setPrintToolkit(item.cuerpo);
        }
      });
    }
  }, [getToolkits]);

  return (
    <div>
      {localStorage.getItem("xcauth") &&
      localStorage.getItem("xcauth") === "true" ? (
        <NavBarAuth />
      ) : (
        <NavBar />
      )}
      {clavesId && clavesId.length > 0 && toolkit && (
        <div className="content viewToolkit subh1home">
          Clave {clavesId[toolkit.claves_id][0].num}:{" "}
          {clavesId[toolkit.claves_id][0].titulo}
        </div>
      )}
      {printToolkit && (
        <div
          className="content viewToolkit"
          dangerouslySetInnerHTML={{ __html: printToolkit }}
        ></div>
      )}
    </div>
  );
}
export default withTranslation()(ViewToolkit);
