import axios from "axios";

export const xcService = {
  get,
  post,
  put,
  patchToken,
  postToken,
  getToken,
  putToken,
  deleteToken,
  getTokenWithBody
};

const baseUrl = process.env.REACT_APP_API; 

async function get(apiEndpoint) {
  return axios
    .get(baseUrl + apiEndpoint, getOptions())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

async function getToken(apiEndpoint) {
  return axios
    .get(baseUrl + apiEndpoint, getOptions())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

async function getTokenWithBody(payload, apiEndpoint) {
  return axios
    .get(baseUrl + apiEndpoint, payload, getOptions())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

async function postToken(apiEndpoint, payload) {
  return axios
    .post(baseUrl + apiEndpoint, payload, getOptions())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

function post(apiEndpoint, payload) {
  return axios
    .post(baseUrl + apiEndpoint, payload, getOptions())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

function put(apiEndpoint, payload) {
  return axios
    .put(baseUrl + apiEndpoint, payload, getOptions())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

function putToken(apiEndpoint, payload) {
  return axios
    .put(baseUrl + apiEndpoint, payload, getOptions())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

async function patchToken(apiEndpoint, payload) {
  return axios
    .patch(baseUrl + apiEndpoint, payload, getOptions())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

async function deleteToken(apiEndpoint) {
  return axios
    .delete(baseUrl + apiEndpoint, getOptions())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

const getOptions = () => ({
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": localStorage.getItem("lang") ?? 'ES',
    Authorization: "bearer " + localStorage.getItem("xctoken"),
  }
});

export const downloadExcel = async (url) => {
  const xhr = new XMLHttpRequest();
  xhr.responseType = "arraybuffer";
  xhr.open("GET", baseUrl + url);
  xhr.setRequestHeader(
    'Authorization', `bearer ${localStorage.getItem("xctoken")}`
  )

  await new Promise((resolve, reject) => {
    xhr.onload = function () {
      if (this.status === 200) {
        const blob = new Blob(
          [xhr.response],
          {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }
        );

        const objectUrl = URL.createObjectURL(blob);

        window.open(objectUrl);
        resolve();
      }
    };

    xhr.onerror = (error) => reject(error);

    xhr.send();
  });
}

export const postDownloadFile = async (
  url,
  body = {},
  type = 'application/pdf',
) => {
  const xhr = new XMLHttpRequest();

  xhr.responseType = "arraybuffer";
  xhr.open("POST", baseUrl + url);
  xhr.setRequestHeader(
    'Authorization', `bearer ${localStorage.getItem("xctoken")}`,
  )
  xhr.setRequestHeader('Content-type', 'application/json');

  await new Promise((resolve, reject) => {
    xhr.onload = function () {
      if (this.status === 200) {
        const blob = new Blob([xhr.response], {type});

        const objectUrl = URL.createObjectURL(blob);

        window.open(objectUrl);
        resolve();
      }
    };

    xhr.onerror = (error) => reject(error);

    xhr.send(JSON.stringify(body));
  });
}

