import {StyleSheet} from "@react-pdf/renderer";

export default StyleSheet.create({
  page: {
    paddingBottom: 45,
    backgroundColor: "#ffffff",
  },
  pageRes: {
    paddingBottom: 45,

    backgroundColor: "#ffffff",
  },
  headerSection: {
    flexDirection: "row",
  },
  section: {
    paddingTop: 15,
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  imagefund: {
    width: 90,
  },
  imagelogo: {
    width: 130,
    height: 95,
    marginHorizontal: 200,
  }, 
  imageRosco: {
    width: 220,
    height: 219,
    marginVertical: 30,
    marginHorizontal: 150,
  },
  centerhead: {
    textAlign: "center",
    marginTop: 30,
    paddingHorizontal: 35,
  },
  centerjust: {
    textAlign: "justify",
    marginTop: 30,
    paddingHorizontal: 35,
  },
  onlycenter: {
    marginTop: 30,
    paddingHorizontal: 35,
  },
  centro: {
    marginHorizontal: 50,
    color: "#dc0740",
    marginBottom: 30,
    marginTop: 10,
    fontSize: 24,
    fontFamily: "Roboto-Bold",
  },
  onetitle: {
    marginBottom: 30,
    marginTop: 10,
    fontSize: 20,
    fontWeight: 700,
    margin: 10,
    padding: 10,
    textAlign: "center",
    fontFamily: "Roboto-Bold",
  },
  onetitle2: {
    marginBottom: 30,
    marginTop: 10,
    fontSize: 16,
    fontWeight: 700,
    margin: 10,
    padding: 10,
    textAlign: "center",
    fontFamily: "Roboto-Bold",
  },
  onetitleSub: {
    marginBottom: 30,
    marginTop: 10,
    fontSize: 11,
    fontWeight: 700,
    margin: 10,
    padding: 10,
    textAlign: "center",
    fontFamily: "Roboto-Bold",
  },
  normalsmall: {
    fontSize: 11,
    margin: 5,
    padding: 5,
    fontWeight: 300,
    textAlign: "justify",
    fontFamily: "Roboto-Light",
  },
  normalsmallp: {
    fontSize: 9,
    margin: 5,
    padding: 5,
    fontWeight: 300,
    textAlign: "justify",
    fontFamily: "Roboto-Light",
  },
  normalsmallbold: {
    fontSize: 14,
    margin: 5,
    padding: 5,
    fontWeight: "bold",
    textAlign: "justify",
    fontFamily: "Roboto-Bold",
  },
  smallClaves: {
    fontSize: 9,
    margin: 5,
    padding: 5,
    fontWeight: 500,
    textAlign: "justify",
    fontFamily: "Roboto-Bold",
  },
  normalstrong: {
    fontSize: 15,
    margin: 5,
    padding: 5,
    fontWeight: "700",
    textAlign: "justify",
    fontFamily: "Roboto-Bold",
  },
  headerfixed: {
    height: 40,
    paddingTop: 10,
    backgroundColor: "#dc0740",
  },
  imagelogoheader: {
    width: 90,
    marginHorizontal: 240,
  },
  grid: {
    flexDirection: "row",
  },
  row: {
    flexDirection: "row",
  },
  cell: {
    textAlign: "left",
  },
  tableRes: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    padding: 0,
    marginLeft: 20,
    borderColor: "#f0f1f2",
    borderRadius: 4,
    backgroundColor: "#fcfcfc",
  },
  tableResHead: {
    display: "table",
    width: "auto",
    padding: 1,
    marginLeft: 20,
    borderRadius: 24,
    border: 0,
    backgroundColor: "#ffffff",
  },
  tableRowResHead: {
    margin: "auto",
    width: "auto",
    flexDirection: "row",
  },
  tableColResSimpleHead: {
    width: "20%",
    color: "#383b41",
    backgroundColor: "#ffffff",
    fontSize: 13,
    fontWeight: 500,
    textAlign: "center",
    fontFamily: "Roboto-Bold",
  },
  tableColResHead: {
    width: "80%",
    backgroundColor: "#ffffff",
    color: "#383b41",
    fontSize: 13,
    fontWeight: 500,
    textAlign: "justify",
    fontFamily: "Roboto-Bold",
  },
  tableRowRes: {
    margin: "auto",
    width: "auto",
    flexDirection: "row",
    fontSize: 9,
    padding: 0,
    fontWeight: 300,
    textAlign: "justify",
    fontFamily: "Roboto-Light",
  },
  tableColResSimple: {
    width: "20%",
    backgroundColor: "#ffffff",
    borderStyle: "solid",
    borderColor: "#f4f5f7",
    borderWidth: 1,
    fontSize: 13,
    fontWeight: 500,
    textAlign: "center",
    fontFamily: "Roboto-Bold",
  },
  tableColRes: {
    width: "80%",
    backgroundColor: "#f0f1f2",
    borderStyle: "solid",
    borderColor: "#f4f5f7",
    borderWidth: 1,
    fontSize: 11,
    fontWeight: 400,
    textAlign: "justify",
    fontFamily: "Roboto-Light",
  },
  tableColResFull: {
    width: "100%",
    backgroundColor: "#fcfcfc",
    borderStyle: "solid",
    borderColor: "#f4f5f7",
    borderWidth: 1,
  },
  tableResH: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    padding: 1,
    marginTop: 10,
    marginLeft: 10,
    backgroundColor: "#f4f5f7",
  },
  tableColResSimpleH: {
    width: 70,
    textAlign: "center",
    fontSize: 9,
    padding: 5,
    fontWeight: 700,
    fontFamily: "Roboto-Bold",
  },
  tableColResH: {
    width: 250,
    textAlign: "center",
    fontSize: 9,
    padding: 5,
    fontWeight: 700,
    fontFamily: "Roboto-Bold",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "55%",
    borderStyle: "solid",
    borderWidth: 0,
  },
  tableColM: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 0,
    paddingLeft: 15,
    flexDirection: "row",
  },
  tableColEnd: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 0,
    fontSize: 8,
    padding: 9,
    fontWeight: 500,
    textAlign: "justify",
    fontFamily: "Roboto-Bold",
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 8,
    padding: 5,
    fontWeight: 300,
    textAlign: "justify",
    fontFamily: "Roboto-Light",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    fontWeight: 600,
    fontFamily: "Roboto-Bold",
    bottom: 30,
    left: 0,
    right: 0,
    marginRight: 20,
    textAlign: "right",
    color: "#000000",
  },
  piePag: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
  },
});
