import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";

export default function* watcherCalculate() {
  yield takeEvery(
    actionTypes.PUNTUAR, 
    calculatePunctuation
    );
}

function* calculatePunctuation({ payload }) {
  try {    
    let apiEndpoint = "/v15/calculate";
    const res = yield call(() =>    
        new Promise((resolve, reject) => {
          xcService.postToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      // yield put({ type: "SET_PUNTUAR", payload: res.data });
      console.log(res.data);
    } 
    else {      
      yield put({
        type: actionTypes.CLAVES_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.CLAVES_ERROR, payload: e });
  }
} 