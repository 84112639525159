import React, { useState, useEffect, useCallback, useRef } from "react";
import NavBarAuth from "../../NavBar/NavBarAuth.js";
import {
  Page,
  Text,
  View,
  Font,
  Image,
  Document,
  PDFDownloadLink,
  Link,
  BlobProvider,
} from "@react-pdf/renderer";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import * as _ from "lodash";
import { v1 as uuidv1 } from "uuid";
import { datosActions } from "actions/datosActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import Feedback from "./Feedback";
import Multiple from "./Multiple";
import Normal from "./Normal";
import Matriz from "./Matriz";
import Rating from "./Rating";
import MatrizMas from "./MatrizMas";
import Polar from '../Polar2';

import {getRawMessage, Language} from "components/Language";

import BarChart from "../chart/BarChart";
import styles from './report.styles';

Font.register({
  family: "Roboto-Light",
  weight: "100,200,300, 400",
  src: "/fonts/Roboto-Light.ttf",
});
Font.register({
  family: "Roboto-Bold",
  weight: "400,500,700",
  src: "/fonts/Roboto-Bold.ttf",
});
Font.register({
  family: "Roboto-LightItalic",
  src: "/fonts/Roboto-LightItalic.ttf",
});
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const urlServer = window?.location.protocol + "//" + window?.location.host;

const getPuntuationBar = (puntuation = 0, wieght = 0, bars = 4) => {
  let lightBars = Math.round(bars * puntuation/wieght)
  const resp = [];

  for(let i = bars;  i > 0; i--) {
    if(lightBars > 0) {
      resp.unshift(1);
      lightBars--;
    } else resp.unshift(0.2);
  }

  return resp;
}

// Create Document Component
const MyDocument = (
  datosCentro,
  resultadosPreguntaCentroClave,
  mTrata,
  image,
  graAbandono,
  graRep,
  datosGraficoData,
  locale
) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.headerSection}>
        <View style={styles.section}>
          <Image style={styles.imagefund} src="/images/logobertelsmann.png" />
        </View>
        <View style={styles.section}></View>
      </View>
      <View style={styles.centerhead} fixed>
        <Image style={styles.imagelogo} src="/images/logo.png" />
        <Image style={styles.imageRosco} src={`/${locale}/images/key_wheel.png`} />
        <Text>{getRawMessage("announce.title")}</Text>
        <Text style={styles.centro}>{datosCentro?.[0]?.nombre}</Text>
        <Text>{getRawMessage("announce.subtitle")}</Text>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View fixed style={styles.headerfixed}>
        <Image style={styles.imagelogoheader} src="/images/logoXcelence.png" />
      </View>
      <View style={styles.centerhead}>
        <Text style={styles.onetitle}>
          {getRawMessage("announce.page_one.title")}
        </Text>
        <Text style={styles.normalsmall}>
          {getRawMessage("announce.page_one.param_one")}
        </Text>
        <Text style={styles.normalsmall}>
          {getRawMessage("announce.page_one.param_two")}
        </Text>
        <Text style={styles.normalsmall}>
          {getRawMessage("announce.page_one.param_three")}
        </Text>
        <Text style={styles.normalsmall}>
          {getRawMessage("announce.page_one.param_four")}
        </Text>
        <Text style={styles.normalsmall}>
          {getRawMessage("announce.page_one.param_five")}
        </Text>
        <Text style={styles.normalsmall}>
          {getRawMessage("announce.page_one.param_six")}
        </Text>
        <Text style={styles.onetitleSub}>
          {getRawMessage("announce.page_one.caption")}
        </Text>
        <Image style={styles.imagelogo} src="/images/logo.png" />
      </View>
      <Image fixed style={styles.piePag} src="/images/piepag.png" />
    </Page>
    <Page size="A4" style={styles.page}>
      <View fixed style={styles.headerfixed}>
        <Image style={styles.imagelogoheader} src="/images/logoXcelence.png" />
      </View>
      <View style={styles.onlycenter}>
        <Text style={styles.onetitle}>
          {getRawMessage("announce.page_two.title")}
        </Text>
        <Text style={styles.onetitle2}>
          {getRawMessage("announce.page_two.subtitle")}
        </Text>
        <Text style={styles.normalsmall}>
          {getRawMessage("announce.page_two.param_one")}
        </Text>
        <View style={{ flexDirection: "row" }}>
          <Image
            style={{
              width: 15,
              height: 15,
              marginLeft: 5,
              marginTop: 7,
            }}
            src="/images/checkg.png"
          />
          <Text style={styles.normalsmall}>
            {getRawMessage("announce.page_two.list_item_one")}
          </Text>
        </View>

        <View style={{ flexDirection: "row" }}>
          <Image
            style={{
              width: 15,
              height: 15,
              marginLeft: 5,
              marginTop: 7,
            }}
            src="/images/checkg.png"
          />
          <Text style={styles.normalsmall}>
            {getRawMessage("announce.page_two.list_item_two")}
          </Text>
        </View>
        <View style={{ flexDirection: "row" }} key={uuidv1()}>
          <Image
            style={{
              width: 15,
              height: 15,
              marginLeft: 5,
              marginTop: 7,
            }}
            src="/images/checkg.png"
          />
          <Text style={styles.normalsmall}>
            {getRawMessage("announce.page_two.list_item_three")}
          </Text>
        </View>
        <View style={{ flexDirection: "row" }} key={uuidv1()}>
          <Image
            style={{
              width: 15,
              height: 15,
              marginLeft: 5,
              marginTop: 7,
            }}
            src="/images/checkg.png"
          />
          <Text style={styles.normalsmall}>
            {getRawMessage("announce.page_two.list_item_four")}
          </Text>
        </View>

        <Text style={styles.onetitle2}>
          {getRawMessage("announce.page_two.subtitle_two")}
        </Text>
        <Text style={styles.normalsmall}>
          {getRawMessage("announce.page_two.param_two")}
        </Text>
        <Text style={styles.normalsmall}>
          {getRawMessage("announce.page_two.param_three")}
        </Text>
      </View>
      <Image fixed style={styles.piePag} src="/images/piepag.png" />
    </Page>
    <Page size="A4" style={styles.page}>
      <View fixed style={styles.headerfixed}>
        <Image style={styles.imagelogoheader} src="/images/logoXcelence.png" />
      </View>

      <View style={styles.centerjust}>
        <Text style={styles.onetitle2}>
          {getRawMessage("announce.page_three.title")}
        </Text>
        <Text style={styles.normalsmall}>
          {getRawMessage("announce.page_three.param_one")}
        </Text>
        <Text style={styles.normalsmall}>
          {getRawMessage("announce.page_three.param_two")}
        </Text>
        <Text style={styles.normalsmall}>
          {getRawMessage("announce.page_three.param_three")}
        </Text>
        <Text style={styles.onetitle2}>
          {getRawMessage("announce.page_three.list_title")}
        </Text>

        <View style={{ display: "table", width: "auto" }}>
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "33%" }}>
              <View style={{ textAlign: "center" }}>
                <Image
                  src="/images/ideap.png"
                  style={{
                    height: 50,
                    width: 50,
                    marginLeft: 50,
                  }}
                />
              </View>
              <Text style={styles.normalsmallp}>
                {getRawMessage("announce.page_three.list_item_one")}
              </Text>
            </View>
            <View style={{ width: "33%" }}>
              <View style={{ textAlign: "center" }}>
                <Image
                  src="/images/conexion.png"
                  style={{
                    height: 50,
                    width: 50,
                    marginLeft: 50,
                  }}
                />
              </View>
              <Text style={styles.normalsmallp}>
                {getRawMessage("announce.page_three.list_item_two")}
              </Text>
            </View>
            <View style={{ width: "33%" }}>
              <View style={{ textAlign: "center" }}>
                <Image
                  src="/images/carpeta.png"
                  style={{
                    height: 50,
                    width: 35,
                    marginLeft: 60,
                  }}
                />
              </View>
              <Text style={styles.normalsmallp}>
                {getRawMessage("announce.page_three.list_item_two")}
              </Text>
            </View>
          </View>
        </View>
        <Text style={styles.onetitle2}>
          {getRawMessage("announce.page_three.subtitle")}
        </Text>
        <Text style={styles.normalsmall}>
          {getRawMessage("announce.page_three.param_four")}
        </Text>
        <Text style={styles.normalsmall}>
          {getRawMessage("announce.page_three.param_five")}
        </Text>
      </View>
      <Image fixed style={styles.piePag} src="/images/piepag.png" />
    </Page>

    <Page size="A4" style={styles.page}>
      <View fixed style={styles.headerfixed}>
        <Image style={styles.imagelogoheader} src="/images/logoXcelence.png" />
      </View>
      <View style={styles.centerhead}>
        <Text
          style={{
            marginBottom: 7,
            marginTop: 10,
            fontSize: 18,
            fontWeight: 500,
            textAlign: "center",
            fontFamily: "Roboto-Bold",
          }}
        >
          {getRawMessage("announce.your_report")}
        </Text>

        <Text
          style={{
            marginBottom: 7,
            marginTop: 4,
            fontSize: 24,
            fontWeight: 700,
            textAlign: "center",
            fontFamily: "Roboto-Bold",
          }}
        >
          {datosCentro?.[0]?.nombre}
        </Text>
        <Text
          style={{
            marginBottom: 20,
            marginTop: 4,
            fontSize: 17,
            fontWeight: 500,
            textAlign: "center",
            fontFamily: "Roboto-Bold",
          }}
        >
          {getRawMessage("course")} {datosCentro?.[0]?.curso}
        </Text>

        <View
          style={{ marginTop: 5, marginLeft: 30, marginRight: 10, padding: 6 }}
        >
          <Text
            style={{
              fontSize: 12,
              fontWeight: 500,

              textAlign: "left",
              fontFamily: "Roboto-Bold",
            }}
          >
            {getRawMessage("report_date")}: {moment(new Date()).format("DD/MM/YYYY")}
          </Text>
        </View>

        <View
          style={{
            marginTop: 5,
            marginLeft: 30,
            marginRight: 10,
            padding: 6,
            backgroundColor: "#2b74c4",
            height: 30,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
          }}
        >
          <Text
            style={{
              fontSize: 10,
              fontWeight: 700,
              marginLeft: 5,
              color: "#FFFFFF",
              textAlign: "left",
              lineHeight: 1.2,
              fontFamily: "Roboto-Bold",
            }}
          >
            {datosCentro?.[0]?.nombre}
          </Text>
        </View>
        <View
          style={{
            marginLeft: 30,
            marginRight: 10,
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderColor: "#f2f2f2",
            borderWidth: 1,
            borderRightWidth: 1,
            borderBottomWidth: 1,
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                width: "40%",
                backgroundColor: "#fafbfc",
                padding: 6,
                fontSize: 10,
                fontWeight: 700,
                marginLeft: 0,
                color: "#383b41",
                textAlign: "left",
                lineHeight: 1,
                fontFamily: "Roboto-Bold",
              }}
            >
              <Text style={{ margin: 5 }}>
                {getRawMessage("ccaa")}
              </Text>
            </View>
            <View
              style={{
                width: "60%",
                backgroundColor: "#ffffff",
                padding: 6,
                fontSize: 10,
                fontWeight: 300,
                marginLeft: 0,
                color: "#383b41",
                textAlign: "left",
                lineHeight: 1,
                fontFamily: "Roboto-Light",
              }}
            >
              <Text style={{ margin: 5 }}>{datosCentro?.[0]?.comunidad}</Text>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                width: "40%",
                backgroundColor: "#fafbfc",
                padding: 6,
                fontSize: 10,
                fontWeight: 700,
                marginLeft: 0,
                color: "#383b41",
                textAlign: "left",
                lineHeight: 1,
                fontFamily: "Roboto-Bold",
              }}
            >
              <Text style={{ margin: 5 }}>
                {getRawMessage("ownership")}
              </Text>
            </View>
            <View
              style={{
                width: "60%",
                backgroundColor: "#ffffff",
                padding: 6,
                fontSize: 10,
                fontWeight: 300,
                marginLeft: 0,
                color: "#383b41",
                textAlign: "left",
                lineHeight: 1,
                fontFamily: "Roboto-Light",
              }}
            >
              <Text style={{ margin: 5 }}>
                {getRawMessage(datosCentro?.[0]?.ownership?.toLowerCase())}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                width: "40%",
                backgroundColor: "#fafbfc",
                padding: 6,
                fontSize: 10,
                fontWeight: 700,
                marginLeft: 0,
                color: "#383b41",
                textAlign: "left",
                lineHeight: 1,
                fontFamily: "Roboto-Bold",
              }}
            >
              <Text style={{ margin: 5 }}>
                {getRawMessage("center_size")}
              </Text>
            </View>
            <View
              style={{
                width: "60%",
                backgroundColor: "#ffffff",
                padding: 6,
                fontSize: 10,
                fontWeight: 300,
                marginLeft: 0,
                color: "#383b41",
                textAlign: "left",
                lineHeight: 1,
                fontFamily: "Roboto-Light",
              }}
            >
              <Text style={{ margin: 5, textTransform: 'lowercase' }}>
                {datosCentro?.[0]?.numalumnos} {getRawMessage("center_size")}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                width: "40%",
                backgroundColor: "#fafbfc",
                padding: 6,
                fontSize: 10,
                fontWeight: 700,
                marginLeft: 0,
                color: "#383b41",
                textAlign: "left",
                lineHeight: 1,
                fontFamily: "Roboto-Bold",
              }}
            >
              <Text style={{ margin: 5 }}>
                {getRawMessage("economy_grade")}
              </Text>
            </View>
            <View
              style={{
                width: "60%",
                backgroundColor: "#ffffff",
                padding: 6,
                fontSize: 10,
                fontWeight: 300,
                marginLeft: 0,
                color: "#383b41",
                textAlign: "left",
                lineHeight: 1,
                fontFamily: "Roboto-Light",
              }}
            >
              <Text style={{ margin: 5 }}>
                {datosCentro?.[0]?.nivelsocioeconomico === 1
                  ? getRawMessage("low")
                  : datosCentro?.[0]?.nivelsocioeconomico === 2
                  ? getRawMessage("middle_low")
                  : datosCentro?.[0]?.nivelsocioeconomico === 3
                  ? getRawMessage("middle")
                  : datosCentro?.[0]?.nivelsocioeconomico === 4
                  ? getRawMessage("middle_high")
                  : datosCentro?.[0]?.nivelsocioeconomico === 5
                  ? getRawMessage("high")
                  : ""
                }
              </Text>
            </View>
          </View>
        </View>

        {/* equipo */}
        <View
          style={{
            marginTop: 20,
            marginLeft: 30,
            marginRight: 10,
            padding: 6,
            backgroundColor: "#2b74c4",
            height: 30,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
          }}
        >
          <Text
            style={{
              fontSize: 10,
              fontWeight: 700,
              marginLeft: 5,
              color: "#FFFFFF",
              textAlign: "left",
              lineHeight: 1.2,
              fontFamily: "Roboto-Bold",
            }}
          >
            {getRawMessage("center_team")}
          </Text>
        </View>
        <View
          style={{
            marginLeft: 30,
            marginRight: 10,
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderColor: "#f2f2f2",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                width: "40%",
                backgroundColor: "#fafbfc",
                padding: 6,
                fontSize: 10,
                fontWeight: 700,
                marginLeft: 0,
                color: "#383b41",
                textAlign: "left",
                lineHeight: 1,
                fontFamily: "Roboto-Bold",
              }}
            >
              <Text style={{ margin: 5 }}>
                {getRawMessage("director")}
              </Text>
            </View>
            <View
              style={{
                width: "60%",
                backgroundColor: "#ffffff",
                padding: 6,
                fontSize: 10,
                fontWeight: 300,
                marginLeft: 0,
                color: "#383b41",
                textAlign: "left",
                lineHeight: 1,
                fontFamily: "Roboto-Light",
              }}
            >
              <Text style={{ margin: 5 }}>
                {datosCentro?.[0]?.nombreDirector}{" "}
                {datosCentro?.[0]?.apellidosDirector}
              </Text>
            </View>
          </View>

          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                width: "40%",
                backgroundColor: "#fafbfc",
                padding: 6,
                fontSize: 10,
                fontWeight: 700,
                marginLeft: 0,
                color: "#383b41",
                textAlign: "left",
                lineHeight: 1,
                fontFamily: "Roboto-Bold",
              }}
            >
              <Text style={{ margin: 5 }}>
                {getRawMessage("collaborator")}
              </Text>
            </View>
            <View
              style={{
                width: "60%",
                backgroundColor: "#ffffff",
                padding: 6,
                fontSize: 10,
                fontWeight: 300,
                marginLeft: 0,
                color: "#383b41",
                textAlign: "left",
                lineHeight: 1,
                fontFamily: "Roboto-Light",
              }}
            >
              {datosCentro?.[0]?.colaboradores.map((item) => (
                <Text style={{ margin: 5 }} key={uuidv1()}>
                  {item.nombre} {item.apellidos}
                </Text>
              ))}
            </View>
          </View>
        </View>

        {/* abandono */}
        <View
          style={{
            marginTop: 20,
            marginLeft: 30,
            marginRight: 10,
            padding: 6,
            backgroundColor: "#2b74c4",
            height: 30,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
          }}
        >
          <Text
            style={{
              fontSize: 10,
              fontWeight: 700,
              marginLeft: 5,
              color: "#FFFFFF",
              textAlign: "left",
              lineHeight: 1.2,
              fontFamily: "Roboto-Bold",
            }}
          >
            {getRawMessage("announce.dropout")}
          </Text>
        </View>
        <View
          style={{
            marginLeft: 30,
            marginRight: 10,
            display: "table",
            width: "auto",
            paddingTop: 10,
            borderStyle: "solid",
            borderColor: "#f2f2f2",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,
          }}
        >
          {
            datosGraficoData.length <= 2
            ?
              (
                <Image
                  style={{            
                    maxWidth: '65%',
                    height: '225px',
                    marginRight: "auto",
                    marginLeft: "auto",
                    border: "1px solid #13a59f",
                    padding: "5px",
                  }}
                  src={graAbandono}
                />
              )
            :
              (
                <Image
                  style={{
                    maxWidth: '100%',
                    height: '225px',
                    marginRight: "auto",
                    marginLeft: "auto",
                    border: "1px solid #13a59f",
                    padding: "5px",
                  }}
                  src={graAbandono}
                />
              )
          }                    
        </View>
      </View>
      <Image fixed style={styles.piePag} src="/images/piepag.png" />
    </Page>

    <Page size="A4" style={styles.page}>
      <View fixed style={styles.headerfixed}>
        <Image style={styles.imagelogoheader} src="/images/logoXcelence.png" />
      </View>
      <View style={styles.centerhead}>
        <Text
          style={{
            marginBottom: 7,
            marginTop: 10,
            fontSize: 18,
            fontWeight: 500,
            textAlign: "center",
            fontFamily: "Roboto-Bold",
          }}
        >
          {getRawMessage("announce.your_report")}
        </Text>

        <Text
          style={{
            marginBottom: 7,
            marginTop: 4,
            fontSize: 24,
            fontWeight: 700,

            textAlign: "center",
            fontFamily: "Roboto-Bold",
          }}
        >
          {datosCentro?.[0]?.nombre}
        </Text>
        <Text
          style={{
            marginBottom: 30,
            marginTop: 4,
            fontSize: 17,
            fontWeight: 500,
            textAlign: "center",
            fontFamily: "Roboto-Bold",
          }}
        >
          {getRawMessage("course")} {datosCentro?.[0]?.curso}
        </Text>

        {/* OFERTA */}
        <View
          style={{
            marginTop: 20,
            marginLeft: 30,
            marginRight: 10,
            padding: 6,
            backgroundColor: "#2b74c4",
            height: 30,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
          }}
        >
          <Text
            style={{
              fontSize: 10,
              fontWeight: 700,
              marginLeft: 5,
              color: "#FFFFFF",
              textAlign: "left",
              lineHeight: 1.2,
              fontFamily: "Roboto-Bold",
            }}
          >
            {getRawMessage("announce.repeater")}
          </Text>
        </View>
        <View
          style={{
            marginLeft: 30,
            marginRight: 10,
            paddingTop: 20,
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderColor: "#f2f2f2",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,
          }}
        >
           {
            datosGraficoData.length <= 2
            ? (<Image
                style={{            
                  maxWidth: '70%',              
                  height: '225px',
                  marginRight: "auto",
                  marginLeft: "auto",
                  border: "1px solid #13a59f",
                  padding: "5px",
                }}
                src={graRep}
                />)
            : (<Image
                style={{            
                  maxWidth: '100%',
                  height: '225px',
                  marginRight: "auto",
                  marginLeft: "auto",
                  border: "1px solid #13a59f",
                  padding: "5px",
                }}
                src={graRep}
                />)
          }
        </View>
      </View>
      <Image fixed style={styles.piePag} src="/images/piepag.png" />
    </Page>

    <Page size="A4" style={styles.page} wrap>
      <View fixed style={styles.headerfixed}>
        <Image style={styles.imagelogoheader} src="/images/logoXcelence.png" />
      </View>
      <View style={styles.centerhead}>
        <Text
          style={{
            marginBottom: 10,
            fontSize: 18,
            fontWeight: 500,
            textAlign: "center",
            fontFamily: "Roboto-Bold",
          }}
        >
          {getRawMessage("center_results")}
        </Text>

        {image && (
          <View style={{ 
            backgroundColor: 'white',
            height: 280,
            width: 350,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}>
            <Image src={image} />
          </View>
        )}

        <View style={styles.table}>
          {mTrata.map((item, i) => {
            return (
              <View key={i} style={styles.tableRow}>
                <View style={styles.tableColEnd}>
                  <View
                    style={{
                      backgroundColor: item.color,
                      width: 18,
                      height: 18,
                      borderRadius: 50,
                      fontSize: 9,
                      color: "#FFFFFF",
                    }}
                  >
                    <Text
                      style={{
                        marginTop: 3,
                        marginLeft: item.clave != 10 ? 6 : 4,
                      }}
                    >
                      {item.clave}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.smallClaves}>{item.clavenombre}</Text>
                </View>
                <View style={styles.tableColM}>
                  {Array.apply(0, Array(10)).map((x, j) => {
                    return (
                      <Text
                        key={j}
                        style={{
                          backgroundColor:
                            j < item.suma / 10 ? item.color : "#f4f5f7",
                          width: 9,
                          height: 9,
                          borderRadius: 9,
                          marginTop: 9,
                          marginLeft: 3,
                        }}
                      >
                        {j}
                      </Text>
                    );
                  })}
                </View>

                <View style={styles.tableColEnd}>
                  <Text
                    style={{
                      fontFamily: "Roboto-Bold",
                      fontSize: 9,
                      fontWeight: 700,
                      marginLeft: 3,
                      marginTop: 1,
                    }}
                  >
                    {item.suma.toFixed(0)}%
                  </Text>
                </View>
              </View>
            );
          })}
          <Text
            style={{
              marginTop: 10,
              fontFamily: "Roboto-Light",
              fontSize: 12,
              fontWeight: 300,
              fontStyle: "normal",
            }}
          >
            {getRawMessage("announce.indicator_part_one")}&nbsp;
            <Text
              style={{
                fontFamily: "Roboto-Bold",
                fontSize: 12,
                fontWeight: 700,
                fontStyle: "normal",
              }}
            >
            {getRawMessage("announce.indicator_part_two")}
            </Text>
            &nbsp;{getRawMessage("announce.indicator_part_three")}&nbsp;
            <Text
              style={{
                fontFamily: "Roboto-Bold",
                fontSize: 12,
                fontWeight: 700,
                fontStyle: "normal",
              }}
            >
              {getRawMessage("announce.indicator_part_four")}
            </Text>
          </Text>
        </View>
      </View>

      <Text
        fixed
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      />

      <Image fixed style={styles.piePag} src="/images/piepag.png" />
    </Page>

    {Object.keys(resultadosPreguntaCentroClave).map((key, item) => {
      return (
        <Page key={uuidv1()} size="A4" style={styles.pageRes} wrap>
          <View fixed style={styles.headerfixed}>
            <Image
              style={styles.imagelogoheader}
              src="/images/logoXcelence.png"
            />
          </View>

          <View style={{ backgroundColor: "#ffffff" }}>
            <View
              style={{
                display: "block",
                position: "relative",
                marginTop: 20,
                marginLeft: 40,
                marginRight: 10,
                height: 30,
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
                backgroundColor: resultadosPreguntaCentroClave[key][0].color,
              }}
            >
              <View
                style={{
                  position: "absolute",
                  height: 45,
                  width: 45,
                  borderRadius: 45,
                  border: 5,
                  borderColor: "#FFFFFF",
                  borderStyle: "solid",
                  left: -14,
                  top: -7,
                  backgroundColor: resultadosPreguntaCentroClave[key][0].color,
                }}
              >
                <Text
                  style={{
                    color: "#FFFFFF",
                    fontSize: 18,
                    fontWeight: 500,
                    marginTop: 5,
                    marginLeft:
                      resultadosPreguntaCentroClave[key][0].num === 10 ? 7 : 12,
                  }}
                >
                  {resultadosPreguntaCentroClave[key][0].num}
                </Text>
              </View>
              <Text
                style={{
                  color: "#FFFFFF",
                  fontSize: 12,
                  fontWeight: 500,
                  marginTop: 9,
                  marginLeft: 40,
                }}
              >
                {key}
              </Text>

              <View
                style={{
                  position: "absolute",
                  right: -1,
                  backgroundColor: "#000000",
                  opacity: 0.2,
                  height: 30,
                  width: 100,
                  textAlign: "center",
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5,
                }}
              >
                <View>
                  <Text
                    style={{
                      marginTop: 6,
                      color: "#FFFFFF",
                      fontSize: 12,
                      fontWeight: 500,
                      opacity: 1,
                    }}
                  >
                    {mTrata[item] ? mTrata[item].suma.toFixed(0) : 0}%
                  </Text>

                  {1 === 2 && (
                    <View
                      style={{
                        flexDirection: "row",
                        textAlign: "center",
                        marginLeft: 15,
                        marginTop: 2,
                      }}
                      key={uuidv1()}
                    >
                      {Array.apply(0, Array(10)).map(function(x, i) {
                        return (
                          <Text
                            style={{
                              backgroundColor:
                                i < mTrata[item].suma / 10
                                  ? "#FFFFFF"
                                  : "#f4f5f7",
                              opacity: i < mTrata[item].suma / 10 ? 1 : 0.2,
                              width: 4,
                              height: 4,
                              borderRadius: 4,
                              marginTop: 9,
                              marginLeft: 3,
                            }}
                            key={uuidv1()}
                          ></Text>
                        );
                      })}
                    </View>
                  )}
                </View>
              </View>
            </View>

            <View
              key={uuidv1()}
              style={{
                flexDirection: "row",
                marginTop: 14,
                marginLeft: 40,
                marginRight: 10,
              }}
            >
              <View
                style={{
                  width: 30,
                  backgroundColor: "#ffffff",
                  padding: 6,
                  fontSize: 13,
                  fontWeight: 700,
                  marginLeft: 0,
                  color: "#ffffff",
                  textAlign: "left",
                  lineHeight: 1,
                  fontFamily: "Roboto-Bold",
                }}
              >
                <Image
                  src="/images/conexion.png"
                  style={{
                    height: 15,
                    width: 15,
                  }}
                />
              </View>
              <View
                style={{
                  width: "80%",
                  backgroundColor: "#ffffff",
                  padding: 6,
                  fontSize: 13,
                  fontWeight: 300,
                  marginLeft: 0,
                  color: "#383b41",
                  textAlign: "left",
                  lineHeight: 1,
                  fontFamily: "Roboto-Light",
                }}
              >
                <Link
                  style={{
                    fontSize: 12,
                    fontWeight: 700,
                    marginTop: 7,
                    marginLeft: 10,
                    opacity: 1,
                    textDecoration: "underline",
                    color: resultadosPreguntaCentroClave[key][0].color,
                  }}
                  target="_blank"
                  href={
                    `${urlServer}/${locale}/toolkit/FichaClave${
                      resultadosPreguntaCentroClave[key][0].num}.pdf`
                  }
                >
                  {getRawMessage("announce.download_chip")}
                </Link>
              </View>
            </View>

            <View
              key={uuidv1()}
              style={{
                flexDirection: "row",
                marginTop: 6,
                marginLeft: 40,
                marginRight: 10,
              }}
            >
              <View
                style={{
                  width: 30,
                  backgroundColor: "#ffffff",
                  padding: 6,
                  fontSize: 13,
                  fontWeight: 700,
                  marginLeft: 0,
                  color: "#ffffff",
                  textAlign: "left",
                  lineHeight: 1,
                  fontFamily: "Roboto-Bold",
                }}
              >
                <Image
                  src="/images/carpeta.png"
                  style={{
                    height: 21,
                    width: 15,
                  }}
                />
              </View>
              <View
                style={{
                  heigth: 25,
                  backgroundColor: "#ffffff",
                  padding: 6,
                  fontSize: 13,
                  fontWeight: 300,
                  marginLeft: 0,
                  color: "#383b41",
                  textAlign: "left",
                  lineHeight: 1,
                  fontFamily: "Roboto-Light",
                }}
              >
                <Link
                  style={{
                    fontSize: 12,
                    fontWeight: 700,
                    marginTop: 7,
                    marginLeft: 10,
                    heigth: 25,
                    textDecoration: "underline",
                    color: resultadosPreguntaCentroClave[key][0].color,
                  }}
                  target="_blank"
                  href={
                    `${urlServer}/${locale.toLowerCase()}/recursos?${
                      resultadosPreguntaCentroClave[key][0].idclave}`
                  }
                >
                  {getRawMessage("announce.resouces_access")}
                </Link>
              </View>
            </View>

            <View style={{ marginHorizontal: 10, marginVertical: 10 }}>
              {resultadosPreguntaCentroClave[key].map((val, i) => {                
                return (
                  <View key={i} wrap={false}>
                    <View style={styles.tableResHead}>
                      <View style={styles.tableRowResHead}>
                        <View style={styles.tableColResHead} >
                          <Text style={{ padding: 10 }}></Text>
                        </View>
                        <View
                          style={styles.tableColResSimpleHead}
                        >
                          <Text style={{ padding: 10 }}></Text>
                        </View>
                      </View>
                    </View>
                    <View style={styles.tableRes}>
                      <View  style={styles.tableRowRes}>
                        <View style={styles.tableColRes} >
                          <View
                            style={{
                              paddingRight: 40,
                              paddingBottom: 8,
                              flexDirection: "row",
                              fontSize: 11,
                              fontWeight: 500,
                              textAlign: "justify",
                              fontFamily: "Roboto-Bold",
                            }}
                          >
                            <Text
                              style={{
                                paddingLeft: 10,
                                paddingTop: 10,
                              }}
                            >
                              {val.nump}{" "}
                            </Text>
                            <Text
                              style={{
                                paddingLeft: 5,
                                paddingTop: 10,
                              }}
                            >
                              {val.titulo?.replace("*", "")}                              
                            </Text>
                          </View>
                        </View>
                        {/* TÍTULO */}
                        <View style={styles.tableColResSimple} >
                          <View
                            style={{
                              pading: 3,
                              textAlign: "center",
                              margin: "auto",                              
                            }}
                          >
                            {getPuntuationBar(val.puntuacion, val.peso, 5).map((opacity, i) => 
                              <Text
                                key={i}
                                style={{
                                  backgroundColor: val.color,
                                  opacity,
                                  width: 12,
                                  height: 4,
                                  borderRadius: 5,
                                  marginTop: 1,
                                  marginBottom: 1,
                                }}
                              />
                            )}
                          </View>
                        </View>
                      </View>
                      {/* RESPUESTA */}
                      <View style={styles.tableRowRes}>
                        <View style={styles.tableColResFull} >
                          <Text
                            style={{
                              padding: 10,
                              color: "#383b41",
                              fontSize: 10,
                              textAlign: "justify",
                              fontFamily: "Roboto-LightItalic",
                            }}
                          >
                            {getRawMessage("announce.center_situation")}&nbsp;&nbsp;
                          </Text>
                          {
                            val.tipopreg === "MULTIPLE" || val.tipopreg === "OPEN" 
                            ? <Multiple pregunta={val} />
                            : val.tipopreg === "MATRIX" || val.tipopreg === "MATRIXMULTIPLE" 
                            ? (<Matriz
                                pregunta={val}
                                subpreguntas={_.uniqBy(val.respuestas, "titulo")}
                                headers={_.uniqBy(val.respuestas, "respuesta")}
                              />) 
                            : val.tipopreg === "RATING" 
                            ? <Rating pregunta={val} />
                            : val.tipopreg === "NIVEL" &&
                              (val.subtipospregunta_id === 4 ||
                              val.subtipospregunta_id === 7) 
                            ? (<MatrizMas
                                pregunta={val}
                                subpreguntas={_.uniqBy(val.respuestas, "titulo")}
                                headers={_.uniqBy(val.respuestas, "respuesta")}
                              />) 
                            : val.tipopreg === "NIVEL" && val.subtipospregunta_id === 2 
                              ? (<Multiple pregunta={val} />) 
                              : (<Normal pregunta={val} />)                          
                          }

                          {val.feedback && val.feedback.length > 0 && (
                            <Feedback feedback={val.feedback} />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
          <Text
            fixed
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
          />
          <Image fixed style={styles.piePag} src="/images/piepag.png" />
        </Page>
      );
    })}
  </Document>
);

const initialLocal = localStorage.getItem('lang') ?? 'ES';
function Informe(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  let firstChart = useRef(null);
  let secondChart = useRef(null);
  let thirdChart = useRef(null);

  const [datosGrafico, setDatosGrafico] = useState([]);
  let [completedChars, setCompletedChars] = useState(0);

  var datosCentro = useSelector((state) => state.datosReducer.datosCentroFull);
  var resultadosCentroClave = useSelector(
    (state) => state.datosReducer.resultadosCentroPeriodoClave
  );
  var resultadosPreguntaCentroClave = useSelector(
    (state) => state.datosReducer.resultadosCentroPeriodoHistoricoClave
  );
  var datosGraficoData = useSelector(
    (state) => state.datosReducer.datosGraficoData
  );

  const { id } = props.match.params;

  var user = JSON.parse(localStorage.getItem("xcuserv2"));

  const perfil = user ? +user.perfil : -1;

  if (perfil === 2) history.push("/auth/start");

  const locale = useSelector((state) => state.setting.locale);

  useEffect(() => {
    dispatch(datosActions.getDatosGrafico(id));
    dispatch(datosActions.getResultadosClavesPeriodoCentro({ id }));
    dispatch(
      datosActions.getResultadosClavesPeriodoCentroHistorico({ id })
    );
    dispatch(datosActions.getCentroFull({ id }));
  }, []);

  useEffect(() => {
    if (datosGraficoData) setDatosGrafico(datosGraficoData);
  }, [datosGraficoData]);

  const onChartCompleted = useCallback((chart64Image) => {
    firstChart.current = chart64Image;
    setCompletedChars(++completedChars);
  }, []);

  const onChartCompletedRep = useCallback((chart64Image) => {
    secondChart.current = chart64Image;
    setCompletedChars(++completedChars);
  }, []);

  const onCompletedWheel = useCallback((chart64Image) => {
    thirdChart.current = chart64Image;
    setCompletedChars(++completedChars);
  }, []);

  if(initialLocal !== locale) {
    window.location.reload();

    return <div className="fullPage bg-white"></div>;
  } 

  return (
    <div className="fullPage bg-white">
      <NavBarAuth />

      <div style={{height: 40, textAlign: "center", margin: 20}}>
        { completedChars === 3 &&
          datosCentro &&
          resultadosPreguntaCentroClave && (
            <PDFDownloadLink
              className="btn btnDownload btnDownloadInforme descargarInforme"
              document={MyDocument(
                datosCentro,
                _.groupBy(resultadosPreguntaCentroClave, "clavenombre"),
                resultadosCentroClave,
                thirdChart.current,
                firstChart.current,
                secondChart.current,
                datosGraficoData,
                locale
              )}
              fileName="informeXcelence.pdf"
            >
              {({loading}) =>
                loading 
                  ? getRawMessage("loading")
                  : getRawMessage("p_results.download_report")
              }
            </PDFDownloadLink>
          )}
      </div>
      <div>
        { completedChars === 3 &&
          datosCentro &&
          resultadosPreguntaCentroClave && (
            <BlobProvider
              document={MyDocument(
                datosCentro,
                _.groupBy(resultadosPreguntaCentroClave, "clavenombre"),
                resultadosCentroClave,
                thirdChart.current,
                firstChart.current,
                secondChart.current,
                datosGraficoData,
                locale
              )}
            >
              {({ url, loading }) =>
                loading ? (
                  <div
                    style={{
                      marginLeft: "200",
                      marginTop: "200",
                    }}
                  >
                    <span style={{ fontSize: 30 }}>
                      <FontAwesomeIcon icon={faSpinner} spin />{" "}
                      <Language langKey="loading_report" />
                    </span>
                  </div>
                ) : (
                  <iframe src={url} style={{ width: "100%", height: "100%" }} />
                )
              }
            </BlobProvider>
          )}
      </div>
      {datosGrafico.length > 0 && (
        <BarChart
          projectKey={"abandono"}
          onComplete={onChartCompleted}
          config={datosGrafico}
          abandono={true}
        />
      )}
      {datosGrafico.length > 0 && (
        <BarChart
          projectKey={"repeticion"}
          onComplete={onChartCompletedRep}
          config={datosGrafico}
          abandono={false}
        />
      )}
      {resultadosCentroClave && (
        <div 
          id="mRosco"
          style={{
            width: '100%',
            maxWidth: 900,
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: -1,
            backgroundColor: '#fff',
            overflow: 'hidden',
          }}
        >
          <Polar
            dataRes={resultadosCentroClave}
            onMounted={onCompletedWheel}
          />
        </div>
      )}
    </div>
  );
}

export default Informe;
