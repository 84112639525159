import { TramRounded } from "@material-ui/icons";
import actionTypes from "constants/actionTypes";
const initialState = {
  claveList: null,
  ready: null,
  claveInfo: null,
  preguntasXClave: null,
  clavesContestadas: null,
  respuestasXPreguntas: null,
  respuestasCentro: null,
  preguntaCompleted: null,
  preguntasContestadasList: null,
  subPreguntas: null,
  subpreguntasRespuestas: null,
  respuestasNivelCentro: null,
  resultadosPreguntasCentro: null,
  showOpcional: null,
  respuestasNoObligatoriasClave: null,
  cambiaPregunta:0,
  cambioPreguntaDisponible: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLAVES_DATA:
      return {
        ...state,
        claveList: action.payload,
      };
    case actionTypes.SET_CLAVE_INFO:
      return {
        ...state,
        claveInfo: action.payload[0],
      };
    case actionTypes.SET_PREGUNTAS_CLAVE:
      return {
        ...state,
        preguntasXClave: action.payload,
      };
    case actionTypes.SET_RESPUESTAS_BY_PREGUNTA:
      return {
        ...state,
        respuestasXPreguntas: action.payload,
      };

    case actionTypes.CLAVES_ERROR:
      return {
        ...state,
        claveListError: action.payload,
      };
    case actionTypes.RESTART_PREGUNTAS_CLAVES:
      return {
        ...state,
        claveInfo: null,
        preguntasXClave: null,
        respuestasCentro: null,
        preguntaCompleted: null,
        subPreguntas: null,
        subpreguntasRespuestas: null,
      };
    case actionTypes.SET_RESPUESTAS_PREGUNTAS_CENTRO:
      return {
        ...state,
        respuestasCentro: action.payload,
      };
    case actionTypes.SET_PREGUNTAS_CONTESTADAS_LIST_CLAVE:
      return {
        ...state,
        preguntasContestadasList: action.payload,
      };
    case actionTypes.SET_CLAVES_CONTESTADAS:
      return {
        ...state,
        clavesContestadas: action.payload,
      };
      case actionTypes.SET_ISCLAVE_CONTESTADAS:
        return {
          ...state,
          isClaveContestada: action.payload,
        };
    case actionTypes.CAMBIO_PREGUNTA:
      return {
        ...state,
        respuesrespuestasXPreguntastasCentro: null,
        respuestasCentro: null,
        preguntaCompleted: null,
        subPreguntas: null,
        subpreguntasRespuestas: null,
        showOpcional: null,
        respuestasXPreguntas: null,
        preguntasContestadasList: null,
        respuestasNivelCentro: null,
        resultadosPreguntasCentro: null
        
      };

    case actionTypes.SET_SUBPREGUNTAS_RESPUESTAS:
      return {
        ...state,
        subpreguntasRespuestas: action.payload,
      };
    case actionTypes.SET_PREGUNTA_COMPLETED:
      return {
        ...state,
        preguntaCompleted: action.payload,
      };
    case actionTypes.SET_SUBPREGUNTAS:
      return {
        ...state,
        subPreguntas: action.payload,
      };
      case actionTypes.SET_RESPUESTAS_NIVEL_CENTRO:
        return {
          ...state,
          respuestasNivelCentro: action.payload,
        };
        case actionTypes.SET_RESULTADOS_PREGUNTAS_CENTRO:
        return {
          ...state,
          resultadosPreguntasCentro: action.payload,
        };
        case actionTypes.SET_SHOW_OPCIONAL:
            return {
              ...state,
              cambiaPregunta: state.cambiaPregunta +1,
              showOpcional: action.payload,
            };
        case actionTypes.SET_NO_OBLIGATORIAS_CLAVE:
                return {
                  ...state,
                  respuestasNoObligatoriasClave: action.payload,
                };
        case actionTypes.PREGUNTA_GUARDADA:
          return {
            ...state,
            cambioPreguntaDisponible: true
          };     
        case actionTypes.CAMBIO_PREGUNTA_REALIZADO:
            return {
              ...state,
              cambioPreguntaDisponible: false
            };    
              
    default:
      return state;
  }
};
